import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { EstadoPedidoService } from './../../../../common/services/estado-pedido.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import * as moment from 'moment'
import { OverlayPanel } from 'primeng/overlaypanel'
import { EstadoDocumento } from 'src/app/model/EstadoDocumento'
import { LocalService } from 'src/app/services/local.service'
import { TipoEntregaService } from 'src/app/services/tipo-entrega.service'
import { Descriptivo } from './../../../../common/model/Descriptivo'
import { FiltroPedido } from './../../../../common/model/FiltroPedido'
import { LoadingService } from './../../../../common/services/loading-data-service.service'
import { TranslateService } from './../../../../common/services/translate.service'
import { CentroCostoService } from './../../../../services/centro-costo.service'
import { PedidoService } from './../../../../services/pedido.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'
import { TotalesPedido } from './TotalesPedido'
import { Parametrico } from 'src/app/common/model/Parametrico';

@Component({
    selector: 'totales-pedido',
    templateUrl: 'totales-pedido.component.html',
    styleUrls: ['totales-pedido.component.less', './../dashboard-element-common.component.less'],
})
export class TotalesPedidoComponent implements OnInit, IDashboardElement {
    public opcionesTexts: string[] = ['ULTIMAS_8HS', 'ULTIMAS_16HS', 'HOY', 'ULTIMOS_7_DÍAS', 'ESTE_MES', 'RANGO_ESPECÍFICO']
    public opciones: Descriptivo[] = [
        new Descriptivo('1', 'ULTIMAS_8HS'),
        new Descriptivo('2', 'ULTIMAS_16HS'),
        new Descriptivo('3', 'HOY'),
        new Descriptivo('4', 'ULTIMOS_7_DÍAS'),
        new Descriptivo('5', 'ESTE_MES'),
        new Descriptivo('RE', 'RANGO_ESPECÍFICO'),
    ]
    public refresh() {
        this.filtro.forceUpdate()
    }
    private title: string
    public filtro: FiltroPedido = new FiltroPedido(null, 0, 100, 'id', 1, false)
    public now: Date = new Date()
    public textoPeriodo: string
    public opcionesEstados: EstadoDocumento[] = []

    public customLoading: LoadingService = new LoadingService()
    @ViewChild('op', { static: true }) op: OverlayPanel
    public static TIPO = 'totales-pedido'
    public static defaultConfig: DashboardConfiguration = {
        minItemCols: 3,
        minItemRows: 3,
        fixedHeader: true,
        x: null,
        y: null,
        rows: 3,
        cols: 3,
        tipo: TotalesPedidoComponent.TIPO,
        resizeEnabled: false,
        refreshable: true,
        params: {},
    }

    private _config: DashboardConfiguration
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        let startup: Promise<any>[] = []
        if (this._config.params['local']) {
            startup.push(
                this.localService.getById(this._config.params['local'], this.customLoading).then((l) => {
                    this.filtro.locales = [l]
                })
            )
        } else {
            this.filtro.local = null
            this.filtro.locales = []
        }

        if (this._config.params['estados']?.length) {
            this.estados = this._config.params['estados'].map(e => Descriptivo.fromData(e))
        } else {
            this.estados = [new Descriptivo("CE","Cerrado"), new Descriptivo("F", "Finalizado")]
        }
        if (this._config.params['centro']) {
            startup.push(
                this.centroCostoService.getById(this._config.params['centro'], this.customLoading).then((c) => {
                    this.filtro.centros = [c]
                })
            )
        } else {
            this.filtro.centros = []
        }
        if (this._config.params['periodo']) {
            this.periodoSeleccionado = this.opciones.filter((op) => op.codigo == this._config.params['periodo'])[0]
            this.seleccionarPeriodo(this.periodoSeleccionado)
        }
        if (this._config.params['fechaDesde']) {
            this.filtro.fechaDesde = this._config.params['fechaDesde']
        }
        if (this._config.params['fechaHasta']) {
            this.filtro.fechaHasta = this._config.params['fechaHasta']
        }
        Promise.all(startup).then((r) => {
            this.updateTitle()
            this.buscar()
        })
    }
    public updateTitle() {
        this.title = this.translateService.get('TOTALES')
        if (this.filtro.centro) {
            this.title += ' - ' + this.filtro.centro.descripcion
        }
        if (this.filtro.local) {
            this.title += ' - ' + this.filtro.local.descripcion
        }
    }

    public periodoSeleccionado: Descriptivo
    public totales: TotalesPedido
    public loadingService: LoadingService = new LoadingService()
    constructor(
        public tipoEntregaService: TipoEntregaService,
        private translateService: TranslateService,
        public centroCostoService: CentroCostoService,
        private pedidoService: PedidoService,
        public localService: LocalService,
        private estadoPedidoService: EstadoPedidoService,
        private messageService: MessagesService
    ) {}
    onChange = (conf: DashboardConfiguration) => {}
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }

    public get centro(): Descriptivo {
        return this.filtro.centro
    }
    public set centro(v: Descriptivo) {
        this.filtro.centro = v
        if (v?.id) {
            this._config.params['centro'] = v.id
            this.onChange(this.config)
            this.updateTitle()
        }
    }

    public get fechaHasta(): Date {
        return this.filtro.fechaHasta
    }
    public set fechaHasta(v: Date) {
        this.filtro.fechaHasta = v
        this.config.params['fechaHasta'] = v
        this.onChange(this.config)
    }

    public get fechaDesde(): Date {
        return this.filtro.fechaDesde
    }
    public set fechaDesde(v: Date) {
        this.filtro.fechaDesde = v
        this.config.params['fechaDesde'] = v
        this.onChange(this.config)
    }

    public get local(): Descriptivo {
        return this.filtro.local
    }
    public set local(v: Descriptivo) {
        this.filtro.local = v
        if (v?.id) {
            this._config.params['local'] = v.id
            this.onChange(this.config)
            this.updateTitle()
        }
    }

    
    public get estados() : Descriptivo[] {
        return this.filtro.estados;
    }
    public set estados(v : Descriptivo[]) {
        this.filtro.estados = v;
        this._config.params['estados'] = v
        this.onChange(this.config)
    }
    

    ngOnInit() {
        this.tipoEntregaService.data.subscribe((d) => {
            this.opcionesTexts.forEach((op, i) => {
                this.opciones[i].descripcion = this.translateService.get(op)
            })
            if (this.periodoSeleccionado) {
                this.periodoSeleccionado = this.opciones.filter((op) => op.codigo == this.periodoSeleccionado.codigo)[0]
            }
            this.textoPeriodo = this.periodoSeleccionado ? this.periodoSeleccionado.descripcion : this.translateService.get('SELECCIONE_UN_PERIO_1')
            this.title = this.translateService.get('TOTALES')
        })

        this.estadoPedidoService.data.subscribe(e => {
            this.opcionesEstados = [new Descriptivo("ALL","Todos") ,...e]
        })
    }

    seleccionarPeriodo(d: Descriptivo) {
        if (!d) return
        switch (d.codigo) {
            case '1':
                this.filtro.setMultiple({
                    fechaDesde: moment().subtract(8, 'hours').startOf('hour').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })

                break
            case '2':
                this.filtro.setMultiple({
                    fechaDesde: moment().subtract(16, 'hours').startOf('hour').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })
                break
            case '3':
                this.filtro.setMultiple({
                    fechaDesde: moment().startOf('day').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })

                break
            case '4':
                this.filtro.setMultiple({
                    fechaDesde: moment().subtract(7, 'days').startOf('day').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })

                break
            case '5':
                this.filtro.setMultiple({
                    fechaDesde: moment().startOf('month').toDate(),
                    fechaHasta: moment().endOf('month').toDate(),
                })

                break
            default:
                break
        }
        if (d.codigo != 'RE') {
            this.config.params['fechaDesde'] = null
            this.config.params['fechaHasta'] = null
        }
        this.config.params['periodo'] = d.codigo
        this.textoPeriodo = this.opciones.filter((op) => op.codigo == d.codigo)[0].descripcion

        this.onChange(this.config)
    }
    public getTitle = () => {
        return this.title
    }
    public buscar() {
        if (!this.estados || !this.estados.length) return this.messageService.error("Seleccione al menos un estado")
        this.pedidoService.getTotalesGeneral(this.filtro, this.customLoading).then((t) => {
            this.totales = t
        })
        this.hideParams()
    }
}
