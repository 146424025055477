import * as moment from 'moment';
import { Filtro } from 'src/app/common/model/Filtro';
import { Descriptivo } from './../common/model/Descriptivo';
import { EstadoDocumento } from './EstadoDocumento';

export class FiltroComprobante extends Filtro {

    constructor(name: string, page?: number, size?: number, sortField: string = 'fecha', order: number = -1, guardable: boolean = true, defaults?:any) {
        super(name, {
            cliente: "Cliente",
            fechaDesde: "Desde",
            fechaHasta: "Hasta",
            estados: "Estados",
            soloImpagas: "Solo con saldo",
            local: "Local",
            tiposComprobantes: "T. Comprobantes"
        }, page, size, sortField, order, guardable);
        defaults && Object.assign(this,defaults)
    }

    private _cliente: Descriptivo;
    private _fechaDesde: Date = moment().subtract(10, 'days').startOf('day').toDate();
    private _fechaHasta: Date;
    private _estados: EstadoDocumento[] = [];
    private _soloImpagas: boolean;
    private _tiposComprobante: Descriptivo[] = [];
    private _ocultarApp: boolean = false;
    public get ocultarApp(): boolean {
        return this._ocultarApp;
    }
    public set ocultarApp(v: boolean) {
        if (v != this._ocultarApp) {
            this._ocultarApp = v;
            this.update();
        }
    }
    protected _locales: Descriptivo[] = [];
    public get locales(): Descriptivo[] {
        return this._locales;
    }
    public set locales(v: Descriptivo[]) {

        this._locales = v;
        this.update();
    }
    public get local(): Descriptivo {
        return this._locales ? this.locales[0] : null;
    }
    public set local(v: Descriptivo) {
        this.locales = v ? [v] : [];

    }
    public get tipoComprobante(): Descriptivo {
        return this.tiposComprobante ? this.tiposComprobante[0] : null;
    }
    public set tipoComprobante(v: Descriptivo) {

        this.tiposComprobante = v ? [v] : [];
        this.update();
    }

    public get tiposComprobante(): Descriptivo[] {
        return this._tiposComprobante;
    }
    public set tiposComprobante(v: Descriptivo[]) {
        this._tiposComprobante = v;
        this.update();
    }
    public get soloImpagas(): boolean {
        return this._soloImpagas;
    }
    public set soloImpagas(v: boolean) {
        if (v != this._soloImpagas) {
            this._soloImpagas = v;
            this.update();
        }
    }
    public get estados(): EstadoDocumento[] {
        return this._estados;
    }
    public set estados(v: EstadoDocumento[]) {
        if (v != this._estados) {
            this._estados = v;
            this.update();
        }
    }

    public tieneEstado(estado: EstadoDocumento) {
        return this.estados.some(e => e.id == estado.id);
    }
    public toggleEstado(estado: EstadoDocumento) {
        if (this.tieneEstado(estado)) {
            this.estados = this.estados.filter(e => e.id != estado.id);
        } else {
            this.estados.push(estado);
        }
        this.update(true);
    }

    public toggleTodos(estados:EstadoDocumento[]){
        this.estados = estados;
    }
    public get cliente(): Descriptivo {
        return this._cliente;
    }
    public set cliente(v: Descriptivo) {
        this._cliente = v;
        this.update();
    }

    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {
        this._fechaDesde = v;
        this.update();
    }

    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {
        this._fechaHasta = moment(v).endOf('day').toDate();
        this.update();
    }

    public clean() {

        let cleanVal = {
            searchStr: "",
            cliente: null,
            fechaDesde: moment().startOf('month').toDate(),
            fechaHasta: null,
            estados: [],
            locales: [],
            tiposComprobante: [],
            soloImpagas: false,
            ocultarApp: false,
        }

        this.setMultiple(cleanVal, false);
        super.clean(true)
    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._searchStr = v.searchStr;
        this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null;
        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null;
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null;
        this._estados = v.estados ? v.estados.map(v => EstadoDocumento.fromData(v)) : [];
        this._soloImpagas = v.soloImpagas;
        this._tiposComprobante = v.tiposComprobante ? v.tiposComprobante.map(t => Descriptivo.fromData(t)) : [];
        this._locales = v.locales ? v.locales.map(l => Descriptivo.fromData(l)) : []
        this._ocultarApp = v.ocultarApp;
        this.update();
    }

    public valid(): boolean {
        return this.fechaDesde != undefined;
    }
}