import { TipoComprobante } from './TipoComprobante';
import { Parametrico } from './../common/model/Parametrico';
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { Cobro } from 'src/app/model/Cobro'
import { IConCobros } from '../common/components/abm-cobros/IConCobros'
import { Cobranza } from './Cobranza'
import { ComprobanteResumen } from './Comprobante'
import { EstadoDocumento } from './EstadoDocumento'
import { GastoPedido } from './GastoPedido'
import { IPedido } from './IPedido'
import { ItemPedido } from './ItemPedido'
import { Moneda } from './Moneda'
import { TipoEntrega } from './TipoEntrega'
import { Ubicacion } from './Ubicacion'
import { Vendedor } from './Vendedor'
import { CanalVenta } from './CanalVenta'

export class Pedido implements IConCobros {
    public esPager: boolean = false
    constructor(
        public id?: number,
        public cliente?: Descriptivo,
        public centro?: Descriptivo,
        public local?: Descriptivo,
        public vendedor?: Descriptivo,
        public fecha: Date = new Date(),
        public entrega: Ubicacion = new Ubicacion(),
        public items: ItemPedido[] = [],
        public gastos: GastoPedido[] = [],
        public descripcionBonificacion?: string,
        public estado?: Descriptivo,
        public bonificacion: number = 0,
        public listaPrecio?: Descriptivo,
        public tipoEntrega?: TipoEntrega,
        public tipoComprobante?: TipoComprobante,
        public cobros: Cobro[] = [],
        public comprobante?: ComprobanteResumen,
        public saldo: number = 0,
        public entregarPedido?: boolean,
        public moneda?: Moneda,
        public esElaborado?: boolean,
        public mesa?: Descriptivo,
        public observaciones?: string,
        public emailContacto?: string,
        public nombreContacto?: string,
        public telefonoContacto?: string,
        public motivoCancelacion?: string,
        public comensales?: number,
        public canalVenta?: Parametrico,
        public numeroPedido?: string,

    ) {}
    public get hayItemsSinComanda() {
        return this.items.some((i) => !i.comanda)
    }

    public static estadoEditable(estado: EstadoDocumento) {
        return estado?.codigo != 'F' && estado?.codigo != 'CE' && estado?.codigo != 'C' && estado?.codigo != 'AN'
    }
    public cobranzas: Cobranza[] = []
    public static fromData(data: any): Pedido {
        if (!data) return null
        const o: Pedido = new Pedido(
            data.id,
            Descriptivo.fromData(data.cliente),
            Descriptivo.fromData(data.centro),
            Descriptivo.fromData(data.local),
            Vendedor.fromData(data.vendedor),
            data.fecha ? new Date(data.fecha) : null,
            data.entrega ? Ubicacion.fromData(data.entrega) : new Ubicacion(),
            data.items ? data.items.map((c) => ItemPedido.fromData(c)) : [],
            data.gastos ? data.gastos.map((c) => GastoPedido.fromData(c)) : [],
            data.descripcionBonificacion,
            data.estado,
            data.bonificacion,
            Descriptivo.fromData(data.listaPrecio),
            TipoEntrega.fromData(data.tipoEntrega),
        )
        o.comprobante = ComprobanteResumen.fromData(data.comprobante)
        o.saldo = data.saldo
        o.moneda = Moneda.fromData(data.moneda)
        o.cobros = data.cobros ? data.cobros.map((c) => Cobro.fromData(c)) : []
        o.esElaborado = data.esElaborado
        o.mesa = Descriptivo.fromData(data.mesa)
        o.observaciones = data.observaciones
        o.emailContacto = data.emailContacto
        o.nombreContacto = data.nombreContacto
        o.telefonoContacto = data.telefonoContacto
        o.motivoCancelacion = data.motivoCancelacion
        o.comensales = data.comensales
        o.canalVenta = Parametrico.fromData(data.canalVenta)
        o.numeroPedido = data.numeroPedido;

        return o
    }

    public get valores(): Cobro[] {
        return this.cobros
    }
    public set valores(v: Cobro[]) {
        this.cobros = v
    }

    public get subtotal() {
        return Math.round(this.items.reduce((a, b) => (a += b.subtotal), 0) * 100) / 100
    }
    public get descuentos() {
        return Math.round((this.items.reduce((a, b) => (a += b.descuentoValor), 0) + this.bonificacion) * 100) / 100
    }
    public get impuestos() {
        return Math.round(this.items.reduce((a, b) => (a += b.impuestos), 0) * 100) / 100
    }

    public getSaldo() {
        return this.total - this.totalCobrado
    }
    public get total() {
        return this.subtotal + this.impuestos - this.descuentos + this.totalGastos
    }
    public get totalGastos() {
        return this.gastos.reduce((a, b) => (a += b.importe), 0)
    }

    public agregarItem(item) {
        const indexItem = this.items.indexOf(item)

        if (indexItem < 0) {
            const indexItem = this.items.findIndex((myItem) => {
                let editable: boolean = !myItem.comanda?.estado || EstadoDocumento.esEditable(myItem.comanda.estado)
                return myItem.producto.codigo == item.producto.codigo && !myItem.observaciones && editable
            })

            if (indexItem >= 0) {
                this.items[indexItem].cantidad++
            } else {
                this.items.push(item)
            }
        } else {
            this.items[indexItem] = item
        }
    }
    public quitarItemPedido(item) {
        this.items = this.items.filter((i) => i != item)
    }
    public newItem() {
        return new ItemPedido()
    }

    public agregarGasto(item) {
        if (this.gastos.indexOf(item) < 0) this.gastos.push(item)
    }
    public quitarGasto(item) {
        this.gastos = this.gastos.filter((i) => i != item)
    }
    public newGasto() {
        return new GastoPedido()
    }

    public agregarCobro(c: Cobro) {
        if (this.cobros.indexOf(c) < 0) this.cobros.push(c)
    }

    public quitarCobro(c: Cobro) {
        this.cobros = this.cobros.filter((cc) => cc != c)
    }

    public get totalCobrado() {
        return this.cobros ? Math.round(this.cobros.reduce((a, b) => (a += b.monto), 0) * 100) / 100 : 0
    }

    public reemplazarCobro(item: Cobro, item2: Cobro) {
        if (!item && this.cobros.indexOf(item2) < 0) {
            this.cobros.push(item2)
        } else {
            var i = this.cobros.indexOf(item)
            if (i >= 0 && item2) {
                this.cobros[i] = item2
            }
        }
    }

    public get codigo() {
        return this.id
    }
    public get descripcion() {
        return this.cliente?.descripcion
    }

    public getItemsAgrupados() {
        return Object.values(
            this.items.reduce(function (groups, item) {
                var val = item.producto.codigo + '-' + item.precioUnitario
                groups[val] =
                    groups[val] ||
                    Object.assign({}, item, {
                        total: 0,
                        cantidad: 0,
                    })
                groups[val].total += item.total
                groups[val].cantidad += item.cantidad
                return groups
            }, {})
        )
    }

    public get esNuevo() {
        return this.estado?.codigo == 'N'
    }
}

export class PedidoResumen implements IPedido {
    public direccion: string
    public esPager: boolean = false
    public get descripcion() {
        return this.cliente ? this.cliente.descripcion : null
    }
    constructor(
        public id?: number,
        public cliente?: Descriptivo,
        public centro?: Descriptivo,
        public local?: Descriptivo,
        public vendedor?: Descriptivo,
        public fecha: Date = new Date(),
        public entrega: Ubicacion = new Ubicacion(),
        public estado?: EstadoDocumento,
        public comprobante?: ComprobanteResumen,
        public total: number = 0,
        public saldo: number = 0,
        public tipoEntrega?: TipoEntrega,
        public mesa?: string,
        public codigoVerificacion?: string,
        public numeroPedido?: string,
        public observaciones?: string,
        public emailContacto?: string,
        public nombreContacto?: string,
        public telefonoContacto?: string,
        public motivoCancelacion: string = '',
        public comensales?: number,
        public canalVenta?: Parametrico,
        public conError?: string
    ) {
        if (this.tipoEntrega?.codigo == 'D') {
            this.direccion = this.entrega?.direccion
        } else {
            this.direccion = this.tipoEntrega?.codigo == 'P' ? 'Pick up' : 'Mesa ' + this.mesa
        }
    }
    public static fromData(data: any): PedidoResumen {
        if (!data) return null
        const o: PedidoResumen = new PedidoResumen(
            data.id,
            Descriptivo.fromData(data.cliente),
            Descriptivo.fromData(data.centro),
            Descriptivo.fromData(data.local),
            Descriptivo.fromData(data.vendedor),
            data.fecha ? new Date(data.fecha) : null,
            data.entrega ? Ubicacion.fromData(data.entrega) : new Ubicacion(),
            EstadoDocumento.fromData(data.estado),
            ComprobanteResumen.fromData(data.comprobante),
            data.total,
            data.saldo,
            TipoEntrega.fromData(data.tipoEntrega),
            data.mesa,
            data.codigoVerificacion,
            data.numeroPedido,
            data.observaciones,
            data.emailContacto,
            data.nombreContacto,
            data.telefonoContacto,
            data.motivoCancelacion,
            data.comensales,
            Parametrico.fromData(data.canalVenta),
            data.conError
        )

        return o
    }



    public get esNuevo() {
        return this.estado?.codigo == 'N'
    }
}
