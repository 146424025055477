import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { OverlayPanel } from 'primeng/overlaypanel'
import { map } from 'rxjs/operators'
import { Descriptivo } from './../../../../common/model/Descriptivo'
import { LoadingService } from './../../../../common/services/loading-data-service.service'
import { MenuConfigService } from './../../../../common/services/menu-config.service'
import { TranslateService } from './../../../../common/services/translate.service'
import { ListaPrecios } from './../../../../lista-precios/model/ListaPrecios'
import { ListaProducto } from './../../../../lista-precios/model/ListaProducto'
import { ListaPreciosService } from './../../../../lista-precios/services/lista-precios.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'app-buscador-precio',
    templateUrl: './buscador-precio.component.html',
    styleUrls: ['./../dashboard-element-common.component.less', './buscador-precio.component.less'],
})
export class BuscadorPrecioComponent implements OnInit, IDashboardElement {
    public static TIPO = 'buscador-precio'
    public refresh() {
        return
    }
    private _config: DashboardConfiguration
    public data: {
        idListado?: number
    }
    public buscarControl: FormControl = new FormControl()
    public customLoading: LoadingService = new LoadingService()
    public static defaultConfig: DashboardConfiguration = {
        fixedHeader: true,
        x: null,
        y: null,
        rows: 3,
        cols: 2,
        tipo: 'buscador-precio',
        params: {},
    }
    public seleccionarListado(listado: Descriptivo) {
        if (listado) {
            this.aplicarSeleccion(listado)
            this.lpService.getById(listado.id, this.customLoading).then((l) => {
                this.listadoSel = l
                this.productosPorFecha = this.listadoSel.getProductosFecha(new Date())
            })
        }
    }
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        if (!v) {
            this.data = {}
            return
        }
        this.data = {
            idListado: v.params['idListado'],
        }
        this.seleccionarListado(new Descriptivo(null, null, this.data.idListado))
    }
    constructor(
        private translateService: TranslateService,
        public lpService: ListaPreciosService,
        public menuConfService: MenuConfigService,
        private router: Router
    ) {}

    public listado: Descriptivo
    public listadoSel: ListaPrecios
    @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel
    public goTo(ruta: string) {
        this.router.navigate([ruta])
    }
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}
    public opciones: MenuItem[] = []
    public productosFiltrados: ListaProducto[] = []
    public productosPorFecha: ListaProducto[] = []
    ngOnInit() {
        this.buscarControl.valueChanges
            .pipe(
                map((v) => {
                    if (typeof v === 'string') {
                        return v.toUpperCase()
                    } else {
                        return ''
                    }
                })
            )
            .subscribe((d) => {
                this._filter(d)
            })
    }
    private _filter(d?: string) {
        this.productosFiltrados = d ? this.productosPorFecha.filter((p) => !d || (p.producto && p.producto.descripcion.toUpperCase().includes(d))) : []
    }
    public irAProducto(event, productoId) {
        if (productoId) {
            event.stopPropagation()
            this.router.navigate(['producto/vista'], { queryParams: { id: productoId } })
        }
    }
    public aplicarSeleccion(item) {
        this.op.hide()
        this.data = {
            idListado: item.id,
        }
        this.config.params = this.data
        this.onChange(this.config)
    }

    public getTitle = () => {
        return this.listadoSel ? this.translateService.get(this.listadoSel.descripcion) : 'Sin definir'
    }
}
