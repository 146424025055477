import * as moment from 'moment';
import { Filtro } from './Filtro';
export class FiltroFecha extends Filtro {
    static getByFechas(fecha: Date, fechaHasta: Date): Filtro {
        let f = new FiltroFecha(null, null, 0, 100, "fechaNota", 1, false);
        f._fechaDesde = fecha ? moment(fecha).startOf('date').toDate() : moment().startOf('date').toDate();
        f._fechaHasta = fechaHasta ? moment(fechaHasta).endOf('date').toDate() : moment().endOf('date').toDate();
        return f;
    }

    constructor(name: string,
        filterMap = {
            fechaDesde: "Desde",
            fechaHasta: "Hasta",
        }, page = 0, size = 100, sortField = "id", order = 1, guardable = true) {
        super(name, filterMap, page, size, sortField, order, guardable);

    }


    private _fechaDesde: Date;
    private _fechaHasta: Date;



    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {

        this._fechaDesde = moment(v).startOf('month').toDate();
        this.update();
    }

    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {
        this._fechaHasta = moment(v).endOf('month').toDate();
        this.update();
    }

    public clean() {
        let cleanVal = {
            searchStr: "",
            fechaDesde: moment().startOf('month').toDate(),
            fechaHasta: null,
            sortOrder: 1
        }
        this.patchValue(cleanVal);
    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._searchStr = v.searchStr;

        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null;
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null;
        this.update();
    }

    public valid(): boolean {
        return this.fechaHasta == null || this.fechaDesde == null || this.fechaDesde < this.fechaHasta;
    }
}