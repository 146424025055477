

import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class Vendedor extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        		public nombre ?: string,
		public numeroDocumento ?: string,
		public email ?: string,
		public telefono ?: string,
		public comentario ?: string,
		public formaPagoDefault ?: Descriptivo,
		public comision ?: number,


    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): Vendedor {
        if (!data) return null;
        const o: Vendedor = new Vendedor(
            data.id,
            data.codigo,
            data.descripcion,

					 data.nombre, 
					 data.numeroDocumento, 
					 data.email, 
					 data.telefono, 
					 data.comentario, 
					 Descriptivo.fromData(data.formaPagoDefault), 
					 data.comision, 
        );

        return o;

    }

}