import { TotalesCajaComponent } from './totales-caja/totales-caja.component';
import { ResumenCajaAG } from './../../authguards/ResumenCajaAG';
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { ListadoMovimientoCajaComponent } from '../movimiento-caja/listado-movimiento-caja/listado-movimiento-caja.component'
import { AdministracionAuthGuard } from './../../authguards/AdministracionAuthGuard'
import { GestorCajaComponent } from './gestor-caja/gestor-caja.component'
import { ListadoCajaComponent } from './listado-caja/listado-caja.component'
const routes: Routes = [
    { path: '', data: { parent: 'caja', animation: 'List' }, component: ListadoCajaComponent, canActivate: [AdministracionAuthGuard] },
    { path: 'edit', data: { parent: 'caja', animation: 'Item' }, component: GestorCajaComponent, canActivate: [AdministracionAuthGuard] },
    { path: 'vista', component: GestorCajaComponent, canActivate: [AdministracionAuthGuard], data: { parent: 'caja', vista: true, animation: 'Item' } },
    { path: 'nuevo', data: { parent: 'caja', animation: 'Item' }, component: GestorCajaComponent, canActivate: [AdministracionAuthGuard] },
    {
        path: 'movimiento-caja',
        data: { animation: 'List' },
        component: ListadoMovimientoCajaComponent,
        canActivate: [ResumenCajaAG],
    },
    {
        path: 'totales-caja',
        data: { animation: 'List' },
        component: TotalesCajaComponent,
        canActivate: [ResumenCajaAG],
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes), CommonComponentsModule],
    exports: [RouterModule],
})
export class CajaRoutingModule {}
