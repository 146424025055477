import { VentasAG } from './VentasAG';
import { Injectable } from '@angular/core';







@Injectable({
    providedIn: 'root'
})
export class PromocionesAG extends VentasAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_PROMOCIONES"]) };



}