import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard } from 'src/app/authguards/AdminAuthGuard';
import { AppMainComponent } from './../app.main.component';
import { EdicionPerfilAG } from './../authguards/EdicionPerfilAG';
import { EmpleadoAuthGuard } from './../authguards/EmpleadoAuthGuard';
import { UsuarioAG } from './../authguards/UsuarioAG';
import { HomeComponent } from './../pages/home/home.component';
import { GenericParametricoComponent } from './../pages/parametricos/generic-parametrico/generic-parametrico.component';
import { GestorParametricoComponent } from './../pages/parametricos/gestor-parametrico/gestor-parametrico.component';
import { ParametricosComponent } from './../pages/parametricos/parametricos/parametricos.component';
import { GestionUserComponent } from './../pages/user/gestion-user/gestion-user.component';
import { UserComponent } from './../pages/user/user.component';

const routes: Routes = [
    {
        path: '',
        component: AppMainComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent, canActivate: [EmpleadoAuthGuard], data: { animation: 'List' } },
            { path: 'usuario', component: UserComponent, canActivate: [UsuarioAG], data: { animation: 'List' } },
            { path: 'usuario/edit', component: GestionUserComponent, canActivate: [EdicionPerfilAG], data: { parent: 'usuario', animation: 'Item' } },
            { path: 'usuario/nuevo', component: GestionUserComponent, canActivate: [UsuarioAG], data: { parent: 'usuario', animation: 'Item' } },
            { path: 'usuario/vista', component: GestionUserComponent, canActivate: [UsuarioAG], data: { parent: 'usuario', vista: true, animation: 'Item' } },
            { path: 'parametros', component: GenericParametricoComponent, canActivate: [AdminAuthGuard], data: { animation: 'List' } },
            { path: 'parametros/edit', component: GestorParametricoComponent, canActivate: [AdminAuthGuard], data: { animation: 'Item' } },
            { path: 'parametros/nuevo', component: GestorParametricoComponent, canActivate: [AdminAuthGuard], data: { animation: 'Item' } },
            { path: 'parametricos', component: ParametricosComponent, canActivate: [AdminAuthGuard], data: { animation: 'List' } },
            // {
            //     path: 'emailtemplate',
            //     component: ListaEmailTemplatesComponent,
            //     canActivate: [AdminAuthGuard],
            //     data: { parent: 'emailtemplate', animation: 'List' },
            // },
            // {
            //     path: 'emailtemplate/edit',
            //     component: GestorEmailTemplateComponent,
            //     canActivate: [AdminAuthGuard],
            //     data: { parent: 'emailtemplate', animation: 'Item' },
            // },
            // {
            //     path: 'emailtemplate/nuevo',
            //     component: GestorEmailTemplateComponent,
            //     canActivate: [AdminAuthGuard],
            //     data: { parent: 'emailtemplate', animation: 'Item' },
            // },
            {
                path: 'emailtemplate',
                loadChildren: () => import('./../pages/parametricos/email-template/email-template.module').then((m) => m.EmailTemplateModule),
                data: { parent: 'emailtemplate' },
            },
            { path: 'producto', loadChildren: () => import('./../pages/producto/producto.module').then((m) => m.ProductoModule) },
            {
                path: 'centro-costo',
                loadChildren: () => import('./../pages/centro-costo/centro.module').then((m) => m.CentroCostoModule),
                data: { parent: 'centro-costo' },
            },
            {
                path: 'categoria-producto',
                loadChildren: () => import('./../pages/categoria-producto/categoria-producto.module').then((m) => m.CategoriaProductoModule),
                data: { parent: 'categoria-producto' },
            },
            {
                path: 'caja',
                loadChildren: () => import('./../pages/caja/caja.module').then((m) => m.CajaModule),
            },
            {
                path: 'tipo-comprobante',
                loadChildren: () => import('./../pages/tipo-comprobante/tipo-comprobante.module').then((m) => m.TipoComprobanteModule),
                data: { parent: 'tipo-comprobante' },
            },
            {
                path: 'menu',
                loadChildren: () => import('./../pages/menu/menu.module').then((m) => m.MenuModule),
                data: { parent: 'menu' },
            },
            {
                path: 'promocion',
                loadChildren: () => import('./../pages/promocion/promocion.module').then((m) => m.PromocionModule),
                data: { parent: 'promocion' },
            },
            {
                path: 'stock',
                loadChildren: () => import('./../pages/stock-insumo/stock-insumo.module').then((m) => m.StockInsumoModule),
                data: { parent: 'stock-insumo' },
            },

            {
                path: 'deposito',
                loadChildren: () => import('./../pages/deposito/deposito.module').then((m) => m.DepositoModule),
                data: { parent: 'deposito' },
            },
            {
                path: 'local',
                loadChildren: () => import('./../pages/local/local.module').then((m) => m.LocalModule),
                data: { parent: 'local' },
            },

            {
                path: 'categoria-insumo',
                loadChildren: () => import('./../pages/categoria-insumo/categoria-insumo.module').then((m) => m.CategoriaInsumoModule),
                data: { parent: 'categoria-insumo' },
            },
            {
                path: 'insumo',
                loadChildren: () => import('./../pages/insumo/insumo.module').then((m) => m.InsumoModule),
                data: { parent: 'insumo' },
            },
            {
                path: 'estado-documento',
                loadChildren: () => import('./../pages/estado-documento/estado-documento.module').then((m) => m.EstadoDocumentoModule),
                data: { parent: 'estado-documento' },
            },
            {
                path: 'estado-pedido',
                loadChildren: () => import('./../pages/estado-pedido/estado-pedido.module').then((m) => m.EstadoPedidoModule),
                data: { parent: 'estado-pedido' },
            },
            {
                path: 'pedido',
                loadChildren: () => import('./../pages/pedido/pedido.module').then((m) => m.PedidoModule),
                data: { parent: 'pedido' },
            },
            {
                path: 'comprobante',
                loadChildren: () => import('./../pages/comprobante/comprobante.module').then((m) => m.ComprobanteModule),
                data: { parent: 'comprobante' },
            },
            {
                path: 'cobranza',
                loadChildren: () => import('./../pages/cobranza/cobranza.module').then((m) => m.CobranzaModule),
                data: { parent: 'cobranza' },
            },
            {
                path: 'factura',
                loadChildren: () => import('./../pages/factura/factura.module').then((m) => m.FacturaModule),
                data: { parent: 'factura' },
            },
            {
                path: 'orden-pago',
                loadChildren: () => import('./../pages/orden-pago/orden-pago.module').then((m) => m.OrdenPagoModule),
                data: { parent: 'orden-pago' },
            },
            {
                path: 'proveedor',
                loadChildren: () => import('./../pages/proveedor/proveedor.module').then((m) => m.ProveedorModule),
                data: { parent: 'proveedor' },
            },
            {
                path: 'notificacion',
                loadChildren: () => import('./../pages/notificacion/notificacion.module').then((m) => m.NotificacionModule),
                data: { parent: 'notificacion' },
            },
            {
                path: 'comanda',
                loadChildren: () => import('./../pages/comanda/comanda.module').then((m) => m.ComandaModule),
                data: { parent: 'comanda' },
            },
            {
                path: 'tipo-entrega',
                loadChildren: () => import('./../pages/tipo-entrega/tipo-entrega.module').then((m) => m.TipoEntregaModule),
                data: { parent: 'tipo-entrega' },
            },

            {
                path: 'cliente',
                loadChildren: () => import('./../pages/cliente/cliente.module').then((m) => m.ClienteModule),
                data: { parent: 'cliente' },
            },
            {
                path: 'unidad',
                loadChildren: () => import('./../pages/unidad/unidad.module').then((m) => m.UnidadModule),
                data: { parent: 'unidad' },
            },
            //Producto

            {
                path: 'marca',
                loadChildren: () => import('./../pages/marca/marca.module').then((m) => m.MarcaModule),
                data: { parent: 'marca' },
            },
            {
                path: 'cuenta-contable',
                loadChildren: () => import('./../pages/cuenta-contable/cuenta-contable.module').then((m) => m.CuentaContableModule),
                data: { parent: 'cuenta-contable' },
            },
            {
                path: 'lista-precios',
                loadChildren: () => import('./../lista-precios/lista-precios.module').then((m) => m.ListaPreciosModule),
                data: { parent: 'lista-precios' },
            },
            {
                path: 'punto-de-venta',
                loadChildren: () => import('./../pages/punto-de-venta/punto-de-venta.module').then((m) => m.PuntoDeVentaModule),
                data: { parent: 'punto-de-venta' },
            },
            {
                path: 'forma-pago',
                loadChildren: () => import('./../pages/forma-pago/forma-pago.module').then((m) => m.FormaPagoModule),
                data: { parent: 'forma-pago' },
            },
            {
                path: 'traspaso-de-caja',
                loadChildren: () => import('./../pages/traspaso-de-caja/traspaso-de-caja.module').then((m) => m.TraspasoDeCajaModule),
                data: { parent: 'traspaso-de-caja' },
            },
            {
                path: 'billete',
                loadChildren: () => import('./../pages/billete/billete.module').then((m) => m.BilleteModule),
                data: { parent: 'billete' },
            },
            {
                path: 'canal-venta',
                loadChildren: () => import('./../pages/canal-venta/canal-venta.module').then((m) => m.CanalVentaModule),
                data: { parent: 'canal-venta' },
            },
            {
                path: 'cambio-estado-caja',
                loadChildren: () => import('./../pages/cambio-estado-caja/cambio-estado.module').then((m) => m.CambioEstadoCajaModule),
            },
            {
                path: 'cambio-saldo-caja',
                loadChildren: () => import('./../pages/cambio-saldo-caja/cambio-saldo-caja.module').then((m) => m.CambioSaldoCajaModule),
            },
            /*{ path: 'cambio-estado-caja', data: { animation: 'List' }, component: ListadoArqueoCajaComponent, canActivate: [AdministracionAuthGuard] },
            {
                path: 'cambio-estado-caja/vista',
                component: GestorArqueoCajaComponent,
                canActivate: [AdministracionAuthGuard],
                data: { vista: true, animation: 'Item' },
            },
            { path: 'cambio-saldo-caja', data: { animation: 'List' }, component: ListadoCambioSaldoCajaComponent, canActivate: [AdministracionAuthGuard] },

            {
                path: 'cambio-saldo-caja/vista',
                component: GestorCambioSaldoCajaComponent,
                canActivate: [AdministracionAuthGuard],
                data: { vista: true, animation: 'Item' },
            },*/
            {
                path: 'estado-reserva',
                loadChildren: () => import('./../pages/estado-reserva/estado-reserva.module').then((m) => m.EstadoReservaModule),
                data: { parent: 'estado-reserva' },
            },
            {
                path: 'reserva',
                loadChildren: () => import('./../pages/reserva/reserva.module').then((m) => m.ReservaModule),
                data: { parent: 'reserva' },
            },
            {
                path: 'sector',
                loadChildren: () => import('./../pages/sector/sector.module').then((m) => m.SectorModule),
                data: { parent: 'sector' },
            },
            {
                path: 'estado-mesa',
                loadChildren: () => import('./../pages/estado-mesa/estado-mesa.module').then((m) => m.EstadoMesaModule),
                data: { parent: 'estado-mesa' },
            },
            {
                path: 'mesa',
                loadChildren: () => import('./../pages/mesa/mesa.module').then((m) => m.MesaModule),
                data: { parent: 'mesa' },
            },
            {
                path: 'configuracion-mercado-pago',
                loadChildren: () =>
                    import('./../pages/configuracion-mercado-pago/configuracion-mercado-pago.module').then((m) => m.ConfiguracionMercadoPagoModule),
                data: { parent: 'configuracion-mercado-pago' },
            },

            {
                path: 'tipo-layout',
                loadChildren: () => import('./../pages/tipo-layout/tipo-layout.module').then((m) => m.TipoLayoutModule),
                data: { parent: 'tipo-layout' },
            },
            {
                path: 'pedido-token',
                loadChildren: () => import('./../pages/pedido-token/pedido-token.module').then((m) => m.PedidoTokenModule),
                data: { parent: 'pedido-token' },
            },
            {
                path: 'reportes',
                loadChildren: () => import('./../reportes/reportes.module').then((m) => m.ReportesModule),
                data: { parent: 'reportes' },
            },
            {
                path: 'nota',
                loadChildren: () => import('./../pages/nota/nota.module').then((m) => m.NotaModule),
                data: { parent: 'nota' },
            },

            {
                path: 'tipo-gasto-pedido',
                loadChildren: () => import('./../pages/tipo-gasto-pedido/tipo-gasto-pedido.module').then((m) => m.TipoGastoPedidoModule),
                data: { parent: 'tipo-gasto-pedido' },
            },

            {
                path: 'tipo-cuotas-tarjeta',
                loadChildren: () => import('./../pages/tipo-cuotas-tarjeta/tipo-cuotas-tarjeta.module').then((m) => m.TipoCuotasTarjetaModule),
                data: { parent: 'tipo-cuotas-tarjeta' },
            },

            {
                path: 'tipo-tarjeta-credito',
                loadChildren: () => import('./../pages/tipo-tarjeta-credito/tipo-tarjeta-credito.module').then((m) => m.TipoTarjetaCreditoModule),
                data: { parent: 'tipo-tarjeta-credito' },
            },

            {
                path: 'restriccion',
                loadChildren: () => import('./../pages/restriccion/restriccion.module').then((m) => m.RestriccionModule),
                data: { parent: 'restriccion' },
            },
        ],
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ImplementacionAppRoutingModule {}
