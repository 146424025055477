import { QrMenuComponent } from './components/qr-menu/qr-menu/qr-menu.component';
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { QRCodeModule } from 'angularx-qrcode'
import { ClipboardModule } from 'ngx-clipboard'
import { IconPickerModule } from 'ngx-icon-picker'
import { SelectorIdiomaComponent } from './../common-component/selector-idioma/selector-idioma.component'
import { AbmCobrosComponent } from './../common/components/abm-cobros/abm-cobros.component'
import { AbmHijoComponent } from './../common/components/abm-hijo/abm-hijo.component'
import { ColorPickerComponent } from './../common/components/color-picker/color-picker.component'
import { ContactosComponent } from './../common/components/contactos/contactos.component'
import { DisponibilidadComponent } from './../common/components/disponibilidad/disponibilidad.component'
import { IconPickerComponent } from './../common/components/icon-picker/icon-picker.component'
import { QrdisplayComponent } from './../common/components/qrdisplay/qrdisplay.component'
import { SelectorCheckboxComponent } from './../common/components/selector-checkbox/selector-checkbox.component'
import { SelectorDiaComponent } from './../common/components/selector-dia/selector-dia.component'
import { SelectorMultipleChipsComponent } from './../common/components/selector-multiple-chips/selector-multiple-chips.component'
import { SpanDineroEditableComponent } from './../common/components/span-dinero-editable/span-dinero-editable.component'
import { SpanListComponent } from './../common/components/span-list/span-list.component'
import { MaterialModule } from './../material.module'
import { GestorParametricoComponent } from './../pages/parametricos/gestor-parametrico/gestor-parametrico.component'
import { FillPipe } from './../pipes/fill.pipe'
import { HabilitadosPipe } from './../pipes/habilitados.pipe'
import { NotDescriptivoPipe } from './../pipes/not-descriptivo.pipe'
import { PrimeNgModule } from './../prime-ng.module'
import { CentroSelectorComponent } from './components/centro-selector/centro-selector.component'
import { DefaultToolbarComponent } from './components/default-toolbar/default-toolbar.component'
import { DescriptivoModule } from './components/descriptivo-material-selector/descriptivo.module'
import { FiltroCompoenent } from './components/filtro/filtro.component'
import { LayoutPickerComponent } from './components/layout-picker/layout-picker.component'
import { LoadingPanelComponent } from './components/loading-panel/loading-panel.component'
import { ModelGestorComponent } from './components/model-gestor/model-gestor.component'
import { ModelListComponent } from './components/model-list/model-list.component'
import { ParametricoSelectorComponent } from './components/parametrico-selector/parametrico-selector.component'
import { ProfileBadgeComponent } from './components/profile-badge/profile-badge.component'
import { CoreModule } from './core.module'
import { LocalSelectorComponent } from './local-selector/local-selector.component'
import { AccionesVisiblesPipe } from './pipes/accionesVisibles.pipe'
import { NoAppPipe } from './pipes/no-app.pipe'
import { CuitValidator } from './utils/CuitValidator'
import { ColorPickerModule } from 'ngx-color-picker';

const pipe = [FillPipe, NoAppPipe, HabilitadosPipe, AccionesVisiblesPipe, NotDescriptivoPipe]
const directives = [CuitValidator]
const components = [
    AbmCobrosComponent,
    AbmHijoComponent,
    ContactosComponent,
    ColorPickerComponent,
    DisponibilidadComponent,
    IconPickerComponent,
    QrdisplayComponent,
    SelectorCheckboxComponent,
    SelectorDiaComponent,
    SelectorMultipleChipsComponent,
    SpanDineroEditableComponent,
    SpanListComponent,
    DefaultToolbarComponent,
    FiltroCompoenent,
    LayoutPickerComponent,
    ModelGestorComponent,
    ModelListComponent,
    ParametricoSelectorComponent,
    ProfileBadgeComponent,
    LoadingPanelComponent,
    SelectorIdiomaComponent,
    GestorParametricoComponent,
    LocalSelectorComponent,
    CentroSelectorComponent,
    QrMenuComponent,
]
@NgModule({
    imports:[
        CoreModule, 
        ReactiveFormsModule,
        PrimeNgModule, 
        MaterialModule, 
        IconPickerModule, 
        QRCodeModule, 
        DescriptivoModule, 
        ColorPickerModule,
        ClipboardModule],

    providers: [],
    declarations: [...directives, ...pipe, ...components],
    exports: [...directives, ...pipe, ...components, CoreModule, DescriptivoModule, PrimeNgModule, MaterialModule],
})
export class CommonComponentsModule {}
