import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { BaseService } from '../common/services/base.service'
import { environment } from './../../environments/environment'
import { Novedad } from './../model/Novedad'
import { AuthService } from './auth.service'
@Injectable({
    providedIn: 'root',
})
export class NovedadService extends BaseService {
    marcarVista(novedad: Novedad): Promise<Novedad> {
        return this.http
            .post(this.getBaseURL('marcarVista'), novedad)
            .toPromise()
            .then((n) => {
                return Novedad.fromData(n)
            })
            .catch((e) => {
                console.debug(e)
                return Promise.reject(e)
            })
    }
    unregister(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        const obs = this.observers.filter((o) => {
            return o.tipo == tipo && o.idLocal == idLocal && o.idEntidad == idEntidad
        })[0]
        if (obs) {
            obs.observadores = obs.observadores.filter((i) => i.getKey() != suscriber.getKey())
        }
    }
    public baseName(): string {
        return 'novedades'
    }
    public initiated: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private _pushToken: string
    private unsuscribe() {
        return this.http
            .post(
                this.getApiURL() + 'novedades/desuscribir/all',
                this.observers.map((ob) => {
                    return {
                        tipo: ob.tipo,
                        idEntidad: ob.idEntidad,
                        destinatario: this._pushToken,
                        idLocal: ob.idLocal,
                    }
                })
            )
            .toPromise()
            .catch((e) => {})
    }
    private _currentLocal: number
    public get currentLocal(): number {
        return this._currentLocal
    }
    public set currentLocal(v: number) {
        if ((!this._currentLocal || this.currentLocal != v) && this.pushToken) {
            this.observers
                .filter((o) => !o.idEntidad)
                .forEach(async (o) => {
                    if (this.currentLocal) {
                        this.unsuscribe()
                    }
                    if (v) {
                        this.http
                            .post(this.getBaseURL('suscribir'), { tipo: o.tipo, destinatario: this.pushToken, idLocal: v })
                            .toPromise()
                            .catch((e) => {})
                    }
                })
        }
        this._currentLocal = v
    }

    public get pushToken(): string {
        return this._pushToken || localStorage.getItem(environment.tokenKey + "_push")
    }
    public set pushToken(v: string) {
        this._pushToken = v
        localStorage.setItem(environment.tokenKey + '_push', v)
    }
    private observers: ObseKey[] = []
    private static updateNovedades: BehaviorSubject<Novedad[]> = new BehaviorSubject([])

    constructor(private authService: AuthService) {
        super()
        NovedadService.updateNovedades.subscribe(this.notify)
        this.authService.onLogin.subscribe(l=>{
            this._pushToken = localStorage.getItem(environment.tokenKey + "_push")
        })
        this.authService.onLogout.subscribe((l) => {
            this.unsuscribe();
           // localStorage.removeItem(environment.tokenKey + "_push");
        })
    }
    public updateToken(token: string) {
        const prevToken = this._pushToken || localStorage.getItem(environment.tokenKey + '_push')
        if (prevToken != token) {
            this.pushToken = token
            if (prevToken) {
                this.http.post(this.getBaseURL('updateToken'), { anterior: prevToken, nuevo: token }).toPromise()
            } else {
                this.observers.forEach((o) => {
                    o.observadores.forEach((obs) => {
                        this.registrarObservador(o.tipo, obs, o.idEntidad)
                    })
                })
            }
        }
    }
    public nuevoPush(msg: any) {
        if (msg.data) {
            NovedadService.updateNovedades.next([Novedad.fromData(msg.data)])
        }
    }
    private async _register(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number, pushObserver: boolean = false) {
        let subs = this.observers.filter((o) => o.tipo == tipo && idEntidad == o.idEntidad && idLocal == o.idLocal)[0]
        if (!subs || subs.observadores.indexOf(suscriber) < 0) {
            if (!subs) {
                subs = new ObseKey({ tipo: tipo, idEntidad: idEntidad, idLocal: idLocal })
                this.observers.push(subs)
            }
            subs.observadores.push(suscriber)
            if (pushObserver) {
                    if (this.pushToken) {
                        this.http
                            .post(this.getBaseURL('suscribir'), { tipo: tipo, idEntidad: idEntidad, destinatario: this.pushToken, idLocal: this.currentLocal })
                            .toPromise()
                            .catch((e) => {})
                    }
                
            }
        }
    }
    public registrarObservador(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        this._register(tipo, suscriber, idEntidad, idLocal)
    }
    public registrarPushObserver(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        this._register(tipo, suscriber, idEntidad, idLocal, true)
    }
    private checking: Promise<any>
    private lastUpdate: Date
    public async check() {
        if (!this.lastUpdate) {
            const lStr = localStorage.getItem(environment.tokenKey + 'lastcheck')
            this.lastUpdate = lStr ? moment(moment(lStr, 'YYYYMMDDHHmm')).toDate() : new Date()
        }
        if (!this.checking && (!this.lastUpdate || moment(new Date()).diff(this.lastUpdate, 'seconds') > 20)) {
            this.checking = this.http
                .get(this.getBaseURL('all') + (this.lastUpdate ? '?lastCheck=' + moment(this.lastUpdate).format('YYYYMMDDHHmm') : ''))
                .toPromise()
                .then((n: Novedad[]) => {
                    this.lastUpdate = new Date()
                    localStorage.setItem(environment.tokenKey + 'lastcheck', moment(this.lastUpdate).format('YYYYMMDDHHmm'))
                    this.notify(n)
                    this.checking = null
                })
                .catch((e) => {
                    this.checking = null
                })
        }
    }
    private notify = (r: Novedad[]) => {
        r.forEach((n) => {
            var o = this.observers.filter(
                (o) => o.tipo == n.tipo && (!o.idLocal || !n.local || o.idLocal == n.local) && (o.idEntidad == n.idEntidad || !o.idEntidad)
            )
            if (o) {
                o.forEach((oo) => oo.observadores.forEach((a) => a.next(n)))
            }
        })
    }
}
export class ObseKey {
    tipo: string
    idEntidad?: number
    idLocal?: number
    observadores: IActualizable[] = []
    constructor(data: any) {
        this.tipo = data.tipo
        this.idEntidad = data.idEntidad
        this.idLocal = data.idLocal
    }
}
export interface IActualizable {
    next(n: Novedad)
    destroy()
    getKey()
}
