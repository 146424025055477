import 'reflect-metadata';
import { StringUtils } from 'src/app/common/utils/string-utils';
import { Descriptivo } from './../model/Descriptivo';
import { ProfilePic } from './../model/ProfilePic';

export class ColumnGenerator {
    public static EMAIL_ROLE = "email-tabla";
    public static BOOLEAN_ROLE = "boolean-tabla";
    public static DATE_ROLE = "date-tabla";
    public static TEXT_ROLE = "text-tabla";
    public static MONEY_ROLE = "money-tabla";
    public static IMAGE_ROLE = "image-tabla";
    public static PROFILE_ROLE = "profile-tabla";
    public static DESCRIPTIVO_ROLE = "descriptivo-tabla";
    public getColumns(item: any): any[] {
        let columns = [];
        //const properties: string[] = Reflect.getOwnPropertyDescriptor( item);
        Object.getOwnPropertyNames(item).forEach(p => {
            if (typeof item[p] != 'function' && p != 'searchStr')
                columns.push({
                    "field": this.getField(item, p),
                    "header": this.getHeader(p, item[p]),
                    "class": this.getClass(p, item[p]),
                    "role": this.getRole(item, p),
                    "sortable": this.getSortable(item, p)
                });
        });

        return columns;
    }
    public getField(item, p) {
        let val = item[p];
        if (val instanceof Descriptivo) {
            return p;

        } else {
            return p;
        }
    }
    public getRole(item, p: string) {
        var i = item[p];
        if (i instanceof Descriptivo) {
            return ColumnGenerator.DESCRIPTIVO_ROLE;

        } else if (typeof i == 'boolean') {
            return ColumnGenerator.BOOLEAN_ROLE;
        } else if (i instanceof Date) {
            return ColumnGenerator.DATE_ROLE;
        } else if (p.includes("email") && typeof i == 'string') {
            return ColumnGenerator.EMAIL_ROLE;
        } else if (p == 'profilePic' || i instanceof ProfilePic) {
            return ColumnGenerator.PROFILE_ROLE;
        } else if (typeof i == 'string' && p.includes("image")) {
            return ColumnGenerator.IMAGE_ROLE;
        } else {
            return ColumnGenerator.TEXT_ROLE;
        }
    }

    public getSortable(item, p: string) {
        var i = item[p];
        if (i instanceof Descriptivo) {
            return true;

        } else if (typeof i == 'boolean') {
            return false;
        } else if (i instanceof Date) {
            return true;
        } else if (p.includes("email") && typeof i == 'string') {
            return true;
        } else if (p == 'profilePic' || i instanceof ProfilePic) {
            return false;
        } else if (typeof i == 'string' && p.includes("image")) {
            return false;
        } else {
            return true;
        }
    }
    public getHeader(p: string, i) {
        if (p == 'id') {
            return "#";
        } else if (p == "profilePic" || i instanceof ProfilePic) {
            return "";
        } else {
            return StringUtils.toTitleCase(p);
        }
    }
    public getClass(p: string, i) {
        if (p == 'id') {
            return "id-column";
        }
        if (p == 'profilePic' || i instanceof ProfilePic) {
            return "profile-pic";
        }
        if (typeof i == 'boolean') {
            return "is-boolean";
        }
    }
}

export function Money() {

    return (target: any, propertyName: string) => {

        return registerProperty(target, propertyName);
    }

    function registerProperty(target: object, propertyKey: string): void {
        let properties: string[] = Reflect.getOwnMetadata(Symbol("Money"), target);

        if (properties) {
            properties.push(propertyKey);
        } else {
            properties = [propertyKey];
            Reflect.defineMetadata(Symbol("Money"), properties, target);
        }
    }
}


export function Email() {

    return (target: any, propertyName: string) => {

        return registerProperty(target, propertyName);
    }
    function registerProperty(target: object, propertyKey: string): void {
        let properties: string[] = Reflect.getOwnMetadata(Symbol("Email"), target);

        if (properties) {
            properties.push(propertyKey);
        } else {
            properties = [propertyKey];
            Reflect.defineMetadata(Symbol("Email"), properties, target);
        }
    }
}