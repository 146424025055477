<div class="home wrapper" *ngIf="options">
    <gridster [options]="options" #grid >
        <gridster-item [item]="config" *ngFor="let config of configs" [ngClass]="{ editando: dbEditable }">
            <div class="header-db" *ngIf="dbEditable || config.fixedHeader || isMobile()">
                <div class="grid">
                    <div class="col drag-handle overflow">
                        <span>
                            {{ db?.title }}
                        </span>
                    </div>
                    <div class="col-fixed" style="width: 2em" (click)="db.refresh()" *ngIf="db.refreshable">
                        <i class="pi pi-refresh"></i>
                    </div>
                    <div class="col-fixed" style="width: 2em" (click)="db.verParams($event)" *ngIf="dbEditable">
                        <i class="pi pi-cog"></i>
                    </div>
                    <div class="col-fixed" style="width: 2em" (click)="delete(config)" *ngIf="dbEditable">
                        <i class="pi pi-trash"></i>
                    </div>
                </div>
            </div>
            <div class="gridster-item-content" [ngClass]="{ 'has-header-db': dbEditable || config.fixedHeader || isMobile() }">
                <dashboard-element [config]="config" #db (onChange)="dbService.guardar(loadService)"> </dashboard-element>
            </div>
        </gridster-item>
    </gridster>
    <button
        pButton
        class="floating editar"
        icon="pi {{ dbEditable ? 'pi pi-save' : 'pi-pencil' }}"
        [ngClass]="{ editando: dbEditable }"
        (click)="toggleEditable($event)"
        pTooltip="{{ (dbEditable ? 'Guardar layout' : 'Editar Layout') | translate }}"
    ></button>
    <button
        class="floating menu"
        (click)="menu.toggle($event)"
        icon="pi pi-plus"
        pButton
        pTooltip="{{ 'AGREGAR_COMPONENTES' | translate }}"
        *ngIf="dbEditable"
    ></button>

    <button
        class="floating cancelar"
        *ngIf="dbEditable"
        (click)="cancelarEdicion($event)"
        icon="pi pi-times"
        pButton
        pTooltip="{{ 'Cancelar cambios' | translate }}"
    ></button>
</div>

<ng-template #operacionesCaja>
    <operaciones-caja [styleClass]="'seccion handle'"></operaciones-caja>
</ng-template>

<ng-template #pedidos let-conf>
    <pedido-shortcut [config]="conf"> </pedido-shortcut>
</ng-template>

<p-menu #menu [model]="opcionesDashboard" [popup]="true" appendTo="body"></p-menu>
