import { Descriptivo } from './../common/model/Descriptivo';
import { Local } from './Local';




export class ConfiguracionUsuario {
  public tipo: string = "ConfiguracionUsuarioVO";
  constructor(
    public id?: number,
    public localSeleccionado?: Descriptivo,
    public localesHabilitados: Descriptivo[] = [],
    public centrosHabilitados: Descriptivo[] = [],
    public opcionesLayout?: string,
    public defaults?: Map<string, Descriptivo>

  ) {
  }
  public static objToStrMap(obj) {
    let strMap = new Map();
    for (let k of Object.keys(obj)) {
      strMap.set(k, obj[k]);
    }
    return strMap;
  }
  public static strMapToObj(strMap: Map<string, Descriptivo>) {
    let obj = Object.create(null);
    Array.from(strMap.keys()).forEach(e => {
      obj[e] = strMap.get(e);
    })
    return obj;
  }
  public static fromData(data: any): ConfiguracionUsuario {
    if (!data) return null;
    const o: ConfiguracionUsuario = new ConfiguracionUsuario(
      data.id,
      Local.fromData(data.localSeleccionado),
      data.localesHabilitados ? data.localesHabilitados.map(c => Descriptivo.fromData(c)) : [],
      data.centrosHabilitados ? data.centrosHabilitados.map(c => Descriptivo.fromData(c)) : [],
      data.opcionesLayout,
    );
    if (data.defaults) {
      if (data.defaults instanceof Map) {
        o.defaults = data.default;
      } else {
        o.defaults = ConfiguracionUsuario.objToStrMap(JSON.parse(data.defaults));
      }
    } else {
      o.defaults = new Map();
    }
    return o;

  }

  public get json() {
    return {
      id: this.id,
      tipo: this.tipo,
      localSeleccionado: this.localSeleccionado,
      localesHabilitados: this.localesHabilitados,
      centrosHabilitados: this.centrosHabilitados,
      opcionesLayout: this.opcionesLayout,
      defaults: this.defaults ? JSON.stringify(ConfiguracionUsuario.strMapToObj(this.defaults)) : {}

    }
  }

}

