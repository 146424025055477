import { TipoIVAGrabado } from 'src/app/model/TipoIVAGrabado'
import { ComandaResumen } from './ComandaResumen'
import { ProductoCabecera } from './ProductoCabecera'

export class ItemPedido {
    constructor(
        public id?: number,
        public producto?: ProductoCabecera,
        public cantidad?: number,
        public precioUnitario?: number,
        public descuento: number = 0,
        public observaciones?: string,
        public tipoIVA?: TipoIVAGrabado,
        public impuestosIncluidos: boolean = false,
        public comanda?: ComandaResumen
    ) {}
    public static fromData(data: any): ItemPedido {
        if (!data) return null
        const o: ItemPedido = new ItemPedido(
            data.id,
            ProductoCabecera.fromData(data.producto),
            data.cantidad,
            data.precioUnitario,
            data.descuento,
            data.observaciones,
            TipoIVAGrabado.fromData(data.tipoIVA),
            data.impuestosIncluidos,
            ComandaResumen.fromData(data.comanda)
        )

        return o
    }

    public get total() {
        return this.subtotal + this.impuestos - this.descuentoValor
    }

    public get descuentoValor() {
        return Math.round(((this.subtotal * this.descuento) / 100) * 100) / 100
    }

    public get subtotal() {
        return Math.round(this.cantidad * this.precioUnitario * 100) / 100
    }
    public get impuestos() {
        if (!this.tipoIVA?.valorIva || this.impuestosIncluidos) return 0
        return Math.round((this.subtotal - this.descuentoValor) * (this.tipoIVA?.valorIva / 100) * 100) / 100
    }

    public get importeDescuento() {
        return Math.round((this.descuento / 100) * this.precioUnitario * this.cantidad * 100) / 100
    }
}
