import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'icon-picker',
    templateUrl: './icon-picker.component.html',
    styleUrls: ['./icon-picker.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IconPickerComponent),
            multi: true,
        },
    ],
})
export class IconPickerComponent implements OnInit, ControlValueAccessor {
    private _icon: string
    public get icon(): string {
        return this._icon
    }
    @Input()
    public set icon(v: string) {
        this._icon = v
        this.onChangeCallback(this._icon)
    }

    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    @Input()
    public disabled: boolean = false
    constructor() {}
    writeValue(obj: any): void {
        this.icon = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    ngOnInit() {}

    onIconPickerSelect(icon: string): void {
        this.icon = icon
    }
}
