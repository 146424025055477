import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { FormaPago } from 'src/app/model/FormaPago'
import { Moneda } from 'src/app/model/Moneda'
import { Cobro } from './Cobro'
import { FileData } from './FileData'

export class CobroEnEfectivo extends Cobro {
    public static TIPO = 'E'
    constructor(
        id?: number,
        formaPago?: FormaPago,
        comprobante?: string,
        monto?: number,
        public caja?: Descriptivo,
        moneda?: Moneda,
        cotizacion: number = 1,
        adjunto?: FileData
    ) {
        super(id, formaPago, comprobante, monto, moneda, cotizacion, adjunto)
        this.tipoCobro = CobroEnEfectivo.TIPO
    }

    public static fromData(data: any): CobroEnEfectivo {
        if (!data) return null
        const o: CobroEnEfectivo = new CobroEnEfectivo(
            data.id,
            FormaPago.fromData(data.formaPago),
            data.comprobante,
            data.monto,
            Descriptivo.fromData(data.caja),
            Moneda.fromData(data.moneda),
            data.cotizaicion,
            FileData.fromData(data.adjunto)
        )

        return o
    }
}
