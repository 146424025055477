import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { OverlayPanel } from 'primeng/overlaypanel'
import { filter } from 'rxjs/operators'
import { ID } from 'src/app/app.module'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Novedad } from 'src/app/model/Novedad'
import { Producto } from 'src/app/model/Producto'
import { TipoProducto } from 'src/app/model/TipoProducto'
import { ProductoService } from 'src/app/services/producto.service'
import { LoadingService } from './../../../../common/services/loading-data-service.service'
import { MenuConfigService } from './../../../../common/services/menu-config.service'
import { TranslateService } from './../../../../common/services/translate.service'
import { FiltroProducto } from './../../../../model/FiltroProducto'
import { ProductoCabecera } from './../../../../model/ProductoCabecera'
import { StockInsumo } from './../../../../model/StockInsumo'
import { IActualizable, NovedadService } from './../../../../services/novedades.service'
import { StockInsumoService } from './../../../../services/stock-insumo.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'app-recargable',
    templateUrl: './recargable.component.html',
    styleUrls: ['./../dashboard-element-common.component.less', './recargable.component.less'],
})
export class RecargableComponent implements OnInit, IDashboardElement, IActualizable {
    public static TIPO = 'recargable'
    private _config: DashboardConfiguration
    public data: Producto
    private key = ID()
    public getKey() {
        return this.key
    }
    public refresh() {
        return
    }
    private _stock: StockInsumo
    public get stock(): StockInsumo {
        return this._stock
    }
    public set stock(v: StockInsumo) {
        if (this._stock != v) {
            this._stock = v
            this.startAnimation()
        }
    }

    public animate: boolean
    public fraccionables: ProductoCabecera[] = []
    public opcionesRecarga: Producto[] = []
    public loadingService: LoadingService = new LoadingService()
    public verRecarga: boolean = false
    public static defaultConfig: DashboardConfiguration = {
        fixedHeader: true,
        x: null,
        y: null,
        rows: 2,
        cols: 1,
        minItemCols: 1,
        minItemRows: 2,
        tipo: 'recargable',
        params: {},
    }
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
    }
    constructor(
        private translateService: TranslateService,
        public menuConfService: MenuConfigService,
        public stockService: StockInsumoService,
        public productoService: ProductoService,
        private messagesService: MessagesService,
        public novedadService: NovedadService,
        private router: Router
    ) {}
    private last: Novedad
    public startAnimation() {
        this.animate = true
        setTimeout(() => {
            this.animate = false
        }, 500)
    }
    next(n: Novedad) {
        if (this.last?.key != n?.key) {
            this.getOpciones()
            this.last = n
            if (this.data) {
                this.productoService.getById(this.data.id, this.loadingService).then((i) => {
                    this.data = i
                    this.stockService.getByProducto(this.data.id, this.loadingService).then((s) => {
                        this.stock = s
                    })
                })
            }
        }
    }
    destroy() {
        this.last = null
        this.novedadService.unregister('producto', this)
    }
    @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel
    public verStock(event) {
        this.router.navigate(['stock'])
    }
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}
    public recargar = (event) => {
        this.verRecarga = true
    }

    public acciones: MenuItem[] = []
    public get proporcion() {
        if (!this.stock || !this.data || !this.data?.capacidad) return 0
        return this.stock?.cantidad / this.data?.capacidad
    }
    public async getOpciones() {
        var filtro = new FiltroProducto(null, 0, 100, 'peso,descripcion', 1, false)
        filtro.tipo = new Descriptivo(TipoProducto.FRACCIONABLE)
        filtro.verArchivados = false
        this.fraccionables = await this.productoService.getCabeceras(filtro, this.loadingService)
    }

    public cancelarRecarga(event?) {
        this.verRecarga = false
    }
    public async onGuardarRecarga(event: StockInsumo) {
        this.stock = event
        this.success(this.translateService.get('Recarga registrada'))
        this.data = await this.productoService.getById(this.data.id)
        this.updateMenu()
        this.cancelarRecarga()
    }
    ngOnInit() {
        this.getOpciones()
        this.novedadService.registrarObservador('producto', this)
        if (this.config?.params['producto-id']) {
            this.productoService.getById(this.config.params['producto-id'], this.loadingService).then((i) => {
                this.data = i
                this.stockService.getByProducto(this.data.id, this.loadingService).then((s) => {
                    this.stock = s
                    this.updateMenu()
                })
            })
        }
        this.stockService.data.pipe(filter((d) => d != undefined)).subscribe((s) => {
            if (this.stock) {
                var n = s.filter((ss) => ss.id == this.stock.id)[0]
                if (n?.cantidad != this.stock?.cantidad) {
                    this.stock = n
                    this.updateMenu()
                    this.startAnimation()
                }
            }
        })
    }
    public updateMenu() {
        this.acciones = [
            {
                label: 'Ver producto',
                icon: 'pi pi-eye',
                visible: true,
                command: () => {
                    this.router.navigate(['producto/vista'], { queryParams: { id: this.data.id } })
                },
            },
            { label: 'Recargar', icon: 'pi pi-refresh', 
            command: this.recargar, visible:this.stock?.cantidad <= this.data?.capacidad },
        ]
        if (this.acciones.filter((a) => a.visible).length == 0) {
            this.acciones.push({ label: 'Sin acciones disponibles' })
        }
    }
    public error(mensaje?: string, titulo?: string) {
        this.messagesService.error(mensaje, titulo)
        return false
    }

    public success(mensaje?: string, titulo?: string) {
        this.messagesService.success(mensaje, titulo)
        return false
    }
    public async aplicarSeleccion(item: ProductoCabecera) {
        this.op.hide()

        this.data = await this.productoService.getById(item.id, this.loadingService)
        if (!this.data.categoriaRecarga) {
            return this.error(this.translateService.get('EL_PRODUCTO_NO_TIEN_21'))
        }
        this.stock = await this.stockService.getByProducto(this.data.id, this.loadingService)

        this.config.params['producto-id'] = this.data.id
        this.onChange(this.config)
    }

    public getTitle = () => {
        return this.data?.productoRecarga ? this.data.prefijo + " " + this.data.productoRecarga : this.translateService.get('Estado de stock')
    }
    isMobile() {
        return window.innerWidth <= 640
    }
    public onDialogShow(event, dialog) {
        if (this.isMobile()) {
            dialog.maximized = true
        }
    }
}
