import { HttpClient } from '@angular/common/http';
import { ID } from 'src/app/app.module';
import { environment } from './../../../environments/environment';
import { GlobalInjector } from './../GlobalInjector';
import { LoadingService } from './loading-data-service.service';
import { MessagesService } from './messages-data-service.service';
export abstract class BaseService {
    protected http: HttpClient
        ;
    private key = ID();
    public getKey() {
        return this.key;
    }
    constructor(protected messages?: MessagesService, public loadingService?: LoadingService) {
        this.http = GlobalInjector.InjectorInstance.get<HttpClient>(HttpClient);
        if (!loadingService) {
            this.loadingService = GlobalInjector.InjectorInstance.get(LoadingService);
        }
        if (!messages) {
            this.messages = GlobalInjector.InjectorInstance.get(MessagesService);
        }
    }
    protected handleOk = (response: any, customLoading?: LoadingService): string => {
        let res = response;
        if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        return res.mensaje;
    }
    protected handleError(error: any, customLoading?: LoadingService): Promise<any> {
        if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        return Promise.reject(error.error || error);
    }

    public abstract baseName(): string;

    getApiURL() {
        return environment.apiUrl;
    }
    public getBaseURL(path?: string): string {
        return this.getApiURL() + this.baseName() + "/" + (path ? path : '');
    }

}