import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { TipoProducto } from 'src/app/model/TipoProducto'
import { Unidad } from 'src/app/model/Unidad'
import { AsociacionInsumo } from './AsociacionInsumo'
import { ConfiguracionPrecio } from './ConfiguracionPrecio'
import { FileData } from './FileData'
import { ImagenProducto } from './ImagenProducto'
import { Marca } from './Marca'
import { MenuProductoCategoria } from './MenuProducto'
import { ProductoAsociado } from './ProductoAsociado'
import { ProductoCabecera } from './ProductoCabecera'
import { ProductoInsumo } from './ProductoInsumo'
import { ProductoVariante } from './ProductoVariante'
import { Restriccion } from './Restriccion'
import { StockInsumo } from './StockInsumo'
import { TipoIVAGrabado } from './TipoIVAGrabado'

export class Producto extends Descriptivo {
    public clonar(): Producto {
        var p = Producto.fromData(this)
        p.id = null
        p.insumos.forEach((i) => (i.id = null))
        p.productosAsociados.forEach((pa) => (pa.id = null))
        p.insumos = []
        p.codigo = null
        p.imagenes.forEach((i) => (i.id = null))

        return p
    }

    public static NOTA: string = '$NOTA$'

    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public habilitado?: boolean,
        public tipoProducto?: Descriptivo,
        public categoria?: Descriptivo,
        public peso?: number,
        public fechaVigencia: Date = new Date(),
        public duracion?: number,
        public basePersonas: number = 1,
        public cantidad: number = 1,
        public insumos: ProductoInsumo[] = [],
        public productosAsociados: ProductoAsociado[] = [],
        public adicionable?: boolean,
        public esDivisible?: boolean,
        public detalle?: string,
        public imagenes: ImagenProducto[] = [],
        public esVisible: boolean = true,
        public marca?: Marca,
        public unidad?: Unidad,
        public categoriasProductoAsociadas: Descriptivo[] = [],
        public categoriaInsumo?: Descriptivo,
        public tipoIVA?: TipoIVAGrabado,
        public esRapido?: boolean,
        public codigoBarras?: String,
        public centroCosto?: Descriptivo,
        public locales: Descriptivo[] = [],
        public esVenta: boolean = true,
        public capacidad?: number,
        public categoriaRecarga?: Descriptivo,
        public descripcionPreview?: string,
        public recomendados: Descriptivo[] = [],
        public variantes: ProductoVariante[] = [],
        public precios: ConfiguracionPrecio[] = [],
        public stockInsumos: StockInsumo[] = [],
        public menus: MenuProductoCategoria[] = [],
        public restricciones: Restriccion[] = [],
        public prefijo: string = ""
    ) {
        super(codigo, descripcion, id)
        if (tipoProducto?.codigo == 'REV' && insumos?.length > 0) {
            this.categoriaInsumo = insumos[0].insumo?.categoria
        }
    }

    public static fromData(data: any): Producto {
        if (!data) return null
        const o: Producto = new Producto(
            data.id,
            data.codigo,
            data.descripcion,

            data.habilitado,
            Descriptivo.fromData(data.tipoProducto),
            Descriptivo.fromData(data.categoria),
            data.peso,
            data.fechaVigencia ? new Date(data.fechaVigencia) : null,
            data.duracion,
            data.basePersonas,
            data.cantidad,
            data.insumos?.map((c) => ProductoInsumo.fromData(c)) || [],
            data.productosAsociados?.map((c) => ProductoAsociado.fromData(c)) || [],
            data.adicionable,
            data.esDivisible,
            data.detalle,
            data.imagenes?.map((c) => ImagenProducto.fromData(c)) || [],
            data.esVisible,
            Marca.fromData(data.marca),
            Unidad.fromData(data.unidad),
            data.categoriasProductoAsociadas?.map((c) => Descriptivo.fromData(c)) || [],
            Descriptivo.fromData(data.categoriaInsumo),
            TipoIVAGrabado.fromData(data.tipoIVA),
            data.esRapido,
            data.codigoBarras,
            Descriptivo.fromData(data.centroCosto),
            data.locales?.map((l) => Descriptivo.fromData(l)) || [],
            data.esVenta,
            data.capacidad,
            Descriptivo.fromData(data.categoriaRecarga),
            data.descripcionPreview,
            data.recomendados?.map((r) => Descriptivo.fromData(r)) || [],
            data.variantes?.map((v) => ProductoVariante.fromData(v)) || [],
            data.prefijo
        )
        o.restricciones = data.restricciones?.map((v) => Restriccion.fromData(v)) || []

        return o
    }

    public esTipo(tipo: string) {
        return this.tipoProducto && this.tipoProducto?.codigo == tipo
    }
    public esServicio() {
        return this.esTipo('SER')
    }
    public esReventa() {
        return this.esTipo('REV')
    }
    public esInsumo() {
        return this.esTipo('INS')
    }
    public esProducto() {
        return this.esTipo('PRD')
    }
    public esElaborado() {
        return this.esTipo('ELAB')
    }
    public esVariante() {
        return this.esTipo('VAR')
    }

    public esFraccionado() {
        return this.esTipo(TipoProducto.FRACCIONABLE)
    }

    public setDefault(imagen: ImagenProducto) {
        this.imagenes.forEach((i) => (i.esPortada = false))
        imagen.esPortada = true
    }
    public getInsumos(tipo: string) {
        return this.insumos
    }
    agregarImagen(f: FileData) {
        this.imagenes.push(new ImagenProducto(null, f, false))
        if (this.imagenes.length == 1) this.imagenes[0].esPortada = true
    }

    quitarImagen(imagen: ImagenProducto) {
        this.imagenes = this.imagenes.filter((i) => i != imagen)
    }

    public agregarVariante() {
        let p = new ProductoVariante()
        if (this.variantes.length == 0) {
            p.peso = 0
        } else {
            p.peso = this.variantes.length
            const def = this.variantes[0]
            def.productosAsociados.forEach((pa) => {
                p.productosAsociados.push(new ProductoAsociado(null, pa.asociado, pa.basePersonas, pa.cantidad))
            })
        }

        this.variantes.push(p)
    }
    public quitarVariante(variante: ProductoVariante) {
        this.variantes = this.variantes.filter((v) => v != variante)
    }

    public quitarInsumo(insumo: AsociacionInsumo) {
        this.insumos = this.insumos.filter((i) => i != insumo)
    }
    public quitarProducto(prod: ProductoAsociado) {
        this.productosAsociados = this.productosAsociados.filter((i) => i != prod)
    }
    public agregarProducto() {
        this.productosAsociados.push(new ProductoAsociado(null, null, 1, 1))
    }
    public get proporcion() {
        if (this.basePersonas <= 0) return 0
        return this.cantidad / this.basePersonas
    }

    public get imagenPortada() {
        var imagen = this.imagenes.filter((i) => i.esPortada)[0]
        return imagen ? imagen.imagenUrl : 'assets/imagen/plus.png'
    }

    public get marcaUrl() {
        return this.marca ? this.marca.imagen : 'assets/imagen/sin_imagen.png'
    }
    public get productoRecarga() {
        if (this.tipoProducto.codigo != TipoProducto.FRACCIONABLE) return
        return this.productosAsociados[0]?.asociado
    }
    public get categorias() {
        return this.categoriasProductoAsociadas ? this.categoriasProductoAsociadas.map((c) => c.descripcion).join(';') : ''
    }
    json = ()=>{
        let o = JSON.parse(JSON.stringify(this));
        o.productosAsociados = this.productosAsociados.map(p=> new ProductoAsociado(p.id,Descriptivo.fromData(p.asociado),p.basePersonas,p.cantidad))
        return o;
    }
}

export class ProductoRanking extends Descriptivo {
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public cantidad?: number,
        public precioUnitario?: number,
        public descuento?: number,
        public imagenPortada?: string,
        public productoRecarga?: ProductoCabecera,
        public tipoIva?: TipoIVAGrabado,
        public fechaFacturacion?: any,
        public fechaPedido?: any,
        public idCliente?: number,
        public nombreCliente?: string,
        public razonSocialCliente?: string,
        public pedidoId?: number,
        public comprobanteId?: number,
        public marca?: string,
        public categorias?: any[],
        public importe: number = 0
    ) {
        super(codigo, descripcion, id)
    }
    public static fromData(data: any): ProductoRanking {
        if (!data) return null
        const o: ProductoRanking = new ProductoRanking(data.id,
            data.codigo,
            data.descripcion,
            data.cantidad,
            data.precioUnitario,
            data.descuento,
            data.imagenPortada,
            null,
            TipoIVAGrabado.fromData(data.tipoIva),
            data.fechaFacturacion,
            data.fechaPedido,
            data.idCliente,
            data.nombreCliente,
            data.razonSocialCliente,
            data.pedidoId,
            data.comprobanteId,
            data.marca
        )

        if (!data.categorias){
            o.categorias = [];
        }else if( typeof data.categorias === 'string'){
            o.categorias = JSON.parse(data.categorias)
        } else if(data.categorias?.length > 2){
            o.categorias = data.categorias;          
        }
        


        o.importe = (o.precioUnitario * o.cantidad) + (o.precioUnitario * o.cantidad) * o.descuento / 100
        return o
    }

    public get imagen() {
        return this.imagenPortada
    }


}
