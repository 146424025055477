import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { EmpleadoAuthGuard } from './../../authguards/EmpleadoAuthGuard'
import { VisualizadorAuthGuard } from './../../authguards/VisualizadorAuthGuard'
import { GestorNotaComponent } from './../nota/gestor-nota/gestor-nota.component'
import { ListadoNotaComponent } from './../nota/listado-nota/listado-nota.component'

const pipe = []
const directives = []
const components = [GestorNotaComponent, ListadoNotaComponent]
const routes: Routes = [
    { path: '', data: { animation: 'List' }, component: ListadoNotaComponent, canActivate: [EmpleadoAuthGuard] },
    { path: 'edit', pathMatch: 'full', data: { animation: 'Item' }, component: GestorNotaComponent, canActivate: [EmpleadoAuthGuard] },
    { path: 'vista', component: GestorNotaComponent, canActivate: [VisualizadorAuthGuard], data: { vista: true, animation: 'Item' } },
    { path: 'nuevo', data: { animation: 'Item' }, component: GestorNotaComponent, canActivate: [EmpleadoAuthGuard] },
]
@NgModule({
    imports: [RouterModule.forChild(routes), CommonComponentsModule],
    providers: [],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})
export class NotaModule {}
