export class MovimientoStock {

    constructor(
        public idStock?: number,
        public cantidad?: number,
        public idStockDesde?: number,
        public justificacion?: string,
        public descartarRestante?: boolean

    ) {

    }
}