

import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Ubicacion } from './Ubicacion';


export class Deposito extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public ubicacion: Ubicacion = new Ubicacion(),
        public esDefault: boolean = false,
        public centro?: Descriptivo

    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): Deposito {
        if (!data) return null;
        const o: Deposito = new Deposito(
            data.id,
            data.codigo,
            data.descripcion,

            Ubicacion.fromData(data.ubicacion),
            data.esDefault,
            Descriptivo.fromData(data.centro)
        );

        return o;

    }

}