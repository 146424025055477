


export class CostoDelivery  {
    constructor(
		public id ?: number, 
		public desde ?: number, 
		public hasta ?: number, 
		public costo ?: number, 

    ) {
    }
    public static fromData(data: any): CostoDelivery {
        if (!data) return null;
        const o: CostoDelivery = new CostoDelivery(
			data.id, 
			data.desde, 
			data.hasta, 
			data.costo, 

        );

        return o;

    }

}