import { MessagesService } from './../../services/messages-data-service.service'
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { commonAnimations } from 'src/app/common-animations'
import { TranslateService } from './../../services/translate.service'
import Cropper from 'cropperjs'

@Component({
    selector: 'profile-badge',
    templateUrl: 'profile-badge.component.html',
    styleUrls: ['profile-badge.component.less'],
    animations: [commonAnimations],
})
export class ProfileBadgeComponent implements OnInit, ControlValueAccessor {
    crop: boolean = false
    croppedImage: string

    @ViewChild('inputFile', { static: false }) inputFile: ElementRef
    scale = 1

    private _type: string = 'landscape'
    public get type(): string {
        return this._type
    }
    @Input()
    public set type(v: string) {
        this._type = v
        if (this.type == 'landscape') {
            this.ratio = 16 / 9
        } else if (this.type == 'portrait') {
            this.ratio = 4 / 3
        }
    }

    public openInput() {
        this.inputFile?.nativeElement && this.inputFile.nativeElement.click()
    }

    public ratio: number = 16 / 9
    public imageElement: ElementRef
    imageSource: any
    cropper: Cropper
    imageDestination: Blob
    @ViewChild('image')
    set image(content: ElementRef) {
        if (content) {
            this.imageElement = content
        }
    }

    constructor(private translateService: TranslateService, private sanitizer: DomSanitizer, private messagesService: MessagesService) {
        var sub = this.translateService.upateData.subscribe((r) => {
            if (!this.defaultImage) {
                this.defaultImage = this.translateService.get('DEFAULT_PROFILE')
                sub.unsubscribe()
            }
        })
    }

    @Input()
    public selected: boolean = false

    public onLoad(e, u: string) {
        this._url = u
    }

    private _url: string
    public get url(): string {
        return this._url
    }
    @Input()
    public set url(v: string) {
        if (v && v != this._url) {
            this._url = v
        }
    }

    @Input()
    public maxSize: number = 250000
    @Input()
    public class: string = ''

    @Input()
    public seleccionable: boolean = false

    public fileUpload: File

    @Output()
    public onFileSelected: EventEmitter<File> = new EventEmitter<File>()
    @Input()
    public defaultImage: string = 'assets/images/default_image.png'
    private safeUrl: string
    @Input()
    public disabled: boolean = false
    ngOnInit() {}

    @ViewChild('file', { static: true }) public file: ElementRef
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    writeValue(obj: any): void {
        this.fileUpload = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    public get profilePic() {
        return this.foto ? this.foto : this.defaultImage
    }
    public get foto() {
        return this.safeUrl ? this.safeUrl : this.url
    }
    handleFileInput(files: FileList) {
        this.fileUpload = files.item(0)

        this.crop = true

        const reader = new FileReader()

        reader.onload = (e: any) => {
            this.imageSource = e.target.result
        }
        reader.readAsDataURL(this.fileUpload)
    }

    displayCropper() {
        this.cropper = new Cropper(this.imageElement.nativeElement, {
            zoomable: true,
            scalable: true,
            aspectRatio: this.ratio,
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas()
                canvas.toBlob((t) => {
                    this.fileUpload = new File([t], 'imagen')
                    console.log(this.fileUpload)
                })
            },
        })
    }

    confirmar() {
        if (this.fileUpload.size > this.maxSize) {
            this.messagesService.error('El tamaño máximo es 250KB')
            this.fileUpload = null
            return
        }

        this.onFileSelected.emit(this.fileUpload)
        this.onChangeCallback(this.fileUpload)
        this.crop = false
    }

    cancelar() {
        this.crop = false
    }
}
