import * as moment from 'moment'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { CobroConCaja } from './CobroConCaja'
import { FormaPago } from './FormaPago'
import { Moneda } from './Moneda'
import { TipoCuotasTarjeta } from './TipoCuotasTarjeta'
import { TipoTarjetaCredito } from './TipoTarjetaCredito'

export class CobroCheque extends CobroConCaja {
    public static TIPO = 'H'

    constructor(
        id?: number,
        formaPago?: FormaPago,
        comprobante?: string,
        monto?: number,
        caja?: Descriptivo,
        moneda?: Moneda,
        cotizacion: number = 1,
        public numeroCheque?: number,
        public fechaDeCobro?: Date,
        public banco?: string,
    ) {
        super(id, formaPago, comprobante, monto, caja, moneda, cotizacion)
        this.tipoCobro = CobroCheque.TIPO
    }

    public static fromData(data: any): CobroCheque {
        if (!data) return null
        const o: CobroCheque = new CobroCheque(
            data.id,
            FormaPago.fromData(data.formaPago),
            data.comprobante,
            data.monto,
            Descriptivo.fromData(data.caja),
            Moneda.fromData(data.moneda),
            data.cotizacion,
            data.numeroCheque,
            data.fechaDeCobro ? moment(data.fechaDeCobro).toDate() : null,
            data.banco,
        )

        return o
    }
}
