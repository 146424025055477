import { Injectable } from '@angular/core'
import { share } from 'rxjs/operators'
import { DescriptivosService } from 'src/app/common/services/descriptivos.service'
import { Cobranza } from 'src/app/model/Cobranza'
import { Comprobante } from '../model/Comprobante'
import { Totales } from '../model/Totales'
import { IAccionesDocumento } from './../common/services/IAccionesDocumento'
import { LoadingService } from './../common/services/loading-data-service.service'
import { Cobro } from './../model/Cobro'
import { FiltroComprobante } from './../model/FiltroComprobantes'

@Injectable({
    providedIn: 'root',
})
export class CobranzaService extends DescriptivosService<Cobranza> implements IAccionesDocumento {

    public accionAnular(documento: Cobranza) {
        return this.anular(documento).then((c: Cobranza) => {
            return c
        })
    }

    public esAccionAnular(documento: Cobranza): boolean {
        return true
    }

    public baseName(): string {
        return 'cobranza'
    }
    public parseToEnt(data: any): Cobranza {
        return Cobranza.fromData(data)
    }
    public newEnt(): Cobranza {
        return new Cobranza()
    }
    public aprobar(c: Cobranza, customLoading?: LoadingService): Promise<Cobranza> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'aprobar', c)
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    public rechazar(c: Cobranza, customLoading?: LoadingService): Promise<Cobranza> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'rechazar', c)
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    public anular(c: Cobranza) {
        this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'anular', c)
            .toPromise()
            .then(
                (r) => {
                    this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e)
            )
    }
    getResumen(filter: FiltroComprobante = new FiltroComprobante(null, 0, 100), customLoading?: LoadingService): Promise<Cobranza[]> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'resumen', filter.json)
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parse(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getByComprobante(idComprobante: number, customLoading?: LoadingService): Promise<Cobro[]> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .get(this.getBaseURL() + 'byComprobante/' + idComprobante)
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parse(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public async devolver(cobranza: Cobranza, comprobante: Comprobante, customLoading?: LoadingService): Promise<any> {
        cobranza.cobros.forEach((c: Cobro) => {
            c.monto = c.monto * -1
        })
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return await this.http
            .post(this.getBaseURL('devolver'), { comprobante: comprobante, cobranza: cobranza })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public getTotales(filtro: FiltroComprobante, customLoading?: LoadingService): Promise<Totales> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('totales'), filtro.json)
            .toPromise()
            .then(
                (r: Totales) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }
}
