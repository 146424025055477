import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { TipoCuotasTarjeta } from 'src/app/model/TipoCuotasTarjeta';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class TipoCuotasTarjetaService extends ParametricoService<TipoCuotasTarjeta>{
    public baseName(): string {
        return "tipo-cuotas-tarjeta";
    }
    public parseToEnt(data: any): TipoCuotasTarjeta {
        return TipoCuotasTarjeta.fromData(data);
    }
    public newEnt(): TipoCuotasTarjeta {
        return new TipoCuotasTarjeta();
    }


}