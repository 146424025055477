import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { LocalService } from 'src/app/services/local.service';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from './breadcrumb.service';
import { commonAnimations } from './common-animations';
import { SessionComponent } from './common/components/session-component.component';
import { Filtro } from './common/model/Filtro';
import { DashboardService } from './pages/home/dashboard-element/dashboard.service';
import { slideInAnimation } from './route-animation';
import { AuthService } from './services/auth.service';
import { ConfiguracionUsuarioService } from './services/configuracion-usuario.service';
import { MessagingService } from './services/messages.service';
import { NovedadService } from './services/novedades.service';
import { ProductoService } from './services/producto.service';
import { StorageService } from './services/storage.service';
export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    PLUS = 107,
    ENTER = 13,
}
export let COLORS = [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
    '#ce99ff',
    '#8eaaff',
    '#2db7ff',
    '#00bffb',
    '#00c3dd',
    '#00c3ba',
    '#55c099',
    '#83bb7f'
    
]
@Component({
    selector: 'front-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [slideInAnimation, commonAnimations],
})
export class AppComponent extends SessionComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        if (this.userSub) this.userSub.unsubscribe()
    }
    darkMenu = environment.darkMenu
    private userSub: Subscription
    title: string = 'Proyecto Base'
    
    public message

    topbarTheme: string = 'dark'

    menuTheme: string = 'dark'

    layoutMode: string = 'dark'

    menuMode: string = 'static'

    inlineMenuPosition: string = 'bottom'

    inputStyle: string = 'filled'

    ripple: boolean = true

    isRTL: boolean = false

    constructor(
        private storageService: StorageService,
        public router: Router,
        private swUpdate: SwUpdate,
        private pushMessages: MessagingService,
        private breadService: BreadcrumbService,
        private novedadesService: NovedadService,
        private configService: ConfiguracionUsuarioService,
        private localService: LocalService,
        private dbService: DashboardService,
        private productoService:ProductoService,
        private primengConfig: PrimeNGConfig,
        messagesService: MessagesService
    ) {
        super(messagesService)
        this.primengConfig.ripple = true;
        this.translateService.use(this.getUsersLocale('es'))
        this.translateService.cargarDataCodebase(this.getUsersLocale('es'))
    }
    public get google_apikey() {
        return environment.firebase.apiKey
    }

    ngOnInit(): void {
        let $this = this
        let windwUrl = window['cambioClave']
       // this.chartJs.plugins.unregister(this.chartLabelPlugin)
       Chart.register(ChartDataLabels);
        if (!this.authService.token && windwUrl.indexOf('/public') < 0 && windwUrl.indexOf('/login') < 0) {
            this.authService
                .refreshToken()
               
        }
  
        let a = this.authService.getAutenticateState().subscribe((r) => {
            if (r == AuthService.LOGUEADO) {
                let p = this.getCurrentUser().subscribe((u) => {
                    if (u != null) {
                        this.pushMessages.requestPermission()
                        this.pushMessages.tokekObs.pipe(filter((t) => t != undefined)).subscribe((t) => {
                            this.novedadesService.updateToken(t)

                            this.pushMessages.getMessages().subscribe((m) => {
                                this.novedadesService.nuevoPush(m)
                            })
                        })
                    }
                })

                this.authService
                    .getUser()
                    .pipe(
                        filter((u) => u != undefined),
                        first()
                    )
                    .subscribe((u) => {
                        this.dbService.init();
                        this.productoService.getAll(new Filtro('dummy', {}), this.loadingService);
                    })
            }
            if (a) a.unsubscribe()
        })

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe((n) => {
                if (confirm('Hay una nueva versión, desea actualizarla?')) {
                    window.location.reload()
                }
            })
        }
    }
    logout() {
        this.clearCredentials().then((r) => {
            this.router.navigate(['login'])
        })
    }
    get username() {
        return this.getUserFullName()
    }

    isTablet() {
        const width = window.innerWidth
        return width <= 1024 && width > 640
    }

    isDesktop() {
        return window.innerWidth > 1024
    }

    isMobile() {
        return window.innerWidth <= 640
    }

    isHorizontal() {
        return false
    }

    // get menuMode(){
    //     return isDesktop()? 'static' : 'overlay'
    // }
}

