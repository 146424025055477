import { Location } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core'
import { FormGroup, NgForm } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmationService, MenuItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { Idioma } from 'src/app/model/Idioma'
import { Identificable } from './../../model/Identficable'
import { IdiomaService } from './../../services/idioma.service'

@Component({
    selector: 'model-gestor',
    templateUrl: 'model-gestor.component.html',
    styleUrls: ['model-gestor.component.less'],
})
export class ModelGestorComponent extends SessionComponent implements OnInit, OnDestroy {
    constructor(
        public route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        public activeRoute: ActivatedRoute,
        private idiomaService: IdiomaService,
        protected _location: Location,
        messagesService: MessagesService,
        protected router: Router
    ) {
        super(messagesService)
    }

    private _menuItemAcciones: MenuItem[] = [
        {
            label: 'Volver',
            command: () => {
                this.volver()
            },
        },
    ]
    public get menuItemAcciones(): MenuItem[] {
        return this._menuItemAcciones
    }

    @Input()
    public customButtons: TemplateRef<any>
    @Input()
    public set menuItemAcciones(v: MenuItem[]) {
        this._menuItemAcciones = v
    }
    @Input()
    public subheader: string
    formChange: Subscription
    private original: string = ''
    @Input()
    public hideTitle: boolean = false

    @Input()
    public vista: boolean = false
    @Input()
    public modelName: string = ''
    @Input()
    public title: string = ''
    @Input()
    public isModal: boolean = false

    @Input()
    public hideButtons: boolean = false
    @Input()
    public conArchivo: boolean = false
    @Output()
    public onItemGuardado: EventEmitter<any> = new EventEmitter<any>()

    @Output()
    public onEditar: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Output()
    public onCancelar: EventEmitter<any> = new EventEmitter<any>()
    @Output()
    public readonlyChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Input()
    public validar: (r: any) => boolean = (r) => {
        return this.form ? this.form.valid : true
    }

    @Input()
    public txtOkMessage: string
    
    @Input()
    public multilenguaje: boolean = false

    @Input()
    public loadEntity: (id: number, idioma?: string) => void = this.defaultLoadEntity

    @Input()
    public verAcciones = false
    @Input()
    public txtPerdidaCambios: string

    private defaultLoadEntity(id: number, idioma: string) {
        if (this.service) {
            this.service.getById(id, null, idioma).then((r) => {
                this.item = r
            })
        }
    }
    private _idiomaSeleccionado: Idioma
    public get idiomaSeleccionado(): Idioma {
        return this._idiomaSeleccionado
    }
    @Input()
    public set idiomaSeleccionado(v: Idioma) {
        var prev = this._idiomaSeleccionado?.codigo
        this._idiomaSeleccionado = v

        if (v && this.item?.id && v?.codigo != prev) {
            this.loadEntity(this.item.id, v.codigo)
        }
    }

    private _form: FormGroup
    public get form(): FormGroup {
        return this._form
    }
    @Input()
    public set form(v: FormGroup | NgForm) {
        this._form = (v instanceof NgForm ) ?  (<NgForm>v).form: v;
        if (this._form) {
            /*	
        	
            this.modificado = false;
                this.formChange = this._form.valueChanges.pipe(distinctUntilChanged()).subscribe(r => {
                    setTimeout(() => {
                        this.modificado = JSON.stringify(this.item) != this.original;
                        if (this.modificado) this.formChange.unsubscribe();
                    });
	
                });
                */
        }
    }

    public editar() {
        if (this.editable) {
            this.readonly = false
            this.readonlyChange.emit(false);
            this.onEditar.emit(true);
        }
    }
    @Input()
    public editable = false
    @Input()
    public readonly: boolean = false

    private defaultTxtOkMessage: string

    private defaultTxtPerdidaCambios: string

    @Input()
    public goBack: boolean = true

    @Input()
    public service: ServicioAbstract<any>
    private _item: Identificable
    public get item(): Identificable {
        return this._item
    }
    @Input()
    public set item(v: Identificable) {
        if (v && ((v.id && this.item != v) || !v.id)) {
            this.original = JSON.stringify(v)
            if (v['codigoIdioma'] != this.idiomaSeleccionado?.codigo) {
                this.idiomaService.getByCodigo(v['codigoIdioma']).then((r) => {
                    this.idiomaSeleccionado = r
                })
            }
            this._item = v
            this.itemChange.emit(this._item)
        }
    }

    @Output()
    public itemChange: EventEmitter<Identificable> = new EventEmitter<Identificable>()
    @Input()
    public persist: boolean = true
    @Input()
    public file: File
    public modificado: boolean = true

    @Input()
    public styleClass: string = 'white'

    ngOnInit() {
        this.subs.push(this.formChange)
    }
    public formValid() {
        return this.form ? this.form.valid : true
    }
    public volver() {
        if (this.goBack) this._location.back()
    }
    public  guardar = async ()=> {
        this.form?.markAsDirty && this.form.markAsDirty();
        if (this.formValid() && (await this.validar(this.item))) {
            if (this.idiomaSeleccionado) {
                this._item['codigoIdioma'] = this.idiomaSeleccionado.codigo
            }
            if (!this.persist) {
                this.itemChange.emit(this.item)
                this.onItemGuardado.emit(this.item)
                //this.modificado = false;
                this.volver()
            } else if (this.conArchivo) {
                return this.service.guardarConArchivo(this.item, this.file).then((r) => {
                    this.success(this.okMessage)
                    this.itemChange.emit(r)
                    this.onItemGuardado.emit(r)
                    //this.modificado = false;
                    this.volver()
                    this.original = JSON.stringify(this.item)
                    this.checkModificado()
                    return r
                })
            } else {
                return this.service.guardar(this.item).then((r) => {
                    this.success(this.okMessage)
                    this.itemChange.emit(r)
                    this.onItemGuardado.emit(r)
                    //this.modificado = false;
                    this.volver()
                    this.original = JSON.stringify(this.item)
                    this.checkModificado()
                    return r
                })
            }
        } else {
           // this.form?.markAsDirty && this.form.markAsDirty()
            this.error('Verifique los campos del formulario')
            return Promise.reject()
        }
    }
    checkModificado() {
        let $this = this
        /*this.formChange = this._form.valueChanges.subscribe(r => {
            setTimeout(() => {
                $this.modificado = JSON.stringify($this.item) != $this.original;
                if ($this.modificado) $this.formChange.unsubscribe();
            });
        	
        */
    }

    get finalTitle(): string {
        const desc = this.item['descripcion'] ? this.item['descripcion'] : '#' + this.item.id
        return (
            this.title ||
            (this.item?.id && this.readonly && `${this.modelName} ${desc}`) ||
            (this.item?.id && ` ${this.modelName} ${desc}`) ||
            this.translateService.get('NUEVO') + ' ' + this.modelName
        )
    }

    get labelCancelar() {
        return this.modificado && !this.readonly ? this.translateService.get('CANCELAR') : this.translateService.get('VOLVER')
    }
    public cancelar() {
        if (this.modificado && !this.readonly) {
            this.confirmationService.confirm({
                key: 'genConf' + this.modelName,
                header: this.translateService.get('GUARDAR_CAMBIOS'),
                message: this.perdidaCambios,
                acceptLabel: this.translateService.get('Salir sin guardar'),
                rejectLabel: this.translateService.get('CANCELAR'),
                accept: () => {
                    if (!this.isModal || (this.isModal && this.goBack)) this._location.back()
                    this.onCancelar.emit()
                },
            })
        } else {
            if (!this.isModal || (this.isModal && this.goBack)) this._location.back()
            this.onCancelar.emit()
        }
    }

    private get okMessage() {
        return this.txtOkMessage ? this.txtOkMessage : this.defaultTxtOkMessage
    }
    private get perdidaCambios() {
        return this.txtPerdidaCambios ? this.txtPerdidaCambios : this.defaultTxtPerdidaCambios
    }
    public updateTexts() {
        this.defaultTxtPerdidaCambios = this.translateService.get('PERDIDA_CAMBIOS')
        this.defaultTxtOkMessage = this.translateService.get('OK_GUARDADO')
    }
}
