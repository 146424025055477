import * as moment from 'moment';
import { Descriptivo } from './../common/model/Descriptivo';
import { Usuario } from './../common/model/Usuario';
import { StockInsumo } from './StockInsumo';

export class StockControl {
	constructor(
		public id?: number,
		public stockInsumo?: Descriptivo,
		public responsable?: Usuario,
		public cantidadRectificada?: number,
		public cantidadActual?: number,
		public justificacion?: string,
		public fechaControl?: Date


	) {
	}
	public static fromData(data: any): StockControl {
		if (!data) return null;
		const o: StockControl = new StockControl(
			data.id,
			Descriptivo.fromData(data.stockInsumo),
			Usuario.fromData(data.responsable),
			data.cantidadRectificada,
			data.cantidadActual,
			data.justificacion,
			data.fechaControl ? new Date(data.fechaControl) : null

		);

		return o;

	}

	public get fechaControlStr() {
		return moment(this.fechaControl).format('DD/MM/YYYY')
	}

}