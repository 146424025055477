import { Injectable } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Filtro } from './../model/Filtro';
import { LoadingService } from './loading-data-service.service';

@Injectable()
export abstract class DescriptivosService<E extends Descriptivo> extends ServicioAbstract<E> {
  public getDescriptivosByDesc(query: any, customLoading?: LoadingService): Promise<E[]> {
    if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
    return this.http.get(this.getBaseURL() + "getByDesc/?val=" + query).toPromise().then(r => {
      if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
      return this.parse(r)
    }).catch((e) => this.handleError(e, customLoading));
  }
  getDescriptivos(filter: Filtro = new Filtro("", {}, 0, 9000), customLoading?: LoadingService): Promise<Descriptivo[]> {
    let $this = this;
    if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
    return this.http.post(this.getBaseURL() + "descriptivos", filter.json)
      .toPromise()
      .then((r) => {
        if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
        return this.parseDescriptivos(r)
      }, (e) => this.handleError(e, customLoading));
  }
  public getByCodigo(codigo: string, customLoading?: LoadingService): Promise<E> {
    if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
    return this.http.get(this.getBaseURL() + "byCodigo/" + codigo).toPromise().then(r => {
      if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
      return r ? this.parseSingle(r) : null;
    }).catch((e) => this.handleError(e, customLoading));
  }
}