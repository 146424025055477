import { Descriptivo } from './../common/model/Descriptivo';
import { ProductoAsociado } from './ProductoAsociado';
export class ProductoVariante extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public detalle?: string,
        public peso: number = 0,
        public productosAsociados: ProductoAsociado[] = [],
        public prioridad: number = 0) {
        super(codigo, descripcion, id);
    }

    public static fromData(data) {
        if (!data) return;
        let o = new ProductoVariante(
            data.id,
            data.codigo,
            data.descripcion,
            data.detalle,
            data.peso,
            data.productosAsociados ? data.productosAsociados.map(p => ProductoAsociado.fromData(p)) : []
        )
        return o;
    }
    public esFraccionado() {
        return false;
    }
    public agregarProducto() {
        this.productosAsociados.push(new ProductoAsociado(null, null, 1, 1))
    }

    public quitarProducto(producto) {
        this.productosAsociados = this.productosAsociados.filter(p => p != producto);
    }
}