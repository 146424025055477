import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { TipoTarjetaCredito } from 'src/app/model/TipoTarjetaCredito';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class TipoTarjetaCreditoService extends ParametricoService<TipoTarjetaCredito>{
    public baseName(): string {
        return "tipo-tarjeta-credito";
    }
    public parseToEnt(data: any): TipoTarjetaCredito {
        return TipoTarjetaCredito.fromData(data);
    }
    public newEnt(): TipoTarjetaCredito {
        return new TipoTarjetaCredito();
    }


}