import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CoreModule } from './../../core.module'
import { DescriptivoMaterialSelectorComponent } from './descriptivo-material-selector.component'

const pipe = []
const directives = []
const components = [DescriptivoMaterialSelectorComponent]

@NgModule({
    imports: [CoreModule, FormsModule, ReactiveFormsModule],
    providers: [],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})
export class DescriptivoModule {}
