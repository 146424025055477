import { Component, Input, OnInit } from '@angular/core'
import { IPedido } from 'src/app/model/IPedido'
import { environment } from './../../../../environments/environment'

@Component({
    selector: 'qrdisplay',
    templateUrl: './qrdisplay.component.html',
    styleUrls: ['./qrdisplay.component.less'],
})
export class QrdisplayComponent implements OnInit {
    @Input()
    public pedido: IPedido
    constructor() {}
    public get numeroPedido() {
        return this.pedido.esPager ? 'T' + this.pedido.id : this.pedido.id + ''
    }
    ngOnInit() {}

    public asociarAPuesto() {}
    public get url() {
        return this.pedido ? environment.appURL + 'agregar-pedido/' + this.numeroPedido + '/' + this.pedido.id + '/' + this.pedido.codigoVerificacion : null
    }
}
