import { EstadoDocumento } from './EstadoDocumento';



export class ComandaResumen {

  constructor(
    public id?: number,
    public estado?: EstadoDocumento,

  ) {
  }
  public static fromData(data: any): ComandaResumen {
    if (!data) return null;
    const o: ComandaResumen = new ComandaResumen(
      data.id,
      EstadoDocumento.fromData(data.estado),

    );

    return o;

  }
  public get order() {
    var codigo = this.estado ? this.estado.codigo : null;
    switch (codigo) {
      case "EE":
      case "F":
        return 99;
      case "EN":
        return 98;
      case "EP":
        return 10;

      default:
        return 0;
    }
  }

}