import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { OverlayPanel } from 'primeng/overlaypanel'
import { filter } from 'rxjs/operators'
import { Deposito } from 'src/app/model/Deposito'
import { Novedad } from 'src/app/model/Novedad'
import { DepositoService } from 'src/app/services/deposito.service'
import { LoadingService } from './../../../../common/services/loading-data-service.service'
import { MenuConfigService } from './../../../../common/services/menu-config.service'
import { TranslateService } from './../../../../common/services/translate.service'
import { StockInsumo } from './../../../../model/StockInsumo'
import { NovedadService } from './../../../../services/novedades.service'
import { StockInsumoService } from './../../../../services/stock-insumo.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'app-estado-stock',
    templateUrl: './estado-stock.component.html',
    styleUrls: ['./../dashboard-element-common.component.less', './estado-stock.component.less'],
})
export class EstadoStockComponent implements OnInit, IDashboardElement {
    public refresh() {
        return
    }
    public static TIPO = 'estado-stock'
    private _config: DashboardConfiguration
    public data: StockInsumo
    public loadingService: LoadingService = new LoadingService()

    public customLoading: LoadingService = new LoadingService()
    public stockInsumosOptions: StockInsumo[] = []
    public static defaultConfig: DashboardConfiguration = {
        fixedHeader: false,
        x: null,
        y: null,
        rows: 2,
        cols: 1,
        tipo: 'estado-stock',
        params: {},
    }
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        if (v && v.params['stock-id'] && this.data?.id != v.params['stock-id']) {
            this.stockService.getById(v.params['stock-id'], this.loadingService).then((i) => {
                this.data = i
            })
        }
    }
    constructor(
        private translateService: TranslateService,
        public menuConfService: MenuConfigService,
        public stockService: StockInsumoService,
        public novedadService: NovedadService,
        private router: Router,
        public depositoService: DepositoService
    ) {}

    private last: Novedad

    private sub: string
    @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel
    public verStock(event) {
        this.router.navigate(['stock'])
    }
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}
    public animate: boolean
    ngOnInit() {
        this.stockService.data.pipe(filter((d) => d != undefined && d.length > 0)).subscribe((d) => {
            if (this.data?.id) {
                var val = d.filter((dd) => dd.id == this.data.id)[0]
                if (val.cantidad != this.data?.cantidad) {
                    this.data = val
                    this.animate = true
                    setTimeout(() => {
                        this.animate = false
                    }, 400)
                }
            }
        })
    }
    public aplicarDepositoSeleccion(deposito: Deposito) {
        this.stockService.getByDeposito(deposito).then((s) => {
            this.stockInsumosOptions = s
        })
    }

    public aplicarSeleccion(item: StockInsumo) {
        this.op.hide()
        this.data = item
        this.config.params['stock-id'] = this.data.id
        this.onChange(this.config)
    }

    public getTitle = () => {
        return this.translateService.get('Estado de stock')
    }

    public esCritico(stockInsumo: StockInsumo): boolean {
        return stockInsumo?.cantidadCritica && stockInsumo.cantidad < stockInsumo.cantidadCritica
    }

    public esAdvertencia(stockInsumo: StockInsumo): boolean {
        return !this.esCritico(stockInsumo) && stockInsumo?.cantidadAdvertencia && stockInsumo.cantidad < stockInsumo.cantidadAdvertencia
    }
}
