import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms'
import { OverlayPanel } from 'primeng/overlaypanel'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Idioma } from 'src/app/model/Idioma'
import { IdiomaService } from './../../common/services/idioma.service'

@Component({
    selector: 'selector-idioma',
    templateUrl: './selector-idioma.component.html',
    styleUrls: ['./selector-idioma.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorIdiomaComponent),
            multi: true,
        },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectorIdiomaComponent), multi: true },
    ],
})
export class SelectorIdiomaComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    private sub: Subscription
    @ViewChild('op', { static: false })
    private op: OverlayPanel
    private _idioma: Idioma
    public get idioma(): Idioma {
        return this._idioma
    }
    @Input()
    public set idioma(v: Idioma) {
        if (v) {
            this._idioma = v
            this.onChangeCallback(this._idioma)
        }
    }
    @Input()
    public readOnly: boolean = false
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    public disabled: boolean = false
    public idiomas: Idioma[] = []
    constructor(public service: IdiomaService) {}
    public handleClick(event) {
        if (this.readOnly) {
            event.preventDefault()
        } else {
            this.op.toggle(event)
        }
    }
    registerOnValidatorChange?(fn: () => void): void {}
    public seleccionar(opcion) {
        this.idioma = opcion
    }
    writeValue(obj: any): void {
        this.idioma = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    get urlImagen(): string {
        return this.idioma?.bandera?.picPathVersion || 'assets/images/default_image.png'
    }
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }
    ngOnInit() {
        this.sub = this.service.data.pipe(filter((t) => t != undefined)).subscribe((r) => {
            this.idiomas = r
            if (!this.idioma?.codigo) {
                this.idioma = r[0]
            }
        })
    }

    validate(control: AbstractControl): ValidationErrors {
        return control ? control.errors : null
    }
}
