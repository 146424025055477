import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnInit, ViewChild } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { AppComponent } from './app.component'
import { DefaultService } from './common/services/default.service'
import { Local } from './model/Local'
import { AuthService } from './services/auth.service'
import { ConfiguracionUsuarioService } from './services/configuracion-usuario.service'
import { LocalService } from './services/local.service'

@Component({
    selector: 'app-inline-profile',
    templateUrl: 'app.profile.component.html',
    styleUrls: ['app.profile.component.less'],
    animations: [
        trigger('menu', [
            state(
                'hidden',
                style({
                    height: '0px',
                })
            ),
            state(
                'visible',
                style({
                    height: '*',
                })
            ),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
        ]),
    ],
})
export class AppInlineProfileComponent implements OnInit {
    @ViewChild('op', { static: true }) op
    _items: MenuItem[] = []
    active: boolean = true
    private sus: Subscription
    public localesDisponibles: Local[] = []
    public localSeleccionado: Local

    constructor(
        public app: AppComponent,
        private router: Router,
        public lService: LocalService,
        private dfService: DefaultService,
        private authService: AuthService,
        private confService: ConfiguracionUsuarioService
    ) {}
    private subs: Subscription[] = []
    ngOnInit() {      
        this.authService.onLogout.subscribe((u) => {
            if (u) {
                this.subs.forEach((s) => {
                    s.unsubscribe()
                })
                this.subs = []
            }
        })
        this.subs.push(this.authService.getUser().subscribe(async (u)=>{
            if(u?.id && u.configuracion?.localSeleccionado){
                this.localSeleccionado = await this.lService.getById(u.configuracion.localSeleccionado.id);
            }
        }))
        this.subs.push( this.lService.localSeleccionadoObs.subscribe((l) => {
            this.localSeleccionado = l
        }))
    }
    public seleccionarLocal(event) {
        if (this.lService.cantidad == 0 && this.usuario?.esGestorCentro()) {
            this.router.navigate(['local/nuevo'])
        } else {
            this.op.show(event)
        }
    }
    public aplicarSeleccion(local: Local) {
        this.op.hide()
        this.confService
            .setLocal(local)
            .then((r) => {
                this.localSeleccionado = local
               })
            .catch((l) => {
                this.localSeleccionado = null
            })
    }
    public editarPerfil() {
        if (!this.app.usuario) return
        let query: NavigationExtras = {
            queryParams: {
                id: this.app.usuario.id,
            },
        }
        this.router.navigate(['usuario/edit'], query)
    }
    onClick(event) {
        this.active = !this.active
        event.preventDefault()
    }
    public isMobile() {
        return window.innerWidth <= 640
    }

    public get usuario() {
        return this.app.usuario
    }
}
