<div class="filtros" [ngClass]="{ maximized: !isMinimized }">
    <button *ngIf="isMinimized && !isFixed" pButton icon="pi pi-filter" (click)="toggleDisplay($event)" class="mini-button ui-button-secondary" pTooltip="{{ 'FILTRAR' | translate }}"></button>
    <button *ngIf="!isMinimized && !isFixed" pButton icon="pi pi-sort-up" (click)="toggleDisplay($event)" class="mini-button ui-button-secondary" pTooltip="{{ 'RETRAER' | translate }}"></button>
    <button
        *ngIf="isMinimized && !isFixed"
        pButton
        icon="pi pi-filter"
        (click)="toggleDisplay($event)"
        class="mini-button ui-button-secondary"
        pTooltip="{{ 'FILTRAR' | translate }}"
    ></button>
    <button
        *ngIf="!isMinimized && !isFixed"
        pButton
        icon="pi pi-sort-up"
        (click)="toggleDisplay($event)"
        class="mini-button ui-button-secondary"
        pTooltip="{{ 'RETRAER' | translate }}"
    ></button>

    <button
        pButton
        icon="pi pi-refresh"
        (click)="filter.forceUpdate(); $event.stopPropagation()"
        class="refresh-button ui-button-secondary"
        pTooltip="{{ 'REFRESCAR' | translate }}"
    ></button>

    <button pButton icon="pi pi-trash" class="clean-button ui-button-secondary" (click)="resetFilter()" pTooltip="{{ 'BORRAR_FILTROS' | translate }}"></button>

    <div class="filtro-content" [ngClass]="{ minimized: isMinimized }">
        <ng-container>
            <ng-container *ngTemplateOutlet="filterTemplateContent || filterContent"></ng-container>
        </ng-container>
    </div>
    <div class="filtro-summary p-fluid" [ngClass]="{ maximized: !isMinimized }" (click)="toggleDisplay($event)">
        <div class="grid grid-nogutter">
            <div class="p-inputgroup lg:col-2 col-12" *ngIf="verBuscador">
                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                <input
                    #gf
                    [(ngModel)]="filter.searchStr"
                    type="text"
                    pInputText
                    [id]="idGlobalFiltro"
                    placeholder="{{ 'BUSCAR' | translate }}."
                    (click)="$event.stopPropagation()"
                    (focusin)="onFocus($event)"
                    (input)="table ? table.filtroGlobal($event.target.value, 'contains') : ''"
                /> 
            </div>
            <div class="lg:col-9 col-12" [ngClass]="{ 'col-12': !verBuscador }">
                <span *ngIf="filter?.summary?.length == 0">{{ 'SIN_FILTROS_SELECCI_5' | translate }}</span>
                <span *ngFor="let item of filter?.summary"
                    >{{ item?.titulo }} <strong pTooltip="{{ item?.valor }}">{{ item?.valor }}</strong></span
                >
            </div>
            <div class="count" *ngIf="filter?.count">
                <!--span
                    >#<strong>{{ filter?.count }}</strong></span
                -->
            </div>
        </div>
    </div>
</div>
<ng-template #filterContent>
    <div class="grid">
        <div class="col-12">
            <ng-content></ng-content>
        </div>

        <div class="col-2 sm-6" *ngIf="!autoSearch && !isMinimized">
            <button
                pButton
                icon="pi pi-search"
                [disabled]="!filter?.valid()"
                label="{{ 'APLICAR_FILTROS' | translate }}"
                (click)="search()"
                pTooltip="{{ 'APLICAR_FILTROS' | translate }}"
            ></button>
        </div>
    </div>
</ng-template>

<p-sidebar [(visible)]="displayFiltros" #filterBar position="right" [style]="{ width: isMobile() ? '100vw' : '30vw' }">
    <div class="header-filtros">
        <h2>Filtros</h2>
    </div>
    <p-scrollPanel  [style]="{height:'calc(100vh - 3em)',width: '100%', 'border':'none', 'padding':'0.25rem 0.5rem'}">
        <ng-container *ngTemplateOutlet="filterTemplateContent"> </ng-container>
    </p-scrollPanel>
</p-sidebar>