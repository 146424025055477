import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Usuario } from '../common/model/Usuario';
import { AuthService } from '../services/auth.service';






@Injectable()
export class AdminAuthGuard implements CanActivate {

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {

        return this.auth.getUser().pipe(filter(u => u != undefined), map(u => {
            return this.esVisible(u);
        }), catchError(e => of(false)));
    }

    private visible: boolean = false;
    constructor(private auth: AuthService) {
        this.auth.getUser().subscribe(u => {
            this.visible = this.esVisible(u);

        });

    }
    public getRoles(): string[] { return ["ROLE_ADMIN"] };

    public esVisible(user: Usuario): boolean {
        return user && user.tieneRol(["ROLE_ADMIN"].concat(this.getRoles()));
    }


}