import { TraspasoCajaAG } from './../../authguards/TraspasoCajaAG';
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { AdministracionAuthGuard } from './../../authguards/AdministracionAuthGuard'
import { VisualizadorAuthGuard } from './../../authguards/VisualizadorAuthGuard'
import { GestorTraspasoDeCajaComponent } from './../traspaso-de-caja/gestor-traspaso-de-caja/gestor-traspaso-de-caja.component'
import { ListadoTraspasoDeCajaComponent } from './../traspaso-de-caja/listado-traspaso-de-caja/listado-traspaso-de-caja.component'
import { GestorArqueoCajaComponent } from './gestor-cambio-estado-caja/gestor-cambio-estado-caja.component';
import { AperturaCajaAG } from 'src/app/authguards/AperturaCajaAG';

const pipe = []
const directives = []
const components = [GestorArqueoCajaComponent]
const routes: Routes = [
    { path: 'edit', pathMatch: 'full', data: { animation: 'Item' }, component: GestorArqueoCajaComponent, canActivate: [AperturaCajaAG] },
    { path: 'vista', component: GestorArqueoCajaComponent, canActivate: [VisualizadorAuthGuard], data: { vista: true, animation: 'Item' } },
    { path: 'nuevo', data: { animation: 'Item' }, component: GestorArqueoCajaComponent, canActivate: [AperturaCajaAG] },
]
@NgModule({
    imports: [RouterModule.forChild(routes), CommonComponentsModule],
    providers: [],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})

export class CambioEstadoCajaModule { }