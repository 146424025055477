<div class="grid container">
    <ng-container>
        <div class="pedidos col-12">
            <div class="pedido" *ngFor="let p of pedidos" (click)="verPedido(p)" [ngClass]="{'animate': p?.animate}">
                <div class="card" [@appear]>
                    <div class="grid align-items-center">
                        <div class="estado" style="width: 5em;" [ngStyle]="{'border-left-color': p.estado?.color}"
                            pTooltip="{{p.estado?.descripcion}}">
                            #{{p.numeroPedido}}
                        </div>
                        <div class="col data">
                            <div class="grid  grid grid-nogutter">
                                <div class="col overflow">
                                    <span *ngIf="!p.nombreContacto">{{p.cliente?.descripcion}}</span>
                                    <span *ngIf="p.nombreContacto">{{p.nombreContacto}}</span>
                                </div>

                                <div class="col text-right importe">
                                    <span>{{p.total| currency}}</span></div>
                                <div class="col-12 notas" *ngIf="p?.observaciones">
                                    <span class="text-right">{{p.observaciones}}</span>
                                </div>
                            </div>
                            <div class="grid  grid grid-nogutter">
                                <div>
                                    <contactos [data]="getContacto(p)" [whatsapp]="true"></contactos>
                                    <span class="error-span" *ngIf="p.conError?.length">
                                        {{p.conError}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="acciones">
                            <button pButton *ngFor="let accion of acciones | visibles:p" [icon]="accion.icon"
                                (click)="accion.command(p); $event.stopPropagation();" [pTooltip]="accion.tooltip"
                                [class]="'accion-tabla '+ accion.styleClass"></button>
                        </div>


                    </div>

                </div>

            </div>
        </div>

    </ng-container>
    <loading-panel [loadingService]="customLoading"></loading-panel>
</div>
<p-dialog [(visible)]="verQR" [modal]="true">
    <qrdisplay [pedido]="itemEditado"></qrdisplay>
</p-dialog>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of localService.data | async" (click)="aplicarSeleccion(c)">
            {{c.descripcion}}
        </li>
    </ul>
</p-overlayPanel>


<!--p-dialog header="" [(visible)]="editandoPedido" appendTo="body" [modal]="true" #dialDetail appendTo="body"
    [styleClass]="'no-header gestor-dialog'" (onShow)="onDialogShow($event,dialDetail)"
    style="width: 90vw; height: 80vh;">
    <gestor-pedido #gestorPedido [item]="pedidoEditado" (onCancelado)="onCancelar($event)" [goBack]="false"
        [isModal]="true" [readonly]="true" [editable]="false" *ngIf="pedidoEditado && editandoPedido">
    </gestor-pedido>
</p-dialog-->

<p-dialog
    [style]="{ width: '70vw' }"
    header="¿Desea rechazar el pedido {{ itemEditado.numeroPedido }}?"
    [(visible)]="displayMotivo"
    [modal]="true"
    closable="false"
    *ngIf="itemEditado"
>
    <div class="p-grid">
        <div class="p-col-12">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Motivo de rechazo</mat-label>
                <input matInput [(ngModel)]="itemEditado.motivoCancelacion" placeholder="Motivo" type="string" #motivo="ngModel" name="motivo" required />
            </mat-form-field>
        </div>
        <div class="p-col-12 checkbox" *ngIf="itemEditado.comprobante && hayCobros">
            <p-checkbox binary="true" [(ngModel)]="hacerDevolucion" label="Generar devolución"> </p-checkbox>
        </div>
    </div>
    <app-gestor-devolucion [(ngModel)]="itemDevolucion" [itemGestorInput]="{ pedido: itemEditado, displayDevolucion: hacerDevolucion }">
    </app-gestor-devolucion>
    <p-footer>
        <button pButton class="boton" (click)="displayMotivo = false; itemEditado.motivoCancelacion = ''" label="Salir sin guardar"></button>
        <button pButton class="boton" (click)="cancelarPedido()" label="Cancelar Pedido"></button>
    </p-footer>
</p-dialog>
