import { Injectable } from '@angular/core';
import { Idioma } from 'src/app/common/model/Idioma';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
@Injectable({
    providedIn: 'root',
})
export class IdiomaService extends ParametricoService<Idioma>{
    public baseName(): string {
        return "idioma";
    }
    public parseToEnt(data: any): Idioma {
        return Idioma.fromData(data);
    }
    public newEnt(): Idioma {
        return new Idioma();
    }




}