import { Injectable } from '@angular/core';
import { Filtro } from 'src/app/common/model/Filtro';

@Injectable({
    providedIn:'root'
})
export class FilterService {
    private currentFilter: Map<string,any> = new Map();  
    public hasFilter(entityName: string){
        return this.currentFilter.has(entityName);
    }
    public getFilter(entityName: string, def?:Filtro){
        return this.currentFilter.has(entityName)? this.currentFilter.get(entityName) : (def || new Filtro(entityName,{}))
    }

    public setFilter(entityName:string, filter:Filtro){
        this.currentFilter.set(entityName,filter);
    }
    
    

}