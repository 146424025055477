<messages></messages>
<p-confirmDialog [key]="'genConf'" icon="fa fa-question-circle" acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
<div
    class="layout-wrapper"
    [ngClass]="{
        'p-input-outlined': app.inputStyle === 'outlined',
        'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL
    }"
    [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme"
>
    <app-topbar></app-topbar>

    <app-rightmenu></app-rightmenu>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-inline-profile></app-inline-profile>
            <app-menu></app-menu>
        </div>
    </div>

    <div class="layout-main">
        <div class="layout-content" [@triggerName]="prepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>

            <app-footer></app-footer>
            <loading-panel #gloading></loading-panel>
        </div>
    </div>
    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>
