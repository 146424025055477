import { Injectable } from '@angular/core';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { Deposito } from 'src/app/model/Deposito';
@Injectable({
    providedIn: 'root',
})
export class DepositoService extends DescriptivosService<Deposito>{
    public baseName(): string {
        return "deposito";
    }
    public parseToEnt(data: any): Deposito {
        return Deposito.fromData(data);
    }
    public newEnt(): Deposito {
        return new Deposito();
    }


}