import { NgModule } from '@angular/core'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { GestorCambioSaldoCajaComponent } from './../cambio-saldo-caja/gestor-cambio-saldo-caja/gestor-cambio-saldo-caja.component'
import { ListadoCambioSaldoCajaComponent } from './../cambio-saldo-caja/listado-cambio-saldo-caja/listado-cambio-saldo-caja.component'
import { CambioSaldoCajaRoutingModule } from './cambio-saldo-caja-routing.module'

const pipe = []
const directives = []
const components = [GestorCambioSaldoCajaComponent, ListadoCambioSaldoCajaComponent]

@NgModule({
    imports: [CambioSaldoCajaRoutingModule, CommonComponentsModule],
    providers: [],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})
export class CambioSaldoCajaModule {}
