import { Pedido } from 'src/app/model/Pedido';
import { Injectable } from '@angular/core'
import { ItemComprobante } from '../model/ItemComprobante'
import { Ticket } from '../model/Ticket'
import { Comprobante, ComprobanteResumen } from './../model/Comprobante'
import { CentroCostoService } from './centro-costo.service'
import { ClienteService } from './cliente.service'
import { ComprobanteService } from './comprobante.service'
import { PedidoService } from './pedido.service'

@Injectable({
    providedIn: 'root',
})
export class TicketService {
    public itemsAgrupados: any

    constructor(
        public ccService: CentroCostoService,
        public clienteService: ClienteService,
        public pedidoService: PedidoService,
        public comprobanteService: ComprobanteService
    ) {}

    async llenarTicket(comprobante: ComprobanteResumen, pedido?: Pedido, esResumen = false): Promise<Ticket> {
        let ticket = new Ticket()

        const centro = comprobante?.centroCosto ?? pedido.centro

        const proms: Promise<any>[] = []
        if (!esResumen && comprobante?.cliente?.id) {
            proms.push(
                this.clienteService.getById(comprobante.cliente.id).then((c) => {
                    ticket.cliente = c
                }) 
            )
        }

        if (comprobante.pedido) {
            proms.push(
                this.pedidoService.getById(comprobante.pedido.id).then((c) => {
                    ticket.pedido = c
                })
            )
        }

        proms.push(
            this.comprobanteService.getCobros(comprobante.id || 0).then((cc) => {
                ticket.cobranzas = cc
            })
        )

        proms.push(
            this.ccService.getByCodigo(centro?.codigo).then((cc) => {
                ticket.centroCosto = cc
            })
        )

        if (!esResumen) {
            proms.push(
                this.comprobanteService.getById(comprobante.id || 0).then((c) => {
                    ticket.comprobante = c
                })
            )
        } else if (pedido?.items?.length) {
            ticket.comprobante = Comprobante.fromData(comprobante)
            pedido.items.forEach((item) => {
                let i = ItemComprobante.fromData(item)
                i.descripcion = item.producto.descripcion
                ticket.comprobante.items.push(i)
            })
        }

        return Promise.all(proms).then((v) => {
            return ticket
        })
    }
}
