import { Moneda } from 'src/app/model/Moneda'
export class Totales {
    public totalesPorMoneda: TotalMoneda[] = []
    public totalConsumido: number = 0
    public totalPagado: number = 0
    public totalSaldo: number = 0
    public count: number
    constructor(data?) {
        if (data) Object.assign(this, data)
    }
    public get pagos() {
        return this.totalPagado ? this.totalPagado : this.totalSaldo ? Math.abs(this.totalConsumido - this.totalSaldo) : this.totalConsumido
    }
    public agregarTotal(tot: TotalMoneda) {
        this.totalConsumido += tot.monto
        this.totalSaldo += tot.saldo
        this.count += tot.count
        this.totalesPorMoneda.push(tot)
    }
}
export class TotalMoneda {
    constructor(public moneda: Moneda, public monto: number, public saldo: number, public count: number) {}
}
