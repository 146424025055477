<div class="grid container">
    <div class="col-12" *ngIf="!data">
        <span> Elija un Stock</span>
    </div>

    <div class="col-12" *ngIf="data">
        <div class="card" (click)="verStock($event)" [ngClass]="{ stockCritico: esCritico(data), stockAdvertencia: esAdvertencia(data) }">
            <div class="grid">
                <div class="col-12 text-center producto overflow" pTooltip="{{ data.insumo?.descripcion }}">
                    <span class="">{{ data.insumo?.descripcion }}</span>
                </div>
                <div class="col-12 valor" [ngClass]="{ grow: animate }">
                    <span>{{ data.cantidad | number: '1.0-3' }}
                        <span class="unidad">{{ data?.insumo?.unidad?.codigo }}</span>
                    <span class="icon">
                            <i
                                class="fa fa-exclamation-triangle iconAdvertencia"
                                *ngIf="esAdvertencia(data)"
                                pTooltip="{{ 'Nivel de Stock Bajo' | translate }}"
                            ></i>
                            <i class="fa fa-exclamation-circle iconCritico" *ngIf="esCritico(data)" pTooltip="{'Nivel de Stock Critico'|translate}}"></i>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <loading-panel [loadingService]="customLoading"></loading-panel>
</div>
<p-overlayPanel #op [dismissable]="false" appendTo="body">
    <descriptivo-material-selector [service]="depositoService" [label]="'DEPOSITO' | translate" [isLazy]="true" (onSelect)="aplicarDepositoSeleccion($event)" [permiteNuevo]="false" name="depositos">
    </descriptivo-material-selector>
    <descriptivo-material-selector *ngIf="stockInsumosOptions?.length" [options]="stockInsumosOptions" [label]="'PRODUCTOS' | translate" [isLazy]="false" (onSelect)="aplicarSeleccion($event)" [permiteNuevo]="false" name="productos">
    </descriptivo-material-selector>
</p-overlayPanel>