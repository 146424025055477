<div class="login content">
  <div class="grid login-content">
    <div class="lg:col-6 col-12 left">
      <img src="assets/implementacion/images/logo.png" />
    </div>
    <div class="lg:col-6 col-12 right">
      <div class="grid align-items-center justify-content-center" style="margin-top:1.5em">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input type="text" matInput placeholder="{{'PH_EMAIL' | translate}}" [(ngModel)]="email" required>
          </mat-form-field>
        </div>
        <div class="col-12">
          <button pButton label="{{'RECUPERAR_CONTRASEÑA' | translate}}" (click)="recuperarPassword()"
            [disabled]="!verified || !email || finalizado"></button>
        </div>
        <re-captcha (resolved)="resolved($event)" [siteKey]="key"></re-captcha>
      </div>
    </div>
  </div>
</div>