<div class="gestor-cuenta-contable content content-white">
    <model-gestor
        [service]="service"
        [(item)]="item"
        [validar]="isValid"
        [modelName]="'CUENTA-CONTABLE' | translate"
        [isModal]="isModal"
        [goBack]="goBack"
        (onItemGuardado)="handleGuardado($event)"
        (onCancelar)="handleCancelar($event)"
        [form]="CuentaContableForm"
        [readonly]="readonly"
    >
        <div class="gestor-form" model-type="dataContent">
            <form #CuentaContableForm="ngForm" id="CuentaContableform">
                <div class="grid" *ngIf="item">
                    <div class="col-12 lg:col-2">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'CODIGO' | translate }}</mat-label>
                            <input
                                matInput
                                placeholder="{{ 'CODIGO_PH' | translate }}"
                                [(ngModel)]="item.codigo"
                                pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
                                type="text"
                                #codigo="ngModel"
                                name="codigo"
                            />
                            <mat-error>
                                {{ getErrorMessage(codigo) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 lg:col-6">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'DESCRIPCION' | translate }}</mat-label>
                            <input
                                matInput
                                placeholder="{{ 'DESCRIPCION_PH' | translate }}"
                                [(ngModel)]="item.descripcion"
                                pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
                                type="text"
                                #descripcion="ngModel"
                                name="descripcion"
                                autocomplete="off"
                            />
                            <mat-error>
                                {{ getErrorMessage(descripcion) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 lg:col-4">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'CATEGORIA' | translate }}</mat-label>
                            <input
                                matInput
                                placeholder="{{ 'CATEGORIA_PH' | translate }}"
                                [(ngModel)]="item.categoria"
                                pTooltip="{{ 'CATEGORIA_TOOLTIP' | translate }}"
                                type="text"
                                #categoria="ngModel"
                                name="categoria"
                            />
                            <mat-error>
                                {{ getErrorMessage(categoria) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>
