import { Injectable } from "@angular/core";

@Injectable()
export class ColorUtils {

	public resolverEstilosContraste(colorHex, estiloPrincipal, estiloSecundario) {
		ColorUtils.contraste(colorHex)


	}
	public static contraste(colorHex: string) {
		var result;
		var rgb;
		var colorSecundario
		if (colorHex && !colorHex.startsWith('rgb')) {
			result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorHex);
			rgb = result ? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
			} : null;

		} else {
			result = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/.exec(colorHex);
			rgb = result ? {
				r: parseInt(result[1]),
				g: parseInt(result[2]),
				b: parseInt(result[3])
			} : null;

		}
		return (rgb && Math.round(rgb.r * .299 +
			rgb.g * .587 +
			rgb.b * .114) > 125) ? '#000000' : '#ffffff';

	}

}