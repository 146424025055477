import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { ConfiguracionUsuarioService } from './../../services/configuracion-usuario.service';
import { Descriptivo } from './../model/Descriptivo';

@Injectable({
    providedIn: "root"
})
export class DefaultService {

    private static KEY = "defaults" + environment.tokenKey;
    private _map: Map<string, Descriptivo> = new Map();
    constructor(private confService: ConfiguracionUsuarioService) {

    }
    cleanDefault(name: string) {
        this.confService.clear(name);

    }

    public clean() {
        this.confService.get().then(d => {
            d.defaults.clear();
            this.confService.save();
        })

    }

    public async getDefault(name) {
        const conf = (await this.confService.get());
        return conf ? conf.defaults.get(name) : null;
    }
    public async setDefault(name: string, data: any) {
        const conf = (await this.confService.get());
        if (conf) {
            conf.defaults.set(name, Descriptivo.fromData(data));
            this.confService.save();
        }
    }
}