<ng-container *ngIf="multiple; else simple">
    <div class="grid">
        <div class="col-12">
            <selector-multiple-chips
                [(ngModel)]="localSeleccionado"
                name="{{ name }}"
                [opciones]="localService.data | async"
                [permiteNuevo]="false"
                [readonly]="readonly || !seleccionable"
                [titulo]="label | translate"
                [required]="required"
            >
            </selector-multiple-chips>
        </div>
    </div>
</ng-container>
<ng-template #simple>
    <div class="grid">
        <div class="col-12">
            <descriptivo-material-selector
                [label]="label | translate"
                [limpiable]="true"
                [required]="required"
                [permiteNuevo]="false"
                [(ngModel)]="localSeleccionado"
                name="{{ name }}"
                [isLazy]="true"
                [service]="localService"
                [readonly]="readonly || !seleccionable"
            >
            </descriptivo-material-selector>
        </div>
    </div>
</ng-template>
