<div class="gestor-nota content content-white">
    <model-gestor
        [service]="service"
        [(item)]="item"
        [validar]="isValid"
        [modelName]="'Nota' | translate"
        [verAcciones]="true"
        [isModal]="isModal"
        [goBack]="goBack"
        (onItemGuardado)="handleGuardado($event)"
        [hideTitle]="hideTitle"
        [editable]="esEditable"
        (onCancelar)="handleCancelar($event)"
        [form]="NotaForm"
        [multilenguaje]="false"
        (onEditar)="onEdit($event)"
    >
        <div model-type="title" *ngIf="isModal" class="text-right modal-actions">
            <button pButton icon="pi pi-pencil" (click)="onEdit($event)" *ngIf="readonly"></button>
            <button pButton icon="pi pi-trash" (click)="delete($event)" *ngIf="item?.id"></button>
        </div>
        <div class="gestor-form" model-type="dataContent">
            <form #NotaForm="ngForm" id="Notaform">
                <div class="grid" *ngIf="item">
                    <div class="col-12" [ngClass]="{ 'lg:col-4': !isModal }">
                        <div class="grid">
                            <div class="col-fixed col-codigo">
                                <color-picker [(ngModel)]="item.color" [readonly]="readonly" name="color"> </color-picker>
                            </div>

                            <div class="col-3" *ngIf="!(!item.esPermanente && item.id)">
                                <p-toggleButton
                                    [(ngModel)]="esPermanente"
                                    name="Es Permanente"
                                    onLabel="{{ 'ES_PERMANENTE' | translate }}"
                                    offLabel="{{ 'ES_PERMANENTE' | translate }}"
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    [disabled]="readonly || item.id"
                                    *ngIf="(!readonly && ((esPermanente && item.id) || !item.id)) || (readonly && esPermanente)"
                                ></p-toggleButton>
                            </div>

                            <div class="col" *ngIf="!esPermanente">
                                <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                    <mat-label>{{ 'FECHANOTA' | translate }}</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="fechaNotaPick"
                                        [(ngModel)]="item.fechaNota"
                                        #fechaNota="ngModel"
                                        name="fechaNota"
                                        [readonly]="readonly || item.id"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="fechaNotaPick" [disabled]="readonly || item.id"></mat-datepicker-toggle>
                                    <mat-datepicker #fechaNotaPick></mat-datepicker>
                                    <mat-error>
                                        {{ getErrorMessage(fechaNota) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col" *ngIf="!esPermanente">
                                <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                    <mat-label>{{ 'FECHAHASTA' | translate }}</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="fechaHastaPick"
                                        [(ngModel)]="item.fechaHasta"
                                        #fechaHasta="ngModel"
                                        name="fechaHasta"
                                        [readonly]="readonly || item.id"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="fechaHastaPick" [disabled]="readonly || item.id"></mat-datepicker-toggle>
                                    <mat-datepicker #fechaHastaPick></mat-datepicker>
                                    <mat-error>
                                        {{ getErrorMessage(fechaNota) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <div class="grid">
                                    <centro-selector
                                        [multiple]="false"
                                        [(ngModel)]="item.centroCosto"
                                        #centroSelector
                                        [name]="'centros'"
                                        [readonly]="readonly"
                                        [required]="true"
                                        class="col-6"
                                    >
                                    </centro-selector>

                                    <local-selector
                                        [multiple]="true"
                                        [(ngModel)]="item.localesHabilitados"
                                        [name]="'locales'"
                                        [readonly]="readonly"
                                        [required]="true"
                                        class="col-6"
                                    >
                                    </local-selector>
                                </div>
                            </div>

                            <div class="col-12 lg:col-6" *ngIf="item?.id">
                                <div class="grid">
                                    <div class="col-12">
                                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                                            <mat-label>{{ 'ULTIMA_MODIFICACIÓN' | translate }}</mat-label>
                                            <input type="text" matInput hidden name="dummy" />
                                            <span>{{ item?.ultimaModificacion | date: 'dd/MM/yyyy' }} - {{ item?.responsable?.descripcion }}</span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" [ngClass]="{ 'lg:col-8': !isModal }" *ngIf="!readonly">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Cabecera' | translate }}</mat-label>
                            <input type="text" hidden matInput name="dummy" />
                            <p-editor
                                [(ngModel)]="item.cabecera"
                                name="cabecera"
                                tabindex="10"
                                [readonly]="readonly"
                                required
                                [style]="{ height: '100%', 'max-height': '20vh', 'min-height': '15vh', overflow: 'auto', 'background-color': 'white' }"
                            >
                            </p-editor>
                            <!--email-editor (loaded)="editorLoaded($event)" #editor [options]="options"></email-editor-->
                            <mat-error>
                                {{ getErrorMessage(cabecera) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12" [ngClass]="{ 'lg:col-8': !isModal }" *ngIf="readonly">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Cabecera' | translate }}</mat-label>
                            <input type="text" hidden matInput name="dummy" />
                            <div [innerHtml]="item.cabecera"></div>
                        </mat-form-field>
                    </div>
                    <div class="col-12" [ngClass]="{ 'lg:col-8': !isModal }" *ngIf="!readonly">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Nota' | translate }}</mat-label>
                            <input type="text" hidden matInput name="dummy" />
                            <p-editor
                                [(ngModel)]="item.contenido"
                                name="nota"
                                tabindex="10"
                                [readonly]="readonly"
                                [style]="{ height: '100%', 'max-height': '40vh', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }"
                            >
                            </p-editor>
                            <!--email-editor (loaded)="editorLoaded($event)" #editor [options]="options"></email-editor-->
                            <mat-error>
                                {{ getErrorMessage(contenido) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12" [ngClass]="{ 'lg:col-8': !isModal }" *ngIf="readonly">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Nota' | translate }}</mat-label>
                            <input type="text" hidden matInput name="dummy" />
                            <div [innerHtml]="item.contenido"></div>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>
