import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Nota } from 'src/app/model/Nota';
import { NotaService } from 'src/app/services/nota.service';
import { FiltroFecha } from './../../../common/model/FiltroFecha';


@Component({
  selector: 'listado-nota',
  templateUrl: 'listado-nota.component.html',
  styleUrls: ['listado-nota.component.less']
})

export class ListadoNotaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: FiltroFecha;

  @Input()
  public guardarFiltro: boolean = true;
  @Input()
  public ocultarCabecera: boolean = false;
  @Input()
  public editable: boolean = true;
  public listado: Nota[] = [];
  @Input()
  public readonly: boolean = false;
  @Input()
  public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';

  @Output()
  public onSelect: EventEmitter<Nota[]> = new EventEmitter();

  public columns = [];
  constructor(messagesService: MessagesService,
    public service: NotaService) {
    super(messagesService)

  }
  ngOnInit() {
    this.filtro = new FiltroFecha("nota_lista", null, 0, 100, "fechaNota", 1, true)
    this.columns = [
      {
        "field": "fechaNota",
        "header": "Fecha Nota",
        "class": "date-tabla",
        "role": "date-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "ultimaModificacion",
        "header": "Ultima Modificacion",
        "class": "date-tabla",
        "role": "date-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "responsable",
        "header": "Responsable",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },
      {
        "field": "contenido",
        "header": "Contenido",
        "class": "text-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 40
      },

    ];
  }

  public updateSeleccion(seleccion: any) {
    if (seleccion?.length || seleccion['id']) {
      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
    }
  }
  ngOnDestroy() { }



}