import { Injectable } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { BufferedDescriptivoService } from 'src/app/services/BufferedDescriptivoService';
import { ProductoService } from 'src/app/services/producto.service';
import { ListaPrecios } from '../model/ListaPrecios';
import { ListaProducto } from '../model/ListaProducto';
import { LoadingService } from './../../common/services/loading-data-service.service';
import { MessagesService } from './../../common/services/messages-data-service.service';
import { FiltroProducto } from './../../model/FiltroProducto';
import { AuthService } from './../../services/auth.service';
import { NovedadService } from './../../services/novedades.service';
@Injectable({
    providedIn: 'root',
})
export class ListaPreciosService extends BufferedDescriptivoService<ListaPrecios>{

    /*constructor(ns: NovedadService, ms?: MessagesService) {
        super(ns, new BehaviorSubject<ListaPrecios[]>([]), ms)
    }*/
    constructor(novedadesService: NovedadService, productoService: ProductoService, authService: AuthService, messServ?: MessagesService,) {
        super(novedadesService, authService, messServ);
        productoService.onChange.subscribe(c => {
            this.updateEnt(null);
        })
    }
    public baseName(): string {
        return "lista-precios";
    }
    public parseToEnt(data: any): ListaPrecios {
        return ListaPrecios.fromData(data);
    }
    public newEnt(): ListaPrecios {
        return new ListaPrecios();
    }
    public getDefault(): Promise<ListaPrecios> {
        return this.getData().then(r => {
            const def = r.filter(l => l.esDefault)[0];
            return def ? def : r[0];
        })
    }
    public getProductos(filtro: FiltroProducto, customLoading?: LoadingService): Promise<ListaProducto[]> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return this.http.post(this.getBaseURL() + "getProductos", filtro.json).toPromise().then((res: any) => {
            return res.map(p => ListaProducto.fromData(p));
        }).finally(() => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
        });
    }

    guardarEdicionMasiva(idLista: number, productos: Descriptivo[], desdeMasivo: Date, hastaMasivo: Date, montoMasivo: number, edicion: boolean, suma: boolean, porcentaje: boolean) {
        return this.http.post(this.getBaseURL() + "guardarEdicionMasiva", {
            'idLista': idLista,
            'productos': productos,
            'desde': desdeMasivo,
            'hasta': hastaMasivo,
            'monto': montoMasivo,
            'edicion': edicion,
            'suma': suma,
            'porcentaje': porcentaje
        })
            .toPromise()
            .then((r) => this.parseSingle(r), this.handleError);
    }

    copiar(item: ListaPrecios, nombre: string) {

        item.id = null;
        item.descripcion = nombre;
        item.productos?.forEach(p => p.id = null)

        return this.guardar(item).then(i => this.parseSingle(i))

    }


}
