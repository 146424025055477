import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Filtro } from 'src/app/common/model/Filtro';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';
import { MessagesService } from './../common/services/messages-data-service.service';
import { AuthService } from './auth.service';
import { BufferedService } from './BufferedService';
import { NovedadService } from './novedades.service';
export abstract class BufferedDescriptivoService<E extends Descriptivo> extends BufferedService<E>{
    constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService,) {
        super(novedadesService, authService, messServ);
    }
    public getDescriptivosByDesc(query: any, customLoading?: LoadingService): Promise<E[]> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return this.http.get(this.getBaseURL() + "getByDesc/?val=" + query).toPromise().then(r => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
            return this.parse(r)
        }).catch((e) => this.handleError(e, customLoading));
    }
    getDescriptivos(filter: Filtro = new Filtro("", {}, 0, 100), customLoading?: LoadingService): Promise<Descriptivo[]> {
        let $this = this;
        let p: Promise<Descriptivo[]>;
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        p = this.getData(filter, customLoading).then(r => { return r.map(p => Descriptivo.fromData(p)) });

        p.finally(() => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
        });
        return p;
    }
    public getByCodigo(codigo: string, customLoading?: LoadingService): Promise<E> {
        let $this = this;
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        let p;
        if (this._data?.length) {
            p = Promise.resolve(this._data.filter(d => d.codigo == codigo)[0]);
        } else {
            p = this.loadData().then(r => {
                return Promise.resolve(this._data.filter(d => d.codigo == codigo)[0]);
            })
        }
        p.finally(() => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
        });
        return p;
    }
}