import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { BehaviorSubject, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AppComponent } from './app.component'
import { AdminAuthGuard } from './authguards/AdminAuthGuard'
import { AdministracionAuthGuard } from './authguards/AdministracionAuthGuard'
import { ElaboradorAG } from './authguards/ElaboradorAG'
import { EmpleadoAuthGuard } from './authguards/EmpleadoAuthGuard'
import { GestorReservasAG } from './authguards/GestorReservasAG'
import { UsuarioAG } from './authguards/UsuarioAG'
import { Usuario } from './common/model/Usuario'
import { MenuConfigService } from './common/services/menu-config.service'
import { AuthService } from './services/auth.service'

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.less'],
})
export class AppMenuComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        if (this.userSub) this.userSub.unsubscribe
    }

    @Input() reset: boolean
    public searchStr: string
    public results: MenuItem[] = []

    private usuario: Usuario
    private userSub: Subscription
    public _menuItems: MenuItem[]
    public rutas: BehaviorSubject<MenuItem[]> = new BehaviorSubject([])
    exit = {
        label: 'Cerrar sesión',
        visible: true,
        command: () => {
            this.authService.logout()
            this.router.navigate(['login'])
        },
        icon: 'pi pi-sign-out ',
    }
    constructor(
        public app: AppComponent,
        public menuConfService: MenuConfigService,
        public adminAuthGuard: AdminAuthGuard,
        private authService: AuthService,
        private gReservas: GestorReservasAG,
        private elaboradorAG: ElaboradorAG,
        public userAG: UsuarioAG,
        public empleadoAuthGuard: EmpleadoAuthGuard,
        public administracionAG: AdministracionAuthGuard,
        public router: Router
    ) {}

    ngOnInit() {
        this._menuItems = []
        let $this = this
        this.actualizarRegistrado()
        this.app.translateService.upateData.subscribe((r) => {
            this.actualizarRegistrado()
        })
        this.menuConfService.menuBS.subscribe((m) => {
            this._menuItems = []
            m.forEach((menu) => {
                if (menu.items?.length) {
                    this._menuItems = this._menuItems.concat(menu.items)
                } else {
                    this._menuItems.push(menu)
                }
            })
        })
        this.authService
            .getUser()
            .pipe(filter((u) => u != undefined))
            .subscribe((u) => {
                this.usuario = u
                this.actualizarRegistrado()
            })
    }

    actualizarRegistrado() {
        let $this = this
    }

    public navegarParam(path, multilenguaje: boolean = false) {
        this.router.navigate(['/parametros'], { queryParams: { tipo: path, multi: multilenguaje } })
    }

    public navegar(path, query?) {
        this.router.navigate([path], { queryParams: query })
    }
    public search(event) {
        this.results = this._menuItems.filter((m) => m.label.toUpperCase().trim().includes(event.query.toUpperCase().trim()))
    }
    public menuPick(event) {
        if (event?.routerLink) {
            this.router.navigate(event?.routerLink)
        } else if (event?.command) {
            event.command()
        }
        this.searchStr = null
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="root ? item : item.items">
            <li [ngClass]="{ 'active-menuitem': isActive(i) }" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
                <a
                    [href]="child.url || '#'"
                    (click)="itemClick($event, child, i)"
                    (mouseenter)="onMouseEnter(i)"
                    class="ripplelink"
                    *ngIf="!child.routerLink"
                    [attr.tabindex]="!visible ? '-1' : null"
                    [attr.target]="child.target"
                >
                    <i *ngIf="child.icon" class="menu-icon {{ child.icon }}"></i>
                    <span>{{ child.label }}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
                    <i class="pi pi-caret-down" *ngIf="child.items"></i>
                </a>

                <a
                    (click)="itemClick($event, child, i)"
                    (mouseenter)="onMouseEnter(i)"
                    class="ripplelink"
                    *ngIf="child.routerLink"
                    [routerLink]="child.routerLink"
                    routerLinkActive="active-menuitem-routerlink"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [attr.tabindex]="!visible ? '-1' : null"
                    [attr.target]="child.target"
                >
                    <i *ngIf="child.icon" class="menu-icon {{ child.icon }}"></i>
                    <span>{{ child.label }}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
                    <i class="pi pi-caret-down" *ngIf="child.items"></i>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">{{ child.label }}</div>
                </div>
                <ul
                    app-submenu
                    [item]="child"
                    *ngIf="child.items"
                    [visible]="isActive(i)"
                    [reset]="reset"
                    [parentActive]="isActive(i)"
                    [@children]="root ? (isActive(i) ? 'visible' : 'hidden') : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"
                ></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state(
                'hiddenAnimated',
                style({
                    height: '0px',
                })
            ),
            state(
                'visibleAnimated',
                style({
                    height: '*',
                })
            ),
            state(
                'visible',
                style({
                    height: '*',
                    'z-index': 100,
                })
            ),
            state(
                'hidden',
                style({
                    height: '0px',
                    'z-index': '*',
                })
            ),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
        ]),
    ],
})
export class AppSubMenuComponent {
    @Input() item: MenuItem

    @Input() root: boolean

    @Input() visible: boolean

    _reset: boolean

    _parentActive: boolean

    activeIndex: number

    constructor(public app: AppComponent) {}

    itemClick(event: Event, item: MenuItem, index: number) {
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault()
            return true
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = this.activeIndex === index ? null : index

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item })
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index
    }

    @Input() get reset(): boolean {
        return this._reset
    }

    set reset(val: boolean) {
        this._reset = val

        if (this._reset) {
            this.activeIndex = null
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive
    }

    set parentActive(val: boolean) {
        this._parentActive = val

        if (!this._parentActive) {
            this.activeIndex = null
        }
    }
}
