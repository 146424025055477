import { ProductoCabecera } from './ProductoCabecera';
export class ProductoNavegable {
    anterior: ProductoCabecera
    posterior: ProductoCabecera
    constructor(anterior: ProductoCabecera,
                posterior: ProductoCabecera) {
    this.anterior = anterior;
    this.posterior = posterior;
    }

    public static fromData(data: any): ProductoNavegable {
        if (!data) return null;
        const o: ProductoNavegable = new ProductoNavegable(
            ProductoCabecera.fromData(data.anterior),
            ProductoCabecera.fromData(data.siguiente),
        );

        return o;

    }
}