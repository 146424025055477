import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmationService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { first } from 'rxjs/operators'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { CentroCostoService } from 'src/app/services/centro-costo.service'
import { LocalService } from 'src/app/services/local.service'
import { GestorCentroAG } from './../../../authguards/GestorCentroAG'
import { Descriptivo } from './../../../common/model/Descriptivo'
import { ProfilePic } from './../../../common/model/ProfilePic'
import { Rol } from './../../../common/model/Rol'
import { Usuario } from './../../../common/model/Usuario'
import { RolService } from './../../../common/services/rol.service'
import { UsuarioService } from './../../../common/services/usuario.service'
import { ConfiguracionUsuarioService } from './../../../services/configuracion-usuario.service'

@Component({
    selector: 'gestion-user',
    templateUrl: 'gestion-user.component.html',
    styleUrls: ['gestion-user.component.less'],
})
export class GestionUserComponent extends SessionComponent implements OnInit {
    constructor(
        public userService: UsuarioService,
        private route: ActivatedRoute,
        private router: Router,
        private confService: ConfirmationService,
        private configuracionService: ConfiguracionUsuarioService,
        private gestorCentroAG: GestorCentroAG,
        private rolesService: RolService,
        public localService: LocalService,
        public centroCostoService: CentroCostoService,

        messagesService: MessagesService
    ) {
        super(messagesService)
    }
    private _item: Usuario = new Usuario()
    private routeSub: Subscription
    public file: File
    @Input()
    public readonly: boolean = false
    public roles: Rol[] = []

    public localesDisponibles: Descriptivo[] = []
    private _centrosElegidos: Descriptivo[] = []
    public get centrosElegidos(): Descriptivo[] {
        return this._centrosElegidos
    }
    public set centrosElegidos(v: Descriptivo[]) {
        this._centrosElegidos = v
        this._item.configuracion.centrosHabilitados = v
        if (this.centrosElegidos?.length) {
            this.localService.getByCentros(this._centrosElegidos.map((c) => c.id)).then((l) => {
                this.localesDisponibles = l
            })
        } else if (this.esAdministrador) {
            this.localService.data.pipe(first()).subscribe((d) => {
                this.localesDisponibles = d
            })
        }
    }

    public onPicSelected(file: File) {
        const reader = new FileReader()
        let $this = this
        if (file.size > 1000000) {
            return this.error(this.translateService.get('EL_ARCHIVO_NO_PUEDE_16'))
        }
        this.file = file
        reader.onload = (e: any) => {
            $this.item.profilePic = new ProfilePic(null, null, null, e.target.result)
        }
        reader.readAsDataURL(file)
    }

    public onGuardado(item: Usuario) {
        this.authService.refreshToken()
    }
    public get item(): Usuario {
        return this._item
    }

    public set item(i: Usuario) {
        this._item = i
    }

    public recuperarContrasena() {
        let $this = this
        this.confService.confirm({
            key: 'genConf',
            header: this.translateService.get('RECUPERAR_CONTRASEÑA'),
            message: this.translateService.get('SE_ENVIARA_UN_EMAI_59'),
            accept: () => {
                $this.authService
                    .recuperarPass(this.item.email)
                    .then((r) => {
                        $this.success(this.translateService.get('EL_EMAIL_A_SIDO_ENV_3'))
                    })
                    .catch(this.errorHandler)
            },
        })
    }

    public async isValid() {
        if(this.item.roles.length == 0){
            return this.error("Debe indicar al menos un rol");
        }
        if (!this._item.esAdmin()) {
            if (!this._item.configuracion?.localesHabilitados?.length && !this._item.esGestorCentro()) {
                return this.error('Debe tener al menos un local habilitado')
            }
            if (!this._item.configuracion?.centrosHabilitados?.length) {
                return this.error('Debe tener al menos un centro de costo habilitado')
            }
        }
        return true
    }
    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }
    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )

        this.routeSub = this.route.queryParams.subscribe((params) => {
            let id: number = <number>params['id']
            if (!this.userService) return
            if (id) {
                this.userService.getById(id).then((r) => {
                    this.item = this.userService.parseSingle(r)
                    this.centrosElegidos = this.item.configuracion.centrosHabilitados
                }).catch(e =>{
                    this.item = null;
                })
            } else {
                this.item = this.userService ? this.userService.newEnt() : null
                this.centrosElegidos = this.usuario.configuracion.centrosHabilitados;
            }
        })
        this.rolesService.getAll().then((r) => {
            const user = this.usuario;
            this.roles = r.filter(rol=>{
                return user.tieneRol([rol.codigo]);
            })
        })
    }
    public onEditar(event) {
        this.router.navigate(['usuario/edit'], { queryParams: { id: this.item.id } })
    }
}
