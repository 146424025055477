import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';






@Injectable({
    providedIn: 'root'
})
export class GestorReservasAG extends AdminAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_GESTOR_RESERVAS"]) };



}