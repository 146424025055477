import { CobroCheque } from './CobroCheque';
import { CobroTarjetaCredito } from './CobroTarjetaCredito'
import { Cobro } from './Cobro'
import { CobroConCaja } from './CobroConCaja'
import { CobroEnEfectivo } from './CobroEnEfectivo'
import { CobroMercadoPago } from './CobroMercadoPago'
export class CobroFactory {
    public static types = [Cobro, CobroConCaja, CobroEnEfectivo, CobroMercadoPago, CobroTarjetaCredito, CobroCheque]
    public static factory

    static newCobro(data?: any) {
        let o = null
        if (!CobroFactory.factory) {
            CobroFactory.factory = {}
            CobroFactory.types.forEach((tt) => (CobroFactory.factory[tt.TIPO] = tt.fromData))
        }
        return CobroFactory.factory[data.tipoCobro](data)
    }

    public static fromData(data) {
        return this.newCobro(data)
    }
}
