import { Injectable } from '@angular/core'
import { share } from 'rxjs/operators'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { ItemGestorDevolucion } from 'src/app/model/ItemGestorDevolucion'
import { Pedido } from 'src/app/model/Pedido'
import { Totales } from '../model/Totales'
import { TotalesPedido } from '../pages/home/components/totales-pedido/TotalesPedido'
import { FiltroReporteEstadoVentas } from '../reportes/model/FiltroReporteEstadoVentas'
import { ReporteEstadoVentas } from '../reportes/model/ReporteEstadoVentas'
import { FiltroPedido } from './../common/model/FiltroPedido'
import { LoadingService } from './../common/services/loading-data-service.service'
import { GenerarComprobante } from './../model/GenerarComprobante'
import { PedidoResumen } from './../model/Pedido'
import { AuthService } from './auth.service'
@Injectable({
    providedIn: 'root',
})
export class PedidoService extends ServicioAbstract<Pedido> {

    getReporteEstadoVentas(filtro: FiltroReporteEstadoVentas, customLoading?: LoadingService): Promise<ReporteEstadoVentas[]> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('reporte/estadoVentas'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getResumenById(idEntidad: number, idioma?: string, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .get(this.getBaseURL('resumen/') + idEntidad + (idioma ? '?idioma=' + idioma : ''))
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return PedidoResumen.fromData(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    notificar(pedido: PedidoResumen | Pedido, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .put(this.getBaseURL('notificar'), pedido)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    public estadosAprobables: string[] = ['P', 'N']
    public estadosRechazables: string[] = ['P', 'N']
    public estadosGenerarComprobante: string[] = ['A', 'E', 'EE', 'EN', 'EP', 'D']
    public baseName(): string {
        return 'pedido'
    }
    public parseToEnt(data: any): Pedido {
        return Pedido.fromData(data)
    }
    public newEnt(): Pedido {
        return new Pedido()
    }
    constructor(private authService: AuthService) {
        super()
    }
    public getTotalesGeneral(filtro: FiltroPedido, customLoading?: LoadingService): Promise<TotalesPedido> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('totales'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    entregar(pedido: PedidoResumen | Pedido, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('entregar'), { idEntidad: pedido?.id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    entregarARepartidor(pedido: PedidoResumen | Pedido, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('entregarARepartidor'), { idEntidad: pedido?.id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getResumen(filtro: FiltroPedido, customLoading?: LoadingService): Promise<PedidoResumen[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('all'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((p) => PedidoResumen.fromData(p))
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    cancelarEntrega(id: number, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('cancelarEntrega'), { idEntidad: id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    aprobar(id: number, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('aprobar'), { idEntidad: id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    cerrar(id: number, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('cerrar'), { idEntidad: id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    reabrir(id: number, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('reabrir'), { idEntidad: id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    rechazar(item: ItemGestorDevolucion, customLoading?: LoadingService): Promise<Pedido> {
        // let $this = this
        // if (customLoading) {
        //     customLoading.addLoadingCount()
        // } else this.loadingService.addLoadingCount()
        // return this.http
        //     .post(this.getBaseURL('rechazar'), { idEntidad: id, motivo: 'No se puede realizar' })
        //     .pipe(share())
        //     .toPromise()
        //     .then(
        //         (r: any) => {
        //             if (customLoading) {
        //                 customLoading.susLoadingCount()
        //             } else this.loadingService.susLoadingCount()
        //             return this.parseSingle(r)
        //         },
        //         (e) => this.handleError(e, customLoading)
        //     )
        let $this = this

        item.cobranza?.cobros.forEach((element) => {
            element.monto = element.monto * -1
        })

        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'rechazar', { idEntidad: item.pedido.id, motivo: item.pedido.motivoCancelacion, cobranza: item.cobranza })
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    enProceso(id: number, customLoading?: LoadingService): Promise<PedidoResumen> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('enPreparacion'), { idEntidad: id })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public getTotales(filtro: FiltroPedido, customLoading?: LoadingService): Promise<Totales> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('count'), filtro.json)
            .toPromise()
            .then(
                (r: Totales) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    generarComprobante(generador: GenerarComprobante, customLoading?: LoadingService): Promise<Pedido> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'generarComprobante', generador.json)
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    cancelarPedido(item: ItemGestorDevolucion, customLoading?: LoadingService): Promise<Pedido> {
        let $this = this

        item.cobranza?.cobros.forEach((element) => {
            element.monto = element.monto * -1
        })

        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'cancelar', { idEntidad: item.pedido.id, motivo: item.pedido.motivoCancelacion, cobranza: item.cobranza })
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public esAprobable(items: PedidoResumen[]) {
        return items?.length && items.every((i) => this.estadosAprobables.some((e) => e == i?.estado?.codigo))
    }

    public esEntregable(items: PedidoResumen[]) {
        return items?.length && items.every((r) => (r?.estado?.codigo == 'EN' && r?.tipoEntrega?.codigo != 'D') || r?.estado?.codigo == 'D')
    }
    public esEntregableRepartidor(items: PedidoResumen[]) {
        return items?.length && items.every((r) => r?.tipoEntrega?.codigo == 'D' && 'EN' == r?.estado?.codigo)
    }
    public esRechazable(items: PedidoResumen[]) {
        return items?.length && items.every((i) => this.estadosRechazables.some((e) => e == i?.estado?.codigo))
    }

    public esProcesable(items: PedidoResumen[]) {
        return items?.length && items.every((i) => i?.estado?.codigo == 'A')
    }
    public esEliminable(item: PedidoResumen) {
        return this.authService.esAdministrador && this.esEditable(item)
    }
    public esEditable(item: PedidoResumen) {
        return !item?.comprobante && item?.estado?.codigo != 'F'
    }
}
