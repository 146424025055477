import { animate, style, transition, trigger } from '@angular/animations'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Novedad } from 'src/app/model/Novedad'
import { LocalService } from 'src/app/services/local.service'
import { AuthService } from './../../../services/auth.service'
import { IActualizable, NovedadService } from './../../../services/novedades.service'

@Component({
    selector: 'app-novedades',
    templateUrl: './novedades.component.html',
    styleUrls: ['./novedades.component.less'],
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
        ]),
    ],
})
export class NovedadesComponent implements OnInit, IActualizable {
    private key = 'NovedadController'
    constructor(
        private novedadService: NovedadService,
        private localService: LocalService,
        private authService: AuthService,
        public messagesService: MessagesService,
        private router: Router
    ) {}
    public novedades: Novedad[] = []
    private local: Descriptivo
    public displayMenu = false
    ngOnInit(): void {
        this.localService.localSeleccionadoObs.subscribe((loc) => {
            if (this.local?.id != loc?.id) {
                this.local?.id && this.novedadService.unregister('pedido', this, null, this.local?.id)
                this.local = loc
                this.local?.id && this.novedadService.registrarObservador('pedido', this, null, this.local.id)
            }
        })
    }
    next(n: Novedad) {
        if (n?.key && n.idRegistrador != this.authService?.usuario?.id && this.novedades.findIndex((prev) => prev.key == n.key) < 0) {
            if (!n.mensaje) {
                n.mensaje = this.generarMensaje(n)
            }
            this.novedades.push(n)
            this.messagesService.success(n.tipo, n.mensaje)
        }
    }
    destroy() {
        this.local?.id && this.novedadService.unregister('pedido', this, null, this.local?.id)
    }
    getKey() {
        return this.key
    }
    accionNovedad(event, novedad: Novedad) {
        if (!novedad) return
        switch (novedad.tipo) {
            case 'pedido':
                if (novedad.idEntidad) {
                    this.router.navigate(['pedido/vista'], { queryParams: { id: novedad.idEntidad } })
                }
                break

            default:
                break
        }

        this.displayMenu = false
    }
    marcarVisto(event, n: Novedad) {
        event.stopPropagation && event.stopPropagation()
        n['loading'] = true
        this.novedadService
            .marcarVista(n)
            .then((n) => {
                this.novedades = this.novedades.filter((nov) => nov.key == n.key)
            })
            .finally(() => {
                n['loading'] = false
            })
    }
    generarMensaje(n: Novedad) {
        switch (n.tipo) {
            case 'pedido':
                return 'Hay un nuevo pedido #' + n.datosAdicionales
            default:
                break
        }
    }
}
