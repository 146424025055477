<div class="listado-cambio-saldo-caja content">
    <model-list
        [service]="service"
        [modelName]="'cambio-saldo-caja'"
        [columns]="columns"
        [(listado)]="listado"
        [isLazy]="true"
        [filtro]="filtro"
        [multilenguaje]="false"
        [modoSeleccion]="modoSeleccion"
        [hideHeader]="ocultarCabecera"
        [editable]="false"
        (seleccionChange)="updateSeleccion($event)"
        [acciones]="acciones"
        [readonly]="readonly"
        [titleContent]="header"
        [handler]="handler"
        [filterContent]="filter"
        [onClick]="ver"
    >
        <ng-template #filter>
            <filtro [(filter)]="filtro" class="p-fluid">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid align-items-center vertical-aligned">
                            <div class="col-12 lg:col-6 small">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'BUSCAR' | translate }}...</mat-label>
                                    <input
                                        [(ngModel)]="filtro.searchStr"
                                        matInput
                                        placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
                                        [(ngModel)]="filtro.searchStr"
                                    />
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-12 lg:col-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'DESDE' | translate }}</mat-label>
                                    <input matInput [matDatepicker]="fechaDesdePicker" [(ngModel)]="filtro.fechaDesde" />
                                    <mat-datepicker-toggle matSuffix [for]="fechaDesdePicker"> </mat-datepicker-toggle>
                                    <mat-datepicker #fechaDesdePicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 lg:col-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'HASTA' | translate }}</mat-label>
                                    <input matInput [matDatepicker]="fechaHastaPicker" [(ngModel)]="filtro.fechaHasta" />
                                    <mat-datepicker-toggle matSuffix [for]="fechaHastaPicker"> </mat-datepicker-toggle>
                                    <mat-datepicker #fechaHastaPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="grid align-items-center vertical-aligned small">
                            <div class="col-12 lg:col-3">
                                <descriptivo-material-selector
                                    [options]="cajas"
                                    [label]="'CAJA' | translate"
                                    name="caja"
                                    [limpiable]="true"
                                    [(ngModel)]="filtro.caja"
                                >
                                </descriptivo-material-selector>
                            </div>

                            <!--div class='col-12 lg:col-3'>
                                    <descriptivo-material-selector [options]="tiposMovimiento"
                                        [label]="'TIPOMOVIMIENTO' | translate" name="tipo" [limpiable]="true"
                                        [(ngModel)]="filtro.tipoMovimiento">
                                    </descriptivo-material-selector>
                                </div-->
                        </div>
                    </div>
                </div>
            </filtro>
        </ng-template>
        <ng-template #header>
            <div class="grid">
                <div class="col-8">
                    <h2 autofocus>{{ 'Cambios de saldo de caja' }}</h2>
                </div>
                <div class="col-4 actions text-right">
                    <button pButton pTooltip="{{ 'Retiro' | translate }}" icon="pi pi-sign-out" class="p-button-outlined" (click)="operar(true)"></button>

                    <button pButton pTooltip="{{ 'Ingreso' | translate }}" icon="pi pi-sign-in" class="p-button-outlined" (click)="operar(false)"></button>
                </div>
            </div>
        </ng-template>
    </model-list>
</div>

<p-dialog
    header=""
    [(visible)]="editandoMovimiento"
    appendTo="body"
    [modal]="true"
    #dialDetail
    [styleClass]="'no-header gestor-dialog'"
    (onShow)="onDialogShow($event, dialDetail)"
>
    <gestor-cambio-saldo-caja
        #gestorEstado
        [item]="itemEditado"
        (onGuardado)="refresh($event)"
        [cajaEditable]="true"
        (onCancelado)="onCancelar($event)"
        [goBack]="false"
        [isModal]="true"
        *ngIf="itemEditado && editandoMovimiento"
    >
    </gestor-cambio-saldo-caja>
</p-dialog>
