import { Component, OnInit } from '@angular/core';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';


@Component({
	selector: 'messages',
	templateUrl: 'messages.component.html',
	styleUrls: ["messages.component.less"]
})

export class MessagesComponent implements OnInit {
	constructor(private messagesService: MessagesService) {
	}
	ngOnInit() {

	}


}