import { Parametrico } from 'src/app/common/model/Parametrico'

export class TipoTarjetaCredito extends Parametrico {
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false
    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return '$MODELNAMELOW'
    }
    public static fromData(data: any): TipoTarjetaCredito {
        if (!data) return null
        const o: TipoTarjetaCredito = new TipoTarjetaCredito(data.id, data.codigo, data.descripcion, data.habilitado, data.esSistema, data.peso, data.esDefault)

        return o
    }
}
