<div class="grid container totales card">
    <div class="col-12">
        <div class="grid content">
            <div class="col-12 perido text-center small">
                <span class="small" *ngIf="periodoSeleccionado?.codigo != 'RE'">{{ textoPeriodo | translate }}</span>
                <span class="small" *ngIf="periodoSeleccionado?.codigo == 'RE'"
                    >{{ textoPeriodo | translate }} -
                    {{ filtro.fechaDesde | date: 'dd/MM/yyyy' }}
                    {{ filtro?.fechaHasta ? ' a ' + (filtro.fechaHasta | date: 'dd/MM/yyyy') : '' }}</span
                >
            </div>

            <div class="col-12 importe" *ngIf="totales && periodoSeleccionado">
                <div class="grid">
                    <div class="col-12 text-center nopad">
                        <span>{{ 'TOTAL_IMPORTE' | translate }} </span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ totales?.totalImporte | currency: 'ARS':'$':'1.2' }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-6" *ngIf="totales && periodoSeleccionado">
                <div class="grid">
                    <div class="col-12 text-left nopad">
                        <span>{{ 'PEDIDOS' | translate }}</span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ totales?.cantidadPedidos | number: '1.0' }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-6" *ngIf="totales && periodoSeleccionado">
                <div class="grid">
                    <div class="col-12 text-left nopad">
                        <span>{{ 'PROMEDIO' | translate }} </span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ totales?.promedioPedido | currency: 'ARS':'$':'1.2' }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <loading-panel [loadingService]="customLoading"></loading-panel>
</div>

<p-overlayPanel #op [dismissable]="false" appendTo="body" [styleClass]="'options'">
    <span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"
        ><i class="pi pi-times"></i
    ></span>
    <div class="grid no-space-mat-form" style="max-width: 30em">
        <div class="col-11">
            <descriptivo-material-selector
                [service]="centroCostoService"
                [label]="'CENTRO_DE_COSTO' | translate"
                [isLazy]="true"
                [limpiable]="true"
                [(ngModel)]="centro"
                [permiteNuevo]="false"
                [name]="'centro'"
                [required]="false"
            >
            </descriptivo-material-selector>
        </div>
        <div class="col-11">
            <descriptivo-material-selector
                [service]="localService"
                [label]="'LOCAL' | translate"
                [limpiable]="true"
                [isLazy]="true"
                [(ngModel)]="local"
                [permiteNuevo]="false"
                [name]="'local'"
                [required]="false"
            >
            </descriptivo-material-selector>
        </div>
        <div class="col-11">
            <descriptivo-material-selector
                [options]="opciones"
                [label]="'PERIODO' | translate"
                [limpiable]="false"
                [(ngModel)]="periodoSeleccionado"
                [permiteNuevo]="false"
                (onSelect)="seleccionarPeriodo($event)"
                [name]="'periodo'"
                [required]="false"
            >
            </descriptivo-material-selector>
        </div>
        <div class="col-11">
            <selector-multiple-chips [(ngModel)]="estados" name="'estados'"
            [opciones]="opcionesEstados" [permiteNuevo]="false"
            [titulo]="'Estados' | translate">
        </selector-multiple-chips>
        </div>
        <div class="col-11" *ngIf="periodoSeleccionado?.codigo == 'RE'">
            <div class="grid">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'DESDE' | translate }}</mat-label>
                        <input matInput [matDatepicker]="fechaDesdePicker" [(ngModel)]="fechaDesde" />
                        <mat-datepicker-toggle matSuffix [for]="fechaDesdePicker"> </mat-datepicker-toggle>
                        <mat-datepicker #fechaDesdePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'HASTA' | translate }}</mat-label>
                        <input matInput [matDatepicker]="fechaHastaPicker" [(ngModel)]="fechaHasta" />
                        <mat-datepicker-toggle matSuffix [for]="fechaHastaPicker"> </mat-datepicker-toggle>
                        <mat-datepicker #fechaHastaPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-12 text-center">
            <button pButton (click)="buscar()" label="{{ 'ACTUALIZAR' | translate }}"></button>
        </div>
    </div>
</p-overlayPanel>
<loading-panel [loadingService]="loadingService"></loading-panel>
