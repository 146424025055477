import { AGModule } from './authguards/ag.module';
import { AsyncPipe, registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import es from '@angular/common/locales/es'
import { DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatIconRegistry } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { GridsterModule } from 'angular-gridster2'
import { RecaptchaModule } from 'ng-recaptcha'
import { IconPickerModule } from 'ngx-icon-picker'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { EmailTemplateService } from 'src/app/services/emailtemplate.service'
import { MessagingService } from 'src/app/services/messages.service'
import { environment } from 'src/environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppBreadcrumbComponent } from './app.breadcrumb.component'
import { AppComponent } from './app.component'
import { AppFooterComponent } from './app.footer.component'
import { AppMainComponent } from './app.main.component'
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component'
import { AppMenuitemComponent } from './app.menuitem.component'
import { AppInlineProfileComponent } from './app.profile.component'
import { AppRightMenuComponent } from './app.rightmenu.component'
import { AppTopbarComponent } from './app.topbar.component'
import { NovedadesComponent } from './common/components/novedades/novedades.component'
import { GlobalInjector } from './common/GlobalInjector'
import { CommonValidator } from './common/services/common-validator.service'
import { RolService } from './common/services/rol.service'
import { TranslateService } from './common/services/translate.service'
import { UsuarioService } from './common/services/usuario.service'
import { UsuarioResumenService } from './common/services/usuarioresumen.service'
import { ModelBindingDirective } from './directives/model-binding.directive'
import { ImplementacionAppRoutingModule } from './implementacion/app-routing.module'
import { JwtInterceptor } from './interceptors/JWTInterceptor'
import { TokenRefreshInterceptor } from './interceptors/TokenRefreshInterceptor'
import { CustomDateAdapter } from './model/CustomDateAdapter'
import { HomeModule } from './pages/home/home.module'
import { LoginComponent } from './pages/login/login.component'
import { MessagesComponent } from './pages/messages/messages.component'
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component'
import { ParametricoModule } from './pages/parametricos/parametrico.module'
import { PasswordResetComponent } from './pages/password-reset/password-reset.component'
import { RecuperarPasswordComponent } from './pages/recuperar-password/recuperar-password.component'
import { RegistrarseComponent } from './pages/registrarse/registrarse.component'
import { GestionUserComponent } from './pages/user/gestion-user/gestion-user.component'
import { UserComponent } from './pages/user/user.component'
import { MenuService } from './services/menu.service'
import { StorageService } from './services/storage.service'
import { TicketService } from './services/ticket.service'
import { TipoNotificacionService } from './services/tipo-notificacion.service'
import { TokenService } from './services/token.service'

registerLocaleData(es)
export function ID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substring(2, 9)
}
@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,

        LoginComponent,
        MessagesComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppInlineProfileComponent,
        AppTopbarComponent,
        PasswordResetComponent,
        UserComponent,
        GestionUserComponent,
        NovedadesComponent,
        RecuperarPasswordComponent,
        RegistrarseComponent,
        AppBreadcrumbComponent,
        ModelBindingDirective,
        AppRightMenuComponent,
        AppMainComponent,
        AppMenuitemComponent,
    ],

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ImplementacionAppRoutingModule,
        AppRoutingModule,
        CommonComponentsModule,
        ParametricoModule,
        FormsModule,
        ReactiveFormsModule,
        HomeModule,
        ReactiveFormsModule,
        HttpClientModule,
        AGModule,
        RecaptchaModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        IconPickerModule,
        NgxMaterialTimepickerModule,
        MatTabsModule,
        GridsterModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
    ],
    providers: [
        TokenService,
        CommonValidator,
        UsuarioService,
        StorageService,
        UsuarioResumenService,
        RolService,
        TranslateService,
        EmailTemplateService,
        TipoNotificacionService,
        TicketService,
        MessagingService,
        AsyncPipe,
        MenuService,
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRefreshInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'es-ES' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: '€' },

        MatIconRegistry,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private injector: Injector, public matIconRegistry: MatIconRegistry) {
        GlobalInjector.InjectorInstance = this.injector
        matIconRegistry.registerFontClassAlias('fontawesome', 'fa')
        //momenttz.tz.setDefault("UTC");
    }
}
//registerLocaleData(localeEs, 'es-ES')
export let COUNTRY = 'ES'
