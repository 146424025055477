import { Injectable } from '@angular/core'
import { share } from 'rxjs/operators'
import { Cliente } from 'src/app/model/Cliente'
import { BufferedDescriptivoService } from 'src/app/services/BufferedDescriptivoService'
import { Descriptivo } from '../common/model/Descriptivo'
import { Filtro } from './../common/model/Filtro'
import { LoadingService } from './../common/services/loading-data-service.service'
import { ClienteResumen } from './../model/Cliente'
import { CuentaCorriente } from './../model/CuentaCorriente'
import { Totales } from '../model/Totales'
import { IServicioCuentaCorriente } from './IServicioCuentaCorriente'
import { FiltroCliente } from '../model/FiltroCliente'
@Injectable({
    providedIn: 'root',
})
export class ClienteService extends BufferedDescriptivoService<Cliente> implements IServicioCuentaCorriente {
    public static CONSUMIDOR_FINAL = '9999999999999'
    public baseName(): string {
        return 'cliente'
    }
    public parseToEnt(data: any): Cliente {
        return Cliente.fromData(data)
    }
    public newEnt(): Cliente {
        return new Cliente()
    }
    protected fillData = this.getAll
    notificarDeuda(notificarA, customLoading?: LoadingService): Promise<boolean> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .put(this.getBaseURL('notificar-deuda'), notificarA)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getCabeceras(filtro: Filtro = new Filtro(null, {}), customLoading?: LoadingService): Promise<ClienteResumen[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('all'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((p) => ClienteResumen.fromData(p))
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getCuentaCorriente(filtro: FiltroCliente, customLoading?: LoadingService): Promise<CuentaCorriente[]> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .put(this.getBaseURL('cuenta-corriente'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((d) => CuentaCorriente.fromData(d))
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    getConsumidorFinal(customLoading?: LoadingService): Promise<Descriptivo> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .get(this.getBaseURL('getConsumidorFinal'))
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return Descriptivo.fromData(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    getTotales(filtro: FiltroCliente = new FiltroCliente(null), customLoading?: LoadingService): Promise<Totales> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('totales'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return new Totales(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getById(id: number, customLoading?: LoadingService, idioma?: string): Promise<Cliente> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .get(this.getBaseURL() + id + (idioma ? '?idioma=' + idioma : ''))
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
}
