import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { AdministracionAuthGuard } from './../../authguards/AdministracionAuthGuard'
import { VisualizadorAuthGuard } from './../../authguards/VisualizadorAuthGuard'
import { GestorCuentaContableComponent } from './../cuenta-contable/gestor-cuenta-contable/gestor-cuenta-contable.component'
import { ListadoCuentaContableComponent } from './../cuenta-contable/listado-cuenta-contable/listado-cuenta-contable.component'

const pipe = []
const directives = []
const components = [GestorCuentaContableComponent, ListadoCuentaContableComponent]
const routes: Routes = [
    { path: '', data: { animation: 'List' }, component: ListadoCuentaContableComponent, canActivate: [AdministracionAuthGuard] },
    { path: 'edit', pathMatch: 'full', data: { animation: 'Item' }, component: GestorCuentaContableComponent, canActivate: [AdministracionAuthGuard] },
    { path: 'vista', component: GestorCuentaContableComponent, canActivate: [VisualizadorAuthGuard], data: { vista: true, animation: 'Item' } },
    { path: 'nuevo', data: { animation: 'Item' }, component: GestorCuentaContableComponent, canActivate: [AdministracionAuthGuard] },
]
@NgModule({
    imports: [RouterModule.forChild(routes), CommonComponentsModule],
    providers: [],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})
export class CuentaContableModule {}
