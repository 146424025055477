import { TemplateRef } from '@angular/core'
import { MenuItem } from 'primeng/api'
export class AccionesTabla implements MenuItem {
    public label: string
    constructor(
        public tooltip: string = '',
        public icon: string = 'pi pi-cog',
        public styleClass: string = 'accion-tabla',
        public command: (r: any | any[], event?, template?) => void = function (r) { },
        public esVisible: (r: any | any[]) => boolean = function (r) {
            return true
        },
        public toggleTemplate?: TemplateRef<any>,
        public visibleMobile:boolean = true
    ) {
        this.label = tooltip
    }
}
