import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ClipboardService } from 'ngx-clipboard'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { Local } from 'src/app/model/Local'
import { Mesa } from 'src/app/model/Mesa'
import { Sector } from 'src/app/model/Sector'
import { environment } from 'src/environments/environment'
import { SessionComponent } from '../../session-component.component'
import { LocalService } from './../../../../services/local.service'
import { MenuDelDiaService } from './../../../../services/menu-del-dia.service'

@Component({
    selector: 'qr-menu',
    templateUrl: './qr-menu.component.html',
    styleUrls: ['./qr-menu.component.less'],
})
export class QrMenuComponent extends SessionComponent implements OnDestroy, OnInit {
    private _sector: Sector
    Qrs: any[] = []
    @ViewChild('QR', { static: false }) QR: ElementRef

    public get sector(): Sector {
        return this._sector
    }
    @Input()
    public set sector(v: Sector) {
        this._sector = v

        if (v) {
            this.localService.getById(this._sector.local.id).then((local: Local) => {
                this.menus = local.menuDisponibles
            })
        }
    }

    @Output()
    public onCerrar: EventEmitter<Boolean> = new EventEmitter<Boolean>()

    menus: Descriptivo[] = []
    mostrarQrs: boolean = false

    private _menuSeleccionado: Descriptivo
    public get menuSeleccionado(): Descriptivo {
        return this._menuSeleccionado
    }
    public set menuSeleccionado(v: Descriptivo) {
        this._menuSeleccionado = v
        this.verQr(this.sector);
    }

    private _mesas: Mesa[]
    public get mesas(): Mesa[] {
        return this._mesas
    }
    @Input() public set mesas(v: Mesa[]) {
        this._mesas = v
    }

    constructor(private menuService: MenuDelDiaService, private cbService: ClipboardService, private localService: LocalService) {
        super()
    }

    ngOnInit(): void {}

    cerrar() {
        this.onCerrar.emit(true)
    }

    public copyLink(url) {
        this.cbService.copy(url)
        this.success('Link copiado a portapapeles')
    }

    verQr(sector) {
        this.mostrarQrs = true

        let mesas = sector.mesas || this.mesas
        this.Qrs = []
        if (this.menuSeleccionado) {
            mesas.forEach((mesa: Mesa) => {
                this.Qrs = [
                    ...this.Qrs,
                    { mesa: mesa.descripcion, url: `${environment.appURL}mesa?locacion=${this.sector.local.id}&local=${this.sector.local.id}&mesa=${mesa.id}&menu=${this.menuSeleccionado.id}` },
                ]
            })
        }
    }

    public imprimirQr() {
        window.print()
    }
}
