import { Injectable } from '@angular/core';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { TipoEntrega } from 'src/app/model/TipoEntrega';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
    providedIn: 'root',
})
export class TipoEntregaService extends ParametricoService<TipoEntrega>{
    public baseName(): string {
        return "tipo-entrega";
    }
    public parseToEnt(data: any): TipoEntrega {
        return TipoEntrega.fromData(data);
    }
    public newEnt(): TipoEntrega {
        return new TipoEntrega();
    }
    public actualizarDescripcion(data: TipoEntrega, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        var p = this.http.put(this.getBaseURL("actualizar-descripcion"), data).toPromise();
        p.then(r => {
            this.loadData(customLoading);
            if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        });
        return p
    };




}