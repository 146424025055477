import { VisualizadorAuthGuard } from 'src/app/authguards/VisualizadorAuthGuard'
import { VentasAG } from 'src/app/authguards/VentasAG'
import { UsuarioAG } from './UsuarioAG'
import { TraspasoCajaAG } from './TraspasoCajaAG'
import { TesoreriaAG } from './TesoreriaAG'
import { ResumenCajaAG } from './ResumenCajaAG'
import { ReservasAG } from './ReservasAG'
import { ReporteAG } from './ReporteAG'
import { PromocionesAG } from './PromocionesAG'
import { PedidosPagerAG } from 'src/app/authguards/PedidosPagerAG'
import { PedidosAG } from './PedidosAG'
import { OrdenPagoAG } from './OrdenPagoAG'
import { InsumosAG } from './InsumosAG'
import { GestorStockAG } from 'src/app/authguards/GestorStockAG'
import { GestorReservasAG } from 'src/app/authguards/GestorReservasAG'
import { GestorPreciosAG } from './GestorPreciosAG'
import { GestorLocalAG } from 'src/app/authguards/GestorLocalAG'
import { GestorComandasAG } from 'src/app/authguards/GestorComandasAG'
import { GestorCentroAG } from 'src/app/authguards/GestorCentroAG'
import { GestionMenuAG } from './GestionMenuAG'
import { FinancieroAG } from './FinancieroAG'
import { FacturasAG } from './FacturasAG'
import { EmpleadoAuthGuard } from './EmpleadoAuthGuard'
import { ElaboradorAG } from './ElaboradorAG'
import { EdicionPerfilAG } from './EdicionPerfilAG'
import { ComprobantesAG } from './ComprobantesAG'
import { CobranzasAG } from './CobranzasAG'
import { Caja } from 'src/app/model/Caja'
import { AperturaCajaAG } from './AperturaCajaAG'
import { AdministracionAuthGuard } from 'src/app/authguards/AdministracionAuthGuard'
import { AdminAuthGuard } from 'src/app/authguards/AdminAuthGuard'
import { NgModule } from '@angular/core'
import { CommonComponentsModule } from 'src/app/common/common-components.module'
import { GestorClientesAG } from './GestorClientesAG'
import { GestorProductosAG } from './GestorProductoAG'
import { GestorProveedorAG } from './GestorProveedorAG'
import { CajaIngresoEgresoAG } from './CajaIngresoEgresoAG'

const pipe = []
const directives = []
const components = []

@NgModule({
    imports: [CommonComponentsModule],
    providers: [
        AdminAuthGuard,
        AdministracionAuthGuard,
        AperturaCajaAG,
        CajaIngresoEgresoAG,
        CobranzasAG,
        ComprobantesAG,
        EdicionPerfilAG,
        ElaboradorAG,
        EmpleadoAuthGuard,
        FacturasAG,
        FinancieroAG,
        GestionMenuAG,
        GestorCentroAG,
        GestorClientesAG,
        GestorComandasAG,
        GestorLocalAG,
        GestorPreciosAG,
        GestorProductosAG,
        GestorProveedorAG,
        GestorReservasAG,
        GestorStockAG,
        InsumosAG,
        OrdenPagoAG,
        PedidosAG,
        PedidosPagerAG,
        PromocionesAG,
        ReporteAG,
        ReservasAG,
        ResumenCajaAG,
        TesoreriaAG,
        TraspasoCajaAG,
        UsuarioAG,
        VentasAG,
        VisualizadorAuthGuard,
    ],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})
export class AGModule {}
