import { Injectable } from '@angular/core';
import { GestorCentroAG } from './GestorCentroAG';






@Injectable()
export class AdministracionAuthGuard extends GestorCentroAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_JEFE_ADM"]) };




}