<div class="gestor-cambio-estado-caja content content-white">
    <model-gestor
        [readonly]="readonly"
        [service]="service"
        [(item)]="item"
        [validar]="isValid"
        [verAcciones]="false"
        [isModal]="isModal"
        [goBack]="goBack"
        (onItemGuardado)="handleGuardado($event)"
        (onCancelar)="handleCancelar($event)"
        [form]="ArqueoCajaForm"
        [multilenguaje]="false"
        (onEditar)="onEdit($event)"
    >
        <div class="title" model-type="title">
            <div class="grid">
                <div class="col title-content">
                    <h3>{{ 'Arqueo de Caja' | translate }}</h3>
                </div>
            </div>
        </div>
        <div class="gestor-form" model-type="dataContent">
            <form #ArqueoCajaForm="ngForm" id="ArqueoCajaform">
                <div class="grid" *ngIf="item">
                    <div class="col-12 lg:col-4">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'FECHA' | translate }}</mat-label>
                            <input matInput [matDatepicker]="fechaPick" [(ngModel)]="item.fecha" #fecha="ngModel" name="fecha" [readonly]="readonly" />
                            <mat-datepicker-toggle matSuffix [for]="fechaPick" [disabled]="readonly"> </mat-datepicker-toggle>
                            <mat-datepicker #fechaPick></mat-datepicker>
                            <mat-error>
                                {{ getErrorMessage(fecha) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Responsable' | translate }}</mat-label>
                            <input matInput hidden name="dummy" />
                            <span class="input">{{ item.responsable?.descripcion }}</span>
                        </mat-form-field>
                    </div>

                    <div class="col-12 lg:col-4" *ngIf="cajaEditable">
                        <descriptivo-material-selector
                            [options]="cajaService?.data | async"
                            [label]="'CAJA' | translate"
                            [limpiable]="false"
                            [(ngModel)]="cajaSeleccionada"
                            [permiteNuevo]="false"
                            (onNew)="nuevocajaOptions()"
                            name="caja"
                            [readonly]="readonly"
                            required
                        >
                        </descriptivo-material-selector>
                    </div>
                    <local-selector [multiple]="false" [(ngModel)]="item.local" [readonly]="readonly" class="col-12 lg:col-4"> </local-selector>
                    <div class="col-12 billetes">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Conteo de Billetes' | translate }}</mat-label>
                            <input type="text" name="dummy" hidden matInput />
                            <div class="grid align-items-center">
                                <div class="col-6 lg:col-3 billete" *ngFor="let billete of item.valores; let i = index">
                                    <div class="billete-wrapper">
                                        <div class="grid align-items-center">
                                            <div class="col-4 text-right">
                                                <span>{{ billete?.descripcion }} </span>
                                            </div>
                                            <div class="col-8 text-center">
                                                <p-inputNumber
                                                    (focusin)="onFocus($event)"
                                                    (onBlur)="updateMonto()"
                                                    [style]="{ 'text-align': 'center' }"
                                                    pTooltip="{{ 'Cantidad de billetes' | translate }}"
                                                    [disabled]="readonly"
                                                    name="{{ 'cantidad' + i }}"
                                                    [(ngModel)]="billete.cantidad"
                                                    [showButtons]="true"
                                                    buttonLayout="horizontal"
                                                    spinnerMode="horizontal"
                                                >
                                                </p-inputNumber>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-12 lg:col-6">
                        <span-dinero-editable [(ngModel)]="monto" [label]="'Monto en caja' | translate" [readonly]="readonly" name="{{ 'monto' + r }}">
                        </span-dinero-editable>
                    </div>
                    <div class="col-12 lg:col-6">
                        <span-dinero-editable [(ngModel)]="item.diferencia" [label]="'Diferencia' | translate" [readonly]="true" name="{{ 'diferencia' + r }}">
                        </span-dinero-editable>
                    </div>
                    <div class="col-12" *ngIf="item.diferencia">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'JUSTIFICACION' | translate }}</mat-label>
                            <input
                                matInput
                                placeholder="{{ 'JUSTIFICACION_PH' | translate }}"
                                required
                                [(ngModel)]="item.justificacion"
                                pTooltip="{{ 'JUSTIFICACION_TOOLTIP' | translate }}"
                                type="text"
                                #justificacion="ngModel"
                                name="justificacion"
                                [readonly]="readonly"
                            />
                            <mat-error>
                                {{ getErrorMessage(justificacion) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>
