import { Descriptivo } from './../common/model/Descriptivo'
export class Novedad {
    constructor(
        public alta: Date = new Date(),
        public tipo: string,
        public key: string,
        public local: number,
        public idEntidad: number,
        public datosAdicionales: string,
        public mensaje: string,
        public idRegistrador: number,
        public tipoOperacion: Descriptivo
    ) {}

    public static fromData(data) {
        if (!data) return
        return new Novedad(
            data.alta ? new Date(data.alta) : null,
            data.tipo,
            data.tipo + (data.idEntidad ? data.idEntidad : ''),
            data.local ? Number(data.local) : null,
            data.idEntidad,
            data.datosAdicionales,
            data.mensaje,
            data.idRegistrador,
            Descriptivo.fromData(data.tipoOperacion)
        )
    }
}
