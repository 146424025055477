import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { Comprobante } from 'src/app/model/Comprobante'
import { IConCobros } from '../common/components/abm-cobros/IConCobros'
import { Cobro } from './Cobro'
import { CobroFactory } from './CobroFactory'
import { ComprobanteResumen } from './Comprobante'
import { EstadoDocumento } from './EstadoDocumento'
import { ItemCobranza } from './ItemCobranza'
import { Moneda } from './Moneda'

export class Cobranza extends Descriptivo implements IConCobros {
    public clienteStr: string
    tieneComprobante(c: ComprobanteResumen | Comprobante) {
        return this.items.some((i) => i.comprobante.id == c.id)
    }

    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public responsable?: Descriptivo,
        public fecha: Date = new Date(),
        public estado?: EstadoDocumento,
        public local?: Descriptivo,
        public importeNeto?: number,
        public ipuestos?: number,
        public numeroComprobante?: string,
        public cobros: Cobro[] = [],
        public items: ItemCobranza[] = [],
        public cliente?: Descriptivo,
        public total?: number,
        public motivoCancelacion?: Descriptivo,
        public moneda?: Moneda
    ) {
        super(codigo, descripcion, id)
        this.clienteStr = cliente ? cliente.descripcion : ''
    }

    public static fromData(data: any): Cobranza {
        if (!data) return null
        const o: Cobranza = new Cobranza(
            data.id,
            data.codigo,
            data.descripcion,
            Descriptivo.fromData(data.responsable),
            data.fecha ? new Date(data.fecha) : null,
            EstadoDocumento.fromData(data.estado),
            Descriptivo.fromData(data.local),
            data.importeNeto,
            data.ipuestos,
            data.numeroComprobante,
            data.cobros ? data.cobros.map((c) => CobroFactory.fromData(c)) : [],
            data.items ? data.items.map((c) => ItemCobranza.fromData(c)) : [],
            Descriptivo.fromData(data.cliente),
            data.total,
            Descriptivo.fromData(data.motivoCancelacion),
            Moneda.fromData(data.moneda)
        )

        return o
    }

    public get valores(): Cobro[] {
        return this.cobros
    }
    public set valores(v: Cobro[]) {
        this.cobros = v
    }

    agregarItem(item: ItemCobranza) {
        if (this.items.indexOf(item) < 0) this.items.push(item)
    }
    public quitarItem(item) {
        this.items = this.items.filter((i) => i != item)
    }

    public get totalNotasACuenta() {
        return this.items.filter((i) => i.comprobante?.tipoComprobante?.esACuenta).reduce((a, b) => (a += b.importe * -1), 0)
    }
    public get totalCalculado() {
        return this.items.reduce((a, b) => (a += b.importe), 0)
    }
    public get totalCobrado() {
        return this.cobros.reduce((a, b) => (a += b.monto), 0)
    }

    public agregarCobro(c: Cobro) {
        if (this.cobros.indexOf(c) < 0) this.cobros.push(c)
    }

    public quitarCobro(c: Cobro) {
        this.cobros = this.cobros.filter((cc) => cc != c)
    }

    public get saldo() {
        return this.totalCalculado - this.totalCobrado
    }

    public getSaldo() {
        return this.saldo
    }

    public reemplazarCobro(item: Cobro, item2: Cobro) {
        if (!item && this.cobros.indexOf(item2) < 0) {
            this.cobros.push(item2)
        } else {
            var i = this.cobros.indexOf(item)
            if (i >= 0 && item2) {
                this.cobros[i] = item2
            }
        }
    }

    public get estadoDesc() {
        return this.estado.descripcion
    }
}
