<p-confirmDialog [key]="'genConf' + modelName" icon="fa fa-question-circle" acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
<loading-panel #gloading></loading-panel>
<div class="model-gestor {{ styleClass }}" [ngClass]="{ modal: isModal }">
    <div class="model-title" #title [hidden]="title.childNodes.length == 0 || hideTitle">
        <ng-content select="[model-type=title]"></ng-content>
    </div>
    <div class="title" *ngIf="title.childNodes.length == 0 && !hideTitle && item">
        <div class="grid" style="width: 100%;">
            <div class="col title-content">
                <h2 class="font-size-gestor">
                    {{ finalTitle }} <p-button icon="pi pi-pencil" (click)="editar()" *ngIf="readonly && editable" styleClass="edit-button"></p-button>
                    <selector-idioma [(ngModel)]="idiomaSeleccionado" name="idioma" *ngIf="multilenguaje" style="margin-left: 2em"></selector-idioma>
                </h2>
            </div>
            <div class="col-fixed text-right actions" style="width: 10em" *ngIf="verAcciones">
                <button
                    pButton
                    [label]="isMobile() ? null : ('ACCIONES' | translate)"
                    [icon]="'pi pi-list'"
                    (click)="menuAccionesDefault.toggle($event)"
                ></button>
            </div>
            <div class="col-12 subheader" *ngIf="subheader">
                <span>{{ subheader }}</span>
            </div>
        </div>
    </div>
    <div class="model-dataContent" #dataContent *ngIf="item">
        <ng-content select="[model-type=dataContent]"></ng-content>
    </div>
    <div class="botonera" *ngIf="!hideButtons">
        <ng-container *ngTemplateOutlet="customButtons ? customButtons : defBotonera"></ng-container>
    </div>
</div>
<ng-template #defBotonera>
    <div class="grid justify-content-end">
        <button pButton label="{{ labelCancelar }}" (click)="cancelar()" class="col"></button>
        <button  class="col" pButton label="{{ 'GUARDAR' | translate }}" (click)="guardar()" [disabled]="!modificado" *ngIf="!readonly && item"></button>
    </div>
</ng-template>

<p-menu #menuAccionesDefault [popup]="true" [model]="menuItemAcciones" appendTo="body"></p-menu>
