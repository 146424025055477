import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { BaseService } from 'src/app/common/services/base.service';
import { ConfiguracionUsuario } from 'src/app/model/ConfiguracionUsuario';
import { LocalService } from 'src/app/services/local.service';
import { Local } from '../model/Local';
import { Descriptivo } from './../common/model/Descriptivo';
import { LoadingService } from './../common/services/loading-data-service.service';
import { AuthService } from './auth.service';
import { NovedadService } from './novedades.service';
@Injectable({
    providedIn: 'root',
})
export class ConfiguracionUsuarioService extends BaseService {
    constructor(private authService: AuthService, private nService: NovedadService, private localService: LocalService) {
        super()
        this.init();
    }
    setLocal(local: Local | Descriptivo, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount() } else this.loadingService.addLoadingCount();

        return this.http.put(this.getApiURL() + "configuracion-usuario/setLocalSeleccionado", local.id).toPromise().then((r: string) => {
            this.localService.seleccionarLocal(local.id);
        }).finally(() => {
            if (customLoading) { customLoading.susLoadingCount() } else this.loadingService.susLoadingCount();
        })

    }
    public localAutorizado(idLocal) {
        if (this.authService.esAdministrador && !this.config.localSeleccionado) return true;
        if (!this.config || !this.config.localesHabilitados.length) return false;
        return this.config.localSeleccionado ? this.config.localSeleccionado.id == idLocal : this.config.localesHabilitados.findIndex(l => l.id == idLocal) >= 0;
    }

    public clear(name: string) {
        this.config.defaults.delete(name);
        this.save();
    }
    public save(customLoading?: LoadingService) {
        if (this.config) {
            if (customLoading) { customLoading.addLoadingCount() } else this.loadingService.addLoadingCount();
            this.http.put(this.getBaseURL(), this.config.json).toPromise().then(c => {
                this.config = ConfiguracionUsuario.fromData(c);
                this.configObs.next(this.config);
                return this.config;
            }).finally(() => {
                if (customLoading) { customLoading.susLoadingCount() } else this.loadingService.susLoadingCount();
            })
        }


    }
    public baseName(): string {
        return "configuracion-usuario";
    }
    public parseToEnt(data: any): ConfiguracionUsuario {
        return ConfiguracionUsuario.fromData(data);
    }
    public newEnt(): ConfiguracionUsuario {
        return new ConfiguracionUsuario();
    }
    public configObs: BehaviorSubject<ConfiguracionUsuario> = new BehaviorSubject(null);
    private sus: Subscription[] = [];

    public init() {
        this.authService.onLogout.subscribe(f => {
            if (f) {
                this.sus.forEach(s => s.unsubscribe());
                this.sus = [];
            }


        })
        this.sus.push(this.authService.getUser().pipe(filter(u => u != undefined)).subscribe(u => {
            if (this.config != u.configuracion) {
                this.config = u.configuracion;
                this.configObs.next(this.config);
                if (!this.config.localSeleccionado) {
                    this.localService.data.pipe(filter(l => l != undefined), first()).subscribe(d => {
                        if (d.length == 1) {
                            this.setLocal(d[0]);
                        }
                    })
                } else {
                    this.localService.seleccionarLocal(this.config.localSeleccionado.id);
                }

            }
        }));
    }
    private config: ConfiguracionUsuario;
    public get(): Promise<ConfiguracionUsuario> {
        return Promise.resolve(this.config);
    }


}