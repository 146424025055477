<div class="grid">
    <div class="col-12">
        <abm-hijo [items]="disponibilidad.horarios" (onAgregar)="disponibilidad.agregarHorario($event)"
            (onQuitar)="disponibilidad.quitarHorario($event)" [newItem]="disponibilidad.newHorario"
            emptyMessage="{{'No hay horarios' | translate}}" [itemTemplate]="itemTemplate" [editable]="!readonly"
            [insertOnly]="true">
            <ng-template #itemTemplate let-item let-r="index">
                <div class="grid">
                    <div class="lg:col-8 col-12 ">
                        <selector-dia [(ngModel)]="item.dias" name="{{'dias'+r}}" [readonly]="readonly"
                        ></selector-dia>
                    </div>
                    <div class="lg:col-4 col-12">
                        <div class="grid">
                            <div class="col-6">
                                
                                <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                                    <span class="p-float-label"  >
                                        <p-calendar [(ngModel)]="item.desde" [timeOnly]="true">
                                        </p-calendar>
                                        <input
                                            pInputText
                                            type="text" 
                                            hidden 
                                            name="dummy"
                                        />

                                        <label>{{'DESDE' | translate}}</label>
                                        

                                    </span>
                                </div>
                                <!-- <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                                    <mat-label>{{'DESDE' | translate}}</mat-label>
                                    <input type="text" hidden name="dummy" matInput>
                                    <p-calendar [(ngModel)]="item.desde" [timeOnly]="true">
                                    </p-calendar>
                                </mat-form-field> -->


                            </div>
                            <div class="col-6">
                                <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                                    <span class="p-float-label"  >
                                        <p-calendar [(ngModel)]="item.hasta" [timeOnly]="true">
                                        </p-calendar>
                                        <input
                                            pInputText
                                            type="text" 
                                            hidden 
                                            name="dummy"
                                        />

                                        <label>{{'HASTA' | translate}}</label>
                                        

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>

        </abm-hijo>
    </div>

</div>
