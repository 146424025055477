import { VentasAG } from './VentasAG';
import { AdministracionAuthGuard } from 'src/app/authguards/AdministracionAuthGuard';
import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';
import { TesoreriaAG } from './TesoreriaAG';






@Injectable({
    providedIn: 'root'
})
export class TraspasoCajaAG extends TesoreriaAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_TR_CAJA"]) };



}