import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { Message } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ID } from 'src/app/app.module';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { Usuario } from '../model/Usuario';
import { FilterService } from './../../services/filter-service';
import { GlobalInjector } from './../GlobalInjector';
import { LoadingService } from './../services/loading-data-service.service';
import { TranslateService } from './../services/translate.service';
import { ErrorHandler } from './../utils/ErrorsHandler';

@Injectable()
export abstract class SessionComponent implements OnDestroy {
    ngOnDestroy(): void {
        if (this.authSub) this.authSub.unsubscribe()
        if (this.translateSub) this.translateSub.unsubscribe()
        if (this.subs)
            this.subs.forEach((s) => {
                if (s) s.unsubscribe()
            })
        this.subs = []
    }

    protected subs: Subscription[] = []
    public yearRangeNacimiento: string
    public environment = environment
    public todos: Descriptivo = Descriptivo.TODOS()
    public finalizado = false
    public editable = false
    protected loadingService: LoadingService
    public authService: AuthService

    public permiteEdicion: boolean = true
    protected key = ID()

    public now: Date = moment().startOf('day').toDate()
    public prevView: string
    locale: any[] = []
    calendarioLocale: any
    get tieneAyuda(): boolean {
        return false
    }
    private authSub: Subscription
    private translateSub: Subscription
    public translateService: TranslateService
    public filterService: FilterService
    public customLoadingService: LoadingService = new LoadingService()
    constructor(public messagesService?: MessagesService, public currentView: string = 'root') {
        this.loadingService = LoadingService.injector.get(LoadingService)
        this.authService = GlobalInjector.InjectorInstance.get<AuthService>(AuthService)
        this.translateService = GlobalInjector.InjectorInstance.get<TranslateService>(TranslateService)
        this.translateService.upateData.pipe(filter((r) => r != null)).subscribe((r) => {
            this.calendarioLocale = this.translateService.get('CALENDARIO_LOCALE')
        })

        this.translateSub = this.translateService.upateData.subscribe((t) => {
            this.updateTexts()
        })
        this.yearRangeNacimiento = this.getYearRange(65, 15)
        if (!this.messagesService) this.messagesService = GlobalInjector.InjectorInstance.get(MessagesService)
        this.filterService = GlobalInjector.InjectorInstance.get<FilterService>(FilterService);
    }
    public compareDescriptivos(v1: Descriptivo, v2: Descriptivo) {
        return !v1 || !v2 ? false : v1.id && v2.id ? v1.id == v2.id : v1.codigo == v2.codigo
    }

    public getUsersLocale(defaultValue: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue
        }
        const wn = window.navigator as any
        let lang = wn.languages ? wn.languages[0] : defaultValue
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage
        return lang
    }

    public getYearRange(from: number, to: number) {
        return moment().startOf('year').subtract(from, 'years').year() + ':' + moment().startOf('year').subtract(to, 'years').year()
    }
    public onFocus(event: any) {
        event.srcElement.select()
    }

    addLoadingCount() {
        this.loadingService.addLoadingCount()
    }

    susLoadingCount() {
        this.loadingService.susLoadingCount()
    }

    isUserLogged(): boolean {
        return this.authService.isLoggedIn
    }
    public get unidadPeso() {
        return 'KG'
    }
    public tieneRol(rol: string) {
        return this.authService.tieneRol(rol)
    }
    get esAdministrador() {
        return this.authService.esAdministrador
    }
    get esGestorCentro() {
        return this.authService.tieneRol('GESTOR_CENTRO')
    }


    get esSoloVisualizador() {
        return this.authService.esSoloVisualizador
    }

    getCurrentUser(): Observable<Usuario> {
        return this.authService.getUser()
    }

    getUserFullName(): string {
        return this.authService.getUserFullName()
    }
    errorHandler = (error, msgs?: Message[]) => {
        this.messagesService.errorHandler(error, msgs)
    }
    protected clearCredentials(): Promise<any> {
        return this.authService.logout()
    }
    public get lenguaje() {
        return this.translateService.idioma
    }
    success(mensaje?: string, titulo?: string) {
        this.messagesService.success(mensaje, titulo)
        return true
    }
    error = (mensaje?: string, titulo?: string) => {
        this.messagesService.error(mensaje, titulo)
        return false
    }
    info = (mensaje?: string, titulo?: string) => {
        this.messagesService.info(mensaje, titulo)
    }
    warning = (mensaje?: string, titulo?: string) => {
        this.messagesService.warning(mensaje, titulo)
    }

    set messageLife(val: number) {
        this.messagesService.life = val
    }

    public goTo(vista: string) {
        this.prevView = this.currentView
        this.currentView = vista
    }

    public volver() {}
    get usuario(): Usuario {
        return this.authService.getCurrentUser
    }

    isTablet() {
        const width = window.innerWidth
        return width <= 1024 && width > 640
    }

    isDesktop() {
        return window.innerWidth > 1024
    }

    isMobile() {
        return window.innerWidth <= 640
    }

    public updateTexts() {}

    public onDialogShow(event, dialog) {
        if (this.isMobile()) {
            dialog.maximized = true
        }
    }
    getErrorMessage(control: AbstractControl) {
        if (control?.errors && (control?.dirty || control?.touched)) {
            return ErrorHandler.getErrorMessage(control)
        }
    }

    public openLink(url, event?) {
        event && event.stopPropagation()
        window.open(url, '_blank')
    }
}
