import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LoadingService } from './../common/services/loading-data-service.service';
import { MessagesService } from './../common/services/messages-data-service.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private loadingService: LoadingService;
    private skippedError: string[] = ["/novedades"];
    private messageService: MessagesService;
    ;
    constructor(private authenticationService: AuthService, messageService: MessagesService) {
        //this.loadingService = LoadingService.injector.get(LoadingService);
        this.messageService = messageService;
    }
    private inSkipped(url: string) {
        return this.skippedError.some(s => url.includes(s));
    }
    private _checkTokenExpiryErr(authRequest, error: HttpErrorResponse): boolean {
        return !authRequest.url.includes('login') && error.status && error.status === 401 && error.error && error.error.message === 'TokenExpired'
    }
    handleRequest(next, request): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //this.loadingService.susLoadingCount();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (!request.url.includes('i18n') && !this.inSkipped(request.url)) {
                    if(!this._checkTokenExpiryErr(request,error)){
                     
                    this.messageService.errorHandler(error);   
                    }
                } else {
                    // this.loadingService.susLoadingCount();
                }

                return throwError(error);
            }));;
    }
    private addToken(request: HttpRequest<any>) {
        const req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authenticationService.token}`
            }
        });
        return req;
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if (this.authenticationService.token) {
            if (!request.url.includes(".amazonaws.com")) {
                request = this.addToken(request);
            }
            return this.handleRequest(next, request);

        } else if (request.url.includes("/login")
            || request.url.includes("/register") || request.url.endsWith("/reauthenticate")
            || request.url.includes("/public")
            || (request.url.includes("assets") && request.url.includes("i18n"))) {
            const xhr = request.clone({
                withCredentials: true
            });
            return this.handleRequest(next, xhr);
        } else {
            return this.authenticationService.getAutenticateState().pipe(filter(r => r == AuthService.LOGUEADO), take(1), switchMap(() => {
                request = this.addToken(request);
                return this.handleRequest(next, request);
            }))
        }
        //this.loadingService.addLoadingCount();



    }

}