import * as moment from 'moment';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { EstadoDocumento } from 'src/app/model/EstadoDocumento';
export class CuentaCorriente {
    public static VENTA = "VE";
    public static COBRANZA = "CO";
    public static COMPRA = "FA";
    public static ORDENPAGO = "OP";
    tipoOperacion: string;
    numeroOperacion: string;
    tipoComprobante: Descriptivo;
    operacion: string;
    idOperacion: number;
    fechaOperacion: Date;
    fechaDisplay: string;
    cliente: Descriptivo;
    importe: number;
    descripcion: string;
    estadoDocumento: EstadoDocumento;
    public searchStr;
    public get id() {
        return this.idOperacion;
    }
    public static fromData(data): CuentaCorriente {
        if (!data) return;
        let o = new CuentaCorriente();
        Object.assign(o, data);
        o.cliente = Descriptivo.fromData(data.cliente);
        o.fechaOperacion = new Date(data.fechaOperacion);
        o.tipoComprobante = Descriptivo.fromData(data.tipoComprobante);
        o.searchStr = o.descripcion + "-" + o.numeroOperacion;
        o.estadoDocumento = EstadoDocumento.fromData(data.estadoDocumento);
        o.fechaDisplay = moment(data.fechaOperacion).format("DD/MM/YYYY hh:mm:ss")
        o.operacion = o.tipoComprobante?.codigo + o.numeroOperacion
        return o;

    }

    public get salida() {
        return (this.tipoOperacion == CuentaCorriente.VENTA || this.tipoOperacion == CuentaCorriente.COMPRA) ? this.importe : 0;
    }

    public get entrada() {
        return (this.tipoOperacion == CuentaCorriente.COBRANZA || this.tipoOperacion == CuentaCorriente.ORDENPAGO) ? this.importe : 0;
    }
}