import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';



@Injectable()
export class TokenService {
    constructor(private auth: AuthService) {
    }

    getAsyncToken() {
        return this.auth.token;
    }

}