<div class="grid container">
    <div class="col-12">
        <div class="grid content">
            <div class="col-12">
                <div class="card plus" (click)="agregarNota()">
                    <i class="pi pi-plus fa-2" pTooltip="{{ 'AGREGAR_NOTA' | translate }}"></i>
                </div>
            </div>
            <div class="col-12" *ngFor="let nota of notas">
                <div
                    class="card"
                    (click)="verNota(nota)"
                    [ngStyle]="{ 'background-color': nota.color ? nota.color : '#fff' }"
                    [ngClass]="{ esPermanente: nota.esPermanente }"
                >
                    <div class="grid nota">
                        <div class="col-12 fecha" *ngIf="!nota.esPermanente">
                            {{
                                mismaFecha(nota?.fechaNota, nota?.fechaHasta)
                                    ? 'Fecha: ' + (nota?.fechaNota | date: 'dd/MM/yyyy')
                                    : 'Desde: ' + (nota?.fechaNota | date: 'dd/MM/yyyy') + ' Hasta: ' + (nota?.fechaHasta | date: 'dd/MM/yyyy')
                            }}
                        </div>
                        <div class="col-12 contenido" [innerHtml]="nota.cabecera"></div>
                    </div>
                    <span class="responsable">{{ nota?.responsable?.descripcion }} - {{ nota?.ultimaModificacion | date: 'dd/MM/yyyy' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<p-overlayPanel #op [dismissable]="false" appendTo="body" [styleClass]="'options'">
    <span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"
        ><i class="pi pi-times"></i
    ></span>
    <div class="grid no-space-mat-form" style="max-width: 30em">
        <div class="col-11">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'Días para adelante' | translate }}</mat-label>
                <input matInput type="number" step="1" [(ngModel)]="dias" />
            </mat-form-field>
        </div>
        <div class="col-12 text-center">
            <button pButton (click)="buscar()" label="{{ 'ACTUALIZAR' | translate }}"></button>
        </div>
    </div>
</p-overlayPanel>
<p-dialog
    [(visible)]="mostrarNota"
    [modal]="true"
    #dialNotaFecha
    header="{{ 'Notas del día' | translate }}"
    [styleClass]="'gestor-dialog'"
    (onShow)="onDialogShow($event, dialNotaFecha)"
    appendTo="body"
    (onHide)="mostrarNota = false; notaEditada = null"
>
    <gestor-nota
        [isModal]="true"
        [goBack]="false"
        [fecha]="filtro.fecha"
        [hideTitle]="false"
        [item]="notaEditada"
        [readonly]="!editandoNota"
        (onGuardado)="mostrarNota = false; filtro.forceUpdate(); notaEditada = null"
        (onCancelado)="mostrarNota = false; notaEditada = null"
        *ngIf="notaEditada"
    >
    </gestor-nota>
</p-dialog>
