import { Descriptivo } from './../common/model/Descriptivo';
import { ProductoCabecera } from './ProductoCabecera';



export class ProductoAsociado {
    constructor(
        public id?: number,
        public asociado?: ProductoCabecera | Descriptivo,
        public basePersonas?: number,
        public cantidad?: number,

    ) {
    }
    public static fromData(data: any): ProductoAsociado {
        if (!data) return null;
        const o: ProductoAsociado = new ProductoAsociado(

            data.id,
            ProductoCabecera.fromData(data.asociado),
            data.basePersonas,
            data.cantidad,
        );

        return o;

    }



}