import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms'
//import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import { BehaviorSubject } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { IOpcionChip } from 'src/app/common/components/selector-multiple-chips/IOpcionChip'
import { Filtro } from 'src/app/common/model/Filtro'
import { Parametrico } from 'src/app/common/model/Parametrico'
import { ChipOptionService } from './../../services/ChipOptionService'
import { LoadingService } from './../../services/loading-data-service.service'
//import { query } from '@angular/animations'

@Component({
    selector: 'selector-multiple-chips',
    templateUrl: './selector-multiple-chips.component.html',
    styleUrls: ['./selector-multiple-chips.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorMultipleChipsComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectorMultipleChipsComponent),
            multi: true,
        },
    ],
})
export class SelectorMultipleChipsComponent implements ControlValueAccessor, Validator, OnInit {
    @Input()
    public placeholder: string = ''

    @Input()
    public titulo: string = 'Buscar'

    @Input()
    public maxItems: number = 100
    @Input()
    public permiteNuevo: boolean = true

    @Input()
    public limpiable: boolean = false
    private _servicio: ChipOptionService
    public get servicio(): ChipOptionService {
        return this._servicio
    }
    @Input()
    public set servicio(v: ChipOptionService) {
        this._servicio = v
    }

    public disabled: boolean = false

    private _seleccionados: IOpcionChip[] = []
    public get seleccionados(): IOpcionChip[] {
        return this._seleccionados
    }
    @Input()
    public set seleccionados(v: IOpcionChip[]) {
        this._seleccionados = v
        this._seleccionados = this._seleccionados.filter((s) => s.codigo != Parametrico.NUEVO.codigo)
        this.onChangeCallback(this._seleccionados)
    }
    @Input()
    public readonly: boolean = false
    @ViewChild('autocompleteInput', { static: false })
    autocompleteInput: ElementRef<HTMLInputElement>
    @Input()
    public filtro: Filtro = new Filtro(null, {}, 0, 100)
    private _opciones: IOpcionChip[] = []
    public get opciones(): IOpcionChip[] {
        return this._opciones
    }

    @Input()
    public set opciones(v: IOpcionChip[]) {
        this._opciones = v
    }

    @Output()
    public onAgregar: EventEmitter<IOpcionChip> = new EventEmitter()
    @Output()
    public onQuitar: EventEmitter<IOpcionChip> = new EventEmitter()
    public inputControl: FormControl = new FormControl()
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchCallback: (_: any) => void = () => {}
    private onValidate: (_?: any) => void | boolean = () => {}
    public dataFiltrada: BehaviorSubject<IOpcionChip[]> = new BehaviorSubject([])
    public loadingService: LoadingService = new LoadingService()
    public editando: boolean = false
    private _touched: boolean = false
    private _required: boolean
    public get required(): boolean {
        return this._required
    }
    @Input()
    public set required(v: boolean) {
        this._required = v
    }

    @Input()
    public newItem: (desc?) => IOpcionChip = (desc?) => {
        return { descripcion: desc }
    }
    @Input()
    public gestor: TemplateRef<any>

    public get idioma(): string {
        return this.filtro.idioma
    }

    @Input()
    public set idioma(v: string) {
        this.filtro.idioma = v
        this.filter(this.filtro.searchStr, false)
    }

    @Input()
    noSpace: boolean = false
    private _itemEditado: IOpcionChip
    public get itemEditado(): IOpcionChip {
        return this._itemEditado
    }
    public set itemEditado(v: IOpcionChip) {
        this._itemEditado = v
    }

    public ctx = {
        itemEditado: this.itemEditado,
        handler: {
            onCancelado: (r) => {
                this.onCancelado()
            },
            onGuardado: (r) => {
                this.onGuardado(r)
            },
        },
    }
    constructor() {}

    public onFocus(event: any) {
        event.srcElement.select()
    }
    isTablet() {
        const width = window.innerWidth
        return width <= 1024 && width > 640
    }

    isDesktop() {
        return window.innerWidth > 1024
    }

    isMobile() {
        return window.innerWidth <= 640
    }

    public onDialogShow(event, dialog) {
        if (this.isMobile()) {
            dialog.maximized = true
        }
    }
    writeValue(obj: any): void {
        if (obj !== this._seleccionados) {
            this._seleccionados = obj
        }
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchCallback = (_: any) => {
            this._touched = true
            fn(_)
        }
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
    validate(control: AbstractControl): ValidationErrors {
        return !this.isValid ? { required: 'Requerido' } : null
    }
    registerOnValidatorChange?(fn: () => void): void {
        this.onValidate = fn
    }

    public quitarItem(item: IOpcionChip) {
        this.seleccionados = this.seleccionados.filter((i) => i.key != item.key)
        this.onQuitar.emit(item)
        this.onValidate(this.seleccionados)
    }

    vaciarSeleccionados() {
        this.seleccionados = []
    }
    agregar(item: IOpcionChip | void) {
        if (!item) return
        if (!this.seleccionados.some((s) => s.codigo == item.codigo)) this.seleccionados.push(item)
        this.onAgregar.emit(item)
        this.onValidate(this.seleccionados)
    }

    onGuardado = (item) => {
        this.editando = false
        this.agregar(item)
        if (!this.servicio) {
            this.opciones.push(item)
            this.opciones = this.opciones.sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
        }
    }
    onCancelado = () => {
        this.itemEditado = null
        this.ctx.itemEditado = this.itemEditado
        this.editando = false
    }
    public onSeleccionado(opcion: IOpcionChip) {
        if (opcion.codigo == Parametrico.NUEVO.codigo) {
            this.itemEditado = this.newItem()
            this.ctx.itemEditado = this.itemEditado
            this.ctx.itemEditado.descripcion = this.filtro.searchStr ? this.filtro.searchStr.substr(0, 1).toUpperCase() + this.filtro.searchStr.substr(1) : null
            this.editando = true
        } else {
            this.agregar(opcion)
            this.onChangeCallback(this.seleccionados)
        }
    }
    public async filter(val?: string, incluyeNuevo: boolean = true) {
        let data = []
        this.filtro.searchStr = val;
        if (this.servicio) {
            data = await this.servicio.getHabilitados(this.filtro, this.loadingService)
            data = this.filtro.apply(data)
        } else {
            data = val ? this.opciones.filter((s) => s.descripcion.toLowerCase().includes(val.toLowerCase())) : this.opciones.slice()
        }
        this.dataFiltrada.next(incluyeNuevo && this.permiteNuevo ? [].concat(data, Parametrico.NUEVO) : data)
        return this.dataFiltrada.value
    }
    public get isValid() {
        return !this.required || this.seleccionados?.length > 0
    }
    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(
                debounceTime(this.servicio ? 400 : 100),
                map((val) => {
                    if (val?.id || val?.codigo) {
                        return val
                    } else {
                        this.filtro.searchStr = val
                        return this.filter(val)
                    }
                })
            )
            .subscribe((r) => {
                return r
            })
    }
}
