import { Injectable } from '@angular/core';
import { CentroCosto } from 'src/app/model/CentroCosto';
import { BufferedDescriptivoService } from 'src/app/services/BufferedDescriptivoService';
@Injectable({
    providedIn: 'root',
})
export class CentroCostoService extends BufferedDescriptivoService<CentroCosto>{
    public baseName(): string {
        return "centro-costo";
    }
    public parseToEnt(data: any): CentroCosto {
        return CentroCosto.fromData(data);
    }
    public newEnt(): CentroCosto {
        return new CentroCosto();
    }



}