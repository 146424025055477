<div class="model-list">
    <div class="top-section grid">
        <div class="col-12 model-title" *ngIf="!hideHeader">
            <ng-container *ngTemplateOutlet="titleContent ? titleContent : defaultTitleContent; context: this"> </ng-container>
            <ng-template #defaultTitleContent>
                <div class="grid">
                    <div class="col-12 lg:col-4" *ngIf="!hideTitle">
                        <h2 autofocus>{{ finalTitle }}</h2>
                    </div>
                    <div class="col text-right">
                        <div class="grid">
                            <div class="col">
                                <div class="p-inputgroup" *ngIf="filtro">
                                    
                                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                   
                                    <input
                                        [(ngModel)]="filtro.searchStr"
                                        pInputText
                                        placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
                                        [(ngModel)]="filtro.searchStr"
                                    />
                                    <button
                                        pButton
                                        icon="pi pi-refresh"
                                        class="p-button-sm p-button-outlined"
                                        (click)="filtro.forceUpdate(); $event.stopPropagation()"
                                    ></button>
                                    <button
                                        *ngIf="filterContent"
                                        pButton
                                        icon="pi pi-filter"
                                        class="p-button-sm"
                                        [ngClass]="{ 'p-button-outlined': !filtro?.hasFilters() }"
                                        (click)="showFilterbar($event)"
                                    ></button>
                                </div>
                            </div>
                            <div class="col-fixed actions">
                                <ng-container *ngTemplateOutlet="headerActionsContent || defaultHeaderActions"></ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 filter-summary" *ngIf="filtro?.summary?.length" (click)="filtro && showFilterbar($event)">
                        <div class="grid align-items-center">
                            <div class="col-fixed">Resumen de filtros:</div>
                            <div class="col-fixed" *ngFor="let item of filtro?.summary">
                                <span
                                    >{{ item?.titulo }} <strong pTooltip="{{ item?.valor }}">{{ item?.valor }}</strong></span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #defaultHeaderActions>
                <button
                    class="p-button-outlined"
                    *ngIf="permiteNuevo"
                    pButton
                    pTooltip="{{ 'NUEVO' | translate }}"
                    icon="pi pi-plus"
                    (click)="nuevo()"
                ></button>
                <button
                    *ngIf="isMobile() && modoSeleccion == 'multiple'"
                    pButton
                    class="p-button-outlined"
                    pTooltip="{{ 'SELECCIONAR_TODOS' | translate }}"
                    icon="{{ todosSeleccionados ? 'pi pi-square-o' : 'pi pi-check-square-o' }}"
                    (click)="seleccionarTodos()"
                ></button>
                <selector-idioma [(ngModel)]="idiomaSeleccionado" name="idioma" *ngIf="multilenguaje"></selector-idioma>
                <layout-picker [(ngModel)]="filtro.layout" name="layout" *ngIf="filtro && allowLayoutSwitch && !isMobile()"></layout-picker>
            </ng-template>
        </div>
    </div>
    <div class="bottom-section grid">
        <div class="col-12 data" *ngIf="filtro">
            <ng-container *ngTemplateOutlet="getTemplateContent(); context: this"> </ng-container>
            <ng-template #defaultMobileDataContent>
                <p-dataView
                    [value]="listadoFiltrado"
                    [lazy]="isLazy"
                    (onLazyLoad)="loadData($event, filtro)"
                    [paginator]="isPaginator"
                    [rowsPerPageOptions]="[5, 10, 20, 50, 100]"
                    [emptyMessage]="emptymessage"
                    [rows]="filtro?.size ? filtro.size : 20"
                    [totalRecords]="totalRecords"
                    dataKey="id"
                >
                    <ng-template let-data pTemplate="listItem">
                        <ng-container
                            *ngTemplateOutlet="itemDataViewTemplate ? itemDataViewTemplate : defaultItemDataviewTemplate; context: { $implicit: data }"
                        >
                        </ng-container>
                    </ng-template>
                </p-dataView>
                <button
                    pButton
                    class="floating"
                    icon="pi pi-bars"
                    [@floatIn]
                    (click)="showAcciones($event, menuAcciones ? menuAcciones : menuAccionesDefault)"
                    *ngIf="seleccion?.length"
                ></button>
            </ng-template>
            <ng-template #defaultDesktopDataContent>
                <p-table
                    #dt
                    [globalFilterFields]="['id', 'searchStr', 'descripcion']"
                    [value]="listadoFiltrado"
                    [paginator]="isPaginator"
                    [selectionMode]="modoSeleccion"
                    [resizableColumns]="true"
                    (onFilter)="updateCount($event)"
                    [lazy]="isLazy"
                    [responsive]="true"
                    (onLazyLoad)="loadData($event, filtro)"
                    [loading]="loading"
                    [rowsPerPageOptions]="[5, 10, 20, 30, 100]"
                    (onHeaderCheckboxToggle)="rowSelectUnselect($event)"
                    [rows]="getPageSize(filtro)"
                    [totalRecords]="totalRecords"
                    [(selection)]="seleccion"
                    dataKey="id"
                    (onSort)="updatePage($event)"
                    (onPage)="updatePage($event)"
                    (onRowSelect)="rowSelectUnselect($event)"
                    (onRowUnselect)="rowSelectUnselect($event)"
                    [columns]="columns"
                >
                    <ng-template pTemplate="caption" *ngIf="selectionAction || download">
                        <div *ngIf="selectionAction">
                            <ng-container *ngTemplateOutlet="selectionAction"> </ng-container>
                        </div>

                        <div class="ui-helper-clearfix" style="text-align: left" *ngIf="download && listadoFiltrado?.length">
                            <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()"></button>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" *ngIf="emptymessage">
                        <tr>
                            <td [attr.colspan]="columnasLen" class="emptymessage text-center">
                                <h4>
                                    <strong *ngIf="!loading">{{ emptymessage }}</strong>
                                    <strong *ngIf="loading">{{ 'Buscando...' | translate }}</strong>
                                </h4>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="header" let-data>
                        <ng-container *ngTemplateOutlet="header"> </ng-container>
                        <tr>
                            <th *ngIf="modoSeleccion == 'multiple'" style="width: 4%; text-overflow: clip" class="print-hidden">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th
                                *ngFor="let col of columns"
                                [ngStyle]="{ width: col.width }"
                                class="{{ col.class }}"
                                pResizableColumn
                                pTooltip="{{ col.header }}"
                                [pSortableColumn]="col.field + (col.role == 'descriptivo-tabla' ? '.descripcion' : '')"
                            >
                                {{ col.header }}
                                <p-sortIcon [field]="col.field + (col.role == 'descriptivo-tabla' ? '.descripcion' : '')" *ngIf="col.sortable" class="">
                                </p-sortIcon>
                            </th>
                            <th *ngIf="(editable || borrable || conResumen) && !readonly" class="print-hidden actions-header">
                                <button
                                    *ngIf="modoSeleccion == 'multiple'"
                                    pButton
                                    class="p-button-outlined"
                                    [label]="'ACCIONES' | translate"
                                    [disabled]="!seleccion?.length || !menuItemAcciones?.length"
                                    [icon]="'pi pi-list'"
                                    (click)="showAcciones($event, menuAcciones ? menuAcciones : menuAccionesDefault)"
                                ></button>
                            </th>
                        </tr>
                        <ng-container *ngTemplateOutlet="subheader"> </ng-container>
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr [pSelectableRow]="data" (click)="onClick(data, $event)">
                            <td *ngIf="modoSeleccion == 'multiple'" class="table-checkbox text-center print-hidden">
                                <p-tableCheckbox [value]="data" (click)="onCheckClick(data, $event)"></p-tableCheckbox>
                            </td>

                            <!-- <td
                                *ngIf="modoSeleccion == 'multiple'"
                                class="table-checkbox text-center estado {{ data?.estado?.codigo }} print-hidden"
                                [ngStyle]="{ 'background-color': data?.estado?.color }"
                            >
                                <p-tableCheckbox [value]="data" (click)="onCheckClick(data, $event)"></p-tableCheckbox>
                            </td> -->

                            <td *ngFor="let col of columns" class="ui-resizable-column {{ col.classFn ? col.classFn(data) : col.class }}">
                                <ng-container *ngTemplateOutlet="dataField; context: { $implicit: data, col: col }"> </ng-container>
                            </td>

                            <td class="ui-resizable-column print-hidden" *ngIf="(editable || borrable || conResumen) && !readonly">
                                <div class="acciones-tabla" *ngIf="acciones && (acciones | visibles: data)?.length < 4 && !readonly">
                                    <button
                                        pButton
                                        class="p-button-outlined"
                                        *ngFor="let accion of acciones | visibles: data"
                                        [icon]="accion.icon"
                                        (click)="ejecutarAccion(accion, data, $event)"
                                        [pTooltip]="accion.tooltip | translate"
                                        [class]="'accion-tabla ' + accion.styleClass"
                                    ></button>
                                </div>

                                <div class="acciones-tabla" *ngIf="acciones && (acciones | visibles: data)?.length >= 4 && !readonly">
                                    <button
                                        pButton
                                        icon="pi pi-cog"
                                        (click)="mostrarMenu($event, data, menu)"
                                        pTooltip="{{ 'MENÚ_DE_ACCIONES' | translate }}"
                                        class="accion-tabla p-button-outlined"
                                    ></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-data>
                        <tr>
                            <td [attr.colspan]="columnasLen" class="expandedRow">
                                <ng-container *ngTemplateOutlet="toggleTemplate; context: { $implicit: data, handler: handlerRowExpanded }"> </ng-container>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" let-data>
                        <ng-container *ngTemplateOutlet="footer"> </ng-container>
                    </ng-template>
                </p-table>
            </ng-template>
        </div>
    </div>
</div>
<p-menu #menu [model]="items" [popup]="true" [appendTo]="'body'"></p-menu>

<ng-template #estadoCol let-data let-col="col">
    <div
        class=" text-center {{ modoSeleccion != 'multiple' && data.estado ? 'estado-container ' + data?.estado?.codigo : '' }}"
        pTooltip="{{ data?.estado?.descripcion }}"
    >
        <span [ngStyle]="{ 'background-color': data?.estado?.color }" class="chip">{{ data[col.field] }}</span>
    </div>
</ng-template>

<ng-template #dataField let-data let-col="col">
    <profile-badge
        [url]="data[col.field]?.picPathVersion || data[col.field]"
        class="small-badge circular desktop-badge"
        *ngIf="col.role == 'profile-tabla'"
        (click)="toggleSelection(data)"
    >
    </profile-badge>
    <span *ngIf="col.role == 'text-tabla'" class="overflow oneline" pTooltip="{{ data[col.field] | translate }}"
    [ngStyle]="{'max-width': col.width || '100%'}">{{ data[col.field] | translate }}</span>
    <input type="text" *ngIf="col.role == 'text-editable-tabla'" [(ngModel)]="data[col.field]" (click)="$event.stopPropagation()" (blur)="updateRow(data)" />

    <span *ngIf="col.role == 'number-tabla'" class="overflow oneline text-center" pTooltip="{{ data[col.field] }}">{{ data[col.field] }}</span>

    <span *ngIf="col.role == 'descriptivo-tabla'" class="overflow oneline" pTooltip="{{ data[col.field]?.descripcion }}">{{
        data[col.field]?.descripcion
    }}</span>
    <span *ngIf="col.role == 'codigo-tabla'" class="overflow oneline" pTooltip="{{ data[col.field]?.descripcion }}">{{ data[col.field]?.codigo }}</span>
    <span *ngIf="col.role == 'color-tabla'" class="overflow color" [ngStyle]="{ 'background-color': data[col.field] }"></span>

    <i *ngIf="col.role == 'boolean-tabla'" class="fa {{ data[col.field] == true ? 'fa-check succes ' : 'fa-ban error' }}"></i>

    <i *ngIf="col.role == 'icon-tabla'" class="{{ data[col.field] }}"></i>
    <a
        class="overflow oneline"
        target="_blank"
        href="mailto:{{ data[col.field] }}"
        title="data[col.field]"
        pTooltip="{{ data[col.field] }}"
        *ngIf="col.role == 'email-tabla'"
        (click)="$event.stopPropagation()"
        >{{ data[col.field] }}</a
    >
    <span class="color" [ngStyle]="{ 'background-color': data[col.field] }" *ngIf="col.role == 'color-tabla'"></span>
    <span *ngIf="col.role == 'date-tabla'" class="overflow">{{ data[col.field] | date: 'dd/MM/yyyy' }}</span>
    <a
        class="overflow oneline"
        href="{{ data[col.href] }}"
        pTooltip="{{ col?.tooltip ? col.tooltip : ('NAVEGAR' | translate) }}"
        *ngIf="col.role == 'link-tabla' && data[col.href]"
        >{{ data[col.field] }}</a
    >
    <span
        class="clickable"
        (click)="col.navegar(data); $event.stopPropagation()"
        pTooltip="{{ col?.tooltip ? col.tooltip : ('NAVEGAR' | translate) }}"
        *ngIf="col.role == 'link-tabla' && !data[col.href]"
        >{{ data[col.field] }}</span
    >

    <ng-container *ngIf="col.role == 'contactos'">
        <ng-container *ngTemplateOutlet="contactos; context: { $implicit: data, col: col }"> </ng-container>
    </ng-container>

    <ng-container *ngIf="col.role == 'habilitado-tabla'">
        <ng-container *ngTemplateOutlet="habilitado; context: { $implicit: data, col: col }"> </ng-container>
    </ng-container>
    <ng-container *ngIf="col.role == 'template'">
        <ng-container *ngTemplateOutlet="col.template; context: { $implicit: data, col: col }"> </ng-container>
    </ng-container>
    <ng-container *ngIf="col.role == 'money-tabla'">
        <ng-container *ngTemplateOutlet="dineroTemplate; context: { $implicit: data, col: col }"> </ng-container>
    </ng-container>
    <ng-container *ngIf="col.role == 'estado'">
        <ng-container *ngTemplateOutlet="estadoCol; context: { $implicit: data, col: col }"> </ng-container>
    </ng-container>
</ng-template>
<div class="loading" *ngIf="loadingVisible" [@inOutAnimation]>
    <span>{{ mensajeLoading }}</span>
</div>

<ng-template #contactos let-data let-col="col">
    <contactos [data]="data"></contactos>
</ng-template>
<ng-template #dineroTemplate let-data let-col="col">
    <span class="overflow" *ngIf="data['moneda']">{{ data[col.field] | currency: 'USD':data['moneda'].simbolo + ' ' }}</span>
    <span class="overflow" *ngIf="!data['moneda']">{{ data[col.field] | currency }}</span>
</ng-template>
<ng-template #defaultItemDataviewTemplate let-data>
    <div class="item-container {{ dataViewGridStyle }}">
        <div class="card animal" (click)="onClick(data, $event)">
            <div class="grid">
                <div
                    class="col-5 lg:col-4 profile"
                    *ngIf="data['imagen']"
                    (click)="toggleSelection(data, $event)"
                    [ngStyle]="{ 'background-image': 'url(' + data['imagen'] + ')' }"
                ></div>
                <div
                    class="col-5 lg:col-4 profile"
                    style="width: 20vw; max-width: 20%"
                    *ngIf="!data['descripcion'] && data['codigo'] && !data['imagen']"
                    (click)="toggleSelection(data, $event)"
                >
                    <span class="id" *ngIf="!data['imagen'] && (data['id'] || data['codigo'])"># {{ data['codigo'] ? data['codigo'] : data['id'] }}</span>
                </div>
                <div class="col data-section">
                    <div class="grid">
                        <div
                            class="col-12 header {{ data?.estado?.codigo ? data?.estado?.codigo + ' estado' : '' }}"
                            *ngIf="data['descripcion']"
                            pTooltip="{{ data?.estado?.descripcion }}"
                        >
                            <div class="grid align-items-end">
                                <div class="col header-wrapper">
                                    <h3>{{ data['descripcion'] }}</h3>
                                </div>
                                <div
                                    class="col-fixed text-right id-wrapper"
                                    [ngStyle]="{ 'background-color': data.estado?.color, color: getTextColor(data?.estado?.color) }"
                                    *ngIf="data['id'] || data['codigo']"
                                >
                                    <span class="id">{{ data['codigo'] ? data['codigo'] : data['id'] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 info">
                            <div class="grid align-items-center">
                                <div class="col campo {{ campo['class'] }}" *ngFor="let campo of camposMobile">
                                    <ng-container *ngTemplateOutlet="dataField; context: { $implicit: data, col: campo }"> </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 acciones">
                <div class="grid">
                    <div
                        class="col button-wrapper"
                        [ngClass]="{ 'not-visible': !accion.visibleMobile }"
                        *ngFor="let accion of acciones | visibles: data:maxItems:data.displayAll"
                    >
                        <button
                            pButton
                            [ngClass]="{ 'not-visible': !accion.visibleMobile }"
                            class="accion-tabla {{ accion.styleClass }} p-button-outlined"
                            (click)="ejecutarAccion(accion, data, $event)"
                            pTooltip="{{ accion.tooltip | translate }}"
                            icon="{{ accion.icon }}"
                        ></button>
                    </div>
                    <div class="col button-wrapper">
                        <button
                            pButton
                            class="accion-tabla"
                            [ngClass]="{ 'p-button-outlined': displayAll }"
                            (click)="toggleActionsButtons($event, data)"
                            pTooltip="{{ 'Más acciones' | translate }}"
                            icon="pi pi-cog"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #habilitado let-data let-col="col">
    <div class="text-center" (click)="handleHabilitarClick($event, data)">
        <i
            [ngClass]="{ clickable: handler?.habilitar || service?.habilitar }"
            class="fa {{ data[col.field] == true ? 'fa-check succes ' : 'fa-ban error' }}"
        ></i>
    </div>
</ng-template>
<p-menu #menuAccionesDefault [hideTransitionOptions]="'2ms'" [popup]="true" [model]="menuItemAcciones" appendTo="body"></p-menu>
<p-confirmDialog header="Eliminar" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-sidebar [(visible)]="displayFilterBar" #filterBar position="right" [style]="{ width: isMobile() ? '100vw' : '30vw' }">
    <div class="header-filtros">
        <h2>Filtros</h2>
    </div>
    <ng-container *ngTemplateOutlet="filterContent; context: this"> </ng-container>
</p-sidebar>
