import { VentasAG } from './VentasAG';
import { Injectable } from '@angular/core';







@Injectable({
    providedIn: 'root'
})
export class GestionMenuAG extends VentasAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_GESTOR_MENU"]) };



}