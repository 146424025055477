import { TipoIngresoBruto } from 'src/app/model/TipoIngresoBruto';


import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { ProfilePic } from '../common/model/ProfilePic';
import { FileData } from './FileData';


export class PuntoDeVenta extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public profilePic: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),
        public firmanteCertificado: string = "1",
        public passwordCertificado?: string,
        public certificado?: FileData,
        public numeroPuntoVenta?: number,
        public centro?: Descriptivo,
		public razonSocial?: string,
		public cuit?: string,
		public tipoIngresoBruto?: Descriptivo,
		public iibb?: string,
		public domicilioFiscal?: string,
		public codigoPostal?: string,
		public tipoIva?: Descriptivo,
		public fechaInicioActividades?: Date,
		public qrAfip?: string,

    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): PuntoDeVenta {
        if (!data) return null;
        const o: PuntoDeVenta = new PuntoDeVenta(
            data.id,
            data.codigo,
            data.descripcion,
            ProfilePic.fromData(data.profilePic),
            data.firmanteCertificado,
            data.passwordCertificado,
            FileData.fromData(data.certificado),
            data.numeroPuntoVenta,
            Descriptivo.fromData(data.centro),
            data.razonSocial,
            data.cuit,
            Descriptivo.fromData(data.tipoIngresoBruto),
            data.iibb,
            data.domicilioFiscal,
            data.codigoPostal,
            Descriptivo.fromData(data.tipoIva),
            data.fechaInicioActividades ? new Date(data.fechaInicioActividades) : null,
            data.qrAfip

        );

        return o;

    }

}