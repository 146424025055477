import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { EmailTemplate } from '../model/EmailTemplate';
import { TranslateService } from './../common/services/translate.service';
@Injectable({
    providedIn: 'root',
})
export class EmailTemplateService extends ServicioAbstract<EmailTemplate>{

    constructor(private translateService: TranslateService) {
        super()
    }
    public newEnt(): EmailTemplate {
        return new EmailTemplate();
    }

    public baseName(): string { return "emailtemplate" }
    public parseToEnt(data: any): EmailTemplate {
        return EmailTemplate.fromData(data);
    }



}