<div class="profile" [ngClass]="{ 'profile-expanded': active, clickeable: localesDisponibles?.length > 1 }" (click)="seleccionarLocal($event)">
    <div class="local-seleccionado">
        <profile-badge [url]="localSeleccionado?.imagen?.picPathVersion" [disabled]="true"> </profile-badge>
    </div>
    <span *ngIf="localSeleccionado" class="profile-name">{{ localSeleccionado?.descripcion }}</span>
    <span *ngIf="lService?.cantidad == 0" class="profile-name">Crear Local</span>
</div>
<div class="usuario" *ngIf="isMobile()">
    <profile-badge [url]="usuario?.imagen?.picPath" class="small-badge circular desktop-badge" (click)="verUsuario()"> </profile-badge>
    <span *ngIf="app.usuario" class="profile-name">{{ app.usuario?.nombreCompleto }}</span>
</div>

<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let lc of lService.data | async" (click)="aplicarSeleccion(lc)">
            <div class="card">
                <div class="grid">
                    <div class="col-fixed" style="width: 7em">
                        <img src="{{ lc?.imagenUrl }}" alt="" style="width: 100%; height: auto; min-height: 3em;" />
                    </div>
                    <div class="col">{{ lc.descripcion }}</div>
                </div>
            </div>
        </li>
    </ul>
</p-overlayPanel>
