<div class="gestor-parametrico content  content-white" *ngIf="itemEditado && service">
    <model-gestor [readonly]="readonly" [service]="service" [(item)]="itemEditado" [validar]="isValid" [file]="file"
        (onItemGuardado)="onGuardado($event)" (onCancelar)="onCancelado($event)" [goBack]="goBack" [isModal]="isModal"
        [title]="title">
        <div class="gestor-form grid" model-type="dataContent">
            <div class="col-12 lg:col-2">
                <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                    <mat-label>{{'CODIGO' | translate}}</mat-label>
                    <input matInput placeholder="{{'PH_CODIGO' | translate}}" [(ngModel)]="itemEditado.codigo"
                        [disabled]="itemEditado.id || !editable" [disabled]="item?.esSistema">
                </mat-form-field>
            </div>
            <div class="col-12 lg:col-8">
                <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                    <mat-label>{{'DESCRIPCION' | translate}}</mat-label>
                    <input matInput placeholder="{{'PH_DESCRIPCION' | translate}}" [(ngModel)]="itemEditado.descripcion"
                        required [disabled]="!editable">
                </mat-form-field>
            </div>
            <div class="col-12 lg:col-2">
                <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                    <mat-label>{{'PESO' | translate}}</mat-label>
                    <input matInput TYPE="number" placeholder="{{'PH_PESO' | translate}}" [(ngModel)]="itemEditado.peso"
                        [disabled]="!editable" [disabled]="item?.esSistema">
                </mat-form-field>
            </div>
            <centro-selector
                [multiple]="false"
                [(ngModel)]="itemEditado.centro"
                #centroSelector
                [name]="'centros'"
                [readonly]="readonly"
                [required]="true"
                class="col-12 lg:col-12"
            >
            </centro-selector>
        </div>
    </model-gestor>

</div>