import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { FiltroMovimiento } from 'src/app/common/model/FiltroMovimiento';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { CambioSaldoCaja } from 'src/app/model/CambioSaldoCaja';
import { CajaService } from 'src/app/services/caja.service';
import { CambioSaldoCajaService } from 'src/app/services/cambio-saldo-caja.service';


@Component({
  selector: 'listado-cambio-saldo-caja',
  templateUrl: 'listado-cambio-saldo-caja.component.html',
  styleUrls: ['listado-cambio-saldo-caja.component.less']
})

export class ListadoCambioSaldoCajaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: FiltroMovimiento;

  @Input()
  public guardarFiltro: boolean = true;
  @Input()
  public ocultarCabecera: boolean = false;
  @Input()
  public editable: boolean = true;
  public listado: CambioSaldoCaja[] = [];
  @Input()
  public readonly: boolean = false;
  @Input()
  public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';

  @Output()
  public onSelect: EventEmitter<CambioSaldoCaja[]> = new EventEmitter();

  public editandoMovimiento: boolean = false;
  public itemEditado: CambioSaldoCaja;
  public handler: any;
  public columns = [];
  public cajas: Descriptivo[] = []

  public esCierre: boolean = false;
  constructor(messagesService: MessagesService,
    public service: CambioSaldoCajaService,
    public cajasService: CajaService) {
    super(messagesService)

  }
  public ver = (item: number | CambioSaldoCaja) => {
    var id: number;
    if (item instanceof CambioSaldoCaja) {
      id = item.id;
    } else {
      id = item;
    }
    this.service.getById(id).then(r => {
      this.itemEditado = r;
      this.editandoMovimiento = true;
    })
  }
  public onCancelar(event) {
    this.editandoMovimiento = false;
    this.itemEditado = null;
  }
  public refresh(event) {
    this.itemEditado = null;
    this.editandoMovimiento = false;
    this.filtro.forceUpdate();
  }
  public acciones = [];
  ngOnInit() {
    this.handler = {
      ver: this.ver,
    }
    this.filtro = new FiltroMovimiento("filtro-cambio-saldo-listado", null);
    this.filtro.sortField = "id";
    this.filtro.sortOrder = 2;
    this.columns = [
      {
        "field": "id",
        "header": "#",
        "class": "id-column",
        "role": "text-tabla",
        "sortable": true,
        "order": 1
      },

      {
        "field": "fecha",
        "header": "Fecha",
        "class": "date-tabla",
        "role": "date-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "caja",
        "header": "Caja",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },
      {
        "field": "responsable",
        "header": "Responsable",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40

      },
      {
        "field": "tipoIcono",
        "header": "",
        "class": "icon-tabla",
        "role": "icon-tabla",
        "sortable": false,
        "tooltip": "tipoDesc",
        "order": 40
      },

      {
        "field": "monto",
        "header": "Monto",
        "class": "money-tabla",
        "role": "money-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "justificacion",
        "header": "Justificacion",
        "class": "text-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 40
      },
    ];
    this.subs.push(this.cajasService.data.subscribe(r => {
      this.cajas = r;
    }));
  }

  public updateSeleccion(seleccion: any) {
    if (seleccion?.length || seleccion['id']) {
      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
    }
  }

  public async operar(esRetiro: boolean) {
    this.itemEditado = new CambioSaldoCaja();
    this.itemEditado.caja = this.filtro.caja ? await this.cajasService.getById(this.filtro.caja.id) : null;
    this.itemEditado.esRetiro = esRetiro;
    this.itemEditado.fecha = new Date();
    this.itemEditado.responsable = this.usuario;
    this.editandoMovimiento = true;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }



}