import { FormaPago } from 'src/app/model/FormaPago'
import { Moneda } from 'src/app/model/Moneda'
import { IValor } from '../common/components/abm-cobros/IConCobros'
import { Descriptivo } from '../common/model/Descriptivo'
import { FileData } from './FileData'

export class Cobro implements IValor {
    public static TIPO = 'C'
    public tipoCobro: string = Cobro.TIPO
    constructor(
        public id?: number,
        public formaPago?: FormaPago,
        public comprobante?: string,
        public monto?: number,
        public moneda?: Moneda,
        public cotizacion: number = 1,
        public caja?: Descriptivo,
        public adjunto?: FileData
    ) {}

    public static fromData(data: any): Cobro {
        if (!data) return null
        const o: Cobro = new Cobro(
            data.id,
            FormaPago.fromData(data.formaPago),
            data.comprobante,
            data.monto,
            Moneda.fromData(data.moneda),
            data.cotizacion,
            Descriptivo.fromData(data.caja),
            FileData.fromData(data.adjunto)
        )

        return o
    }
}
