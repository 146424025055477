import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MenuDelDiaService {

    constructor(private http: HttpClient) {

    }
    getApiURL() {
        return environment.apiUrl;
    }
    public getBaseURL(path?: string): string {
        return this.getApiURL() + "menu-del-dia/" + (path ? path : '');
    }
    getByLocal(id?: number, idioma?: string): Promise<any> {
        let $this = this;

        return this.http.get(this.getBaseURL("getMenuDelDia?id=") + id + (idioma ? "&idioma=" + idioma : ""))
            .toPromise()
            .then((r: any) => {
                return r;
            });
    }
}