import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Local } from 'src/app/model/Local';
import { LoadingService } from '../common/services/loading-data-service.service';
import { BufferedDescriptivoService } from './BufferedDescriptivoService';
@Injectable({
    providedIn: 'root',
})
export class LocalService extends BufferedDescriptivoService<Local>{


    public cantidad = 0;
    private _localSeleccionado: Local;
    public get localSeleccionado(): Local {
        return this._localSeleccionado;
    }
    public set localSeleccionado(v: Local) {
        this._localSeleccionado = v;
        this.localSeleccionadoObs.next(v);
    }
    public localSeleccionadoObs: BehaviorSubject<Local> = new BehaviorSubject(null);

    public seleccionarLocal(idLocal) {
        this.getById(idLocal).then(l => {
            this.localSeleccionado = l;
            if (l)
                this.novedadesService.currentLocal = l.id;
        })
    }
    public getByCentro(idCentro) {
        return this.getData().then(locs => {
            return locs.filter(l => !idCentro || l.centroCosto?.id == idCentro);
        })
    }

    public getByCentros(ids: number[]) {
        return this.getData().then(locs => {
            return locs.filter(l => ids.indexOf(l.centroCosto?.id) > -1);
        })
    }
    public admiteReservas: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public init() {
        super.init();
        if (!this.admiteReservas) this.admiteReservas = new BehaviorSubject([]);
        this.data.pipe(filter(v => v != undefined)).subscribe(v => {
            if (v?.length && v.some(l => l.permiteReservas)) {
                this.admiteReservas.next(v.filter(l => l.permiteReservas));
            } else {
                this.admiteReservas.next([]);
            }
            this.cantidad = v.length;
            if (!this.localSeleccionado && v?.length == 1) {
                this.localSeleccionado = v[0];
            }


        })
    }


    getByMenu(id: number, customLoading?: LoadingService): Promise<Local[]> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return this.http.get(this.getBaseURL() + "byMenu/" + id ).toPromise().then(r => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
            return this.parse(r)
        }).catch((e) => this.handleError(e, customLoading));    }


    public baseName(): string {
        return "local";
    }
    public parseToEnt(data: any): Local {
        return Local.fromData(data);
    }
    public newEnt(): Local {
        return new Local();
    }
}