import { VentasAG } from './VentasAG';
import { AdministracionAuthGuard } from 'src/app/authguards/AdministracionAuthGuard';
import { Injectable } from '@angular/core';






@Injectable({
    providedIn: 'root'
})
export class PedidosAG extends VentasAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_PEDIDOS"]) };



}