import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { OverlayPanel } from 'primeng/overlaypanel'
import { Nota } from 'src/app/model/Nota'
import { NotaService } from 'src/app/services/nota.service'
import { Descriptivo } from './../../../../common/model/Descriptivo'
import { FiltroFecha } from './../../../../common/model/FiltroFecha'
import { MenuConfigService } from './../../../../common/services/menu-config.service'
import { TranslateService } from './../../../../common/services/translate.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'app-notas',
    templateUrl: './notas.component.html',
    styleUrls: ['./../dashboard-element-common.component.less', './notas.component.less'],
})
export class NotasComponent implements OnInit, IDashboardElement {
    public static TIPO = 'notas'
    private _config: DashboardConfiguration
    public filtro: FiltroFecha
    public now: Date = new Date()
    public textoPeriodo: string
    public refresh() {
        this.filtro.forceUpdate()
    }
    public data: {
        idListado?: number
    }
    public buscarControl: FormControl = new FormControl()
    public static defaultConfig: DashboardConfiguration = {
        fixedHeader: true,
        x: null,
        y: null,
        rows: 7,
        cols: 3,
        refreshable: true,
        tipo: NotasComponent.TIPO,
        params: {
            dias: 30,
        },
    }
    public buscar() {
        this.filtro.setMultiple({
            fechaDesde: moment(new Date()).startOf('date').toDate(),
            fechaHasta: moment(new Date())
                .endOf('date')
                .add(this.dias ? this.dias : 30, 'days')
                .toDate(),
        })
        this.updateTitle()
        this.onChange(this.config)
        this.op.hide()
    }
    private title: string
    public periodoSeleccionado: Descriptivo
    public mostrarNota = false
    public notaEditada: Nota
    public editandoNota: boolean = false
    public verNota(nota: Nota) {
        this.notaEditada = Nota.fromData(nota)
        this.mostrarNota = true
        this.editandoNota = false
    }
    public get config(): DashboardConfiguration {
        return this._config
    }
    isMobile() {
        return window.innerWidth <= 640
    }
    public onDialogShow(event, dialog) {
        if (this.isMobile()) {
            dialog.maximized = true
        }
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        if (!v) {
            this.data = {}
            return
        }
        if (!this.filtro) {
            this.filtro = new FiltroFecha(null, null, 0, 100, 'esPermanente,fechaNota', 1, false)
        }
        this.dias = this._config.params['dias']
        this.buscar()
    }
    constructor(
        private translateService: TranslateService,
        public notasService: NotaService,
        public menuConfService: MenuConfigService,
        private router: Router
    ) {}
    @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel
    public goTo(ruta: string) {
        this.router.navigate([ruta])
    }
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}
    public notas: Nota[] = []
    ngOnInit() {
        if (!this.filtro) {
            this.filtro = new FiltroFecha(null, null, 0, 100, 'esPermanente,fechaNota', 1, false)
        }
        this.filtro.valueChange.subscribe((v) => {
            this.notasService.getAll(v).then((n) => {
                this.notas = n
            })
        })
    }
    public agregarNota() {
        this.notaEditada = this.notasService.newEnt()
        this.notaEditada.fechaNota = moment().startOf('date').toDate()
        this.notaEditada.fechaHasta = moment().endOf('date').toDate()
        this.mostrarNota = true
        this.editandoNota = true
    }
    private _dias: number
    public get dias(): number {
        return this._dias
    }
    public set dias(v: number) {
        this._dias = v
        this.config.params['dias'] = v
    }

    public getTitle = () => {
        return this.title
    }
    public updateTitle() {
        this.title = 'Notas desde ' + moment(this.filtro.fechaDesde).format('DD/MM/YYYY') + ' hasta ' + moment(this.filtro.fechaHasta).format('DD/MM/YYYY')
    }

    public mismaFecha(fechaA: Date, fechaB: Date): boolean {
        return moment(fechaA).isSame(fechaB, 'date')
    }
}
