<div class="grid">
    <div class="col-12 lg:col-6">
        <p-card (click)="verOpcionesRecarga($event)">
            <img src="{{ stockRecarga?.productoAsociado?.imagenPortada }}" alt="" style="width: 100%; object-fit:contain"/>
        </p-card>
    </div>
    <div class="col-12 lg:col-6 capacidad-container">
        <p-card>
            <span class="capacidad">{{ producto?.capacidad }} {{ producto?.unidad?.codigo }}</span>
            <span class="perc">{{ proporcion * 100 }}% </span>
            <span class="cant">{{ stock?.cantidad }} {{ producto?.unidad?.codigo }}</span>
            <div
                class="bar-indicador"
                [ngStyle]="{ top: (1 - proporcion) * 100 + '%' }"
                [ngClass]="{ warning: proporcion < 0.5, danger: proporcion < 0.2 }"
            ></div>
        </p-card>
    </div>
    <div class="flecha card">
        <div class="grid">
            <div class="col-12 no-space-mat-form text-center">
                <mat-form-field appearance="outline">
                    <mat-label>{{ stockRecarga?.insumo?.unidad?.descripcion | translate }} </mat-label>
                    <input
                        matInput
                        placeholder="{{ 'Cantidad' | translate }}"
                        [(ngModel)]="cantidad"
                        (focusin)="onFocus($event)"
                        pTooltip="{{ 'Cantidad' | translate }}"
                        type="number"
                        name="cantidad"
                        required
                        min="1"
                        [max]="maxVal"
                        [disabled]="descartarRestante"
                    />
                    <span matSuffix>{{ stockRecarga?.insumo?.unidad?.codigo }}</span>
                </mat-form-field>
            </div>
            <div class="col-12 text-center">
                <i class="pi pi-chevron-right"></i>
            </div>
            <div class="col-12 text-center">
                <p-toggleButton
                    onLabel="{{ 'Descartar restante' | translate }}"
                    offLabel="{{ 'Solo recarga' | translate }}"
                    iconPos="left"
                    onIcon="{{ 'pi pi-check-square-o' }} "
                    offIcon="{{ 'pi pi-square-o' }} "
                    [(ngModel)]="descartarRestante"
                >
                </p-toggleButton>
            </div>
        </div>
    </div>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of opcionesRecarga | notDescriptivo: stockRecarga?.productoAsociado?.id" (click)="aplicarSeleccion(c)">
            {{ c.descripcion }}
        </li>
    </ul>
</p-overlayPanel>
<div class="footer-buttons">
    <button pButton label="{{ 'CANCELAR' | translate }}" (click)="cancelarRecarga($event)"></button>
    <button pButton label="{{ 'RECARGAR' | translate }}" (click)="confirmarRecarga()"></button>
</div>
