<p-dataView
    #dv
    [value]="items"
    [paginator]="conPaginado"
    [rows]="20"
    paginatorPosition="bottom"
    [emptyMessage]="emptyMessage ? emptyMessage : ('SIN_REGISTROS' | translate)"
>
    <ng-template pTemplate="header">
        <ng-content *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader"></ng-content>
    </ng-template>
    <ng-template let-item pTemplate="listItem" let-r="rowIndex">
        <ng-content *ngTemplateOutlet="gridTemplate ? gridTemplate : defaultGrid; context: { $implicit: item, index: r }"> </ng-content>
    </ng-template>
</p-dataView>

<p-dialog
    [(visible)]="editando"
    appendTo="body"
    [modal]="true"
    #dialDetail
    [styleClass]="'no-header gestor-dialog'"
    (onShow)="onDialogShow($event, dialDetail)"
>
    <ng-container *ngTemplateOutlet="gestor; context: ctx"></ng-container>
</p-dialog>
<ng-template #defaultGrid let-item let-index="index">
    <div class="col-12">
        <div class="grid align-items-center justify-content-center">
            <div class="col" (click)="editar($event, item)">
                <ng-content *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItem; context: { $implicit: item, index: index }"> </ng-content>
            </div>
            <div class="col-fixed text-center" style="width: 4em; padding-top: 0px;">
                <button pButton class="accion-quitar" (click)="quitarItem(item)" icon="pi pi-trash" *ngIf="editable"></button>
                <button pButton class="accion" (click)="onVer.emit(item)" icon="pi pi-eye" *ngIf="visualizable"></button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #defaultItem let-item let-index="index">
    <div class="grid align-items-center justify-content-center">
        <div class="col-2">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label *ngIf="index == 0">{{ 'CODIGO' | translate }}</mat-label>
                <input
                    matInput
                    placeholder="{{ 'CODIGO_PH' | translate }}"
                    [(ngModel)]="item.codigo"
                    readonly
                    pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
                    type="text"
                    #codigo="ngModel"
                    name="{'codigo'+index}"
                />
            </mat-form-field>
        </div>
        <div class="col-10">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label *ngIf="index == 0">{{ 'DESCRIPCION' | translate }}</mat-label>
                <input
                    matInput
                    placeholder="{{ 'DESCRIPCION_PH' | translate }}"
                    [(ngModel)]="item.descripcion"
                    readonly
                    pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
                    type="text"
                    #descripcion="ngModel"
                    name="{'descripcion'+index}"
                />
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #defaultHeader>
    <button
        pButton
        [label]="labelNuevo | translate"
        icon="pi pi-plus"
        (click)="agregarOpcion()"
        *ngIf="(gestor || insertOnly) && editable"
        id="{{ buttonName }}"
    ></button>
</ng-template>
