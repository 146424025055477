import { Parametrico } from 'src/app/common/model/Parametrico'
import { ProfilePic } from 'src/app/common/model/ProfilePic'

export class Restriccion extends Parametrico {
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false,
        public codigoIdioma: string = 'ES',
        public icono: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),
        public excluyente: boolean = false
    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return '$MODELNAMELOW'
    }
    public static fromData(data: any): Restriccion {
        if (!data) return null
      
        const o: Restriccion = new Restriccion(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esDefault,
            data.codigoIdioma,
            ProfilePic.fromData(data.icono),
            data.excluyente
        )
        return o
    }

    public get imagenUrl(){
        return this.icono?.picPath || null; 
    }
}
