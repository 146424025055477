import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TranslateService } from './../../services/translate.service'

@Component({
    selector: 'selector-dia',
    templateUrl: './selector-dia.component.html',
    styleUrls: ['./selector-dia.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorDiaComponent),
            multi: true,
        },
    ],
})
export class SelectorDiaComponent implements OnInit, ControlValueAccessor, OnDestroy {
    private sub: Subscription
    constructor(private tService: TranslateService) {}
    calendarioLocale: any
    ngOnDestroy(): void {
        if (this.sub) this.sub.unsubscribe()
    }
    ngOnInit() {
        this.sub = this.tService.upateData.subscribe((r) => {
            this.calendarioLocale = this.tService.get('CALENDARIO_LOCALE')
        })
    }

    public tieneDia(dia: number) {
        return this.diasSeleccionados ? this.diasSeleccionados.indexOf(dia) >= 0 : false
    }

    public toggleDia(dia: number) {
        if (!this.diasSeleccionados || this.readonly) return
        if (!this.tieneDia(dia)) {
            this.diasSeleccionados.push(dia)
        } else {
            this.diasSeleccionados = this.diasSeleccionados.filter((d) => d != dia)
        }
    }

    private _diasSeleccionados: number[] = []
    public get diasSeleccionados(): number[] {
        return this._diasSeleccionados
    }
    @Input()
    public set diasSeleccionados(v: number[]) {
        this._diasSeleccionados = v
        this.onChangeCallback(this._diasSeleccionados)
    }

    @Input()
    public readonly: boolean = false

    public diasSemanaNum = [0, 1, 2, 3, 4, 5, 6]

    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    public disabled: boolean = false

    writeValue(obj: any): void {
        this._diasSeleccionados = obj ? obj : []
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    public getNombreDia(v: number) {
        if (!this.calendarioLocale.dayNames) return
        return this.calendarioLocale?.dayNames[v]
    }
    public get diasSemana(): string[] {
        return this.calendarioLocale?.dayNamesMin || []
    }
}
