import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { TipoIVA } from 'src/app/model/TipoIVA'

export class ItemComprobante {
    constructor(
        public id?: number,
        public producto?: Descriptivo,
        public descripcion?: string,
        public tipoIVA?: TipoIVA,
        public precioUnitario?: number,
        public cantidad: number = 1,
        public descuento: number = 0,
        public impuestos: number = 0,
        public precioUnitarioBruto: number = 0,
        public valorDescuento: number = 0
    ) {}
    public static fromData(data: any): ItemComprobante {
        if (!data) return null
        const o: ItemComprobante = new ItemComprobante(
            data.id,
            Descriptivo.fromData(data.producto),
            data.descripcion,
            TipoIVA.fromData(data.tipoIVA),
            data.precioUnitario,
            data.cantidad,
            data.descuento,
            data.impuestos,
            data.precioUnitarioBruto,
            data.valorDescuento
        )

        return o
    }

    public get total() {
        return this.subtotal + this.impuestos*this.cantidad
    }

    public get totalNeto() {
        return this.subtotalNeto - this.valorDescuento*this.cantidad
    }

    public get subtotal() {
        return this.cantidad * this.precioUnitarioBruto 
    }

    public get subtotalNeto() {
        return this.cantidad * this.precioUnitario 
    }
    public get precioTotal() {
        return this.precioUnitario * this.cantidad 
    }
}
