import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeModule } from './pages/home/home.module'
import { LoginComponent } from './pages/login/login.component'
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component'
import { PasswordResetComponent } from './pages/password-reset/password-reset.component'
import { RecuperarPasswordComponent } from './pages/recuperar-password/recuperar-password.component'
import { DownloadFileComponent } from './public/download-file/download-file.component'

const routes: Routes = [
    { path: 'login', component: LoginComponent, data: { animation: 'List' } },
    //{ path: 'public/registrarse', component: RegistrarseComponent, data: { animation: 'Item' }, }, // FALTA LA PAGINA DE REGISTRO
    { path: 'public/envio-recupero', component: RecuperarPasswordComponent },
    { path: 'public/recupero-contrasenia/:token', component: PasswordResetComponent, data: { animation: 'Item' } },
    { path: 'public/:tipo/:token', component: DownloadFileComponent },

    //Usuarios

    //Paramtros

    { path: '**', component: PageNotFoundComponent },
]
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            relativeLinkResolution: 'legacy',
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
        }),
        HomeModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
