import * as moment from 'moment';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Usuario } from 'src/app/common/model/Usuario';
import { ListaProducto } from './ListaProducto';

export class ListaPrecios extends Descriptivo {


    constructor(id?: number, codigo?: string, descripcion?: string,
        public moneda?: Descriptivo,
        public productos: ListaProducto[] = [],
        public ultimaActualizacion?: Date,
        public responsable?: Descriptivo,
        public esDefault?: boolean,
        public habilitado?: boolean,
        public codigoEstado?: string,
        public impuestosIncluidos: boolean = true,
        public centroCosto?: Descriptivo,
        public locales: Descriptivo[] = [],


    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): ListaPrecios {
        if (!data) return null;
        const o: ListaPrecios = new ListaPrecios(
            data.id,
            data.codigo,
            data.descripcion,
            Descriptivo.fromData(data.moneda),
            data.productos.map(c => ListaProducto.fromData(c)),
            data.ultimaActualizacion ? new Date(data.ultimaActualizacion) : null,
            Usuario.fromData(data.responsable),
            data.esDefault,
            data.habilitado,
            data.codigoEstado,
            data.impuestosIncluidos,
            Descriptivo.fromData(data.centroCosto),
            data.locales ? data.locales.map(l => Descriptivo.fromData(l)) : []
        );

        return o;
    }

    getProductosFecha(f: Date) {
        let productosRango = this.productos.filter(p => p.fechaDesde <= f && (!p.fechaHasta || p.fechaHasta >= f));
        productosRango.forEach(p => p.cumpleFecha = true);
        let productosUnicos = this.productos.filter((p, i, arr) => arr.findIndex(pp => pp.producto.id === p.producto.id) === i);
        let productosNoRango = productosUnicos.filter(pu => !productosRango.some(pp => pp.producto.id == pu.producto.id));
        productosNoRango.forEach(p => p.cumpleFecha = false);
        return productosRango.concat(productosNoRango).sort((a, b) => a.producto.descripcion < b.producto.descripcion ? -1 : 1);
    }

    getPrecio(producto: Descriptivo, fecha: Date) {
        var precio = this.productos.filter(l => l.aplica(producto, fecha))[0];
        return precio ? precio.precio : null;
    }
    getByProducto(producto: Descriptivo) {
        return this.productos.filter(p => p.aplica(producto) || (p.producto.id == producto.id && moment(p.fechaDesde).isAfter(new Date())));
    }
    public tieneLocal(l: Descriptivo | Descriptivo[]) {
        const locales = [].concat(l);
        return this.locales.some(loc => locales.some(ll => ll.id == loc.id));
    }

    public tieneProducto(producto: number): boolean {
        const res = this.productos.some(p => p.producto.id == producto)
        return res
    }

}
