import { Descriptivo } from 'src/app/common/model/Descriptivo';




export class TraspasoDeCaja {
	constructor(
		public id?: number,
		public fecha: Date = new Date(),
		public descripcion?: string,
		public responsable?: Descriptivo,
		public cajaOrigen?: Descriptivo,
		public cajaDestino?: Descriptivo,
		public formaPagoDestino?: Descriptivo,
		public formaPagoOrigen?: Descriptivo,
		public monto?: number,
		public costo?: number,

	) {
	}
	public static fromData(data: any): TraspasoDeCaja {
		if (!data) return null;
		const o: TraspasoDeCaja = new TraspasoDeCaja(
			data.id,
			data.fecha ? new Date(data.fecha) : new Date(),
			data.descripcion,
			Descriptivo.fromData(data.responsable),
			Descriptivo.fromData(data.cajaOrigen),
			Descriptivo.fromData(data.cajaDestino),
			Descriptivo.fromData(data.formaPagoDestino),
			Descriptivo.fromData(data.formaPagoOrigen),
			data.monto,
			data.costo,

		);

		return o;

	}

}