import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { KEY_CODE } from 'src/app/app.component';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { UsuarioService } from './../../common/services/usuario.service';

@Component({
	selector: 'password-reset',
	templateUrl: 'password-reset.component.html',
	styleUrls: ['password-reset.component.less']
})

export class PasswordResetComponent extends SessionComponent implements OnInit {
	public confPass: string = "";
	public pass: string = "";
	public valido: boolean = false;
	private token: string = "";



	constructor(private usuarioService: UsuarioService, private router: Router, private route: ActivatedRoute, messagesService: MessagesService) {
		super(messagesService, "reset-password")
	}
	ngOnInit() {
		this.route.params.pipe(first()).subscribe(params => {
			if (params["token"]) {
				this.token = params["token"];
				this.usuarioService.validarToken(this.token).then(r => {
					if (r) {
						this.valido = true;

					}
				})
			}
		})

	}

	actualizarPassword() {
		if (this.confPass !== this.pass) {
			return this.error("No coinciden los valores ingresados");
		}
		this.finalizado = true;
		this.usuarioService.actualizarPassword(this.pass, this.token).then(r => {
			if (r) {
				this.success("Contraseña actualizada. Por favor vuelva a loguearse.")
				setTimeout(() => this.router.navigate(["login"]), 1500 )
				;
			} else {
				this.error("Hubo un error al actualizar la contraseña. Comuniquese con administración");

			}
		})

	}

	@HostListener('window:keyup', ['$event']) 
	keyEvent(event: KeyboardEvent) {
		console.log(event);
		if (event.code == "Enter") {
		  this.actualizarPassword();
		}
	
	  }

}