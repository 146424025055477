<div class="login">
    <div class="login-content">
        <div class="grid">
            <div class="lg:col-6 col-12 left">
                <img src="assets/implementacion/images/logo.png" />
            </div>
            <div class="lg:col-6 col-12 right">
                <form name="form" (ngSubmit)="login()" role="form">
                    <div class="grid field">
                        <div class="lg:col-4 col-12 col-nopad">
                            <label for="float-input">{{ 'Usuario' | translate }}</label>
                        </div>

                        <div class="lg:col-8 col-12 col-nopad">
                            <input name="usuario" type="text" size="30" pInputText [(ngModel)]="username" required />
                        </div>
                    </div>
                    <div class="grid field">
                        <div class="lg:col-4 col-12 col-nopad">
                            <label for="float-input">{{ 'Contraseña' | translate }}</label>
                        </div>

                        <div class="lg:col-8 col-12 col-nopad">
                            <input name="password" type="password" size="30" pInputText [(ngModel)]="password" required />
                        </div>
                    </div>
                    <div class="buttons grid p-fluid justify-content-center">
                        <button type="submit" pButton class="p-button-success" label="{{ 'ingresar' | translate }}"></button>

                        <button type="button" pButton class="p-link" (click)="olvidePass()" label="{{ 'olvide_pass' | translate }}"></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<messages></messages>
