import { AdministracionAuthGuard } from 'src/app/authguards/AdministracionAuthGuard';
import { Injectable } from '@angular/core';






@Injectable({
    providedIn: 'root'
})
export class FacturasAG extends AdministracionAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_FACTURAS"]) };



}