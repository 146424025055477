<div class="p-inputgroup selector-multiple-chips" [ngClass]="{ readonly: readonly }">
    <span class="p-inputgroup-addon" *ngIf="readonly || seleccionados?.length">
        <i class="fa fa-eraser clickable" (click)="vaciarSeleccionados()"></i>
    </span>
    <span class="p-float-label">
        <p-autoComplete
            [dataKey]="'key'"
            [(ngModel)]="seleccionados"
            [suggestions]="dataFiltrada | async"
            (completeMethod)="filter($event.query)"
            [multiple]="true"
            field="descripcion"
            (onUnselect)="quitarItem($event)"
            (onSelect)="onSeleccionado($event)"
            [readonly]="readonly"
            forceSelection="true"
            appendTo="body"
            styleClass="autocomplete-multiple-chips"
            inputStyleClass="input-autocomplete"
            panelStyleClass="panel-autocomplete"
        >
            <ng-template let-chip pTemplate="selectedItem">
                <div class="option-image">
                    <img [src]="chip?.imagenUrl" alt="" *ngIf="chip?.imagenUrl" class="small-icon" />
                    <i class="{{ chip?.icon }}" *ngIf="chip?.icon"></i>
                    <span>{{ chip?.descripcion }}</span>
                </div>
            </ng-template>
            <ng-template let-option pTemplate="item">
                <i [class]="option?.icon ? option?.icon : 'dummy'" style="margin-right: 0.5em"></i>{{ option?.descripcion }}
            </ng-template>
        </p-autoComplete>
        <label class="">{{ isValid ? titulo : titulo + ' - Seleccione al menos 1' }}</label>
    </span>
</div>

<p-dialog [(visible)]="editando" appendTo="body" [modal]="true" #dialDetail [styleClass]="'gestor-dialog'" (onShow)="onDialogShow($event, dialDetail)">
    <ng-container *ngTemplateOutlet="gestor ? gestor : defaultGestor; context: ctx"></ng-container>
</p-dialog>
<ng-template #defaultGestor>
    <span>no se definió formulario</span>
</ng-template>
