import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { ConfiguracionUsuario } from './../../model/ConfiguracionUsuario';
import { ProfilePic } from './ProfilePic';
import { Rol } from './Rol';

export class Usuario extends Descriptivo {

    public static MODEL_NAME = "usuario";

    constructor(
        id?: number,
        public username?: string,
        public nombre?: string,
        public apellido?: string,
        public activo: boolean = true,
        public bloqueado: boolean = false,
        public email?: string,
        public profilePic = new ProfilePic(null, 'assets/images/default_profile.png'),
        public telefono?: string,
        public roles: Rol[] = [],
        public esVerificado: boolean = false,
        public configuracion: ConfiguracionUsuario = new ConfiguracionUsuario(),



    ) {
        super(id + "", username, id);
        this.telefono = telefono;
        this.roles = roles;
        this.esVerificado = esVerificado;
    }
    entityName(): string {
        return Usuario.MODEL_NAME;
    }
    public static fromData(data: any): Usuario {
        if (!data) return null;
        let u: Usuario = new Usuario(data.id,
            data.username,
            data.nombre,
            data.apellido,
            data.activo,
            data.bloqueado,
            data.email,
            data.profilePic ? ProfilePic.fromData(data.profilePic) : new ProfilePic(),
            data.telefono,
            data.roles ? data.roles.map(Rol.fromData) : [],
            data.esVerificado,
            ConfiguracionUsuario.fromData(data.configuracion))

        return u;
    }

    esAdmin() {
        return this.roles.some(r => r.codigo == Rol.ADMIN_CODE);
    }
    esGestorCentro() {
        return this.roles.some(r => r.codigo == Rol.GESTOR_CENTRO) ;
    }
    tieneRol(roles: string[]) {
        return roles.some(r => this.tieneSingleRol(r));
    }
    tieneSingleRol(rol:string){
        return this.esAdmin() || (this.esGestorCentro() && rol !=Rol.ADMIN_CODE) || this.roles.some(r=> r.codigo === rol)
    }

    esSoloVisualizador() {
        return this.roles.length === 1 && this.roles.some(r => r.codigo == Rol.VISUALIZADOR_CODE);
    }

    get nombreCompleto(): string {
        return this.descripcion;
    }

    get nombreApellido(): string {
        return this.nombre && this.apellido ? `${this.nombre} ${this.apellido}` : this.descripcion
    }

    public get imagen() {
        return this.profilePic;
    }

}
