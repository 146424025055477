import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { OverlayPanel } from 'primeng/overlaypanel'
import { Local } from 'src/app/model/Local'
import { LocalService } from 'src/app/services/local.service'
import { TipoEntregaService } from 'src/app/services/tipo-entrega.service'
import { LoadingService } from './../../../../common/services/loading-data-service.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'pedido-shortcut',
    templateUrl: 'pedido-shortcut.component.html',
    styleUrls: ['pedido-shortcut.component.less', './../dashboard-element-common.component.less'],
})
export class PedidoShortcutComponent implements OnInit, IDashboardElement {
    public refresh() {
        return
    }
    private _localElegido: Local
    public get localElegido(): Local {
        return this._localElegido
    }
    @Input()
    public set localElegido(v: Local) {
        this._localElegido = v
        if (this._localElegido) {
            this.config.params['idLocal'] = v.id
        } else {
            this.config.params['idLocal'] = null
        }
    }

    @ViewChild('op', { static: true }) op: OverlayPanel
    public static TIPO = 'pedido'
    public static defaultConfig: DashboardConfiguration = {
        minItemCols: 2,
        minItemRows: 2,
        fixedHeader: true,
        x: null,
        y: null,
        rows: 2,
        cols: 3,
        tipo: 'pedido',
        params: {},
    }

    private _config: DashboardConfiguration
    public customLoading: LoadingService = new LoadingService()
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        if (this._config && this._config.params?.['idLocal']) {
            this.localService.getById(this._config.params?.['idLocal'], this.customLoading).then((r) => {
                this.localElegido = r
            })
        }
    }

    constructor(public tipoEntregaService: TipoEntregaService, private router: Router, public localService: LocalService) {}
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}
    public aplicarSeleccion(local) {
        this.localElegido = local
        this.op.hide()
        this.onChange(this.config)
    }
    ngOnInit() {}
    nuevoPedido(idLocal, idTipoEntrega) {
        this.router.navigate(['pedido/nuevo'], { queryParams: { localId: idLocal, tipoEntregaId: idTipoEntrega } })
    }
    public getTitle = () => {
        return this.localElegido ? this.localElegido.descripcion : 'Sin local elegido'
    }
}
