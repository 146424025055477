<div class="parametricos content">
    <div class="grid title">
        <div class="col-6 lg:col-8">
            <h3>{{'PARAMETROS' | translate}}</h3>
        </div>

    </div>
    <div class="grid">
        <div class="col-12 lg:col-3 wrapper-parametro" *ngFor="let param of parametros">
            <button pButton (click)="gotoParameter(param)" class="ui-button-secondary param-button"
                [label]="param.label" [icon]="param.icono"></button>
        </div>
    </div>
</div>