<div class="layout-topbar p-shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo text-center" routerLink="/">
            <h3>{{ 'TITULO' | translate }}</h3>
        </a>

        <a class="layout-menu-button p-shadow-6" (click)="appMain.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-bars"></i>
        </a>

        <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>
    <div class="layout-topbar-right">
        <div class="layout-topbar-actions-left"></div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <!--li class="layout-topbar-item">
                    <app-novedades></app-novedades>
                </li-->
                <li class="layout-topbar-item" (click)="verUsuario()">
                    <span style="margin-right: 5px" *ngIf="usuario?.nombreCompleto">{{ usuario?.nombreCompleto }}</span>
                    <profile-badge [url]="usuario?.imagen?.picPath" class="small-badge circular desktop-badge"> </profile-badge>
                </li>

                <li class="layout-topbar-item">
                    <a
                        href="#"
                        class="layout-topbar-action rounded-circle"
                        (click)="menu.toggle($event); $event.preventDefault()"
                        *ngIf="itemsConfiguracion?.length"
                    >
                        <i class="pi fs-large pi-cog"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<p-slideMenu #menu [model]="itemsConfiguracion" [popup]="true" backLabel="{{ 'VOLVER' | translate }}" viewportHeight="400"> </p-slideMenu>
