import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Producto } from 'src/app/model/Producto';
import { StockInsumo } from 'src/app/model/StockInsumo';
import { ProductoService } from 'src/app/services/producto.service';
import { StockInsumoService } from 'src/app/services/stock-insumo.service';
import { Descriptivo } from './../../../common/model/Descriptivo';
import { LoadingService } from './../../../common/services/loading-data-service.service';
import { MessagesService } from './../../../common/services/messages-data-service.service';
import { TranslateService } from './../../../common/services/translate.service';
import { FiltroProducto } from './../../../model/FiltroProducto';
import { MovimientoStock } from './../../../model/MovimientoStock';
import { ProductoCabecera } from './../../../model/ProductoCabecera';

@Component({
  selector: 'recarga-accion',
  templateUrl: './recarga-accion.component.html',
  styleUrls: ['./recarga-accion.component.less']
})
export class RecargaAccionComponent implements OnInit {


  @ViewChild("op", { static: true }) op;

  private _producto: Producto;
  public stock: StockInsumo;
  public stockRecarga: StockInsumo;
  public loadingService: LoadingService = new LoadingService();
  public opcionesRecarga: ProductoCabecera[] = [];

  private _descartarRestante: boolean;
  public get descartarRestante(): boolean {
    return this._descartarRestante;
  }
  public set descartarRestante(v: boolean) {
    this._descartarRestante = v;
    if (v) {
      this.cantidad = this.producto.capacidad;
    } else {
      this.cantidad = this.producto.capacidad - this.stock.cantidad;
    }

  }

  public verOpciones: boolean = false;
  public cantidad: number;
  @Output()
  public onCancelar: EventEmitter<boolean> = new EventEmitter();
  @Output()
  public onGuardar: EventEmitter<StockInsumo> = new EventEmitter();
  public get maxVal() {
    if (!this.producto || !this.stock) return 0;
    return this.producto.capacidad - this.stock.cantidad;
  }
  public get producto(): Producto {
    return this._producto;

  }
  @Input()
  public set producto(v: Producto) {
    if (v?.id) {
      this._producto = v;
      this.stockService.getByProducto(v.id).then(s => {
        this.stock = s;
        this.cantidad = this.producto.capacidad - this.stock.cantidad;
      })
      if (this._producto.productoRecarga) {
        this.actualizarStockRecarga(this._producto.productoRecarga);
      }
    }

  }

  public actualizarStockRecarga(p: ProductoCabecera | Descriptivo) {
    if (!p.id) return;
    this.stockService.getByProducto(p.id).then(s => {
      this.stockRecarga = s;
    })
  }

  public get proporcion() {
    if (!this.stock || !this.producto || !this.producto?.capacidad) return 0;
    return this.stock?.cantidad / this.producto?.capacidad;
  }



  constructor(public productoService: ProductoService, public stockService: StockInsumoService, private messagesService: MessagesService,
    private translateService: TranslateService) { }

  ngOnInit() {
  }
  public async getOpciones() {
    if (!this.opcionesRecarga?.length) {
      var filtro = new FiltroProducto(null, 0, 100, "cantidad,descripcion", 2, false);
      filtro.soloVenta = false;
      filtro.categorias = [this.producto.categoriaRecarga];
      this.opcionesRecarga = await this.productoService.getCabeceras(filtro, this.loadingService);
    }

  }
  public async verOpcionesRecarga(event) {
    await this.getOpciones();
    this.op.show(event);

  }
  public aplicarSeleccion(p: ProductoCabecera) {
    this.actualizarStockRecarga(p);
    this.op.hide();
  }
  public error(mensaje?: string, titulo?: string) {
    this.messagesService.error(mensaje, titulo);
    return false;
  }
  public confirmarRecarga() {
    var recarga = new MovimientoStock();
    if (!this.descartarRestante && (this.cantidad + this.stock.cantidad) > this.producto.capacidad) {
      return this.error(this.translateService.get("NO_PUEDE_SUPERAR_L_11"));
    }
    if (!this.stockRecarga) {
      return this.error(this.translateService.get("INDIQUE_UN_PRODUCTO_6"));
    }
    recarga.cantidad = this.cantidad;
    recarga.idStockDesde = this.stockRecarga.id;
    recarga.idStock = this.stock.id;
    recarga.descartarRestante = this.descartarRestante;
    this.stockService.recargar(recarga).then(r => {
      this.onGuardar.emit(r);
    })

  }
  public onFocus(event: any) {
    event.srcElement.select();
  }

  public cancelarRecarga(event?) {
    this.op.hide();
    this.onCancelar.emit(true);
  }

}
