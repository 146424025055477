<div class='gestor-traspaso-de-caja content  content-white'>


    <model-gestor [readonly]="readonly" [service]='service' [(item)]='item' [validar]='isValid'
        [modelName]="'TraspasoDeCaja' | translate" [verAcciones]="false" [isModal]="isModal" [goBack]="goBack"
        (onItemGuardado)="handleGuardado($event)" [editable]="esEditable" (onCancelar)="handleCancelar($event)"
        [form]="TraspasoDeCajaForm" [multilenguaje]="false" (onEditar)="onEdit($event)">

        <div class='gestor-form' model-type='dataContent'>
            <form #TraspasoDeCajaForm="ngForm" id="TraspasoDeCajaform">
                <div class="grid" *ngIf="item">

                    <div class="col-12 lg:col-4">
                        <mat-form-field appearance="outline" [ngClass]="{'readonly':readonly}">
                            <mat-label>{{'FECHA' | translate}}</mat-label>
                            <input matInput [matDatepicker]="fechaPick" [(ngModel)]="item.fecha" #fecha="ngModel"
                                name="fecha" [readonly]="readonly">
                            <mat-datepicker-toggle matSuffix [for]="fechaPick" [disabled]="readonly">
                            </mat-datepicker-toggle>
                            <mat-datepicker #fechaPick></mat-datepicker>
                            <mat-error>
                                {{getErrorMessage(fecha)}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class='col-12 lg:col-4'>
                        <mat-form-field appearance='outline' floatLabel="always" [ngClass]="{'readonly':readonly}">
                            <mat-label>{{'RESPONSABLE' | translate}}</mat-label>
                            <input matInput placeholder="{{'Responsable' | translate}}" [ngModel]='getUserFullName()'
                                pTooltip="{{'RESPONSABLE' | translate}}" type="text" #descripcion="ngModel"
                                name="responsable" [readonly]="true">

                        </mat-form-field>
                    </div>
                    <div class='col-12'>
                        <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                            <mat-label>{{'DESCRIPCION' | translate}}</mat-label>
                            <input matInput placeholder="{{'DESCRIPCION_PH' | translate}}"
                                [(ngModel)]='item.descripcion' pTooltip="{{'DESCRIPCION_TOOLTIP' | translate}}"
                                type="text" #descripcion="ngModel" name="descripcion" [readonly]="readonly" required>
                            <mat-error>
                                {{getErrorMessage(descripcion)}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-12 lg:col-6">
                        <div class="grid grid-nogutter">
                            <div class="col-12">
                                <descriptivo-material-selector [options]="cajaOptions"
                                    [label]="'CAJAORIGEN' | translate" [limpiable]="limpiable"
                                    [(ngModel)]="item.cajaOrigen" [permiteNuevo]="false" (onNew)="nuevocajaOptions()"
                                    name="cajaOrigen" [readonly]="readonly || !cajaEditable">
                                </descriptivo-material-selector>
                            </div>
                            <div class="col-12">
                                <parametrico-selector [service]="formaPagoService" #formaPagoOrigen="ngModel"
                                    [(ngModel)]='item.formaPagoOrigen' [limpiable]='true' [permiteNuevo]="true"
                                    name='forma-pago' [titulo]="'FORMAPAGOORIGEN' | translate" [readonly]="readonly">
                                </parametrico-selector>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <div class="grid  grid grid-nogutter">
                            <div class='col-12'>
                                <descriptivo-material-selector [options]="cajaOptions"
                                    [label]="'CAJADESTINO' | translate" [limpiable]="limpiable"
                                    [(ngModel)]="item.cajaDestino" [permiteNuevo]="false" (onNew)="nuevocajaOptions()"
                                    name="cajaDestino" [readonly]="readonly">
                                </descriptivo-material-selector>
                            </div>

                            <div class='col-12'>
                                <parametrico-selector [service]="formaPagoService" #formaPagoDestino="ngModel"
                                    [(ngModel)]='item.formaPagoDestino' [limpiable]='true'
                                    [permiteNuevo]="esAdministrador" name='forma-pago'
                                    [titulo]="'FORMAPAGODESTINO' | translate" [readonly]="readonly">
                                </parametrico-selector>
                            </div>
                        </div>
                    </div>
                    <div class='col-12 lg:col-6'>
                        <span-dinero-editable [(ngModel)]="item.monto" [label]="'Monto' | translate"
                            [readonly]="readonly" name="{{'monto'+r}}" [minValue]="0">
                        </span-dinero-editable>

                    </div>
                    <div class='col-12 lg:col-6'>
                        <span-dinero-editable [(ngModel)]="item.costo" [label]="'Costo de la transferencia' | translate"
                            [readonly]="readonly" name="{{'costo'+r}}" [minValue]="0">
                        </span-dinero-editable>

                    </div>

                </div>
            </form>
        </div>
    </model-gestor>
</div>
