<div class="grid container">
    <div class="col-12" *ngIf="!data">
        <span> Elija un atajo</span>
    </div>

    <div class="col" *ngIf="data">
        <div class="shortcut card grid align-items-center justify-content-center" (click)="goTo(data?.ruta)"
            pTooltip="{{data?.tooltip}}">
            <span class="overflow">{{data?.titulo | translate}}</span>

        </div>
    </div>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of menuConfService.rutasBS | async" (click)="aplicarSeleccion(c)">
            {{c.label}}
        </li>
    </ul>
</p-overlayPanel>
