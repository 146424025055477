import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArqueoCaja } from 'src/app/model/ArqueoCaja';
import { Caja } from 'src/app/model/Caja';
import { BilleteService } from 'src/app/services/billete.service';
import { CajaService } from 'src/app/services/caja.service';
import { AuthService } from './../../services/auth.service';

@Component({
  selector: 'operaciones-caja',
  templateUrl: './operaciones-caja.component.html',
  styleUrls: ['./operaciones-caja.component.less']
})
export class OperacionesCajaComponent implements OnInit {

  public cajas: Caja[] = [];
  public itemEditado: ArqueoCaja;
  public editandoMovimiento: boolean = false;
  constructor(public cajaService: CajaService,
    public router: Router,
    public authService: AuthService,
    public billeteService: BilleteService) { }
  @Input()
  public styleClass: string;
  ngOnInit() {
  }

  public async genererCambioEstado(caja: Caja, cierre: boolean) {
    this.itemEditado = new ArqueoCaja();
    this.itemEditado.valores = await this.billeteService.iniciarBilletes();
    this.itemEditado.caja = caja;
    //this.itemEditado.esCierre = cierre;
    this.itemEditado.responsable = this.authService.usuario;
    this.editandoMovimiento = true;
  }
  public verCaja(caja: Caja) {
    this.router.navigate(['movimiento-caja'], { queryParams: { id: caja.id } })
  }
  public onDialogShow(event, dialog) {
    if (this.isMobile()) {
      dialog.maximized = true;

    }
  }
  isMobile() {
    return window.innerWidth <= 640;
  }
  public onCancelar(event) {
    this.editandoMovimiento = false;
    this.itemEditado = null;
  }
  public refresh(event) {
    this.editandoMovimiento = false;
    this.itemEditado = null;
    this.cajaService.forceRefresh();
  }
}
