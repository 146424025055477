import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { MessagingService } from 'src/app/services/messages.service'
import { SessionComponent } from './../../common/components/session-component.component'

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['login.component.less'],
})
export class LoginComponent extends SessionComponent implements OnInit {
    username: string
    password: string
    ngOnInit(): void {
        if (this.authService.isLoggedIn) {
            this.router.navigate(['home'])
        }
    }

    constructor(private router: Router, private messagingService: MessagingService, messagesService: MessagesService) {
        super(messagesService)
    }

    login(): void {
        const $this = this
        this.addLoadingCount()
        this.authService.login(this.username, this.password).then((r) => {
            if (r) {
                $this.router.navigate(['home'])
            }

            $this.susLoadingCount()
        })
    }
    registrarse() {
        this.router.navigate(['public/registrarse'])
    }
    olvidePass() {
        this.router.navigate(['public/envio-recupero'])
    }
}
