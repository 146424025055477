


export class Ubicacion {
    constructor(
        public longitud?: number,
        public latitud?: number,
        public direccion?: string,
        public urlUbicacion?: string

    ) {
    }
    public static fromData(data: any): Ubicacion {
        if (!data) return null;
        const o: Ubicacion = new Ubicacion(

            data.longitud,
            data.latitud,
            data.direccion,
            data.urlUbicacion
        );

        return o;

    }


}