
import { Parametrico } from 'src/app/common/model/Parametrico';


export class TipoComprobante extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        public esACuenta: boolean = false,
        public esNota: boolean = false,
        public letra: string = 'X',
        public discriminaIva: boolean = false,
        public codigoAfip: string = '',
        esDefault: boolean = false
    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return "TipoComprobanteLOW";
    }
    public static fromData(data: any): TipoComprobante {
        if (!data) return null;
        const o: TipoComprobante = new TipoComprobante(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esACuenta,
            data.esNota,
            data.letra,
            data.discriminaIva,
            data.codigoAfip,
            data.esDefault
        );

        return o;

    }
    public get esOficial(){
        return this.codigoAfip
    }

}