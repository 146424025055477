import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    static cacheKey: string = "cache_" + environment.tokenKey;
    static filtersStorageKey: string = "filters_" + environment.tokenKey;
    private cache = {};
    private filters = {};
    private ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor() {

        this.ready.next(true);
        this.ready.complete();
    }
    public saveCache(key: string, v: any) {
        this.ready.toPromise().then(r => {
            this.cache[key] = v;
            sessionStorage.setItem(key, JSON.stringify(v));
        })

    }
    public removeCache(key: string) {
        this.cache[key] = null;
        sessionStorage.removeItem(key);
    }
    public getCache(key: string): Promise<any> {
        return this.ready.toPromise().then(() => {
            if (!this.cache[key]) {
                this.cache[key] = JSON.parse(sessionStorage.getItem(key));
            }
            return this.cache[key];
        });
    }

    public saveFilter(key: string, v: any) {
        this.ready.toPromise().then(r => {
            this.filters[key] = v;
            sessionStorage.setItem(key, JSON.stringify(v));
        });
    }

    public getFilter(key: string) {
        return this.ready.toPromise().then(() => {
            if (!this.filters[key]) {
                this.filters[key] = JSON.parse(sessionStorage.getItem(key));
            }
            return this.filters[key];
        });
    }
    public removeFilters(key: string) {
        return this.ready.toPromise().then(() => {
            this.filters[key] = null;
            sessionStorage.removeItem(key);
        });
    }
}
