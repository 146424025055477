import { Component, Input, OnInit } from '@angular/core'
import { Disponibilidad } from './../../../model/Disponibilidad'

@Component({
    selector: 'disponibilidad',
    templateUrl: './disponibilidad.component.html',
    styleUrls: ['./disponibilidad.component.less'],
})
export class DisponibilidadComponent implements OnInit {
    @Input()
    public readonly: boolean = false
    @Input()
    public disponibilidad: Disponibilidad = new Disponibilidad()
    constructor() {}

    ngOnInit() {}
}
