<div class="profile-badge {{ class }}">
    <div class="selected-overlay" *ngIf="selected" [@appear]>
        <i class="pi pi-check selected"></i>
    </div>

    <div class="badge  {{ class }} " [ngClass]="{ clickable: seleccionable && !disabled }"
        [ngStyle]="{ 'background-image': ' url(' + (profilePic ? profilePic : defaultImage) + ')' }"
        (click)="seleccionable && !disabled ? inputFile.click() : ''"></div>



</div>
<input type="file" (change)="handleFileInput($event.target.files)" #inputFile name="inputfile" [hidden]="true" accept="image/*" />


<p-dialog (onShow)="displayCropper()" header="" [(visible)]="crop" appendTo="body" [modal]="true" #cropper [styleClass]="'no-header gestor-dialog'">

    <img #image [src]="imageSource" crossorigin>

    <p-footer>
        <button pButton (click)="cancelar()" icon="pi pi-times"
            label="{{ 'CANCELAR' | translate }}"></button>
        <button pButton (click)="confirmar()" icon="pi pi-save" label="{{ 'CONFIRMAR' | translate }}"></button>
    </p-footer>

</p-dialog>