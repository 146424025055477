import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { Filtro } from '../common/model/Filtro'
import { GlobalInjector } from './../common/GlobalInjector'
import { Identificable } from './../common/model/Identficable'
import { Novedad } from './../model/Novedad'
import { AuthService } from './auth.service'
import { IActualizable, NovedadService } from './novedades.service'
import { StorageService } from './storage.service'
@Injectable()
export abstract class BufferedService<E extends Identificable> extends ServicioAbstract<E> implements IActualizable {
    protected _data: any[]
    private lastUpdate: Date
    public cantidad: number = 0
    protected updating: Promise<any[]>
    public data: BehaviorSubject<any[]> = new BehaviorSubject([])
    private storageService: StorageService
    constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
        super(messServ)
        //this.data = data ? data : new BehaviorSubject([]);
        this.storageService = GlobalInjector.InjectorInstance.get(StorageService)
        if (this.novedadesService) {
            this.novedadesService.registrarObservador(this.baseName(), this)
        } else {
            console.log('NO SE PUDO REGISTRAR PARA ACTUALIZAR')
        }
        this.data.subscribe((d) => {
            if (d?.length) {
                this.storageService.saveCache(this.baseName(), d)
            }
        })
        authService.onLogout.subscribe((v) => {
            this.destroy()
        })
        authService.onLogin.subscribe((v) => {
            this.init()
        })
    }

    public init() {
        this.loadData(new LoadingService())
    }
    public destroy() {
        this._data = null
        this.updating = null
    }
    public getData(filter?: Filtro, customLoading?: LoadingService): Promise<E[]> {
        this.novedadesService.check()
        if (!this.updating && !this._data) {
            return this.loadData(customLoading).then((d) => {
                return filter ? filter.apply(d) : d
            })
        } else if (this.updating) {
            return this.updating.then((d) => {
                return filter ? filter.apply(d) : d
            })
        } else {
            return Promise.resolve(filter ? filter.apply(this._data) : this._data)
        }
    }

    public getAll(filter?: Filtro, customLoading?: LoadingService): Promise<E[]> {
        if (!filter) {
            return this.getData()
        } else
            return super.getAll(filter, customLoading).then((r) => {
                this._data = this.parse(r)
                this.data.next(this._data)
                return r
            })
    }
    private cleanCache() {
        this.storageService.removeCache(this.baseName())
    }
    public forceRefresh() {
        this._data = []
        this.cleanCache()
        this.loadData(new LoadingService())
    }
    public updateEnt(id: number) {
        if (id && this._data.some((e) => e.id == id)) {
            super.getById(id, new LoadingService()).then((r) => {
                this._data[this._data.findIndex((e) => e.id == r.id)] = r
                this.data.next(this._data)
            })
        } else if (!id) {
            this.loadData(new LoadingService())
        }
    }

    public next(n: Novedad) {
        // if (n && n.key != this._ultimaNovedad?.key) {
        this.updateEnt(n.idEntidad)
        // }
    }
    public async guardarConArchivo(e: E, file: File, customLoading?: LoadingService) {
        var p = super.guardarConArchivo(e, file, customLoading).then((v) => {
            if (v.id) {
                this._data.push(v)
            } else {
                this._data[this._data.indexOf(v.id)] = v
            }
            this.data.next(this._data)
            return v
        })
        return p
    }
    public async guardar(e: E, customLoading?: LoadingService) {
        var p = super.guardar(e, customLoading).then((v) => {
            if (v.id) {
                this._data.push(v)
            } else {
                this._data[this._data.findIndex((d) => d.id == v.id)] = v
            }
            this.data.next(this._data)
            return v
        })
        return p
    }

    public async eliminar(e: number, customLoading?: LoadingService) {
        var p = super.eliminar(e, customLoading).then((v) => {
            this._data = this._data.filter((d) => d.id != e)
            this.data.next(this._data)
        })
        return p
    }

    getById(id: number, customLoading?: LoadingService, idioma?: string): Promise<E> {
        const susLoading  = ()=>{
            if (customLoading) {
                customLoading.susLoadingCount()
            } else this.loadingService.susLoadingCount()
        }
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        const r = this._data? this._data.find(d=> d.id == id) :null;
        if(r) { susLoading();
            return Promise.resolve(r);
        }
        return super.getById(id, customLoading,idioma).then(backendObject=>{
            return this.getData()
            .then((r) => {
                let index = r.findIndex((e) => e.id == id);
                if(index>=0) r[index] = backendObject;
                return index >= 0? r[index] : null;
            })
            .finally(() => {
                susLoading()
            })
        }).finally(()=>{
            susLoading()
        })
    }
    protected fillData: (f, l) => Promise<any[]> = this.getAll
    protected loadData(customLoading?: LoadingService): Promise<E[]> {
        if (!this.updating)
            this.updating = this.fillData(new Filtro(null, {}, 0, 3000), customLoading)
                .then((r: any) => {
                    this._data = [...r]
                    this.data.next(this._data)

                    return this._data
                })
                .finally(() => {
                    this.updating = null
                })

        if (!this._data?.length) {
            return this.storageService
                .getCache(this.baseName())
                .then((l) => {
                    if (!this._data || this._data?.length == 0) {
                        if (l) {
                            this._data = this.parse(l)
                        } else {
                            this._data = []
                            this.data.next(this._data)
                        }
                        return this._data
                    }
                })
                .then((d) => {
                    if (!d?.length) {
                        return this.updating
                    } else {
                        return d
                    }
                })
        } else {
            return Promise.resolve(this._data)
        }
    }
}
