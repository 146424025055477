import { ID } from 'src/app/app.module';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { FileData } from './FileData';
import { ItemMenu } from './ItemMenu';

export class CategoriaMenu extends Descriptivo {
    tieneProducto(id: number): boolean {
        return this.items?.length? this.items.some(i => i.producto.id == id) : false
    }
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public items: ItemMenu[] = [],
        public peso?: number,
        public codigoIdioma: string = 'ES',
        public imagenPortada?: FileData,
        public centro?: Descriptivo,
        public menu?: Descriptivo,
        public habilitado = false,
    ) {
        super(codigo, descripcion, id)
        if(!this.codigo) this.codigo = ID();
    }

    public static fromData(data: any): CategoriaMenu {
        if (!data) return null
        const o: CategoriaMenu = new CategoriaMenu(
            data.id,
            data.codigo,
            data.descripcion,
            data.items.map((c) => ItemMenu.fromData(c)),
            data.peso,
            data.codigoIdioma,
            FileData.fromData(data.imagenPortada),
            Descriptivo.fromData(data.centro),
            Descriptivo.fromData(data.menu),
        )
        if (o.items?.length)
        o.habilitado = !o.items.every(i => !i.visible)

        return o
    }

    public get portada() {
        return this.imagenPortada ? this.imagenPortada.imagen : 'assets/images/default-banner.png'
    }
}
