<div class="grid align-items-center justify-content-center" *ngIf="pedido?.codigoVerificacion">
    <div class="col-12 text-center qrcode">
        <span> <strong>Pedido N° {{numeroPedido}}</strong></span>
    </div>
    <div class="col-12 qr-container text-center">
        <qrcode [qrdata]="url" [width]="256" [errorCorrectionLevel]="'L'" elementType="url" ></qrcode>
    </div>
    <div class="col-12 text-center qrcode">
        <span> <strong>{{pedido.codigoVerificacion}}</strong></span>
    </div>
    <div class="col-12 text-center">
        <button pButton label="Asociar a puesto" (click)="asociarAPuesto()"></button>
    </div>
</div>
<span *ngIf="!pedido?.codigoVerificacion">No hay codigo QR</span>
