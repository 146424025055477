import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { filter } from 'rxjs/operators'
import { AppComponent } from './app.component'
import { AppMainComponent } from './app.main.component'
import { AdminAuthGuard } from './authguards/AdminAuthGuard'
import { GestorCentroAG } from './authguards/GestorCentroAG'
import { GestorLocalAG } from './authguards/GestorLocalAG'
import { AuthService } from './services/auth.service'

@Component({
    selector: 'app-topbar',
    templateUrl: 'app.topbar.component.html',
    styleUrls: ['app.topbar.component.less'],
})
export class AppTopbarComponent implements OnInit {
    public itemsConfiguracion: MenuItem[] = []
    public get usuario() {
        return this.authService.usuario
    }
    public verConfiguracion: boolean = false
    constructor(
        public app: AppComponent,
        public appMain: AppMainComponent,
        private authService: AuthService,
        private gestorCentro: GestorCentroAG,
        private gestorLocal: GestorLocalAG,
        private adminAuthGuard: AdminAuthGuard,
        private router: Router
    ) {}
    ngOnInit() {
        this.app.translateService.upateData.subscribe((r) => {
            this.generarMenu()
        })
        this.authService
            .getUser()
            .pipe(filter((u) => u != undefined))
            .subscribe((u) => {
                this.generarMenu()
            })
    }
    public isMobile() {
        return window.innerWidth <= 640
    }
    public verUsuario() {
        this.router.navigate(['usuario/edit'], { queryParams: { id: this.usuario.id } })
    }
    public navegarParam(path, multilenguaje: boolean = false) {
        this.router.navigate(['/parametros'], { queryParams: { tipo: path, multi: multilenguaje } })
    }
    public esAdministrador() {
        return this.usuario.esAdmin()
    }
    public irAHome() {
        this.router.navigate(['home'])
    }
    private generarMenu() {
        let $this = this
        this.itemsConfiguracion = [
            {
                label: this.app.translateService.get('Mi establecimiento'),
                icon: 'fa fa-building',
                visible: this.gestorLocal.esVisible(this.authService.usuario),
                items: [
                    {
                        label: this.app.translateService.get('USUARIOS'),
                        icon: 'fa fa-users',
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        routerLink: ['usuario'],
                    },
                    {
                        label: this.app.translateService.get('CENTRO-COSTO_TITLE'),
                        icon: 'fa fa-money',
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        routerLink: ['centro-costo'],
                    },
                    {
                        label: this.app.translateService.get('Punto de Venta'),
                        icon: 'fa fa-map',
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        routerLink: ['punto-de-venta'],
                    },
                    {
                        label: this.app.translateService.get('Mercado Pago'),
                        icon: 'fa fa-credit-card-alt',
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        routerLink: ['configuracion-mercado-pago'],
                    },

                    {
                        label: this.app.translateService.get('LOCALES'),
                        icon: 'fa fa-map',
                        routerLink: ['local'],
                    },
                    {
                        label: this.app.translateService.get('Sectores'),
                        icon: 'fa fa-map',
                        routerLink: ['sector'],
                    },
                    {
                        label: this.app.translateService.get('MESAS'),
                        icon: 'fa fa-table',
                        items: [
                            {
                                label: this.app.translateService.get('Layout'),
                                icon: 'fa fa-map',
                                routerLink: ['mesa/layout'],
                            },
                            {
                                label: this.app.translateService.get('Gestión'),
                                icon: 'fa fa-cog',
                                routerLink: ['mesa'],
                            },
                        ],
                    },
                    {
                        label: this.app.translateService.get('CAJA_TITLE'),
                        icon: 'fa fa-money',
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        routerLink: ['caja'],
                    },
                    {
                        label: this.app.translateService.get('DEPOSITOS'),
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        icon: 'fa fa-building',
                        routerLink: ['deposito'],
                    },
                    {
                        label: this.app.translateService.get('CATEGORIA_PRODUCTO_TITULO'),
                        visible: this.gestorCentro.esVisible(this.authService.usuario),
                        icon: 'fa fa-tag',
                        routerLink: ['categoria-producto'],
                    },
                ],
            },
            {
                label: this.app.translateService.get('Parámetros'),
                visible: this.adminAuthGuard.esVisible(this.authService.usuario),
                items: [
                   
                    {
                        label: this.app.translateService.get('Notificaciones'),
                        icon: 'fa fa-envelope',
                        routerLink: ['notificacion'],
                    },
                    {
                        label: this.app.translateService.get('TIPO-PROVEEDOR_PARAM_TITULO'),
                        icon: 'fa fa-user',
                        command: () => {
                            $this.navegarParam('tipo-proveedor')
                        },
                    },
                    {
                        label: this.app.translateService.get('TIPO-COMPROBANTE_PARAM_TITULO'),
                        icon: 'fa fa-money',
                        routerLink: ['tipo-comprobante'],
                    },

                    {
                        label: this.app.translateService.get('Estados de Documento'),
                        icon: 'fa fa-tag',
                        routerLink: ['estado-documento'],
                    },
                    {
                        label: this.app.translateService.get('Estados de Pedido'),
                        icon: 'fa fa-tag',
                        routerLink: ['estado-pedido'],
                    },
                    {
                        label: this.app.translateService.get('Estados de Reserva'),
                        icon: 'fa fa-tag',
                        routerLink: ['estado-reserva'],
                    },
                    {
                        label: this.app.translateService.get('Tipos de Entrega'),
                        icon: 'fa fa-bicycle',
                        routerLink: ['tipo-entrega'],
                    },
                    {
                        label: this.app.translateService.get('EMAIL_TEMPLATE'),
                        icon: 'fa fa-envelope',
                        visible: this.adminAuthGuard.esVisible(this.authService.usuario),
                        routerLink: ['emailtemplate'],
                    },
                    {
                        label: this.app.translateService.get('FORMA-PAGO_PARAM_TITULO'),
                        icon: 'fa fa-money',
                        routerLink: ['forma-pago'],
                    },

                    {
                        label: this.app.translateService.get('TipoTarjetaCredito_PLR'),
                        icon: 'fa fa-credit-card',
                        routerLink: ['tipo-tarjeta-credito'],
                    },
                    {
                        label: this.app.translateService.get('Cuotas con Tarjeta'),
                        icon: 'fa fa-credit-card',
                        routerLink: ['tipo-cuotas-tarjeta'],
                    },

                    {
                        label: this.app.translateService.get('Billetes'),
                        icon: 'fa fa-money',
                        routerLink: ['billete'],
                    },
                    {
                        label: this.app.translateService.get('CANAL_VENTA'),
                        icon: 'fa fa-shopping-cart',
                        routerLink: ['canal-venta'],
                    },
                    {
                        label: this.app.translateService.get('TIPO-CLIENTE_PARAM_TITULO'),
                        icon: 'fa fa-user',
                        command: () => {
                            $this.navegarParam('tipocliente')
                        },
                    },

                    // {
                    //     label: this.app.translateService.get('CATEGORIA_PRODUCTO_TITULO'),
                    //     icon: 'fa fa-tag',
                    //     routerLink: ['categoria-producto'],
                    // },

                    {
                        label: this.app.translateService.get('CUENTA-CONTABLE_TITLE'),
                        icon: 'fa fa-book',
                        routerLink: ['cuenta-contable'],
                    },

                    {
                        label: this.app.translateService.get('UNIDAD_TITLE'),
                        icon: 'fa fa-balance-scale ',

                        routerLink: ['unidad'],
                    },
                    {
                        label: this.app.translateService.get('TIPO-INGRESO-BRUTO_PARAM_TITULO'),
                        icon: 'fa fa-money',
                        command: () => {
                            $this.navegarParam('tipo-ingreso-bruto')
                        },
                    },
                    {
                        label: this.app.translateService.get('TIPO-IVA_PARAM_TITULO'),
                        icon: 'fa fa-dollar',
                        command: () => {
                            $this.navegarParam('tipo-iva')
                        },
                    },

                    {
                        label: this.app.translateService.get('MARCA_TITULO'),
                        icon: 'fa fa-money',
                        routerLink: ['marca'],
                    },
                    {
                        label: this.app.translateService.get('CATEGORIAS-INSUMO'),
                        icon: 'fa fa-money',
                        routerLink: ['categoria-insumo'],
                    },
                    {
                        label: this.app.translateService.get('TIPOS-LAYOUT'),
                        icon: 'fa fa-mobile',
                        routerLink: ['tipo-layout'],
                    },
                    {
                        label: this.app.translateService.get('RESTRICCIONES'),
                        icon: 'fa fa-ban',
                        routerLink: ['restriccion'],
                    },
                ],
            },
        ]
        this.verConfiguracion = this.itemsConfiguracion.length > 0
    }
}
