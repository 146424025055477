<div class="layout-sidebar-filter p-fluid">
    <p-autoComplete
        [(ngModel)]="searchStr"
        [suggestions]="results"
        (completeMethod)="search($event)"
        field="label"
        placeholder="Buscar opción..."
        (onSelect)="menuPick($event)"
    ></p-autoComplete>
</div>
<ul class="layout-menu">
    <li app-menuitem *ngFor="let item of menuConfService?.menuBS | async; let i = index" [item]="item" [index]="i" [root]="true"></li>
    <li app-menuitem [item]="exit" [index]="99" [root]="false"></li>
</ul>
