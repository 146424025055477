import { HttpRequest, HttpResponse } from '@angular/common/http'
import { Subject } from 'rxjs'
import { share } from 'rxjs/operators'
import { Descriptivo } from '../../common/model/Descriptivo'
import { Filtro } from './../model/Filtro'
import { BaseService } from './base.service'
import { LoadingService } from './loading-data-service.service'
import { MessagesService } from './messages-data-service.service'

export abstract class ServicioAbstract<E> extends BaseService {
    constructor(messages?: MessagesService, loadingService?: LoadingService) {
        super(messages, loadingService)
    }

    public onChange: Subject<E> = new Subject()

    protected parseDescriptivos(res: any): Descriptivo[] {
        let descriptivos: Descriptivo[] = []
        if (res !== null) {
            var $this = this
            descriptivos = res.map((d) => Descriptivo.fromData(d))
        }
        return descriptivos
    }

    public abstract baseName(): string
    public abstract parseToEnt(data): E
    public abstract newEnt(): E
    public count(filtro: Filtro, customLoading?: LoadingService): Promise<number> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('count'), filtro.json)
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r ? r : 0
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    getAll(filtro: Filtro = new Filtro('dummy', {}), customLoading?: LoadingService): Promise<E[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('all'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return $this.parse(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getById(id: number, customLoading?: LoadingService, idioma?: string): Promise<E> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .get(this.getBaseURL() + id + (idioma ? '?idioma=' + idioma : ''))
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return $this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    parse(res: any): any[] {
        let $this = this
        if (res) {
            let result = res.map((f) => $this.parseToEnt(f))
            return result
        }
        return []
    }

    parseSingle(res: any): E {
        let $this = this
        if (res !== null) {
            return $this.parseToEnt(res)
        }
    }
    guardarConArchivo(e: E, file: File, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        if (e['id']) {
            const frmData = new FormData()
            if (file) frmData.append('file', file)
            let vo = this.parseToEnt(e)
            if (vo['profilePic']) vo['profilePic']['previewPicPath'] = null
            frmData.append('vo', this.toString(vo))
            const request = new HttpRequest('PUT', this.getBaseURL(), frmData)
            return this.http
                .request(request)
                .toPromise()
                .then(
                    (r: HttpResponse<E>) => {
                        if (customLoading) {
                            customLoading.susLoadingCount()
                        } else this.loadingService.susLoadingCount()
                        const e = this.parseSingle(r.body)
                        this.onChange.next(e)
                        return e
                    },
                    (e) => this.handleError(e, customLoading)
                )
        } else {
            const frmData = new FormData()
            if (file) frmData.append('file', file)
            let vo = this.parseToEnt(e)
            if (vo['profilePic']) vo['profilePic']['previewPicPath'] = null
            frmData.append('vo', this.toString(vo))
            const request = new HttpRequest('POST', this.getBaseURL(), frmData)
            return this.http
                .request(request)
                .toPromise()
                .then(
                    (r: HttpResponse<E>) => {
                        if (customLoading) {
                            customLoading.susLoadingCount()
                        } else this.loadingService.susLoadingCount()
                        const e = this.parseSingle(r.body)
                        this.onChange.next(e)
                        return e
                    },
                    (e) => this.handleError(e, customLoading)
                )
        }
    }
    public toString(vo: E) {
        return JSON.stringify(vo)
    }
    guardar(e: E, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        if (e['id']) {
            return this.http
                .put(this.getBaseURL(), e)
                .toPromise()
                .then(
                    (r) => {
                        if (customLoading) {
                            customLoading.susLoadingCount()
                        } else this.loadingService.susLoadingCount()
                        const e = this.parseSingle(r)
                        this.onChange.next(e)
                        return e
                    },
                    (e) => this.handleError(e, customLoading)
                )
        } else {
            return this.http
                .post(this.getBaseURL(), e)
                .toPromise()
                .then(
                    (r) => {
                        if (customLoading) {
                            customLoading.susLoadingCount()
                        } else this.loadingService.susLoadingCount()
                        const e = this.parseSingle(r)
                        this.onChange.next(e)
                        return e
                    },
                    (e) => this.handleError(e, customLoading)
                )
        }
    }
    eliminar(id: number, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .delete(this.getBaseURL() + id)
            .toPromise()
            .then(
                (r) => {
                    this.handleOk(r, customLoading)
                    this.onChange.next(null)
                },
                (e) => this.handleError(e, customLoading)
            )
    }
}
