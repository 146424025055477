import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { FiltroMovimiento } from 'src/app/common/model/FiltroMovimiento'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { UsuarioService } from 'src/app/common/services/usuario.service'
import { CambioSaldoCaja } from 'src/app/model/CambioSaldoCaja'
import { MovimientoCaja } from 'src/app/model/MovimientoCaja'
import { CajaService } from 'src/app/services/caja.service'
import { FormaPagoService } from 'src/app/services/forma-pago.service'
import { MovimientoCajaService } from 'src/app/services/movimiento-caja.service'
import { TipoMovimientoService } from 'src/app/services/tipo-movimiento.service'
import { BreadcrumbService } from './../../../breadcrumb.service'
import { AccionesTabla } from './../../../common/model/AccionesTabla'
import { ArqueoCaja } from './../../../model/ArqueoCaja'
import { Caja } from './../../../model/Caja'
import { TraspasoDeCaja } from './../../../model/TraspasoDeCaja'
import { BilleteService } from './../../../services/billete.service'
import { SaldosTotales } from './../../../services/movimiento-caja.service'

@Component({
    selector: 'listado-movimiento-caja',
    templateUrl: 'listado-movimiento-caja.component.html',
    styleUrls: ['listado-movimiento-caja.component.less'],
})
export class ListadoMovimientoCajaComponent extends SessionComponent implements OnInit, OnDestroy {
    public filtro: FiltroMovimiento
    public listado: MovimientoCaja[] = []
    public verTraspasoCaja: boolean = false
    public displayFilterBar: boolean = false;
    public columns = [
        {
            field: 'id',
            header: '#',
            class: 'id-column',
            role: 'text-tabla',
            sortable: true,
            order: 1,
        },
        {
            field: 'imagenUrl',
            header: 'Forma de Pago',
            class: 'profile-pic',
            role: 'profile-tabla',
            sortable: true,
            order: 3,
            width: '4em',
        },
        {
            field: 'fecha',
            header: 'Fecha',
            class: 'date-tabla',
            role: 'date-tabla',
            sortable: false,
            esMobile: true,
            order: 40,
            width: '8em',
        },
        {
            field: 'detalle',
            header: 'Detalle',
            class: 'text-tabla',
            role: 'text-tabla',
            sortable: false,
            esMobile: true,
            order: 40,
        },

        {
            field: 'referencia',
            header: 'Referencia',
            class: 'link-tabla text-center',
            role: 'link-tabla',
            //"tooltip":"N° ",
            navegar: (r) => {
                this.navegar(r)
            },
            href: 'href',
            esMobile: true,
            sortable: true,
            width: '6em',
            order: 40,
        },
        {
            field: 'responsable',
            header: 'Responsable',
            class: 'descriptivo-tabla text-center',
            role: 'descriptivo-tabla',
            sortable: true,
            esMobile: true,
            width: '16em',
            order: 40,
        },
        {
            field: 'salida',
            header: 'Salida',
            class: 'money-tabla',
            role: 'money-tabla',
            sortable: false,
            esMobile: true,
            width: '12em',
            order: 40,
        },
        {
            field: 'entrada',
            header: 'Entrada',
            class: 'money-tabla',
            role: 'money-tabla',
            sortable: false,
            esMobile: true,
            width: '12em',
            order: 40,
        },
        {
            field: 'saldo',
            header: 'Saldo en Caja',
            class: 'money-tabla',
            role: 'money-tabla',
            sortable: false,
            width: '12em',
            order: 40,
        },
    ]

    public cajas: Caja[] = []
    public caja: Caja
    public tiposMovimiento: Descriptivo[] = []
    public responsables: Descriptivo[] = []

    public saldos: SaldosTotales

    public totalSalida: number = 0
    public totalEntrada: number = 0
    public totalSaldo: number = 0

    constructor(
        messagesService: MessagesService,
        public service: MovimientoCajaService,
        private router: Router,
        private billeteService: BilleteService,
        private aRoute: ActivatedRoute,
        private cajaService: CajaService,
        private tipoService: TipoMovimientoService,
        private respService: UsuarioService,
        public formasPagoService: FormaPagoService,
        private breadcrumbService: BreadcrumbService
    ) {
        super(messagesService)
        this.breadcrumbService.setItems([{ label: 'Finanzas' }])
    }
    public editandoMovimiento: boolean = false
    public acciones: MenuItem[] = []
    public aplicarSeleccion(caja: Caja) {
        this.caja = caja
        this._cajaSeleccionada = Descriptivo.fromData(caja)
        this.filtro.caja = this._cajaSeleccionada
        this.buildMenu()
        this.op.hide()
    }
    private _cajaSeleccionada: Descriptivo
    public get cajaSeleccionada(): Descriptivo {
        if (!this._cajaSeleccionada) {
            this._cajaSeleccionada = this.filtro.caja
        }
        return this._cajaSeleccionada
    }
    public set cajaSeleccionada(v: Descriptivo) {
        this._cajaSeleccionada = v
        this.filtro.caja = v
        this.cajaService.getById(v.id).then((r) => {
            this.aplicarSeleccion(r)
        })
    }
    @ViewChild('op', { static: true }) op
    private routeSub: Subscription
    public verCajas(event) {
        if (this.cajas.length > 1) {
            this.op.toggle(event)
        }
    }
    public buildMenu() {
        this.acciones = []
        if (this.caja) {
            this.acciones.push(
                new AccionesTabla('Arqueo', 'pi pi-balance-scale', '', (item) => {
                    this.arqueo()
                })
            )

            if (this.esAdministrador) {
                this.acciones.push(
                    new AccionesTabla('Traspaso', 'pi pi-exchange', '', (item) => {
                        this.nuevoTraspaso()
                    })
                )
                this.acciones.push(
                    new AccionesTabla('Ingreso', 'pi pi-sign-in', '', (item) => {
                        this.cambiarSaldo(false)
                    })
                )
                this.acciones.push(
                    new AccionesTabla('Retiro', 'pi pi-sign-out', '', (item) => {
                        this.cambiarSaldo(true)
                    })
                )
                this.acciones.push(
                    new AccionesTabla('Totales', 'fa fa-usd', '', (item) => {
                        this.irATotales()
                    })
                )

            }
        }
    }
    public itemEditado: ArqueoCaja
    public traspaso: TraspasoDeCaja
    public cambioSaldo: CambioSaldoCaja
    public onCancelar(event) {
        this.itemEditado = null
        this.editandoMovimiento = false
        this.traspaso = null
        this.cambioSaldo = null
    }
    public refresh(event: ArqueoCaja) {
        this.onCancelar(event)
        setTimeout(() => {
            this.cajaSeleccionada = event.caja
        }, 200)
        this.filtro.forceUpdate()
    }
    arqueo = () => {
        this.traspaso = null
        this.cambioSaldo = null
        this.itemEditado = new ArqueoCaja(null, new Date(), null, this.usuario, this.caja, 0, 0, null)
        this.billeteService.iniciarBilletes().then((r) => {
            this.itemEditado.valores = r
            this.editandoMovimiento = true
        })
    }
    nuevoTraspaso = () => {
        if (!this.caja || !this.esAdministrador) return this.error('Solo un administrador puede realizar traspasos')
        this.itemEditado = null
        this.cambioSaldo = null
        this.traspaso = new TraspasoDeCaja(null, new Date(), null, this.usuario, this.caja)
        this.editandoMovimiento = true
    }
    cambiarSaldo = (esRetiro: boolean) => {
        if (!this.caja || !this.esAdministrador) return this.error('solo un administrador puede realizar esta operación')
        this.traspaso = null
        this.itemEditado = null
        this.cambioSaldo = new CambioSaldoCaja(null, new Date(), null, this.usuario, this.caja, 0, null, esRetiro)
        this.editandoMovimiento = true
    }

    ngOnInit() {
        this.filtro = new FiltroMovimiento('movimiento-caja_filtros')
        this.subs.push(
            this.cajaService.data.subscribe((r) => {
                this.cajas = r
                if (this.cajas.length == 1 && !this.cajaSeleccionada) {
                    this.aplicarSeleccion(this.cajas[0])
                }
            })
        )
        this.tipoService.getDescriptivos().then((ts) => (this.tiposMovimiento = ts))
        this.respService.getDescriptivos().then((us) => (this.responsables = us))
        this.routeSub = this.aRoute.queryParams.subscribe((params) => {
            let id: number = <number>params['id']
            if (id) {
                setTimeout(() => {
                    this.cajaService.getById(id).then((r) => {
                        this.aplicarSeleccion(r)
                        //this.filtro.forceUpdate();
                    })
                }, 100)
            }
        })
        this.buildMenu()
    }
    getData = (filtro, loading) => {
        if (!this.cajaSeleccionada) {
            return []
        }
        return this.service.getAll(filtro, loading).then((r) => {
            this.service.getSaldos(this.filtro).then((s) => (this.saldos = s))
            this.totalEntrada = this.totalSaldo = this.totalSalida = 0
            r.forEach((s) => {
                this.totalEntrada += s.entrada
                this.totalSalida += s.salida
                this.totalSaldo += s.entrada - s.salida
            })
            return r
        })
    }
    ngOnDestroy() { }

    cleanFiltro() {
        if (this.cajas.length > 0) {
            this.filtro.clean()
            this.filtro.caja = Descriptivo.fromData(this.cajas[0])
        }
    }

    navegar = (movimiento: MovimientoCaja) => {
        let query: NavigationExtras = {
            queryParams: {
                id: movimiento.referencia,
            },
        }
        switch (movimiento.tipoMovimiento.codigo) {
            case 'PO':
                this.router.navigate(['orden-pago/vista'], query)
                break
            case 'PC':
                this.router.navigate(['cobranza/vista'], query)
                break
            case 'TC':
                this.router.navigate(['traspaso-de-caja/vista'], query)
                break
            case 'DEC':
                this.router.navigate(['cambio-estado-caja/vista'], query)
                break
            case 'CS':
                this.router.navigate(['cambio-saldo-caja/vista'], query)
                break
            default:
                this.error('navegacion no disponible para ' + movimiento.tipoMovimiento.descripcion + ' ' + movimiento.referencia)
                break
        }
    }
    public showFilterbar(event) {
        event?.stopPropagation && event.stopPropagation();
        this.displayFilterBar = true;
    }

    irATotales() {

      const queryParams = this.cajaSeleccionada? { id: this.cajaSeleccionada.id } : {}

      this.router.navigate(['caja/totales-caja'], { queryParams })
    }
}
