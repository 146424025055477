
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Caja } from 'src/app/model/Caja';
import { CajaService } from 'src/app/services/caja.service';

@Component({
  selector: 'listado-caja',
  templateUrl: 'listado-caja.component.html',
  styleUrls: ['listado-caja.component.less']
})

export class ListadoCajaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro = new Filtro("caja_filtros", {}, 0, 10);
  public listado: Caja[] = [];
  public columns = [
    {
      "field": "id",
      "header": "#",
      "class": "id-column",
      "role": "text-tabla",
      "sortable": true,
      "order": 1
    },
    {
      "field": "descripcion",
      "header": "Descripcion",
      "class": "text-tabla",
      "role": "text-tabla",
      "sortable": true,
      "order": 40
    },
    {
      "field": "centroCosto",
      "header": "Centro de Costo",
      "class": "descriptivo-tabla",
      "role": "descriptivo-tabla",
      "sortable": true,
      "order": 40
    },
    {
      "field": "localesDesc",
      "header": "Locales",
      "class": "text-tabla",
      "role": "text-tabla",
      "sortable": true,
      "order": 40
    }

  ];
  constructor(messagesService: MessagesService,
    public service: CajaService) {
    super(messagesService)

  }
  ngOnInit() { }
  ngOnDestroy() { }


}