import { Insumo } from 'src/app/model/Insumo';
import { AsociacionInsumo } from './AsociacionInsumo';



export class ProductoInsumo implements AsociacionInsumo {
    constructor(
        public id?: number,
        public insumo?: Insumo,
        public cantidad?: number,

    ) {
    }
    public static fromData(data: any): ProductoInsumo {
        if (!data) return null;
        const o: ProductoInsumo = new ProductoInsumo(

            data.id,
            Insumo.fromData(data.insumo),
            data.cantidad,
        );

        return o;

    }

}