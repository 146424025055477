import { Descriptivo } from './../common/model/Descriptivo';
export class ItemMenu {

    constructor(
        public id?:number,
        public visible:boolean = true,
        public noDisponible:boolean = false,
        public peso:number = 9999,
        public producto?:Descriptivo,
        public nuevo: Boolean = false,
        public destacado: Boolean = false,
        

    ){

    }

    public static fromData(data) {
        if (!data) return null
        let o = 
        new ItemMenu(
            data.id,data.visible,data.noDisponible,data.peso, Descriptivo.fromData(data.producto), data.nuevo ?? false, data.destacado ?? false
        )

        if (!o.peso || o.peso == 9999){
            o.peso = o.producto.id
        }
        return o;
    } 
}