import { map } from 'rxjs/operators';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Filtro } from './../model/Filtro';
import { UsuarioResumen } from './../model/resumen/UsuarioResumen';
import { Injectable } from "@angular/core";
@Injectable()
export class UsuarioResumenService extends ServicioAbstract<UsuarioResumen>{
    public baseName(): string {
        return "usuario"
    }
    public parseToEnt(data: any): UsuarioResumen {
        return UsuarioResumen.fromData(data);
    }
    public newEnt(): UsuarioResumen {
        return new UsuarioResumen();
    }

    getAll(filtro: Filtro): Promise<UsuarioResumen[]> {
        return this.http.post(this.getBaseURL() + "getAllResumen", filtro.json).toPromise().then(r => this.parse(r));
    }

    getByEmail(e: string): Promise<UsuarioResumen[]> {
        return this.http.get(this.getBaseURL() + "byEmail/" + e).pipe(map((r: any) => { return r ? r.map(u => UsuarioResumen.fromData(u)) : [] })).toPromise();
    }


}