<div class="gestion-user content content-white">
    <model-gestor
        [readonly]="readonly"
        [service]="userService"
        [(item)]="item"
        [validar]="isValid"
        [file]="file"
        (onItemGuardado)="onGuardado($event)"
        [conArchivo]="true"
        [editable]="permiteEdicion"
        [modelName]="'user'"
        (onEditar)="onEditar($event)"
    >
        <div class="gestor-form" model-type="dataContent" *ngIf="item">
            <div class="grid">
                <div class="col-12 lg:col-3">
                    <div class="grid">
                        <div class="col-12 pic-wrapper">
                            <div class="pic">
                                <profile-badge
                                    [seleccionable]="true"
                                    [url]="item.profilePic?.picPathVersion"
                                    [disabled]="readonly"
                                    class="full-badge"
                                    (onFileSelected)="onPicSelected($event)"
                                >
                                </profile-badge>
                                <i class="pi pi-pencil edit-icon" *ngIf="!readonly"></i>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="recuperar">
                                <button
                                    pButton
                                    label="{{ 'RECUPERAR_CONTRASEÑA' | translate }}"
                                    class="ui-button-warning"
                                    icon="pi pi-key"
                                    (click)="recuperarContrasena()"
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-9">
                    <div class="grid">
                        <div class="col-12 lg:col-6">
                            <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                <mat-label>{{ 'NICKNAME' | translate }}</mat-label>
                                <input
                                    matInput
                                    placeholder="{{ 'PH_NICKNAME' | translate }}"
                                    [(ngModel)]="item.username"
                                    [readonly]="item.id || readonly"
                                    required
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-12 lg:col-6">
                            <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                <mat-label>{{ 'EMAIL' | translate }}</mat-label>
                                <input
                                    type="email"
                                    matInput
                                    placeholder="{{ 'PH_EMAIL' | translate }}"
                                    [(ngModel)]="item.email"
                                    [readonly]="readonly"
                                    required
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-12 lg:col-4">
                            <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                <mat-label>{{ 'NOMBRE' | translate }}</mat-label>
                                <input matInput placeholder="{{ 'PH_NOMBRE' | translate }}" [readonly]="readonly" [(ngModel)]="item.nombre" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 lg:col-4">
                            <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                <mat-label>{{ 'APELLIDO' | translate }}</mat-label>
                                <input matInput placeholder="{{ 'PH_APELLIDO' | translate }}" [readonly]="readonly" [(ngModel)]="item.apellido" />
                            </mat-form-field>
                        </div>

                        <div class="col-12 lg:col-4">
                            <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                                <mat-label>{{ 'TELEFONO' | translate }}</mat-label>
                                <input type="phone" matInput placeholder="{{ 'PH_TELEFONO' | translate }}" [(ngModel)]="item.telefono" [readonly]="readonly" />
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="esGestorCentro">
                            <selector-multiple-chips
                                [(ngModel)]="item.roles"
                                name="rolesSeleccionados"
                                [opciones]="roles"
                                [permiteNuevo]="false"
                                [readonly]="readonly"
                                [titulo]=" 'ROLES' | translate "
                            >
                            </selector-multiple-chips>
                        </div>
                        <div class="col-12 lg:col-6" *ngIf="esGestorCentro">
                            <selector-multiple-chips
                                [(ngModel)]="centrosElegidos"
                                name="centros"
                                [opciones]="centroCostoService.data | async"
                                [permiteNuevo]="false"
                                [readonly]="readonly || !esAdministrador"
                                [titulo]="'Centros habilitados' | translate"
                            >
                            </selector-multiple-chips>
                        </div>
                        <div class="col-12 lg:col-6" *ngIf="esGestorCentro">
                            <selector-multiple-chips
                                [(ngModel)]="item.configuracion.localesHabilitados"
                                name="locales"
                                [opciones]="localesDisponibles"
                                [permiteNuevo]="false"
                                [readonly]="readonly || !esGestorCentro"
                                [titulo]="'Locales habilitados' | translate"
                            >
                            </selector-multiple-chips>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </model-gestor>
</div>
