import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { ProfilePic } from 'src/app/common/model/ProfilePic'
import { ConfiguracionMenu } from 'src/app/model/ConfiguracionMenu'
import { CategoriaMenu } from './CategoriaMenu'
import { Disponibilidad } from './Disponibilidad'
import { ProductoCabecera } from './ProductoCabecera'

export class Menu extends Descriptivo {

    getCategoriesByProduct(prod: ProductoCabecera): CategoriaMenu {
        return this.categorias.find(c=> c.tieneProducto(prod.id));
      
    }
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public detalle?: Descriptivo,
        public imagenPortada?: ProfilePic,
        public categorias: CategoriaMenu[] = [],
        public peso?: number,
        public habilitado?: boolean,
        public codigoIdioma: string = 'ES',
        public listadoPrecios?: Descriptivo,
        public tieneLanding: boolean = true,
        public disponibilidad: Disponibilidad = new Disponibilidad([]),
        public configuracion: ConfiguracionMenu = new ConfiguracionMenu(),
        public mostrarMasVendidos: boolean = false,
    ) {
        super(codigo, descripcion, id)
    }

    public quitarCategoria(cat: CategoriaMenu) {
        this.categorias = this.categorias.filter((c) => c != cat)
    }
    public static fromData(data: any): Menu {
        if (!data) return null
        const o: Menu = new Menu(
            data.id,
            data.codigo,
            data.descripcion,

            data.detalle,
            ProfilePic.fromData(data.imagenPortada),
            data.categorias.map((c) => CategoriaMenu.fromData(c)),
            data.peso,
            data.habilitado,
            data.codigoIdioma,
            Descriptivo.fromData(data.listadoPrecios),
            data.tieneLanding,
            Disponibilidad.fromData(data.disponibilidad),
            ConfiguracionMenu.fromData(data.configuracion),
            data.mostrarMasVendidos,
        )
        o.categorias = o.categorias.sort((a, b) => {
            return a.peso > b.peso ? 1 : a.peso == b.peso ? (a.descripcion > b.descripcion ? 1 : -1) : -1
        })
        return o
    }
}
