import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { FileData } from './../model/FileData';
@Injectable({
    providedIn: 'root',
})
export class FileService extends BaseService {
    public baseName(): string {
        return "";
    }

    postFile(fileToUpload: File, tag: string): Promise<FileData> {
        const endpoint = this.getApiURL() + ('fileUpload/' + tag);
        const formData: FormData = new FormData();
        formData.append('files', fileToUpload, fileToUpload.name);
        return this.http
            .post(endpoint, formData).toPromise().then(r => {
                return FileData.fromData(r[0]);
            })
            .catch((e) => this.handleError(e));
    }
}