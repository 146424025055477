
import { FileData } from './FileData';


export class ImagenProducto {
    constructor(
        public id?: number,
        public imagen?: FileData,
        public esPortada?: boolean,

    ) {
    }
    public static fromData(data: any): ImagenProducto {
        if (!data) return null;
        const o: ImagenProducto = new ImagenProducto(

            data.id,
            FileData.fromData(data.imagen),
            data.esPortada,
        );

        return o;

    }

    public get imagenUrl() {
        return this.imagen ? this.imagen.url : "assets/images/default.png";
    }

}