import { Injectable } from '@angular/core'
import { Message, MessageService } from 'primeng/api'
import { LoadingService } from './loading-data-service.service'
import { TranslateService } from './translate.service'

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    public loadingService: LoadingService
    public _life: number = 3000
    constructor(private mService: MessageService, private translator: TranslateService) {
        this.loadingService = LoadingService.injector.get(LoadingService)
    }
    get life(): number {
        return this._life
    }
    set life(val: number) {
        this._life = val
    }
    public errorHandler = (error, msgs?: Message[]) => {
        let _error = { message: '' }
        if (!error.error || !error.error.message) {
            _error.message = 'Error Interno'
        } else {
            _error.message = this.translator.get(error.error.message)

            //_error = error;
        }
        this.loadingService.resetLoadingCount()
        msgs = []
        msgs.push({
            severity: 'error',
            detail: _error.message,
        })
        this.cleanMessages()
        this.mService.add({
            severity: 'error',
            detail: _error.message,
        })
    }
    public cleanMessages() {
        this.mService.clear()
    }
    public success(message?: string, titulo?: string) {
        this.mService.add({
            severity: 'success',
            detail: message,
            summary: titulo,
        })
    }
    public error(message?: string, titulo?: string) {
        this.mService.add({
            severity: 'error',
            detail: message,
            summary: titulo,
        })
    }
    public info(message?: string, titulo?: string) {
        this.mService.add({
            severity: 'info',
            detail: message,
            summary: titulo,
        })
    }
    public warning(message?: string, titulo?: string) {
        this.mService.add({
            severity: 'warning',
            detail: message,
            summary: titulo,
        })
    }
}
