import { animate, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'
import { ConfirmationService, MenuItem } from 'primeng/api'
import { Menu } from 'primeng/menu'
import { Subscription } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { commonAnimations } from 'src/app/common-animations'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { Idioma } from './../../../model/Idioma'
import { ModelListHandler } from './../../interfaces/ModelListHandler'
import { AccionesTabla } from './../../model/AccionesTabla'
import { Filtro } from './../../model/Filtro'
import { ResumenModel } from './../../model/ResumenModel'
import { IdiomaService } from './../../services/idioma.service'
import { LoadingService } from './../../services/loading-data-service.service'
import { ColorUtils } from './../../utils/color-utils'

@Component({
    selector: 'model-list',
    templateUrl: 'model-list.component.html',
    styleUrls: ['./model-list.component.less'],
    animations: [
        commonAnimations,
        trigger('inOutAnimation', [
            transition(':enter', [style({ bottom: '-1em', opacity: 0 }), animate('0.2s ease-out', style({ bottom: '0', opacity: 1 }))]),
            transition(':leave', [style({ bottom: '0', opacity: 1 }), animate('0.2s ease-in', style({ bottom: '-1em', opacity: 0 }))]),
        ]),
        trigger('floatIn', [
            transition(':enter', [
                style({ bottom: '-6em' }),
                animate('0.2s ease-out', style({ bottom: '1em' })),
                animate('0.1s ease-out', style({ bottom: '0.5em' })),
            ]),
            transition(':leave', [
                style({ bottom: '0.75em' }),
                animate('0.1s ease-in', style({ bottom: '1em' })),
                animate('0.2s ease-in', style({ bottom: '-6em' })),
            ]),
        ]),
    ],
})
export class ModelListComponent extends SessionComponent {
    public displayAll: boolean = false

    public toggleActionsButtons(event, data) {
        event?.stopPropagation && event.stopPropagation()
        data['displayAll'] = !data['displayAll']
    }

    @ViewChild('defaultMobileDataContent', { static: false })
    public defaultMobileDataContent: TemplateRef<any>
    @ViewChild('defaultDesktopDataContent', { static: false })
    public defaultDesktopDataContent: TemplateRef<any>
    @Input()
    public itemDataViewTemplate: TemplateRef<any>

    @Input()
    public menuAcciones
    public displayFilterBar: boolean = false;
    @ViewChild('menuAccionesDefault', { static: true })
    public menuAccionesDefault
    @Input()
    public navegable: boolean = true
    @ViewChild('menu', { static: true })
    private menu: any
    public items: MenuItem[] = []
    @Input()
    public showDataview: boolean = false
    @Input()
    public loading: boolean = false

    @Input()
    public defaultClick:'ver'|'editar' = 'ver';
    private _menuItemAcciones: MenuItem[] = [
        {
            label: 'Eliminar',
            command: () => this.eliminarMasivo,
        },
    ]
    public get menuItemAcciones(): MenuItem[] {
        return this._menuItemAcciones
    }
    @Input()
    public set menuItemAcciones(v: MenuItem[]) {
        this._menuItemAcciones = v
    }

    @Input()
    public appendAccionesToDefault = false;

    
    private _download : Boolean = false;
    public get download() : Boolean {
        return this._download;
    }
    @Input()
    public set download(v : Boolean) {
        this._download = v;
    }
    
    public getTemplateContent() {
        return this.isMobile() || this.filtro?.layout == 'G'
            ? this.mobileDataContent
                ? this.mobileDataContent
                : this.defaultMobileDataContent
            : this.dataContent
            ? this.dataContent
            : this.defaultDesktopDataContent
    }
    @Input()
    public modoSeleccion: 'single' | 'multiple' | 'none' = 'none'

    @Input()
    public onClick: (item, event?) => void = (item, event) => {
        if (!this.isMobile() && this.modoSeleccion == 'none' && this.navegable) 
        if(this.defaultClick ==='ver'){this.ver(item.id)} else {this.editar(item.id)};
        event.stopPropagation()
    }
    @Input()
    public hideHeader: boolean = false
    @Input()
    public hideTitle: boolean = false

    public seleccion: ResumenModel[] = []

    @Output()
    public seleccionChange: EventEmitter<ResumenModel[]> = new EventEmitter<ResumenModel[]>()

    public subFiltro: Subscription
    @Input()
    public footer: TemplateRef<any>
    @Input()
    public selectionAction: TemplateRef<any>
    @Input()
    public header: TemplateRef<any>
    @Input()
    public subheader: TemplateRef<any>
    @Input()
    public dataContent: TemplateRef<any>

    @Input()
    public mobileDataContent: TemplateRef<any>

    @Input()
    public filterContent: TemplateRef<any>

    @Input()
    public headerActionsContent: TemplateRef<any>

    @Input()
    public titleContent: TemplateRef<any>

    @Input()
    public borrable: boolean = true

    @Input()
    public handler: ModelListHandler

    @Input()
    public getRowClass: (any) => string | void = (r) => {
        return
    }

    @Input()
    public acciones: AccionesTabla[]
    @Input()
    public isLazy: boolean = false
    @Input()
    public isPaginator: boolean = true

    @Input()
    public editable: boolean = true

    @Input()
    public conResumen: boolean = true

    @Input()
    public permiteNuevo: boolean = true

    @Input()
    public customLoadingService: LoadingService

    public loadingVisible: boolean = false
    public mensajeLoading: string = 'Guardando...'
    @Input()
    public multilenguaje: boolean = false
    @Input()
    public listado: ResumenModel[] = []
    public listadoFiltrado: ResumenModel[] = []

    @Input()
    updateRowImplementation = (data: ResumenModel, custoLoadingService?: LoadingService) => {
        this.service.guardar(data, custoLoadingService)
    }

    public updateRow(data) {
        data['codigoIdioma'] = this.idiomaSeleccionado ? this.idiomaSeleccionado.codigo : 'ES'
        this.updateRowImplementation(data, this.getLoadingService())
    }
    @Input()
    public readonly: boolean = false
    @Input()
    public totalRecords: number = 0
    @Input()
    public allowLayoutSwitch: boolean = true

    @Input()
    public emptymessage: string = 'Sin registros'
    @Output()
    public totalRecordsChange: EventEmitter<number> = new EventEmitter<number>()

    @Input()
    public editModal: boolean = false

    @Output()
    public listadoChange: EventEmitter<ResumenModel[]> = new EventEmitter<ResumenModel[]>()
    @Input()
    public service: ServicioAbstract<any>

    public filtroCargado: boolean = false
    private _filtro: Filtro
    public get filtro(): Filtro {
        return this._filtro
    }
    @Input()
    public set filtro(v: Filtro) {
        this._filtro = v
        if (this.subFiltro) {
            this.subFiltro.unsubscribe()
            this.subFiltro = null
        } if(v)
            this.registerFilter()
    }

    private _camposMobile: any[]
    public get camposMobile(): any[] {
        return this._camposMobile
    }
    public set camposMobile(v: any[]) {
        this._camposMobile = v
    }
    private _columns: any[]
    public get columns(): any[] {
        return this._columns
    }
    @Input()
    public set columns(v: any[]) {
        this._columns = v
        this.camposMobile = this.columns.filter((c) => c.esMobile)
    }
    @Input()
    public txtEliminar: string

    @Input()
    public dataViewGridStyle: string = 'col-12 lg:col-3 md-4'
    @Input()
    public txtOkEliminar: string = this.translateService.get('EL_ITEM_FUE_ELIMINA_15')
    @Input()
    public extraFilter: (r: any[]) => any[] = (r) => {
        return r
    }

    @ViewChild('dt', { static: false }) table
    private defaultTxtEliminar: string

    private defaultTxtOkEliminar: string
    @Input()
    public modelName: string = ''

    @Input()
    public title: string = ''

    private defaultGetData = (f: Filtro, l: LoadingService) => {
        return f && this.service?.getAll ? this.service.getAll(f, this.getLoadingService()) : Promise.resolve(this.listado)
    }

    private defaultCount = (f: Filtro, l: LoadingService) => {
        return f && this.service?.getAll ? this.service.count(f, this.getLoadingService()) : Promise.resolve(this.listado?.length)
    }

    @Input()
    public getData: (f: Filtro, l: LoadingService) => Promise<any[]> = this.defaultGetData

    public onHeaderCheckboxToggle(event) {
        this.seleccionChange.emit(this.seleccion)
    }
    @Input()
    public getCount: (f: Filtro, l?: LoadingService) => Promise<number> = this.defaultCount

    private _idiomaSeleccionado: Idioma
    public get idiomaSeleccionado(): Idioma {
        return this._idiomaSeleccionado
    }
    public set idiomaSeleccionado(v: Idioma) {
        this._idiomaSeleccionado = v ? v : this.idiomaService.default
        this.filtro.idioma = v?.codigo || 'ES'
    }

    public get finalTitle() {
        return this.title ? this.title : this.modelName ? this.modelName.toUpperCase() : this.translateService.get('SIN_TITULO')
    }
    constructor(
        public activeRoute: ActivatedRoute,
        public confirmationService: ConfirmationService,
        public idiomaService: IdiomaService,
        public messagesService: MessagesService,
        protected router: Router
    ) {
        super(messagesService)
    }
    public showFilterbar(event){
        event?.stopPropagation && event.stopPropagation();
        this.displayFilterBar= true;
    }
    refreshFilter() {
        if (this.filtro) {
            this.filtro.forceUpdate()
        }
    }
    registerFilter() {
        if (!this.filtro) this.filtro = new Filtro(null, {},0,2000,"id",1,false);
        if (this.subFiltro) this.subFiltro.unsubscribe();

        this.subFiltro = this.filtro.valueChange
            .pipe(
                debounceTime(600),
                filter((v) => v != undefined)
            )
            .subscribe((r) => {
                if (this.filtro?.idioma && (!this.idiomaSeleccionado || this.idiomaSeleccionado?.codigo != this.filtro?.idioma)) {
                    this.idiomaService.getByCodigo(this.filtro.idioma).then((r) => {
                        this.idiomaSeleccionado = r
                    })
                }
                if(this.filtro.newSearch){
                    this.table && this.table.reset();  
                }
                //
                this.getItems(this.filtro).then((v) => {
                    this.listadoFiltrado = [...v]
                    this.updateTotalRecords()
                })
                this.filterService.setFilter(this.modelName,r);
            })
        this.subs.push(this.subFiltro)
    }
    public get columnasLen() {
        return this.columns ? this.columns.length + (this.modoSeleccion == 'multiple' ? 1 : 0) + (!this.readonly ? 1 : 0) : 1
    }
    public getTextColor(color: string) {
        return ColorUtils.contraste(color)
    }
    public get defaultAcciones() {
        return [
            new AccionesTabla(
                'EDITAR',
                'pi pi-pencil',
                '',
                (da, event) => {
                    this.editar(da.id)
                    event.stopPropagation && event.stopPropagation()
                },
                (data) => {
                    return this.editable
                }
            ),
            new AccionesTabla(
                'BORRAR',
                'pi pi-trash',
                '',
                (da, event) => {
                    event.stopPropagation && event.stopPropagation()
                    this.eliminar(da.id, event)
                },
                (data) => {
                    return this.borrable
                }
            ),
            //new AccionesTabla(this.translateService.get('HABILITAR'),"pi pi-check","",this.habilitar, (data)=>{return this.ha}),
            new AccionesTabla(
                'VER',
                'pi pi-eye',
                '',
                (da, event) => {
                    this.ver(da.id)
                    event.stopPropagation && event.stopPropagation()
                },
                (data) => {
                    return true
                }
            ),
        ]
    }

    public handlerRowExpanded
    ngOnDestroy(){
        super.ngOnDestroy();
        this.filtro?.unsuscribe();
    } 
    ngOnInit() {
        this.handlerRowExpanded = {
            onToggle: (data, event) => this.table.toggleRow(data, event),
            onCancelar: (data, event) => this.table.toggleRow(data, event),
            onGuardado: (data, event) => this.table.toggleRow(data, event),
        }

        this.updateTexts()
        if (this.appendAccionesToDefault && this.acciones?.length) {
            this.acciones = [...this.acciones, ...this.defaultAcciones]
        }
        if (!this.acciones) {
            this.acciones = this.defaultAcciones
        }

        //if (!this.filtro) this.filtro =new Filtro(null, {}, 0, 20, 'id', 1, false);
        if(this.filterService.hasFilter(this.modelName))
            this.filtro.patchValue(this.filterService.getFilter(this.modelName).json);
        this.subs.push(
            this.getLoadingService().loading.subscribe((l) => {
                this.loading = l
            })
        )
        /*if (!this.isLazy && !this.service) {
            this.getItems(this.filtro).then((r) => {
                this.registerFilter()
            })
        } else {
            this.registerFilter()
        }*/

        this.filtroCargado = true
    }

    public mostrarMenu(event, item,menu:Menu) {
        this.items = []
        
        this.acciones.forEach((a) => {
            let funcionEjecutar = this.ejecutarAccion
            this.items.push({
                label: this.translateService.get(a.tooltip),
                icon: a.icon,
                visible: a.esVisible(item),
                command: function (ev) {
                    return funcionEjecutar(a, item, ev)
                },
            })
        })
        
        menu.toggle(event);
        event?.stopPropagation && event.stopPropagation();
    }

    loadData = (event, f) => {
        if(!this.isLazy){
            let page = event.first == 0 ? 0 : Math.floor(event.first / event.rows)
            if (f.ready) f.setPage(page, event.rows, event.sortField ? event.sortField : f.sortField, event.sortOrder ? event.sortOrder : f.sortOrder, this.isLazy)
            this.seleccion = []
        }
      
    }
    public updateCount(event) {
        this.filtro.count = event.filteredValue ? event.filteredValue.length : 0
    }
    public updatePage(event) {
        let page = event.first == 0 ? 0 : Math.floor(event.first / event.rows)
        this.filtro.setPage(
            page,
            event.rows,
            event.sortField ? event.sortField : this.filtro.sortField,
            event.sortOrder ? event.sortOrder : this.filtro.sortOrder,
            this.isLazy
        )
    }

    public updateTotalRecords() {
        if (this.isLazy) {
            this.getCount(this.filtro).then((r) => {
                this.totalRecords = r
            })
        } else {
            this.totalRecords = this.listado.length
        }
    }
    public executeAccion(v, item) {
        var func = v
        return func(item)
    }
    getLoadingService() {
        return this.customLoadingService || this.loadingService
    }
    getItems = async (filtro) => {
        if (!this.isLazy) {
            const l = filtro.apply(this.listado)
            this.listadoChange.emit(this.listado)
            return l
        }
        this.mensajeLoading = 'Buscando...'
        this.loading = true
        var f = this.getData ? this.getData : this.defaultGetData
        return f(filtro, this.getLoadingService()).then((r) => {
            this.listado = this.extraFilter(r)
            this.listadoChange.emit(this.listado)
            this.totalRecordsChange.emit(this.totalRecords)
            this.mensajeLoading = ''
            this.loading = false
            return r
        })
    }

    public nuevo() {
        if (this.handler && this.handler.nuevo) {
            this.handler.nuevo()
        } else {
            this.router.navigate([this.modelName + '/nuevo'])
        }
    }
    public eliminar(id: number, event?) {
        if (this.handler && this.handler.eliminar) {
            this.handler.eliminar(id)
        } else {
            this.confirmationService.confirm({
                // key: 'genConf',
                header: 'Eliminar',
                message: this.qEliminar,
                accept: () => {
                    let $this = this
                    $this.service.eliminar(id).then((res) => {
                        // $this.getItems(this.filtro)
                        $this.success(this.okEliminar)
                        $this.filtro.forceUpdate()

                    })
                },
            })
        }
    }

    public editar(id: number) {
        if (this.handler && this.handler.editar) {
            this.handler.editar(id)
        } else {
            let query: NavigationExtras = {
                queryParams: {
                    id: id,
                },
            }
            this.router.navigate([this.modelName + '/edit'], query)
        }
    }

    public ver(id: number) {
        if (this.handler && this.handler.editar) {
            this.handler.editar(id)
        } else {
            let query: NavigationExtras = {
                queryParams: {
                    id: id,
                },
            }
            this.router.navigate([this.modelName + '/vista'], query)
        }
    }

    private get qEliminar() {
        return this.txtEliminar ? this.txtEliminar : this.defaultTxtEliminar
    }
    private get okEliminar() {
        return this.txtOkEliminar ? this.txtOkEliminar : this.defaultTxtOkEliminar
    }
    public updateTexts() {
        this.defaultTxtEliminar = this.translateService.get('DESEA_ELIMINAR_EL__18')
        this.defaultTxtOkEliminar = this.translateService.get('EL_ITEM_FUE_ELIMINA_15')
        if (!this.emptymessage) this.emptymessage = this.translateService.get('SIN_REGISTROS')
    }
    public rowSelectUnselect(event) {
        this.seleccionChange.emit(this.seleccion)
    }
    public eliminarMasivo() {
        this.success('En desarrollo')
    }
    public showAcciones(event, menu) {
        if (this.menuItemAcciones) {
        }
        menu.toggle(event)
    }
    public get todosSeleccionados() {
        return this.seleccion.length == this.listado.length && this.seleccion.length > 0
    }
    public seleccionarTodos() {
        if (this.modoSeleccion == 'multiple') {
            if (this.todosSeleccionados) {
                this.seleccion = []
                this.listado.forEach((s) => (s['selected'] = false))
            } else {
                this.seleccion = [...this.listado]
                this.seleccion.forEach((s) => (s['selected'] = true))
            }
        }
    }
    public toggleSelection(item, event) {
        if (this.modoSeleccion != 'multiple') return
        event.stopPropagation()
        if (this.seleccion.some((i) => i?.id == item?.id)) {
            this.seleccion = this.seleccion.filter((i) => i?.id != item?.id)
            item['selected'] = false
        } else {
            this.seleccion.push(item)
            item['selected'] = true
        }
        this.rowSelectUnselect(event)
    }

    public onCheckClick: (item, event?) => void = (item, event) => {
        item.selected = !item.selected
        event.stopPropagation()
    }

    public getPageSize(filtro?: Filtro): number {
        return filtro?.size <= 30 || filtro?.size == 100 ? filtro.size : 30
    }
    public get maxItems(){
        return this.isDesktop()? 6: 4;
    }
    public toggleTemplate: TemplateRef<any> = null
    handleHabilitarClick(event, data) {
        event?.stopPropagation && event.stopPropagation()
        var serviceHandler = this.handler ? this.handler : this.service
        if (!serviceHandler || !serviceHandler['habilitar']) return
        var prom
        if (data.habilitado) {
            prom = serviceHandler['deshabilitar'](data.id, this.customLoadingService)
        } else {
            prom = serviceHandler['habilitar'](data.id, this.customLoadingService)
        }
        prom.then((v) => {
            data.habilitado = v.habilitado
        })
    }
    ejecutarAccion = (accion: AccionesTabla, data, event) => {
        event?.stopPropagation && event.stopPropagation()
        accion.command(data, event)

        if (accion?.toggleTemplate) {
            this.toggleTemplate = accion.toggleTemplate
            this.table.toggleRow(data, event)
        }
    }
}
