import { Component, forwardRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subscription } from 'rxjs'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { LocalService } from 'src/app/services/local.service'
import { CentroCostoService } from './../../../services/centro-costo.service'
import { ConfiguracionUsuarioService } from './../../../services/configuracion-usuario.service'

@Component({
    selector: 'centro-selector',
    templateUrl: './centro-selector.component.html',
    styleUrls: ['./centro-selector.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CentroSelectorComponent),
            multi: true,
        },
    ],
})
export class CentroSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input()
    public multiple: boolean = false

    @Input()
    public readonly: boolean = false

    @Input()
    public customLabel: string

    public get label() {
        return this.customLabel ? this.customLabel : this.multiple ? 'Centros' : 'Centro'
    }

    @Input()
    public required: boolean = false
    @Input()
    public name: string
    @Input()
    public autohide: boolean = true

    @Input()
    public autoFill: boolean = true

    private sus: Subscription
    @HostBinding('class.hidden') private selfHide: boolean = false
    private _seleccionable: boolean = false
    public get seleccionable(): boolean {
        return this._seleccionable
    }
    public set seleccionable(v: boolean) {
        this._seleccionable = v
        this.selfHide = this.autohide && !this._seleccionable
    }

    private _centroCostoSeleccionado: Descriptivo | Descriptivo[]
    public get centroCostoSeleccionado(): Descriptivo | Descriptivo[] {
        return this._centroCostoSeleccionado
    }
    @Input()
    public set centroCostoSeleccionado(v: Descriptivo | Descriptivo[]) {
        this._centroCostoSeleccionado = v
        this.onChangeCallback(this._centroCostoSeleccionado)
    }

    constructor(public centroService: CentroCostoService, public localService: LocalService, private configService: ConfiguracionUsuarioService) {}
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    public disabled: boolean = false

    writeValue(obj: any): void {
        this._centroCostoSeleccionado = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
    private susLocal: Subscription
    ngOnInit(): void {
        this.sus = this.centroService.data.subscribe((l) => {
            this.seleccionable = l.length > 1
        })
        setTimeout(() => {
            this.susLocal = this.localService.localSeleccionadoObs.subscribe((l) => {
                if (
                    this.autoFill &&
                    !this.readonly &&
                    l &&
                    (!this.centroCostoSeleccionado || (!this.centroCostoSeleccionado['id'] && !this.centroCostoSeleccionado['length']))
                ) {
                    if (this.multiple) {
                        this.centroCostoSeleccionado = [l.centroCosto]
                    } else {
                        this.centroCostoSeleccionado = l.centroCosto
                    }
                    if (this.susLocal) {
                        this.susLocal.unsubscribe()
                    }
                }
            })
        }, 1000)
    }

    ngOnDestroy(): void {
        if (this.sus) this.sus.unsubscribe()
    }
}
