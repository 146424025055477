import { Injectable } from '@angular/core'
import { DescriptivosService } from 'src/app/common/services/descriptivos.service'
import { Menu } from 'src/app/model/Menu'
import { CategoriaMenuService } from 'src/app/services/categoria-menu.service'
import { Descriptivo } from '../common/model/Descriptivo'
import { LoadingService } from './../common/services/loading-data-service.service'
import { MenuProducto, MenuProductoCategoria } from './../model/MenuProducto'
@Injectable({
    providedIn: 'root',
})
export class MenuService extends DescriptivosService<Menu> {
    constructor(public categoriaMenuService: CategoriaMenuService) {
        super()
    }
    public actualizarDescripcion(data: Menu, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        var p = this.http.put(this.getBaseURL('actualizar-descripcion'), data).toPromise()
        p.then((r) => {
            //this.loadData(customLoading);
            if (customLoading) {
                customLoading.susLoadingCount()
            } else this.loadingService.susLoadingCount()
        })
        return p
    }
    public baseName(): string {
        return 'menu'
    }
    public parseToEnt(data: any): Menu {
        return Menu.fromData(data)
    }
    public newEnt(): Menu {
        return new Menu()
    }

    public getByMenuProducto(producto: number, locales: Descriptivo[]): Promise<MenuProductoCategoria[]> {
        let filter = {
            locales: locales,
            producto: producto,
        }
        let menus: MenuProductoCategoria[] = []
        let idAnt: number
        return this.http
            .post(this.getBaseURL('menuProducto'), filter)
            .toPromise()
            .then((ms: any[]) => {
                ms.forEach((m: MenuProducto) => {
                    let categoria = { idCategoria: m.idCategoriaMenu, descripcion: m.descripcionCategoriaMenu, idProducto: m.idProducto }

                    if (idAnt === m.idMenu) {
                        if (m.idProducto)
                            menus.find((menu) => menu.idMenu == m.idMenu).categorias.push(new Descriptivo(null, m.descripcionCategoriaMenu, m.idCategoriaMenu))
                    } else {
                        menus.push(new MenuProductoCategoria(m.idMenu, m.descripcionMenu, []))
                        idAnt = m.idMenu
                        if (m.idProducto)
                            menus.find((menu) => menu.idMenu == m.idMenu).categorias.push(new Descriptivo(null, m.descripcionCategoriaMenu, m.idCategoriaMenu))
                    }
                })
                return menus
            })
            .catch((e) => this.handleError(e))
    }
}
