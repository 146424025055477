import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { GridsterModule } from 'angular-gridster2'
import { CajaModule } from '../caja/caja.module'
import { UsuarioAG } from './../../authguards/UsuarioAG'
import { CommonComponentsModule } from './../../common/common-components.module'
import { RecargaAccionComponent } from './../components/recarga-accion/recarga-accion.component'
import { NotaModule } from './../nota/nota.module'
import { OperacionesCajaComponent } from './../operaciones-caja/operaciones-caja.component'
import { AperturaCierreComponent } from './components/apertura-cierre/apertura-cierre.component'
import { BuscadorPrecioComponent } from './components/buscador-precio/buscador-precio.component'
import { EstadoStockComponent } from './components/estado-stock/estado-stock.component'
import { NotasComponent } from './components/notas/notas.component'
import { PedidoShortcutComponent } from './components/pedido-shortcut/pedido-shortcut.component'
import { PedidosNuevosComponent } from './components/pedidos-nuevos/pedidos-nuevos.component'
import { RecargableComponent } from './components/recargable/recargable.component'
import { ShortcutComponent } from './components/shortcut/shortcut.component'
import { TotalesPedidoComponent } from './components/totales-pedido/totales-pedido.component'
import { DashboardElementComponent } from './dashboard-element/dashboard-element.component'
import { DashBoardElementHostDirective } from './dashboard-element/dashboard-element.directive'
import { HomeComponent } from './home.component'
const routes: Routes = [{ path: '', component: HomeComponent, canActivate: [UsuarioAG] }]
const declarations = [
    HomeComponent,
    DashboardElementComponent,
    DashBoardElementHostDirective,
    PedidoShortcutComponent,
    EstadoStockComponent,
    ShortcutComponent,
    RecargableComponent,
    TotalesPedidoComponent,
    PedidosNuevosComponent,
    BuscadorPrecioComponent,
    OperacionesCajaComponent,
    AperturaCierreComponent,
    NotasComponent,
    RecargaAccionComponent,
]
@NgModule({
    imports: [RouterModule.forChild(routes), CommonComponentsModule, GridsterModule, CajaModule, NotaModule],
    providers: [],
    declarations: declarations,
    entryComponents: [
        PedidoShortcutComponent,
        ShortcutComponent,
        AperturaCierreComponent,
        PedidosNuevosComponent,
        EstadoStockComponent,
        RecargableComponent,
        RecargaAccionComponent,
        BuscadorPrecioComponent,
        TotalesPedidoComponent,
        NotasComponent,
    ],
    exports: declarations,
})
export class HomeModule {}
