<div class="gestor-cambio-saldo-caja content content-white">
    <model-gestor
        [readonly]="readonly"
        [service]="service"
        [(item)]="item"
        [validar]="isValid"
        [verAcciones]="false"
        [isModal]="isModal"
        [goBack]="goBack"
        (onItemGuardado)="handleGuardado($event)"
        [editable]="!readonly"
        (onCancelar)="handleCancelar($event)"
        [form]="CambioSaldoCajaForm"
        [multilenguaje]="false"
        (onEditar)="onEdit($event)"
    >
        <div class="title" model-type="title">
            <div class="grid">
                <div class="col title-content">
                    <h3>{{ esRetiro ? 'Retiro' : ('Ingreso' | translate) }}</h3>
                </div>
            </div>
        </div>
        <div class="gestor-form" model-type="dataContent">
            <form #CambioSaldoCajaForm="ngForm" id="CambioSaldoCajaform">
                <div class="grid" *ngIf="item">
                    <div class="col-12 lg:col-4">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'FECHA' | translate }}</mat-label>
                            <input matInput [matDatepicker]="fechaPick" [(ngModel)]="item.fecha" #fecha="ngModel" name="fecha" [readonly]="readonly" />
                            <mat-datepicker-toggle matSuffix [for]="fechaPick" [disabled]="readonly"> </mat-datepicker-toggle>
                            <mat-datepicker #fechaPick></mat-datepicker>
                            <mat-error>
                                {{ getErrorMessage(fecha) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'Responsable' | translate }}</mat-label>
                            <input matInput hidden name="dummy" />
                            <span class="input">{{ item.responsable?.descripcion }}</span>
                        </mat-form-field>
                    </div>

                    <div class="col-12 lg:col-4" *ngIf="cajaEditable">
                        <descriptivo-material-selector
                            [service]="cajaService"
                            [label]="'CAJA' | translate"
                            [limpiable]="false"
                            [(ngModel)]="item.caja"
                            [permiteNuevo]="false"
                            name="caja"
                            [readonly]="readonly"
                            required
                        >
                        </descriptivo-material-selector>
                    </div>
                    <local-selector [multiple]="false" [(ngModel)]="item.local" [name]="'locales'" [readonly]="readonly" [required]="true" class="col">
                    </local-selector>
                    <div class="col">
                        <parametrico-selector
                            [listado]="formaPagoService.data | async"
                            #formaPago="ngModel"
                            [required]="true"
                            [(ngModel)]="item.formaPago"
                            [limpiable]="true"
                            [permiteNuevo]="true"
                            name="forma-pago"
                            [titulo]="'Forma de pago' | translate"
                            [readonly]="readonly"
                        >
                        </parametrico-selector>
                    </div>
                    <div class="col-12 lg:col-6">
                        <span-dinero-editable
                            [(ngModel)]="item.monto"
                            [label]="'Monto' | translate"
                            [readonly]="readonly"
                            name="{{ 'monto' + r }}"
                            [minValue]="0"
                        >
                        </span-dinero-editable>
                    </div>

                    <div class="col-12">
                        <mat-form-field appearance="outline" [ngClass]="{ readonly: readonly }">
                            <mat-label>{{ 'JUSTIFICACION' | translate }}</mat-label>
                            <input
                                matInput
                                placeholder="{{ 'JUSTIFICACION_PH' | translate }}"
                                required
                                [(ngModel)]="item.justificacion"
                                pTooltip="{{ 'JUSTIFICACION_TOOLTIP' | translate }}"
                                type="text"
                                #justificacion="ngModel"
                                name="justificacion"
                                [readonly]="readonly"
                            />
                            <mat-error>
                                {{ getErrorMessage(justificacion) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>
