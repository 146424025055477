<div class="grid qr-menu">
    <div class="col-12">
        <div class="grid">
            <div class="col-12">
                <div class="grid">
                    <div class="col-12 lg:col-6 print-hidden">
                        <descriptivo-material-selector
                            [options]="menus"
                            [label]="'Seleccione un menú' | translate"
                            [limpiable]="true"
                            [(ngModel)]="menuSeleccionado"
                            [permiteNuevo]="false"
                            name="menu"
                            [required]="true"
                        >
                        </descriptivo-material-selector>
                    </div>
                    <div class="col-6 lg:col-1">
                        <button
                            pButton
                            [disabled]="!mostrarQrs || !Qrs?.length"
                            label="{{ 'IMPRIMIR' | translate }}"
                            (click)="imprimirQr()"
                            class="agregar-button"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="grid" *ngIf="mostrarQrs" #QR>
                    <div class="col-12 lg:col-4 qr-container text-center" *ngFor="let qr of Qrs">
                        <div class="grid grid-nogutter">
                            <div class="col-12 text-center">
                                <span>{{ qr.mesa }}</span>
                            </div>
                            <div class="col-12">
                                <qrcode [qrdata]="qr.url" [width]="256" [errorCorrectionLevel]="'L'" elementType="url"></qrcode>
                            </div>
                            <div class="col-8 col-offset-2 print-hidden">
                                <div class="p-inputgroup">
                                    
                                    <input type="text" readonly pInputText [(ngModel)]="qr.url" />
                                    <button type="button" pButton pRipple icon="pi pi-copy" (click)="copyLink(qr.url)"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <button pButton label="{{ 'CERRAR' | translate }}" (click)="cerrar()" class="agregar-button text-left"></button>
            </div>
        </div>
    </div>
</div>
