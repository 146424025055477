import { TipoLayout } from './TipoLayout'

export class ConfiguracionMenu {
    constructor(public id?: number, public tipoLayout?: TipoLayout) {}

    public static fromData(data: any): ConfiguracionMenu {
        if (!data) return null
        const o: ConfiguracionMenu = new ConfiguracionMenu(data.id, TipoLayout.fromData(data.tipoLayout))

        return o
    }
}
