import * as moment from "moment";
import { Descriptivo } from "src/app/common/model/Descriptivo";
import { ProductoCabecera } from "./../../model/ProductoCabecera";

export class ListaProducto {
  cumpleFecha = true;
  constructor(
    public id?: number,
    public producto?: ProductoCabecera,
    public precio?: number,
    public fechaDesde?: Date,
    public fechaHasta?: Date,
    public costo?: number,
    public listaPrecio?: Descriptivo
  ) {}

  public static fromData(data: any): ListaProducto {
    if (!data) return null;
    const o: ListaProducto = new ListaProducto(
      data.id,
      ProductoCabecera.fromData(data.producto),
      data.precio,
      data.fechaDesde ? new Date(data.fechaDesde) : null,
      data.fechaHasta ? new Date(data.fechaHasta) : null,
      data.costo
    );

    return o;
  }
  public get habilitado() {
    return this.producto ? this.producto.habilitado : false;
  }
  public get rango() {
    if (!this.fechaDesde || !this.cumpleFecha) return "";
    let fechaDesdeStr: string = moment(this.fechaDesde).format("DD/MM/YYYY") + " ";
    let fechaHastaStr: string = this.fechaHasta
      ? " hasta " + moment(this.fechaHasta).format("DD/MM/YYYY") + " "
      : this.habilitado
      ? " en adelante "
      : " no disponible";
    return fechaDesdeStr + fechaHastaStr;
  }

  public get precioRango() {
    return this.cumpleFecha ? this.precio : null;
  }

  public aplica(producto: Descriptivo, fecha: Date = new Date()) {
    return this.producto.id == producto.id && moment(this.fechaDesde).isBefore(fecha) && (this.fechaHasta == null || moment(this.fechaHasta).isAfter(fecha));
  }
  public get descripcion() {
    return this.producto?.descripcion;
  }
}
