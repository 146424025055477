<div class="listado-movimiento-caja content">
    <model-list
        [service]="service"
        [modelName]="'movimiento-caja'"
        [columns]="columns"
        [title]="'MOVIMIENTOS_CAJA' | translate"
        [(listado)]="listado"
        [isLazy]="true"
        [filtro]="filtro"
        [getData]="getData"
        [navegable]="false"
        [permiteNuevo]="false"
        [editable]="false"
        [borrable]="false"
        [conResumen]="false"
        [filterContent]="customFilter"
        [subheader]="customSubheader"
        [isPaginator]="false"
        [footer]="footer"
        [onClick]="navegar"
        [titleContent]="header"
    >
        <ng-template #customFilter>
            <filtro [(filter)]="filtro" class="p-fluid">
                <div class="grid">
                    <div class="col-12 small">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'BUSCAR' | translate }}...</mat-label>
                            <input [(ngModel)]="filtro.searchStr" matInput placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" [(ngModel)]="filtro.searchStr" />
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-12 lg:col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'DESDE' | translate }}</mat-label>
                                    <input matInput [matDatepicker]="fechaDesdePicker" [(ngModel)]="filtro.fechaDesde" />
                                    <mat-datepicker-toggle matSuffix [for]="fechaDesdePicker"> </mat-datepicker-toggle>
                                    <mat-datepicker #fechaDesdePicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 lg:col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'HASTA' | translate }}</mat-label>
                                    <input matInput [matDatepicker]="fechaHastaPicker" [(ngModel)]="filtro.fechaHasta" />
                                    <mat-datepicker-toggle matSuffix [for]="fechaHastaPicker"> </mat-datepicker-toggle>
                                    <mat-datepicker #fechaHastaPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <descriptivo-material-selector
                            [options]="formasPagoService.data | async"
                            [label]="'FORMA_DE_PAGO' | translate"
                            name="formaPago"
                            [limpiable]="true"
                            [(ngModel)]="filtro.formaPago"
                            [itemTemplate]="conIcono"
                        >
                            <ng-template #conIcono let-option>
                                <div class="grid">
                                    <div class="col-fixed" style="width: 3em">
                                        <profile-badge [url]="option.imagenUrl" class="small-badge desktop-badge" [disabled]="true"> </profile-badge>
                                    </div>
                                    <div class="col">
                                        <span>{{ option.descripcion }}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </descriptivo-material-selector>
                    </div>
                    <div class="col">
                        <descriptivo-material-selector
                            [options]="responsables"
                            [label]="'RESPONSABLE' | translate"
                            name="responsable"
                            [limpiable]="true"
                            [(ngModel)]="filtro.responsable"
                        >
                        </descriptivo-material-selector>
                    </div>
                </div>
            </filtro>
        </ng-template>
        <ng-template #customSubheader>
            <tr class="subheader">
                <td colspan="6" class="text-right">Saldo al {{ filtro.fechaDesde | date: 'dd/MM/yyyy' }}</td>
                <td class="money-column">
                    <span>{{ saldos?.salidas | currency }}</span>
                </td>
                <td class="money-column">
                    <span>{{ saldos?.entradas | currency }}</span>
                </td>
                <td class="money-column">
                    <span>{{ saldos?.saldo | currency }}</span>
                </td>
            </tr>
            <tr class="totales subheader">
                <td colspan="6" class="text-right total">
                    <b>{{ 'TOTAL' | translate }}</b>
                </td>
                <td class="text-right">{{ -totalSalida | currency }}</td>
                <td class="text-right">{{ totalEntrada | currency }}</td>
                <td class="text-right">{{ totalSaldo | currency }}</td>
            </tr>
        </ng-template>
        <ng-template #footer>
            <tr class="totales subheader">
                <td colspan="6" class="text-right total">
                    <b>{{ 'TOTAL' | translate }}</b>
                </td>
                <td class="text-right">{{ -totalSalida | currency }}</td>
                <td class="text-right">{{ totalEntrada | currency }}</td>
                <td class="text-right">{{ totalSaldo | currency }}</td>
            </tr>
        </ng-template>
    </model-list>
    <ng-template #header>
        <div class="grid filter-container">
            <div class="col nowrap">
                <h2 autofocus>{{'Movimientos de caja: ' | translate}} <span class="caja" (click)="verCajas($event)"
                        [ngClass]="{'clickable': cajas.length > 1 }">
                        {{caja?.descripcion? caja.descripcion : 'Sin caja seleccionada'}} <i class="fa fa-chevron-down"
                            *ngIf="cajas.length > 1"></i></span>
                </h2>
            </div>
                <div class="col-fixed flex ">
                    <div class="ui-inputgroup" *ngIf="filtro">
                        <input *ngIf="isLazy" [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" [(ngModel)]="filtro.searchStr" />
                        <input
                            *ngIf="!isLazy"
                            (input)="table.filterGlobal($event.target.value.toUpperCase(), 'contains')"
                            pInputText
                            placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
                            [(ngModel)]="filtro.searchStr"
                        />
                        <button pButton icon="pi pi-refresh" class="ui-button-sm ui-button-outlined" (click)="filtro.forceUpdate(); $event.stopPropagation()"></button>
                        <button
                            pButton
                            icon="pi pi-filter"
                            class="ui-button-sm"
                            [ngClass]="{ 'ui-button-outlined': !filtro?.hasFilters() }"
                            (click)="showFilterbar($event)"
                        ></button>
                    </div>               
                    
            </div>
            <div class="col-fixed  estado-caja" *ngIf="caja">
                <span *ngIf="!caja?.estaCerrada">{{'ABIERTA' | translate}}</span>
                <span *ngIf="caja?.estaCerrada">{{'CERRADA' | translate}}</span>

            </div>
            <div class="col-fixed" *ngIf="acciones?.length">
                <button pButton label="{{'ACCIONES' | translate}}" (click)="menu.toggle($event)"
                    icon="fa fa-bars"></button>
            </div>
        </div>
        <p-menu #menu [popup]="true" [model]="acciones" appendTo="body"></p-menu>
    </ng-template>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of cajas" (click)="aplicarSeleccion(c)">
            {{ c.descripcion }}
        </li>
    </ul>
</p-overlayPanel>

<p-dialog
    header=""
    [(visible)]="editandoMovimiento"
    appendTo="body"
    [modal]="true"
    #dialDetail
    [styleClass]="'no-header gestor-dialog'"
    (onShow)="onDialogShow($event, dialDetail)"
>
    <gestor-cambio-estado-caja
        #gestorEstado
        [item]="itemEditado"
        (onGuardado)="refresh($event)"
        [cajaEditable]="false"
        (onCancelado)="onCancelar($event)"
        [goBack]="false"
        [isModal]="true"
        [caja]="itemEditado.caja"
        [esCierre]="itemEditado?.esCierre"
        *ngIf="itemEditado && editandoMovimiento"
    >
    </gestor-cambio-estado-caja>
    <gestor-traspaso-de-caja
        #gestorTraspaso
        (onGuardado)="refresh($event)"
        [cajaEditable]="false"
        (onCancelado)="onCancelar($event)"
        [goBack]="false"
        [isModal]="true"
        [item]="traspaso"
        *ngIf="traspaso && editandoMovimiento"
    >
    </gestor-traspaso-de-caja>

    <gestor-cambio-saldo-caja
        #gestorCambioSaldo
        (onGuardado)="refresh($event)"
        [cajaEditable]="false"
        (onCancelado)="onCancelar($event)"
        [goBack]="false"
        [isModal]="true"
        [item]="cambioSaldo"
        *ngIf="cambioSaldo && editandoMovimiento"
    >
    </gestor-cambio-saldo-caja>
</p-dialog>
<p-sidebar [(visible)]="displayFilterBar" #filterBar position="right" [style]="{ width: isMobile() ? '100vw' : '30vw' }">
    <div class="header-filtros">
        <h2>Filtros</h2>
    </div>
    <ng-container *ngTemplateOutlet="customFilter; context: this"> </ng-container>
</p-sidebar>