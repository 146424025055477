import { CentroCosto } from './CentroCosto'
import { Cliente } from './Cliente'
import { Cobranza } from './Cobranza'
import { Comprobante } from './Comprobante'
import { Pedido } from './Pedido'

export class Ticket {
    pedido?: Pedido
    comprobante?: Comprobante
    centroCosto?: CentroCosto
    cliente?: Cliente
    cobranzas?: Cobranza[] = []

    public esConsumidorFinal() {
        return this.cliente?.esConsumidorFinal
    }

    public get totalBonificaciones(){
        return this.comprobante? (this.comprobante.descuentos) : (this.pedido? this.pedido.descuentos : 0);
    }
}
