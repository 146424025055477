
import { Parametrico } from 'src/app/common/model/Parametrico';
import { ProfilePic } from 'src/app/common/model/ProfilePic';


export class Idioma extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        public bandera: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),
        esDefault: boolean = false,



    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return "IdiomaLOW";
    }
    public static fromData(data: any): Idioma {
        if (!data) return null;
        const o: Idioma = new Idioma(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            ProfilePic.fromData(data.bandera),
            data.esDefault,
        );

        return o;

    }

    public get imagen() {
        return this.bandera ? this.bandera.picPathVersion : "assets/images/default_profile.png";
    }

}