import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ConfirmationService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { CuentaContable } from 'src/app/model/CuentaContable'
import { CuentaContableService } from 'src/app/services/cuenta-contable.service'
import { SessionComponent } from './../../../common/components/session-component.component'

@Component({
    selector: 'gestor-cuenta-contable',
    templateUrl: 'gestor-cuenta-contable.component.html',
    styleUrls: ['gestor-cuenta-contable.component.less'],
})
export class GestorCuentaContableComponent extends SessionComponent implements OnDestroy, OnInit {
    private routeSub: Subscription
    @Input()
    public item: CuentaContable = new CuentaContable()
    @Input()
    public goBack: boolean = true
    @Input()
    public isModal: boolean = false

    @Output()
    public onGuardado: EventEmitter<CuentaContable> = new EventEmitter<CuentaContable>()

    @Output()
    public onCancelado = new EventEmitter()
    @Input()
    public readonly = false
    constructor(
        messagesService: MessagesService,
        public service: CuentaContableService,
        private route: ActivatedRoute,
        private confService: ConfirmationService
    ) {
        super(messagesService)
    }

    public handleGuardado(item) {
        this.onGuardado.emit(item)
    }
    public handleCancelar(item) {
        this.onCancelado.emit()
    }

    public isValid() {
        return true
    }
    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )
        if (this.route.snapshot.data?.parent == 'cuenta-contable') {
            this.routeSub = this.route.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.service.getById(id).then((r) => {
                        this.item = r
                    })
                } else {
                    this.item = this.service ? this.service.newEnt() : null
                }
            })
        }
    }

    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }
}
