<div class="ui-g ui-fluid login">
    <div class="login-content grid align-items-center justify-content-center">
        <div class="col-12 lg:col-6 left">
            <img src="assets/implementacion/images/logo.png" />
        </div>
        <div class="col-12 lg:col-6 right">
            <div class="grid">
                <div class="col-12" *ngIf="esperarMail">
                    <span>{{'SE_ENVIÓ_UN_MAIL_A__19' | translate}}</span>
                </div>

                <div class="col-12" *ngIf="error">
                    <span>{{error?.error || ('HUBO_UN_ERROR' | translate)}}</span>
                </div>
                <div class="col-12" *ngIf="!esperarMail">
                    <form role="form" #form>
                        <div class="grid  align-items-center justify-content-center">
                            <div class="col-12 lg:col-8">
                                <mat-form-field>
                                    <mat-label>{{'NOMBRE' | translate}}</mat-label>
                                    <input matInput placeholder="{{'NOMBRE' | translate}}" [(ngModel)]='registro.nombre'
                                        pTooltip="{{'NOMBRE' | translate}}" type="text" #nombre="ngModel" name="nombre"
                                        required>
                                    <mat-error>
                                        {{getErrorMessage(nombre)}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 lg:col-8">
                                <mat-form-field>
                                    <mat-label>{{'APELLIDO' | translate}}</mat-label>
                                    <input matInput placeholder="{{'APELLIDO' | translate}}"
                                        [(ngModel)]='registro.apellido' pTooltip="{{'APELLIDO' | translate}}"
                                        type="text" #apellido="ngModel" name="apellido " required>
                                    <mat-error>
                                        {{getErrorMessage(apellido)}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 lg:col-8">
                                <mat-form-field>
                                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                                    <input matInput placeholder="{{'EMAIL' | translate}}" [(ngModel)]='registro.email'
                                        pTooltip="{{'EMAIL' | translate}}" type="email" #email="ngModel" name="email"
                                        required email>
                                    <mat-error>
                                        {{getErrorMessage(email)}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 text-center" style="width: auto;">
                                <re-captcha (resolved)="resolved($event)" [formControl]="recaptcha" [siteKey]="key">
                                </re-captcha>
                            </div>
                            <div class="col-12 buttons">


                                <button type="submit" pButton class="register-button"
                                    label="{{'REGISTRARSE' | translate}}" (click)="registrarse()"></button>


                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
