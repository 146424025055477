


export class ProfilePic {
    private _picPathVersion: string;
    public static NUEVO: ProfilePic = new ProfilePic(null, "assets/images/plus.png")
    constructor(

        public id?: number,
        public picPath: string = 'assets/images/default_image.png',
        public version?: number,
        public previewPicPath?: string,
    ) {
        this._picPathVersion = this.picPath;// + (this.version ? ("?" + this.version) : "");
    }



    public get picPathVersion(): string {
        return this.previewPicPath ? this.previewPicPath : this._picPathVersion;
    }

    public static fromData(data: any): ProfilePic {
        if (!data) return null;
        let o: ProfilePic = new ProfilePic(
            data.id,
            data.picPath,
            data.version,
            data.previewPicPath);
        return o;

    }


}
