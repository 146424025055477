import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AdministracionAuthGuard } from './../../authguards/AdministracionAuthGuard'
import { VisualizadorAuthGuard } from './../../authguards/VisualizadorAuthGuard'
import { GestorCambioSaldoCajaComponent } from './../cambio-saldo-caja/gestor-cambio-saldo-caja/gestor-cambio-saldo-caja.component'
import { ListadoCambioSaldoCajaComponent } from './../cambio-saldo-caja/listado-cambio-saldo-caja/listado-cambio-saldo-caja.component'

const routes: Routes = [
    { path: '', data: { parent: 'cambio-saldo-caja', animation: 'List' }, component: ListadoCambioSaldoCajaComponent, canActivate: [AdministracionAuthGuard] },
    {
        path: 'edit',
        data: { parent: 'cambio-saldo-caja', animation: 'Item' },
        component: GestorCambioSaldoCajaComponent,
        canActivate: [AdministracionAuthGuard],
    },
    {
        path: 'vista',
        component: GestorCambioSaldoCajaComponent,
        canActivate: [VisualizadorAuthGuard],
        data: { parent: 'cambio-saldo-caja', vista: true, animation: 'Item' },
    },
    {
        path: 'nuevo',
        data: { parent: 'cambio-saldo-caja', animation: 'Item' },
        component: GestorCambioSaldoCajaComponent,
        canActivate: [AdministracionAuthGuard],
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],

    exports: [RouterModule],
})
export class CambioSaldoCajaRoutingModule {}
