import * as moment from 'moment';
import { Filtro } from 'src/app/common/model/Filtro';
import { EstadoDocumento } from 'src/app/model/EstadoDocumento';
import { PedidoResumen } from 'src/app/model/Pedido';
import { Pedido } from './../../model/Pedido';
import { Descriptivo } from './Descriptivo';
export class FiltroPedido extends Filtro {
    aplica(p: PedidoResumen | Pedido): boolean {
        return p && (!this.fechaDesde || moment(p.fecha).isSameOrAfter(this.fechaDesde, "date")) &&
            (!this.fechaHasta || moment(p.fecha).isSameOrBefore(this.fechaHasta, "date")) &&
            (!this.locales?.length || this.locales.some(l => l.id == p.local?.id)) &&
            (!this.estados?.length || this.estados.some(l => l.codigo == p.estado?.codigo)) &&
            (!this.soloImpagas || p.saldo > 0) &&
            (!this.cliente || this.cliente.id == p.cliente?.id) &&
            (!this.tipoEntrega || this.tipoEntrega.id == p.tipoEntrega?.id)


    }

    constructor(name: string,
        page = 0, size = 20, sort = "fecha", order = 2, guardable: boolean = true) {
        super(name, {
            cliente: "Cliente",
            responsable: "Responsable",
            fechaDesde: "Desde",
            fechaHasta: "Hasta",
            local: "Punto de venta"
        }, page, size, sort, order, guardable);

    }
    protected _fechaDesde: Date;
    protected _fechaHasta: Date;
    protected _cliente: Descriptivo;
    protected _responsable: Descriptivo;

    protected _estados: Descriptivo[] = [];

    protected _soloImpagas: boolean;

    protected _soloSinComprobante: boolean;

    protected _centros: Descriptivo[] = [];
    protected _tipoEntrega: Descriptivo;
    public get tipoEntrega(): Descriptivo {
        return this._tipoEntrega;
    }
    public set tipoEntrega(v: Descriptivo) {
        if (v != this._tipoEntrega) {
            this._tipoEntrega = v;
            this.update();
        }
    }
    public get centros(): Descriptivo[] {
        return this._centros;
    }
    public set centros(v: Descriptivo[]) {
        if (v != this._centros) {
            this._centros = v;
            this.update();
        }
    }
    protected _locales: Descriptivo[];
    public get locales(): Descriptivo[] {
        return this._locales;
    }
    public set locales(v: Descriptivo[]) {
        if (v != this._locales) {
            this._locales = v;
            this.update();
        }
    }
    protected _excluirRapidos: boolean = true;
    public get excluirRapidos(): boolean {
        return this._excluirRapidos;
    }
    public set excluirRapidos(v: boolean) {
        if (v != this._excluirRapidos) {
            this._excluirRapidos = v;
            this.update();
        }
    }
    public tieneTodos(estados: EstadoDocumento[]) {

        return !estados?.length || !this.estados?.length || this.estados.every(e => estados.some(ee => ee.id == e.id))
    }
    public toggleTodos(estados: EstadoDocumento[]) {
        this.estados = estados;
    }

    public get local(): Descriptivo {
        return this._locales ? this.locales[0] : null;
    }
    public set local(v: Descriptivo) {
       this.locales = v? [v]:[];
    }

    public get centro(): Descriptivo {
        return this._centros ? this._centros[0] : null;
    }
    public set centro(v: Descriptivo) {
        this.centros = v? [v]:[];
    }

    public get soloSinComprobante(): boolean {
        return this._soloSinComprobante;
    }
    public set soloSinComprobante(v: boolean) {
        if (v != this._soloSinComprobante) {
            this._soloSinComprobante = v;
            this.update();
        }
    }
    public get soloImpagas(): boolean {
        return this._soloImpagas;
    }
    public set soloImpagas(v: boolean) {
        if (v != this._soloImpagas) {
            this._soloImpagas = v;
            this.update();
        }
    }


    public get cliente(): Descriptivo {
        return this._cliente;
    }
    public set cliente(v: Descriptivo) {
        this._cliente = v;
        this.update();
    }

    public get responsable(): Descriptivo {
        return this._responsable;
    }
    public set responsable(v: Descriptivo) {
        this._responsable = v;
        this.update();
    }

    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {
        this._fechaDesde = v;
        this.update();
    }

    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {
        this._fechaHasta = moment(v).endOf('day').toDate();
        this.update();
    }

    public get estados(): Descriptivo[] {
        return this._estados;
    }
    public set estados(v: Descriptivo[]) {
        this._estados = v;
        this.update();
    }

    public toggleEstado(estado) {
        if (this.tieneEstado(estado)) {
            this._estados = this._estados.filter(e => e?.codigo != estado?.codigo);
        } else {
            this._estados.push(estado);
        }
        this.update(true);
    }

    public tieneEstado(estado) {
        return this.estados.some(e => e?.codigo == estado?.codigo);
    }


    public clean() {
        let cleanVal = {
            cliente: null,
            responsable: null,
            fechaDesde: moment().startOf('month').toDate(),
            fechaHasta: null,
            estados: [],
            soloSinComprobante: false,
            soloImpagas: false,
            local: null,
            excluirRapidos: true,
            centros: [],
            locales: [],
            tipoEntrega: null


        }
        super.clean(false)
        this.patchValue(cleanVal);

    }

    public patchValue(v: any) {
        this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null;
        this._responsable = v.responsable ? Descriptivo.fromData(v.responsable) : null;
        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null;
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null;
        this._estados = v.estados ? v.estados.map(e => Descriptivo.fromData(e)) : [];
        this._soloImpagas = v.soloImpagas;
        this._centros = v.centros ? v.centros.map(c => Descriptivo.fromData(c)) : [];
        this._locales = v.locales ? v.locales.map(c => Descriptivo.fromData(c)) : [];
        this._soloSinComprobante = v.soloSinComprobante;
        this._excluirRapidos = v.excluirRapidos;
        this._tipoEntrega = Descriptivo.fromData(v.tipoEntrega)

        super.patchValue(v);
    }

    public valid(): boolean {
        return this.fechaDesde != undefined;
    }
}