import * as moment from 'moment';
import { Descriptivo } from './Descriptivo';
import { Filtro } from './Filtro';

export class FiltroMovimiento extends Filtro {

    constructor(name: string,
        filterMap = {
            tipoMovimiento: "Tipo Movimiento",
            responsable: "Responsable",
            fechaDesde: "Desde",
            fechaHasta: "Hasta",
            formaPago: "Forma de pago"
        }) {
        super(name, filterMap);

        this.guardable = true;
    }

    private _caja: Descriptivo;
    private _tipoMovimiento: Descriptivo;
    private _responsable: Descriptivo;
    private _fechaDesde: Date;
    private _fechaHasta: Date;
    private _formaPago: Descriptivo;
    public get formaPago(): Descriptivo {
        return this._formaPago;
    }
    public set formaPago(v: Descriptivo) {
        if (v != this._formaPago) {
            this._formaPago = v;
            this.update();
        }
    }

    public get caja(): Descriptivo {
        return this._caja;
    }
    public set caja(v: Descriptivo) {
        this._caja = v;
        this.update();
    }

    public get tipoMovimiento(): Descriptivo {
        return this._tipoMovimiento;
    }
    public set tipoMovimiento(v: Descriptivo) {
        this._tipoMovimiento = v;
        this.update();
    }

    public get responsable(): Descriptivo {
        return this._responsable;
    }
    public set responsable(v: Descriptivo) {
        this._responsable = v;
        this.update();
    }

    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {

        this._fechaDesde = moment(v).startOf('day').toDate();
        this.update();
    }

    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {
        this._fechaHasta = moment(v).endOf('day').toDate();
        this.update();
    }

    public clean() {
        let cleanVal = {
            searchStr: "",
            tipoMovimiento: null,
            responsable: null,
            fechaDesde: moment().startOf('month').toDate(),
            fechaHasta: null,
            formaPago: null,
            sortField: "fecha",
            sortOrder: 2
        }
        this.patchValue(cleanVal);
    }

    public patchValue(v: any) {
        this._searchStr = v.searchStr;
        this._caja = v.caja ? Descriptivo.fromData(v.caja) : null;
        this._tipoMovimiento = v.tipoMovimiento ? Descriptivo.fromData(v.tipoMovimiento) : null;
        this._responsable = v.responsable ? Descriptivo.fromData(v.responsable) : null;
        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null;
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null;
        this._formaPago = v.formaPago ? Descriptivo.fromData(v.formaPago) : null;
        this.update();
    }

    public valid(): boolean {
        return this.fechaDesde != undefined;
    }
}