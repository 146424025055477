import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject, throwError } from 'rxjs'
import { catchError, switchMap, filter, take } from 'rxjs/operators'
import { AuthService } from '../services/auth.service'
import { GlobalInjector } from './../common/GlobalInjector'
import { LoadingService } from './../common/services/loading-data-service.service'

@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
    private loadingService: LoadingService
    private refreshing = false
    private _refreshSubject: Subject<any> = new Subject()
    constructor(private authenticationService: AuthService) {
        this.loadingService = GlobalInjector.InjectorInstance.get(LoadingService)
    }
    private _checkTokenExpiryErr(authRequest, error: HttpErrorResponse): boolean {
        return !authRequest.url.includes('login') && error.status && error.status === 401 && error.error && error.error.message === 'TokenExpired'
    }
    private addToken(request: HttpRequest<any>, token) {
        const req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        })
        return req
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (this._checkTokenExpiryErr(request, err)) {
                    if (!this.refreshing) {
                        this._refreshSubject.next(null)
                        this.refreshing = true
                        return this.authenticationService.refreshToken().pipe(
                            switchMap((token: any) => {
                                this.refreshing = false
                                this._refreshSubject.next(token.token)
                                return next.handle(this.addToken(request, token.token))
                            }),
                            catchError((err) => {
                                this.refreshing = false

                                this.authenticationService.logout()
                                return throwError(err)
                            })
                        )
                    }
                    return this._refreshSubject.pipe(
                        filter((token) => token !== null),
                        take(1),
                        switchMap((token) => next.handle(this.addToken(request, token)))
                    )
                }
            })
        )
    }
}
