import { IOpcionChip } from 'src/app/common/components/selector-multiple-chips/IOpcionChip'
import { StringUtils } from './../utils/string-utils'
import { Descriptivo } from './Descriptivo'
import { ResumenModel } from './ResumenModel'
export class Parametrico extends Descriptivo implements ResumenModel, IOpcionChip {
    public static UNIDAD_PRODUCTO_ID = 'unidadproducto'
    static NUEVO: Parametrico = new Parametrico(null, 'NN', 'Nuevo', true, true, 0, 'pi pi-plus')
    public searchStr: string
    constructor(
        public id?: number,
        codigo?: string,
        descripcion?: string,
        public habilitado: boolean = true,
        public esSistema: boolean = false,
        public peso: number = 99,
        public icon?: string,
        public esDefault: boolean = false,
        public centro?: Descriptivo
    ) {
        super(codigo, descripcion)
        this.searchStr = StringUtils.getSinTildes([this.id, this.codigo, this.descripcion].join('_'))
    }
    entityName(): string {
        return this.tipoParametro()
    }

    public tipoParametro(): string {
        return 'parametrico'
    }
    public static fromData(data: any) {
        if (!data) return null

        let o: Parametrico = new Parametrico(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.icono,
            data.esDefault,
            Descriptivo.fromData(data.centro)
        )
        return o
    }

    public get iconoClass() {
        return this.icon
    }
}
