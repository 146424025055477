import { Injectable } from '@angular/core';
import { GestorCentroAG } from './GestorCentroAG';






@Injectable({
    providedIn: 'root'
})
export class VentasAG extends GestorCentroAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_VENTAS"]) };



}