import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { share } from 'rxjs/operators'
import { DescriptivosService } from 'src/app/common/services/descriptivos.service'
import { Producto } from 'src/app/model/Producto'
import { FiltroRanking } from '../model/FiltroRanking'
import { GlobalInjector } from './../common/GlobalInjector'
import { Descriptivo } from './../common/model/Descriptivo'
import { LoadingService } from './../common/services/loading-data-service.service'
import { MessagesService } from './../common/services/messages-data-service.service'
import { FiltroProducto } from './../model/FiltroProducto'
import { ProductoRanking } from './../model/Producto'
import { ProductoCabecera } from './../model/ProductoCabecera'
import { ProductoNavegable } from './../model/ProductoNavegable'
import { AuthService } from './auth.service'
import { NovedadService } from './novedades.service'
import { StorageService } from './storage.service'

@Injectable({
    providedIn: 'root',
})
export class ProductoService extends DescriptivosService<Producto> {

    public filtro: FiltroProducto

    public baseName(): string {
        return 'producto'
    }
    public parseToEnt(data: any): Producto {
        return Producto.fromData(data)
    }
    public newEnt(): Producto {
        return new Producto()
    }
    private _data: ProductoCabecera[] = []
    public data: BehaviorSubject<ProductoCabecera[]> = new BehaviorSubject([])
    private updating
    private storageService: StorageService
    constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
        super(messServ)
        this.storageService = GlobalInjector.InjectorInstance.get(StorageService)
        this.loadData()
        this.data.subscribe((d) => {
            if (d?.length) {
                this.storageService.saveCache(this.baseName(), d)
            }
        })
    }
    public getData(): Promise<ProductoCabecera[]> {
        if (!this.updating && !this._data) {
            return this.loadData()
        } else if (this.updating) {
            return this.updating
        } else {
            return Promise.resolve(this._data)
        }
    }
    private loadData() {
        if (!this.updating)
            this.updating = this.fillCabeceras(new FiltroProducto(null), new LoadingService())
                .then((r: any) => {
                    this._data = [...r]
                    this.data.next(this._data)
                    return this._data
                })
                .finally(() => {
                    this.updating = null
                })

        if (!this._data?.length) {
            return this.storageService
                .getCache(this.baseName())
                .then((l) => {
                    if (!this._data || this._data?.length == 0) {
                        if (l) {
                            this._data = this.parse(l)
                        } else {
                            this._data = []
                            this.data.next(this._data)
                        }
                        return this._data
                    }
                })
                .then((d) => {
                    if (!d?.length) {
                        return this.updating
                    } else {
                        return d
                    }
                })
        } else {
            return Promise.resolve(this._data)
        }
    }
    public getAdicionables(
        filter: FiltroProducto = new FiltroProducto(null, 0, 100, 'peso,descripcion', 1, false),
        customLoading?: LoadingService
    ): Promise<ProductoCabecera[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('adicionables'), filter.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((p) => ProductoCabecera.fromData(p))
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public getHabilitados = (filter: FiltroProducto = new FiltroProducto(''), customLoading?: LoadingService): Promise<Descriptivo[]> => {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        filter.habilitados = true
        return this.getData().then((p) => {
            if (customLoading) customLoading.susLoadingCount()
            else this.loadingService.susLoadingCount()
            return filter.apply(p).map((prod) => Descriptivo.fromData(prod))
        })
    }
    private fillCabeceras = (filter: FiltroProducto = new FiltroProducto(''), customLoading?: LoadingService): Promise<ProductoCabecera[]> => {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()

        filter.size = null
        return this.http
            .post(this.getBaseURL() + 'cabeceras', filter.json)
            .toPromise()
            .then((r: any) => {
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()
                return r.map((p) => ProductoCabecera.fromData(p))
            })
    }
    public getCabeceras = (filter: FiltroProducto = new FiltroProducto(''), customLoading?: LoadingService): Promise<ProductoCabecera[]> => {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.getData().then((p) => {
            if (customLoading) customLoading.susLoadingCount()
            else this.loadingService.susLoadingCount()
            return filter.apply(p)
        })
    }
    getRapidos = (
        filter: FiltroProducto = new FiltroProducto(null, 0, 1000, null, null, false),
        customLoading?: LoadingService
    ): Promise<ProductoCabecera[]> => {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        filter.habilitados = true
        filter.soloRapidos = true
        return this.getData().then((p) => {
            if (customLoading) customLoading.susLoadingCount()
            else this.loadingService.susLoadingCount()
            return filter.apply(p).map((prod) => ProductoCabecera.fromData(prod))
        })
    }
    getMultiple(ids: number[], customLoading?: LoadingService) {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('multipleById'), ids)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((p) => Producto.fromData(p))
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getRanking = (
        filtro: FiltroRanking = new FiltroRanking(null, 0, 1000, null, null, false),
        customLoading?: LoadingService
    ): Promise<ProductoRanking[]> => {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('ranking'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((p) => ProductoRanking.fromData(p))
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    habilitar = (id: number, customLoading?: LoadingService): Promise<Producto> => {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('habilitar'), id)
            .toPromise()
            .then((r) => {
                return this.parseSingle(r)
            })
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }
    deshabilitar = (id: number, customLoading?: LoadingService): Promise<Producto> => {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('deshabilitar'), id)
            .toPromise()
            .then((r) => {
                return this.parseSingle(r)
            })
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }
    cambiarFavorito = (item: ProductoCabecera | Producto, esFavorito: boolean, customLoading?: LoadingService): Promise<Producto> => {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('cambiarFavorito'), { id: item.id, esFavorito: esFavorito })
            .toPromise()
            .then((r) => {
                return this.parseSingle(r)
            })
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }

    getAnteriorProximo(item: number) {
        if (!this.filtro) return Promise.resolve(null)
        return this.http
            .post(this.getBaseURL(`navegables/${item}`), this.filtro.json)
            .toPromise()
            .then((r) => {
                return ProductoNavegable.fromData(r)
            })
    }
    
    public async guardarConArchivo(e: Producto, file: File, customLoading?: LoadingService) {
        const isUpdate = e.id>= 1;
        return super.guardarConArchivo(e, file, customLoading).then(v=> {this.updateCache(isUpdate,v)
        return v})
    }
    public async guardar(e: Producto, customLoading?: LoadingService) {
        const isUpdate = e.id>= 1;
        return super.guardar(e, customLoading).then(v=> {this.updateCache(isUpdate,v)
        return v})
    }
    private updateCache(isUpdate:boolean,v:Producto){
            if (!isUpdate) {
                this._data.push(ProductoCabecera.fromData(v))
            } else {
                this._data[this._data.findIndex((d) => d.id == v.id)] = ProductoCabecera.fromData(v)
            }
            this.data.next(this._data)
    }


    async getCabeceraById(key: any): Promise<ProductoCabecera> {
        return await this._data.find( p => p.id == key)
    }
    eliminar(id: number, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .delete(this.getBaseURL() + id)
            .toPromise()
            .then(
                (r) => {
                    this.handleOk(r, customLoading)
                    this.onChange.next(null)
                    this._data.filter(p => p.id === id)[0].habilitado = false;
                },
                (e) => this.handleError(e, customLoading)
            )
    }
}
