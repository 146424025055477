import { Descriptivo } from 'src/app/common/model/Descriptivo';




export class Cliente extends Descriptivo {
	constructor(id?: number, codigo?: string, descripcion?: string,
		public nombre?: string,
		public tipoCliente?: Descriptivo,
		public numeroDocumento?: string,
		public email?: string,
		public telefono?: string,
		public comentario?: string,
		public activo?: boolean,
		public facebook?: string,
		public instagram?: string,
		public iva?: Descriptivo,
		public domicilio?: string,
		public formaPagoDefault?: Descriptivo,
		public descuento?: number,
		public tipoDocumento?: Descriptivo,
		public razonSocial?: string,
		public localesHabilitados: Descriptivo[] = [],
		public esConsumidorFinal?: boolean


	) {
		super(codigo, nombre ? nombre : razonSocial, id)
	}

	public static fromData(data: any): Cliente {
		if (!data) return null;
		const o: Cliente = new Cliente(
			data.id,
			data.codigo,
			data.descripcion,

			data.nombre,
			Descriptivo.fromData(data.tipoCliente),
			data.numeroDocumento,
			data.email,
			data.telefono,
			data.comentario,
			data.activo,
			data.facebook,
			data.instagram,
			Descriptivo.fromData(data.iva),
			data.domicilio,
			Descriptivo.fromData(data.formaPagoDefault),
			data.descuento,
			Descriptivo.fromData(data.tipoDocumento),
			data.razonSocial,
			data.localesHabilitados ? data.localesHabilitados.map(l => Descriptivo.fromData(l)) : [],
			data.esConsumidorFinal
		);

		return o;

	}

}
export class ClienteResumen {
	constructor(public id?: number,
		public nombre?: string,
		public tipoCliente?: Descriptivo,
		public numeroDocumento?: string,

		public email?: string,
		public telefono?: string,
		public comentario?: string,
		public activo?: boolean,
		public importe: number = 0,
		public saldo: number = 0,
		public razonSocial?: string


	) {
		if (!razonSocial) {
			this.razonSocial = this.nombre;
		}
	}

	public static fromData(data: any): ClienteResumen {
		if (!data) return null;
		const o: ClienteResumen = new ClienteResumen(
			data.id,
			data.descripcion || data.nombre,
			Descriptivo.fromData(data.tipoCliente),
			data.numeroDocumento,
			data.email,
			data.telefono,
			data.comentario,
			data.activo,
			data.importe,
			data.saldo,
			data.razonSocial
		);

		return o;

	}

	public get descripcion() {
		return this.nombre ? this.nombre : this.razonSocial;
	}
}