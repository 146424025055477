<div class="grid container">
    <div class="col-12" *ngIf="!localElegido">
        <span> Elija un local</span>
    </div>
    <ng-container *ngIf="localElegido">
        <div class="col" *ngFor="let te of tipoEntregaService.habilitados | async">
            <div class="shortcut card" (click)="nuevoPedido(localElegido.id, te.id)">
                <div class="grid align-items-center" pTooltip="{{te.descripcion}}">
                    <div class="col-12 icon" [ngStyle]="{'background-image': 'url('+te.imagenUrl+')'}">


                    </div>
                    <div class="col-12 overflow" *ngIf="config?.rows * config?.cols > 2">
                        <span>{{te.descripcion}}</span>
                    </div>
                </div>

            </div>
        </div>
    </ng-container>
    <loading-panel [loadingService]="customLoading"></loading-panel>
</div>

<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of localService.data | async" (click)="aplicarSeleccion(c)">
            {{c.descripcion}}
        </li>
    </ul>
</p-overlayPanel>
