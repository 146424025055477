import { Injectable } from '@angular/core'
import { share } from 'rxjs/operators'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { Comprobante } from 'src/app/model/Comprobante'
import { IAccionesDocumento } from './../common/services/IAccionesDocumento'
import { LoadingService } from './../common/services/loading-data-service.service'
import { Cobranza } from './../model/Cobranza'
import { ComprobanteResumen } from './../model/Comprobante'
import { FiltroComprobante } from './../model/FiltroComprobantes'
@Injectable({
    providedIn: 'root',
})
export class ComprobanteService extends ServicioAbstract<Comprobante> implements IAccionesDocumento {

    public accionAnular(documento: Comprobante) {
        return this.eliminar(documento.id)
    }
    public esAccionAnular(documento: Comprobante): boolean {
        return documento.estado && documento.estado.codigo != 'F' && documento.estado.codigo != 'AN' && !documento.numeroDocumento
    }
    toFile = (x, nombre, sub) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        /* if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }*/

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)

        var link = document.createElement('a')
        link.href = data
        link.download = nombre ? nombre + '.pdf' : 'comprobante.pdf'
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))

        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            link.remove()
            sub.unsubscribe()
        }, 100)
    }
    descargar(id: number, nombre?: string, customLoading?: LoadingService) {
        var sub = this.http.get(this.getBaseURL('descargar/' + id), { responseType: 'blob' }).subscribe((r) => this.toFile(r, nombre, sub))
    }
    descargarToken(token: string, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        const obst = this.http.get(this.getApiURL() + 'public/comprobante/descargar/' + token, { responseType: 'blob' })

        var sub = obst.subscribe((r) => {
            if (customLoading) {
                customLoading.susLoadingCount()
            } else this.loadingService.susLoadingCount()
            this.toFile(r, null, sub)
        })
        return obst.toPromise()
    }
    getResumenAll(filtro: FiltroComprobante = new FiltroComprobante(null), customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('all'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((c) => ComprobanteResumen.fromData(c))
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    verPDF(id: number, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        var sub = this.http.get(this.getBaseURL('descargar/' + id), { responseType: 'blob' }).subscribe((x) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([x], { type: 'application/pdf' })
            var url = URL.createObjectURL(newBlob)
            window.open(url, '_blank')
            if (customLoading) {
                customLoading.susLoadingCount()
            } else this.loadingService.susLoadingCount()
            sub.unsubscribe()
        })
    }
    getImpagos(filtro: any, customLoading?: LoadingService): Promise<Comprobante[]> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('impagos'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((f) => Comprobante.fromData(f))
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    enviarComprobante(itemEditado: ComprobanteResumen | Comprobante, destinatario: string, customLoading?: LoadingService): Promise<boolean> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('enviarComprobante'), { idEntidad: itemEditado.id, destinatario: destinatario })
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    public getCobros(idComprobante: number, customLoading?: LoadingService): Promise<Cobranza[]> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .get(this.getBaseURL('cobros/' + idComprobante))
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((f) => Cobranza.fromData(f))
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public getTotales(filtro: FiltroComprobante, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('totales'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    getByIds(ids: number[], customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('byIds'), ids)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r.map((f) => ComprobanteResumen.fromData(f))
                },
                (e) => this.handleError(e, customLoading)
            )
    }

    public baseName(): string {
        return 'comprobante'
    }
    public parseToEnt(data: any): Comprobante {
        return Comprobante.fromData(data)
    }
    public newEnt(): Comprobante {
        return new Comprobante()
    }

    public getTotalImpuestos(comprobante: Comprobante): Map<number, { descripcion: string; valor: number }> {
        let totalImp: Map<number, { descripcion: string; valor: number; total: number }> = new Map()

        comprobante.items.forEach((item) => {
            if (!totalImp.has(item.tipoIVA.id)) {
                totalImp.set(item.tipoIVA.id, { descripcion: item.tipoIVA.descripcion, valor: 0, total: 0 })
            }
            const toModify = totalImp.get(item.tipoIVA.id)
            toModify.total = toModify.total + item.subtotalNeto
            toModify.valor = toModify.valor + item.impuestos * item.cantidad
        })

        return totalImp
    }

    cambiarTipo(comprobante: ComprobanteResumen,customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('cambiarTipo'), comprobante)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )    }

}
