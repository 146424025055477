import { NgModule } from '@angular/core'
import { CommonComponentsModule } from './../../common/common-components.module'
import { CoreModule } from './../../common/core.module'
import { GenericParametricoComponent } from './generic-parametrico/generic-parametrico.component'
import { ParametricosComponent } from './parametricos/parametricos.component'

@NgModule({
    imports: [CommonComponentsModule, CoreModule],
    providers: [],
    declarations: [ParametricosComponent, GenericParametricoComponent],
    exports: [ParametricosComponent, GenericParametricoComponent],
})
export class ParametricoModule {}
