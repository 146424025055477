import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmationService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { TraspasoDeCaja } from 'src/app/model/TraspasoDeCaja'
import { CajaService } from 'src/app/services/caja.service'
import { FormaPagoService } from 'src/app/services/forma-pago.service'
import { TraspasoDeCajaService } from 'src/app/services/traspaso-de-caja.service'
import { SessionComponent } from './../../../common/components/session-component.component'

@Component({
    selector: 'gestor-traspaso-de-caja',
    templateUrl: 'gestor-traspaso-de-caja.component.html',
    styleUrls: ['gestor-traspaso-de-caja.component.less'],
})
export class GestorTraspasoDeCajaComponent extends SessionComponent implements OnDestroy, OnInit {
    private routeSub: Subscription
    @Input()
    public item: TraspasoDeCaja
    @Input()
    public goBack: boolean = true
    @Input()
    public isModal: boolean = false
    @Input()
    public cajaEditable: boolean = true
    @Input()
    public readonly: boolean = false

    @Output()
    public onGuardado: EventEmitter<TraspasoDeCaja> = new EventEmitter<TraspasoDeCaja>()

    @Output()
    public onCancelado = new EventEmitter()

    public usuarioOptions: Descriptivo[] = []
    public cajaOptions: Descriptivo[] = []

    constructor(
        messagesService: MessagesService,
        public service: TraspasoDeCajaService,
        private route: ActivatedRoute,
        private router: Router,
        private confService: ConfirmationService,
        private cajaService: CajaService,
        public formaPagoService: FormaPagoService
    ) {
        super(messagesService)
    }

    public handleGuardado(item) {
        this.onGuardado.emit(item)
    }
    public handleCancelar(item) {
        this.onCancelado.emit()
    }

    public async isValid() {
        return true
    }

    public onEdit(event) {
        if (this.item?.id) this.router.navigate(["'traspaso-de-caja/edit"], { queryParams: { id: this.item.id } })
    }

    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )
        if (this.route.snapshot.data?.parent == 'traspaso-de-caja') {
            this.routeSub = this.route.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : 'ES').then((r) => {
                        this.item = r
                    })
                } else {
                    this.item = this.service ? this.service.newEnt() : null
                    this.item['codigoIdioma'] = this.translateService.idioma ? this.translateService.idioma : 'ES'
                }
            })
        }
        this.cajaService.getAll().then((r) => {
            this.cajaOptions = r
        })
    }

    public get esEditable() {
        return this.esAdministrador
    }

    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }
}
