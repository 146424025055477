import { Descriptivo } from 'src/app/common/model/Descriptivo';




export class Insumo extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public esCostoFijo?: boolean,
        public esEstoqueable?: boolean,
        public categoria?: Descriptivo,
        public unidad?: Descriptivo,
        public habilitado?: boolean,
        public esReventa?: boolean,
        public centro?: Descriptivo,
        public localesHabilitados: Descriptivo[] = []


    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): Insumo {
        if (!data) return null;
        const o: Insumo = new Insumo(
            data.id,
            data.codigo,
            data.descripcion,
            data.esCostoFijo,
            data.esEstoqueable,
            Descriptivo.fromData(data.categoria),
            Descriptivo.fromData(data.unidad),
            data.habilitado,
            data.esReventa,
            Descriptivo.fromData(data.centro),
            data.localesHabilitados ? data.localesHabilitados.map(l => Descriptivo.fromData(l)) : []
        );

        return o;

    }

}