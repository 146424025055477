import { StockControlService } from 'src/app/services/stock-control.service';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Insumo } from './Insumo';
import { ProductoCabecera } from './ProductoCabecera';
import { StockControl } from './StockControl';



export class StockInsumo extends Descriptivo {
    constructor(
        public id?: number,
        public insumo?: Insumo,
        public cantidad?: number,
        public productoAsociado?: ProductoCabecera,
        public deposito?: Descriptivo,
        public stockControl?: StockControl,
        public cantidadAdvertencia?: number,
        public cantidadCritica?: number


    ) {
        super(id + "", insumo.descripcion, id)
    }
    public static fromData(data: any): StockInsumo {
        if (!data) return null;
        const o: StockInsumo = new StockInsumo(
            data.id,
            Insumo.fromData(data.insumo),
            data.cantidad,
            ProductoCabecera.fromData(data.productoAsociado),
            Descriptivo.fromData(data.deposito),
            StockControl.fromData(data.stockControl),
            data.cantidadAdvertencia,
            data.cantidadCritica
        );

        return o;

    }

    public get cantidadUnidad() {
        return this.cantidad + " " + (this.insumo?.unidad ? this.insumo.unidad.codigo : 'un');
    }

    public get responsableControl() {
        return this.stockControl?.responsable?.nombre;
    }

    public get fechaControl() {
        return this.stockControl?.fechaControlStr;
    }


}