import { Descriptivo } from 'src/app/common/model/Descriptivo'

export class MenuProducto {
    constructor(
        public idMenu: number,
        public descripcionMenu: string,
        public idCategoriaMenu: number,
        public descripcionCategoriaMenu: string,
        public idProducto?: number
    ) {}
    public static fromData(data: any): MenuProducto {
        if (!data) return null
        const o: MenuProducto = new MenuProducto(data.idMenu, data.descripcionMenu, data.idCategoriaMenu, data.descripcionCategoriaMenu, data.idProducto)

        return o
    }
}

export class MenuProductoCategoria {
    constructor(
        public idMenu: number,
        public descripcionMenu: string,
        // public categorias: {
        //     idCategoria: number
        //     descripcion: string
        //     idProducto?: number
        // }[]
        public categorias: Descriptivo[]
    ) {}
    public static fromData(data: any): MenuProductoCategoria {
        if (!data) return null
        const o: MenuProductoCategoria = new MenuProductoCategoria(data.idMenu, data.descripcionMenu, data.categorias)

        return o
    }

    public set singleCategoria(cat:Descriptivo){
        this.categorias = cat? [cat]: [];
    }
    public get singleCategoria(){
        return this.categorias? this.categorias[0] : null;
    }
}
