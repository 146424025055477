import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { CambioSaldoCaja } from 'src/app/model/CambioSaldoCaja'
import { CambioSaldoCajaService } from 'src/app/services/cambio-saldo-caja.service'
import { FormaPagoService } from 'src/app/services/forma-pago.service'
import { SessionComponent } from './../../../common/components/session-component.component'
import { CajaService } from './../../../services/caja.service'

@Component({
    selector: 'gestor-cambio-saldo-caja',
    templateUrl: 'gestor-cambio-saldo-caja.component.html',
    styleUrls: ['gestor-cambio-saldo-caja.component.less'],
})
export class GestorCambioSaldoCajaComponent extends SessionComponent implements OnDestroy, OnInit {
    private routeSub: Subscription
    @Input()
    public item: CambioSaldoCaja
    @Input()
    public goBack: boolean = true
    @Input()
    public isModal: boolean = false
    @Input()
    public cajaEditable: boolean = true

    @Input()
    public readonly: boolean = false

    @Output()
    public onGuardado: EventEmitter<CambioSaldoCaja> = new EventEmitter<CambioSaldoCaja>()

    @Output()
    public onCancelado = new EventEmitter()

    constructor(
        messagesService: MessagesService,
        public service: CambioSaldoCajaService,
        private route: ActivatedRoute,
        private router: Router,
        public cajaService: CajaService,
        public formaPagoService: FormaPagoService
    ) {
        super(messagesService)
    }

    public handleGuardado(item) {
        this.onGuardado.emit(item)
    }
    public handleCancelar(item) {
        this.onCancelado.emit()
    }

    public async isValid() {
        return true
    }

    public onEdit(event) {
        if (this.item?.id) this.router.navigate(["'cambio-saldo-caja/edit"], { queryParams: { id: this.item.id } })
    }

    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )
        if (this.route.snapshot.data?.parent == 'cambio-saldo-caja') {
            this.routeSub = this.route.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : 'ES').then((r) => {
                        this.item = r
                    })
                } else {
                    if (!this.item && this.service) {
                        this.item = this.service.newEnt()
                    }
                    this.item['codigoIdioma'] = this.translateService.idioma ? this.translateService.idioma : 'ES'
                }
            })
        }
    }
    public get esRetiro() {
        return this.item ? this.item.esRetiro : false
    }
    public get esEditable() {
        return this.esAdministrador
    }

    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }
}
