<div class="grid">
    <div class="col-fix seleccionar-todos" *ngIf="!readonly">
        <p-checkbox [(ngModel)]="allSelected" binary="true" (onChange)="updateSelection($event)"
            ngModelOptions="{standalone:true}" [disabled]="!options?.length || readonly"
            pTooltip="{{'SELECCIONAR_TODOS' | translate}}"> </p-checkbox>
    </div>
    <div class="col">
        <mat-form-field appearance='outline' floatLabel="always">
            <mat-label>{{header| translate}}</mat-label>
            <input class="dummy" name="dummy" hidden matInput>
            <div class="grid">
                <div class="{{checkboxClass}}" *ngFor="let r of options; let i = index ">
                    <p-checkbox [value]="r" [label]="r.label" [(ngModel)]="selected" ngModelOptions="{standalone:true}"
                        [disabled]="readonly">
                    </p-checkbox>
                </div>
            </div>

        </mat-form-field>
    </div>


</div>
