import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { PrimeNGConfig } from 'primeng/api'
import { AppComponent } from './app.component'
import { MenuService } from './app.menu.service'
import { commonAnimations } from './common-animations'
import { slideInAnimation } from './route-animation'

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [slideInAnimation, commonAnimations],
})
export class AppMainComponent implements AfterViewInit, OnInit, OnDestroy {
    topbarMenuActive: boolean

    menuActive: boolean

    staticMenuDesktopInactive: boolean

    mobileMenuActive: boolean

    menuClick: boolean

    mobileTopbarActive: boolean

    topbarRightClick: boolean

    topbarItemClick: boolean

    activeTopbarItem: string

    documentClickListener: () => void

    configActive: boolean

    configClick: boolean

    rightMenuActive: boolean

    menuHoverActive = false

    searchClick = false

    search = false

    currentInlineMenuKey: string

    inlineMenuActive: any[] = []

    inlineMenuClick: boolean

    constructor(public renderer: Renderer2, private menuService: MenuService, private primengConfig: PrimeNGConfig, public app: AppComponent) {}

    ngOnInit() {
        this.menuActive = this.isStatic() && this.isDesktop()
    }
    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
    }
    ngAfterViewInit() {
        // hides the horizontal submenus or top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', () => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null
                this.topbarMenuActive = false
            }

            if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
                this.menuService.reset()
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false
            }

            if (!this.menuClick) {
                if (this.mobileMenuActive) {
                    this.mobileMenuActive = false
                }

                if (this.isOverlay()) {
                    this.menuActive = false
                }

                this.menuHoverActive = false
                this.unblockBodyScroll()
            }

            if (!this.searchClick) {
                this.search = false
            }

            if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
                this.inlineMenuActive[this.currentInlineMenuKey] = false
            }

            this.inlineMenuClick = false
            this.searchClick = false
            this.configClick = false
            this.topbarItemClick = false
            this.topbarRightClick = false
            this.menuClick = false
        })
    }

    onMenuButtonClick(event) {
        this.menuActive = !this.menuActive
        this.topbarMenuActive = false
        this.topbarRightClick = true
        this.menuClick = true

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive
        } else {
            this.mobileMenuActive = !this.mobileMenuActive
            if (this.mobileMenuActive) {
                this.blockBodyScroll()
            } else {
                this.unblockBodyScroll()
            }
        }

        event.preventDefault()
    }

    onTopbarMobileButtonClick(event) {
        this.mobileTopbarActive = !this.mobileTopbarActive
        event.preventDefault()
    }

    onRightMenuButtonClick(event) {
        this.rightMenuActive = !this.rightMenuActive
        event.preventDefault()
    }

    onMenuClick($event) {
        this.menuClick = true

        if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false
        }
    }

    onSearchKeydown(event) {
        if (event.keyCode == 27) {
            this.search = false
        }
    }

    onInlineMenuClick(event, key) {
        if (key !== this.currentInlineMenuKey) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false
        }

        this.inlineMenuActive[key] = !this.inlineMenuActive[key]
        this.currentInlineMenuKey = key
        this.inlineMenuClick = true
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null
        } else {
            this.activeTopbarItem = item
        }

        if (item === 'search') {
            this.search = !this.search
            this.searchClick = !this.searchClick
        }

        event.preventDefault()
    }

    onTopbarSubItemClick(event) {
        event.preventDefault()
    }

    onRippleChange(event) {
        this.app.ripple = event.checked
        this.primengConfig.ripple = event.checked
    }

    onConfigClick(event) {
        this.configClick = true
    }
    isTablet() {
        const width = window.innerWidth
        return width <= 1024 && width > 640
    }
    isDesktop() {
      //  return window.innerWidth > 991
        return window.innerWidth > 1024
    }

    isMobile() {
      //  return window.innerWidth <= 991
        return window.innerWidth <= 1024
    }

    isOverlay() {
        return this.app.menuMode === 'overlay' || !this.isDesktop()
    }

    isStatic() {
        return this.app.menuMode === 'static' && this.isDesktop();
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal'
    }

    isSlim() {
        return this.app.menuMode === 'slim'
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll')
        } else {
            document.body.className += ' blocked-scroll'
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll')
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
        }
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener()
        }
    }
}
