import { BehaviorSubject } from 'rxjs'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { BufferedDescriptivoService } from 'src/app/services/BufferedDescriptivoService'
import { AuthService } from './../../services/auth.service'
import { NovedadService } from './../../services/novedades.service'
import { Filtro } from './../model/Filtro'
import { Parametrico } from './../model/Parametrico'
import { LoadingService } from './loading-data-service.service'

export abstract class ParametricoService<E extends Parametrico> extends BufferedDescriptivoService<E> {
    constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
        super(novedadesService, authService, messServ)
        this.data.subscribe((r) => {
            this.habilitados.next(r.filter((v) => v['habilitado']))
        })
    }

    public habilitados: BehaviorSubject<E[]> = new BehaviorSubject([])

    public getHabilitados(filtro: Filtro = new Filtro(null,{},0,1000,"peso,descripcion",1), customLoading?: LoadingService): Promise<E[]> {
        if (!filtro) {
            return this.getData().then((r) => r.filter((a) => a['habilitado'] == true))
        }
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'parametrico/all', filtro.json)
            .toPromise()
            .then((r:any) => {
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()
                return this.parse(filtro.apply(r))
            })
            .catch((e) => {
                return this.handleError(e, customLoading)
            })
    }

    private _default: E

    public get default() {
        if (!this._default) this._default = this._data ? this._data.filter((d) => d.esDefault)[0] : null
        return this._default
    }
    public async getDefault(): Promise<E> {
        if (this.updating) {
            return this.updating.then((d) => {
                return Promise.resolve(this.default)
            })
        } else {
            return Promise.resolve(this.default)
        }
    }
    public habilitar(id: number, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'habilitar', id)
            .toPromise()
            .then((r) => {
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()

                return this.parseSingle(r)
            })
            .catch((e) => this.handleError(e, customLoading))
    }
    public deshabilitar(id: number, customLoading?: LoadingService) {
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + 'deshabilitar', id)
            .toPromise()
            .then((r) => {
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()

                return this.parseSingle(r)
            })
            .catch((e) => this.handleError(e, customLoading))
    }

    protected loadData(customLoading?: LoadingService): Promise<E[]> {
        if (this.updating) return this.updating
        const $this = this
        this.updating = this.getAll(new Filtro(null, {}, 0, 3000, 'peso,descripcion', 1), customLoading)
            .then((r) => {
                $this._data = [...r]
                $this.cantidad = $this._data.length
                $this.data.next(this._data)
                $this._default = $this._data.filter((d) => d.esDefault)[0]
                return $this._data
            })
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
                $this.updating = null
            })

        return $this.updating
    }
}
