import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { CobroConCaja } from './CobroConCaja'
import { FormaPago } from './FormaPago'
import { Moneda } from './Moneda'
import { TipoCuotasTarjeta } from './TipoCuotasTarjeta'
import { TipoTarjetaCredito } from './TipoTarjetaCredito'

export class CobroTarjetaCredito extends CobroConCaja {
    public static TIPO = 'T'

    constructor(
        id?: number,
        formaPago?: FormaPago,
        comprobante?: string,
        monto?: number,
        caja?: Descriptivo,
        moneda?: Moneda,
        cotizacion: number = 1,
        public tipoTarjeta?: TipoTarjetaCredito,
        public cantidadCuotas?: TipoCuotasTarjeta,
        public numeroTarjeta?: string,
        public idPago?: string
    ) {
        super(id, formaPago, comprobante, monto, caja, moneda, cotizacion)
        this.tipoCobro = CobroTarjetaCredito.TIPO
    }

    public static fromData(data: any): CobroTarjetaCredito {
        if (!data) return null
        const o: CobroTarjetaCredito = new CobroTarjetaCredito(
            data.id,
            FormaPago.fromData(data.formaPago),
            data.comprobante,
            data.monto,
            Descriptivo.fromData(data.caja),
            Moneda.fromData(data.moneda),
            data.cotizacion,
            TipoTarjetaCredito.fromData(data.tipoTarjeta),
            TipoCuotasTarjeta.fromData(data.cantidadCuotas),
            data.numeroTarjeta,
            data.idPago
        )

        return o
    }
}
