import { animate, style, transition, trigger } from '@angular/animations';
export const commonAnimations = [
    trigger('appear', [

        transition(
            ':enter',
            [
                style({ opacity: 0, 'max-height': "1px" }),
                animate('0.2s ease-in',
                    style({ 'max-height': "6em" })),

            ]
        )

    ]),
    trigger('growTop', [

        transition(
            ':enter',
            [
                style({ "max-height": "1px", "overflow": "hidden" }),
                animate('0.2s ease-in-out',
                    style({ "max-height": "100vh", "overflow": "hidden" }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ "max-height": "100vh", "overflow": "unset" }),
                animate('0.2s ease-in-out',
                    style({ "max-height": "1px", "overflow": "hidden" }))
            ]
        )

    ]),
    trigger('vanish', [

        transition(
            ':enter',
            [
                style({ opacity: 0 }),
                animate('0.2s ease-in-out',
                    style({ opacity: 1 }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ opacity: 1 }),
                animate('0.2s ease-in-out',
                    style({ opacity: 0 }))
            ]
        )

    ]),
]
    ;
