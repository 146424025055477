import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2'
import { MenuItem } from 'primeng/api'
import { filter } from 'rxjs/operators'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { LocalService } from 'src/app/services/local.service'
import { AppComponent } from './../../app.component'
import { BreadcrumbService } from './../../breadcrumb.service'
import { SessionComponent } from './../../common/components/session-component.component'
import { ListaPreciosService } from './../../lista-precios/services/lista-precios.service'
import { MenuDelDiaService } from './../../services/menu-del-dia.service'
import { TipoEntregaService } from './../../services/tipo-entrega.service'
import { DashboardService } from './dashboard-element/dashboard.service'
import { DashboardConfiguration } from './dashboard-element/DashboardConfiguration'

@Component({
    selector: 'eventos-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less'],
})
export class HomeComponent extends SessionComponent implements OnInit {
    public titulo: string = ''

    public puntosVenta: Descriptivo[] = []
    constructor(
        private router: Router,
        messagesService: MessagesService,
        private menuService: MenuDelDiaService,
        public dbService: DashboardService,
        public localService: LocalService,
        public tipoEntregaService: TipoEntregaService,
        public lService: ListaPreciosService,
        private appComponent: AppComponent,
        private breadcrumbService: BreadcrumbService
    ) {
        super(messagesService)
        this.breadcrumbService.setItems([])
    }
    public configs: DashboardConfiguration[] = []
    public crearComponente = (val: string) => {
        this.configs.push(DashboardService.newConfiguracion(val))
    }
    public delete = (config) => {
        this.configs.splice(this.configs.indexOf(config), 1)
    }
    public loadService: LoadingService = new LoadingService()
    public opcionesDashboard: MenuItem[] = []
    public dbEditable: boolean = false
    public toggleEditable(event) {
        if (this.dbEditable) {
            this.dbService.configs = [].concat(this.configs)
            this.dbService.guardar()
        }
        this.dbEditable = !this.dbEditable
        this.options.draggable.enabled = this.dbEditable
        this.options.resizable.enabled = this.dbEditable
        this.options.api.optionsChanged()
        
        event.stopPropagation()
    }
    public cancelarEdicion(event) {
        this.dbEditable = false
        this.configs = [].concat(this.dbService.configs.map((v) => Object.assign({}, v)))
        this.options.draggable.enabled = this.dbEditable
        this.options.resizable.enabled = this.dbEditable
        this.options.api.optionsChanged()
        event.stopPropagation()
    }
    ngOnInit() {
        this.titulo = 'Bienvenido ' + this.getUserFullName()
        this.subs.push(
            this.dbService.opciones.pipe(filter((o) => o != undefined)).subscribe((o) => {
                this.opcionesDashboard = o.map((oo) => {
                    return {
                        label: oo.label,
                        command: () => {
                            this.crearComponente(oo.tipo)
                        },
                    }
                })
            })
        )
        this.dbService.configsBS.pipe(filter((c) => c != undefined)).subscribe((c) => {
            this.configs = [].concat(c.map((v) => Object.assign({}, v)))
        })
        this.options = {
            itemChangeCallback: this.itemChanged,
            itemResizeCallback: this.itemResize,
            gridSizeChangedCallback: this.updateSizeChange,
            gridType: GridType.Fixed,
            compactType: CompactType.None,
            margin: 10,
            outerMargin: true,
            mobileBreakpoint: 640,
            minCols: 1,
            maxCols: 100,
            minRows: 1,
            maxRows: 100,
            maxItemCols: 50,
            minItemCols: 1,
            maxItemRows: 50,
            minItemRows: 1,
            maxItemArea: 100,
            minItemArea: 1,
            defaultItemCols: 2,
            defaultItemRows: 1,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            fixedColWidth: 105,
            fixedRowHeight: 60,
            ignoreMarginInRow: false,
            draggable: {
                enabled: false,
                ignoreContentClass: 'gridster-item-content',
                ignoreContent: false,
                dragHandleClass: 'drag-handler',
            },
            resizable: {
                enabled: false,
            },
            swap: false,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: DisplayGrid.OnDragAndResize,
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: false,
        }
    }
    options: GridsterConfig

    updateSizeChange = () => {
        this.options.maxItemRows = this.isMobile() ? 4 : 50
    }
    itemResize = (item, itemComponent) => {}
    itemChanged = (item, itemComponent) => {}
    nuevoPedido(idLocal, idTipoEntrega) {
        this.router.navigate(['pedido/nuevo'], { queryParams: { localId: idLocal, tipoEntregaId: idTipoEntrega } })
    }
    public async irListaPrecios() {
        let lp = await this.lService.getDefault()
        if (!lp) this.error('No hay un listado default')
        this.router.navigate(['lista-precios/vista'], { queryParams: { id: lp.id } })
    }
    public goTo(path) {
        this.router.navigate([path])
    }
    get esDefault() {
        return !this.esAdministrador
    }
}
