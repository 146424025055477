

import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class FileData extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public nombreOriginal?: string,
        public nombreInterno?: string,
        public version?: number,
        public url?: string,
        public tag?: string,


    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): FileData {
        if (!data) return null;
        const o: FileData = new FileData(
            data.id,
            data.codigo,
            data.descripcion,

            data.nombreOriginal,
            data.nombreInterno,
            data.version,
            data.url,
            data.tag,
        );

        return o;

    }

    public get imagen() {
        return this.url;
    }

}