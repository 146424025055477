import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'abm-hijo',
  templateUrl: './abm-hijo.component.html',
  styleUrls: ['./abm-hijo.component.less']
})
export class AbmHijoComponent implements OnInit {

  @Input()
  public items: any[] = [];

  public itemEditado;
  @Input()
  public headerTemplate: TemplateRef<any>;

  @Input()
  public gestor: TemplateRef<any>

  @Input()
  public labelNuevo: string = "Agregar";

  @Input()
  public conPaginado: boolean = false;

  @Input()
  public nuevoTemplate: TemplateRef<any>

  @Input()
  public itemTemplate: TemplateRef<any>;

  @Input()
  public gridTemplate: TemplateRef<any>;

  @Input()
  public visualizable: boolean;
  @Output()
  public onVer: EventEmitter<any> = new EventEmitter();

  @Output()
  public onQuitar: EventEmitter<any> = new EventEmitter();

  @Output()
  public onAgregarYContinuar: EventEmitter<any> = new EventEmitter();

  @Output()
  public onEditado: EventEmitter<any> = new EventEmitter();

  @Input()
  public emptyMessage: string;

  @Output()
  public onAgregar: EventEmitter<any> = new EventEmitter();

  @Input()
  public editable: boolean = true;
  @Input()
  public newItem: () => any = () => { return {} };
  public ctx;
  public editando: boolean = false;
  @Input()
  public insertOnly: boolean = false;

  constructor(private router: Router) { }

  @Input()
  public buttonName: string = "agregarBtn";

  ngOnInit() {
    this.ctx = {
      itemEditado: this.itemEditado,
      handler: {
        onGuardado: (r) => this.onGuardado(r),
        onCancelado: (r) => this.onCancelar(),
        onGuardarYContinuar: (r) => this.onGuardarYContinuar(r)
      }

    };
  }

  public onGuardarYContinuar(item) {
    if (!item.id) {
      this.onAgregarYContinuar.emit(item);
    } else {
      if (this.itemEditado)
        this.itemEditado = Object.assign(this.itemEditado, item);
    }
    this.agregarOpcion();
  }

  public onGuardado(item) {
    this.editando = false;
    if (!item.id) {
      this.onAgregar.emit(item);
    } else {
      if (this.itemEditado)
        this.itemEditado = Object.assign(this.itemEditado, item);
    }
  }

  public onCancelar() {
    this.itemEditado = null;
    this.editando = false;
  }

  public quitarItem(item) {
    this.onQuitar.emit(item);
  }

  public editar(event, item) {
    if (this.insertOnly) return;
    this.ctx.itemEditado = item;
    this.editando = true;
  }
  public agregarOpcion() {
    if (!this.gestor || this.insertOnly) return this.onAgregar.emit(this.newItem());
    this.ctx.itemEditado = this.newItem();
    this.editando = true;

  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  public onDialogShow(event, dialog) {
    if (this.isMobile()) {
      dialog.maximized = true;

    }
  }

}
