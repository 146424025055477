import * as moment from 'moment'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { ProfilePic } from 'src/app/common/model/ProfilePic'
import { CentroCosto } from 'src/app/model/CentroCosto'
import { CostoDelivery } from './CostoDelivery'
import { FileData } from './FileData'
import { Horario } from './Horario'
import { TurnoReserva } from './TurnoReserva'
import { Ubicacion } from './Ubicacion'

export class Local extends Descriptivo {
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public ubicacion: Ubicacion = new Ubicacion(),
        public horarios: Horario[] = [],
        public menuDisponibles: Descriptivo[] = [],
        public deposito?: Descriptivo,
        public esDefault: boolean = false,
        public esApp: boolean = false,
        public centroCosto?: CentroCosto,
        public listadoDefault?: Descriptivo,
        public puntoDeVenta?: Descriptivo,
        public turnosReserva: TurnoReserva[] = [],
        public permiteReservas: boolean = true,
        public capacidad: number = 0,
        public diasAntelacion?: number,
        public fechaMaximaReserva?: Date,
        public imagen?: ProfilePic,
        public imagenHeader?: FileData,
        public admitePedido: boolean = false,
        public esLocacion?: boolean,
        public locacion?: Descriptivo,
        public configuracionMercadoPago?: Descriptivo,
        public costosDelivery: CostoDelivery[] = [],
        public admiteDelivery: boolean = false,
        public telefono?: string,
        public email?: string,
        public facebook?: string,
        public instagram?: string,
        public qrAfip?: string,
        public habilitado: boolean = true,
        public masVendidos?: number,
        public tieneAfip?:boolean,
        public identificadorUrl?:string
    ) {
        super(codigo, descripcion, id)
        if (ubicacion == null) {
            this.ubicacion = new Ubicacion()
        }
        this.resumenHorario = this.horarios?.length > 0 ? 'FALTA GENERAR HORARIOS' : 'SIN_HORARIOS'
    }

    private crearHorarios() {
        let dias: Map<String, String[]> = new Map()

        this.horarios.forEach((h) => {
            if (dias.has(h.diasSTR)) {
                dias.get(h.diasSTR).push(h.horarioSTR)
            } else {
                dias.set(h.diasSTR, [h.horarioSTR])
            }
        })
    }
    private _resumenHorario: string
    public get resumenHorario(): string {
        return this._resumenHorario
    }
    public set resumenHorario(v: string) {
        this._resumenHorario = v
    }

    public get direccion() {
        return this.ubicacion?.direccion
    }
    public static fromData(data: any): Local {
        if (!data) return null
        const o: Local = new Local(
            data.id,
            data.codigo,
            data.descripcion,

            Ubicacion.fromData(data.ubicacion),
            data.horarios ? data.horarios.map((c) => Horario.fromData(c)) : [],
            data.menuDisponibles ? data.menuDisponibles.map((m) => Descriptivo.fromData(m)) : [],
            Descriptivo.fromData(data.deposito),
            data.esDefault,
            data.esApp,
            CentroCosto.fromData(data.centroCosto),
            Descriptivo.fromData(data.listadoDefault),
            Descriptivo.fromData(data.puntoDeVenta),
            data.turnosReserva ? data.turnosReserva.map((t) => TurnoReserva.fromData(t)) : [],
            data.permiteReservas,
            data.capacidad,
            data.diasAntelacion,
            data.fechaMaximaReserva ? new Date(data.fechaMaximaReserva) : null,
            ProfilePic.fromData(data.imagen),
            FileData.fromData(data.imagenHeader),
            data.admitePedido,
            data.esLocacion,
            Descriptivo.fromData(data.locacion),
            Descriptivo.fromData(data.configuracionMercadoPago),
            data.costosDelivery ? data.costosDelivery.map((c) => CostoDelivery.fromData(c)) : [],
            data.admiteDelivery,
            data.telefono,
            data.email,
            data.facebook,
            data.instagram,
            data.qrAfip,
            data.habilitado,
            data.masVendidos,
            data.tieneAfip,
            data.identificadorUrl
        )

        return o
    }
    public agregarTurnoReserva(item: TurnoReserva) {
        if (!this.turnosReserva.some((ec) => ec == item)) {
            this.turnosReserva.push(item)
        }
    }
    public quitarTurnoReserva(item: TurnoReserva) {
        this.turnosReserva = this.turnosReserva.filter((i) => i != item)
    }
    newTurnoReserva = () => {
        var t = new TurnoReserva()
        t.capacidad = this.capacidad
        return t
    }
    public agregarCosto(item: CostoDelivery) {
        if (!this.costosDelivery.some((ec) => ec == item)) {
            this.costosDelivery.push(item)
        }
    }
    public quitarCosto(item: CostoDelivery) {
        this.costosDelivery = this.costosDelivery.filter((i) => i != item)
    }
    newCosto = () => {
        var t = new CostoDelivery()
        return t
    }

    public getTurnosByFecha(date: Date) {
        let dia = moment(date).isoWeekday()
        return this.turnosReserva.filter((t) => t.esDia(dia))
    }
    public getHorariosTurnos(fecha: Date) {
        if (!fecha) return []
        const vals = this.getTurnosByFecha(fecha).reduce((flat, turno) => flat.concat(turno.horarios), [])
        return vals ? Array.from(new Set(vals)) : []
    }

    public get imagenUrl(){
        return this.imagen?.picPath || 'assets/images/default_image.png';
    }
}
