<div class="grid container">
    <div class="col-12" *ngIf="!data">
        <span> Elija un producto</span>
    </div>
    <div class="error-block" *ngIf="!stock">
        <span>

            No hay Stock para el producto de recarga
        </span>
    </div>
    <div class="col-12" *ngIf="data">
        <div class="card" (click)="menuAcciones.toggle($event)">
            <div class=" pic-wrapper" pTooltip="{{data?.productoRecarga?.descripcion}}">
                <img [src]="data?.productoRecarga?.imagen" alt="">
            </div>
            <div class="bar-indicador"
                [ngClass]="{'warning': proporcion < 0.5, 'danger': proporcion < 0.2, 'animate':animate}"
                [ngStyle]="{top: (1 - proporcion)*100 + '%'}"
                pTooltip="{{proporcion * 100 | number :'1.0'}} %  - {{stock?.cantidad}} {{data?.unidad?.codigo}}">

            </div>

        </div>

    </div>

</div>
<p-menu #menuAcciones [model]="acciones" [popup]="true" appendTo="body"></p-menu>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of fraccionables" (click)="aplicarSeleccion(c)">
            {{c.descripcion}}
        </li>
    </ul>
</p-overlayPanel>
<p-dialog header="{{'Recarga' | translate}}" [(visible)]="verRecarga" [modal]="true" #dmStock
    [styleClass]="'gestor-dialog'" (onShow)="onDialogShow($event,dmStock)" appendTo="body">

    <recarga-accion [producto]="data" *ngIf="data && verRecarga" (onCancelar)="cancelarRecarga($event)"
        (onGuardar)="onGuardarRecarga($event)">

    </recarga-accion>
</p-dialog>
