<div class="listado-totales-caja content">
    <model-list [modelName]="'totales-caja'" [columns]="columns" [title]="'TOTALES_CAJA' | translate"
        [(listado)]="listado" [isLazy]="true" [filtro]="filtro" [getData]="getData" [navegable]="false"
        [permiteNuevo]="false" [editable]="false" [borrable]="false" [conResumen]="false" [filterContent]="customFilter"
        [isPaginator]="false" [titleContent]="header" [itemDataViewTemplate]="itemDataView">
        <ng-template #customFilter>
            <filtro [(filter)]="filtro" class="p-fluid">
                <div class="grid">
                    <div class="col-12 small">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'BUSCAR' | translate }}...</mat-label>
                            <input [(ngModel)]="filtro.searchStr" matInput
                                placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" [(ngModel)]="filtro.searchStr" />
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-12 lg:col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'DESDE' | translate }}</mat-label>
                                    <input matInput [matDatepicker]="fechaDesdePicker"
                                        [(ngModel)]="filtro.fechaDesde" />
                                    <mat-datepicker-toggle matSuffix [for]="fechaDesdePicker"> </mat-datepicker-toggle>
                                    <mat-datepicker #fechaDesdePicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 lg:col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'HASTA' | translate }}</mat-label>
                                    <input matInput [matDatepicker]="fechaHastaPicker"
                                        [(ngModel)]="filtro.fechaHasta" />
                                    <mat-datepicker-toggle matSuffix [for]="fechaHastaPicker"> </mat-datepicker-toggle>
                                    <mat-datepicker #fechaHastaPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <descriptivo-material-selector [options]="formasPagoService.data | async"
                            [label]="'FORMA_DE_PAGO' | translate" name="formaPago" [limpiable]="true"
                            [(ngModel)]="filtro.formaPago" [itemTemplate]="conIcono">
                            <ng-template #conIcono let-option>
                                <div class="grid">
                                    <div class="col-fixed" style="width: 3em">
                                        <profile-badge [url]="option.imagenUrl" class="small-badge desktop-badge"
                                            [disabled]="true"> </profile-badge>
                                    </div>
                                    <div class="col">
                                        <span>{{ option.descripcion }}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </descriptivo-material-selector>
                    </div>
                    <div class="col">
                        <descriptivo-material-selector [options]="responsables" [label]="'RESPONSABLE' | translate"
                            name="responsable" [limpiable]="true" [(ngModel)]="filtro.responsable">
                        </descriptivo-material-selector>
                    </div>
                </div>
            </filtro>
        </ng-template>
        <!-- <ng-template #customSubheader>
            <tr class="subheader">
                <td colspan="6" class="text-right">Totales desde {{ filtro.fechaDesde | date: 'dd/MM/yyyy' }}</td>
            </tr>
            <tr *ngIf="filtro.fechaHasta" class="subheader">
                <td colspan="6" class="text-right">hasta {{ filtro.fechaHasta | date: 'dd/MM/yyyy' }}</td>
            </tr>
        </ng-template> -->

    </model-list>
    <ng-template #header>
        <div class="grid filter-container">
            <div class="col nowrap">
                <h2 autofocus>{{'Totales de caja: ' | translate}} <span class="caja" (click)="verCajas($event)"
                        [ngClass]="{'clickable': cajas.length > 1 }">
                        {{caja?.descripcion? caja.descripcion : 'Sin caja seleccionada'}} <i class="fa fa-chevron-down"
                            *ngIf="cajas.length > 1"></i></span>
                </h2>
            </div>

            <div class="col-2 estado-caja">
                <span>
                    Desde {{ filtro.fechaDesde | date: 'dd/MM/yyyy' }}
                </span>
                <span *ngIf="filtro.fechaHasta">
                    hasta {{ filtro.fechaHasta | date: 'dd/MM/yyyy' }}
                </span>
            </div>

            <div class="col-fixed flex ">
                <div class="ui-inputgroup" *ngIf="filtro">
                    <button pButton icon="pi pi-refresh" class="ui-button-sm ui-button-outlined"
                        (click)="filtro.forceUpdate(); $event.stopPropagation()"></button>
                    <button pButton icon="pi pi-filter" class="ui-button-sm"
                        [ngClass]="{ 'ui-button-outlined': !filtro?.hasFilters() }"
                        (click)="showFilterbar($event)"></button>
                </div>

            </div>
            <div class="col-fixed flex">
                <div class="ui-inputgroup">
                    <button pButton class="ui-button-sm" icon="fa fa-money" (click)="irAListado()"
                        label="Movimientos"></button>
                </div>

            </div>
            <div class="col-fixed" *ngIf="acciones?.length">
                <button pButton label="{{'ACCIONES' | translate}}" (click)="menu.toggle($event)"
                    icon="fa fa-bars"></button>
            </div>
        </div>
        <p-menu #menu [popup]="true" [model]="acciones" appendTo="body"></p-menu>
    </ng-template>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of cajas" (click)="aplicarSeleccion(c)">
            {{ c.descripcion }}
        </li>
    </ul>
</p-overlayPanel>

<p-sidebar [(visible)]="displayFilterBar" #filterBar position="right"
    [style]="{ width: isMobile() ? '100vw' : '30vw' }">
    <div class="header-filtros">
        <h2>Filtros</h2>
    </div>
    <ng-container *ngTemplateOutlet="customFilter; context: this"> </ng-container>
</p-sidebar>


<ng-template #itemDataView let-data>
    <div class="item-container col-12 md-4 lg:col-3">
        <div class="card animal">
            <div class="grid">
                <div class="col data-section">
                    <div class="grid">
                        
                        <div class="col-12 header"
                            *ngIf="data['descripcion']" pTooltip="{{ data?.estado?.descripcion }}">
                            <div class="grid align-items-end">
                                <div class="col header-wrapper">
                                    <h3>{{ data['descripcion'] }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 info">
                            <div class="grid p-align-right">
                                <div class="col-3 profile-pic">
                                    <profile-badge
                                    [url]="data['imagen']"
                                    class="medium-badge circular desktop-badge"
                                >
                                </profile-badge>
                                </div>
                                <div class="col-9 campo">
                                    <span>{{data['total'] | currency: '$' : 'symbol' : '1.0-0' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>