import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'
import { Moneda } from 'src/app/model/Moneda'
import { ErrorHandler } from './../../utils/ErrorsHandler'
import { IMoneda } from './IMoneda'

@Component({
    selector: 'span-dinero-editable',
    templateUrl: './span-dinero-editable.component.html',
    styleUrls: ['./span-dinero-editable.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SpanDineroEditableComponent),
            multi: true,
        },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SpanDineroEditableComponent), multi: true },
    ],
})
export class SpanDineroEditableComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
    @ViewChild('input')
    private input: ElementRef
    @Input()
    public label: string

    public importeForm: FormControl
    @Input()
    public readonly: boolean = false

    @Input()
    public tooltip: string
    // @Input()
    // public editable: boolean = false

    public editando: boolean = false

    @Input()
    public minValue: number

    private _moneda: Moneda = Moneda.fromData({ simbolo: '€ ', codigo: 'EUR', descripcion: 'Euros', locale: 'es-es' })
    public get moneda(): Moneda {
        return this._moneda
    }

    @Input()
    public set moneda(v: Moneda) {
        if (v) this._moneda = v
        else this._moneda = Moneda.fromData({ simbolo: '€ ', codigo: 'EUR', descripcion: 'Euros', locale: 'es-es' })
    }

    @Input()
    public maxValue: number

    @Input()
    public decimals: number = 2

    @Output()
    public onChange: EventEmitter<number> = new EventEmitter()
    @Input()
    public required: boolean = false

    public disabled: boolean = false

    private _importeMuestra: number
    public get importeMuestra(): number {
        return this._importeMuestra
    }
    @Input()
    public set importeMuestra(v: number) {
        this._importeMuestra = v
        if (this.importeForm) this.importeForm.setValue(v)
    }

    constructor() {}

    public getDecimals() {
        return this.decimals > 2 ? this.decimals : 2
    }

    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}

    writeValue(obj: any): void {
        this.importeForm.setValue(obj)
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
   
    ngOnDestroy(): void {}
    private minValidator = (control: AbstractControl) => {
        return control && control.value && this.minValue && control.value < this.minValue ? { min: { min: this.minValue } } : null
    }
    private maxValidator = (control: AbstractControl) => {
        return control && control.value && this.maxValue && control.value > this.maxValue ? { max: { max: this.maxValue } } : null
    }

    ngOnInit() {
        this.importeForm = new FormControl(null, [this.minValidator, this.maxValidator])
        if (this.importeMuestra) {
            this.importeForm.setValue(this.importeMuestra)
        }
        this.importeForm.valueChanges.pipe(debounceTime(200)).subscribe((r) => {
            this.onChange.emit(r)
            this.onChangeCallback(r)
        })
    }
    validate(control: AbstractControl): ValidationErrors {
        control.setErrors(this.importeForm.errors)
        return control ? control.errors : null
    }
    getErrorMessage(control: AbstractControl) {
        if (control?.errors) {
            return ErrorHandler.getErrorMessage(control)
        }
    }

    public onFocus(event: any) {
        event.srcElement.select()
    }
}
