<span class="p-overlay-badge" (click)="displayMenu = !displayMenu">
    <i class="pi pi-bell fs-large"></i>
    <span class="p-badge p-badge-warning" *ngIf="novedades?.length"> {{ novedades?.length }}</span>
</span>

<ul class="layout-topbar-action-panel p-shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="displayMenu">
    <li *ngFor="let nov of novedades" (click)="accionNovedad($event, nov)">
        <div class="novedad-wrapper" [ngClass]="{ loading: nov?.loading }">
            <span class="tipo">{{ nov.tipo }} </span>
            <span class="mensaje">{{ nov.mensaje }}</span>
            <p-progressSpinner *ngIf="nov?.loading"></p-progressSpinner>
            <div class="icon-wrapper">
                <i class="pi pi-eye" pTooltip="{{ 'Marcar como visto' | translate }}" (click)="marcarVisto($event, nov)"> </i>
            </div>
        </div>
    </li>
</ul>
