import { NgModule } from '@angular/core'
import { CambioEstadoCajaModule } from '../cambio-estado-caja/cambio-estado.module'
import { CambioSaldoCajaModule } from '../cambio-saldo-caja/cambio-saldo-caja.module'
import { CommonComponentsModule } from './../../common/common-components.module'
import { CuentaContableModule } from './../cuenta-contable/cuenta-contable.module'
import { ListadoMovimientoCajaComponent } from './../movimiento-caja/listado-movimiento-caja/listado-movimiento-caja.component'
import { TraspasoDeCajaModule } from './../traspaso-de-caja/traspaso-de-caja.module'
import { CajaRoutingModule } from './caja-routing.module'
import { GestorCajaComponent } from './gestor-caja/gestor-caja.component'
import { ListadoCajaComponent } from './listado-caja/listado-caja.component';
import { TotalesCajaComponent } from './totales-caja/totales-caja.component'

const pipe = []
const directives = []
const components = [ListadoCajaComponent, GestorCajaComponent, ListadoMovimientoCajaComponent]
@NgModule({
    imports: [CajaRoutingModule, CommonComponentsModule, CuentaContableModule, TraspasoDeCajaModule, CambioEstadoCajaModule, CambioSaldoCajaModule],
    providers: [],
    declarations: [...pipe, ...directives, ...components, TotalesCajaComponent],
    exports: [...pipe, ...directives, ...components],
})
export class CajaModule { }
