<ng-container *ngIf="multiple; else simple">
    <selector-multiple-chips [(ngModel)]="centroCostoSeleccionado" name="{{name}}"
        [opciones]="centroService.data | async" [permiteNuevo]="false" [readonly]="readonly || !seleccionable"
        [titulo]="label | translate">
    </selector-multiple-chips>
</ng-container>
<ng-template #simple>
    <descriptivo-material-selector [label]="label | translate" [limpiable]="true" [required]="required"
        [permiteNuevo]="false" [(ngModel)]="centroCostoSeleccionado" name="{{name}}" [isLazy]="false"
        [options]="centroService.data | async" [readonly]="readonly  || !seleccionable">
    </descriptivo-material-selector>
</ng-template>
