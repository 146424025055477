import { AfterViewInit, Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subscription } from 'rxjs'
import { Local } from 'src/app/model/Local'
import { LocalService } from 'src/app/services/local.service'
import { Descriptivo } from './../model/Descriptivo'

@Component({
    selector: 'local-selector',
    templateUrl: './local-selector.component.html',
    styleUrls: ['./local-selector.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocalSelectorComponent),
            multi: true,
        },
    ],
})
export class LocalSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
    @Input()
    public multiple: boolean = false

    @Input()
    public readonly: boolean = false

    @Input()
    public customLabel: string

    public get label() {
        return this.customLabel ? this.customLabel : this.multiple ? 'Locales' : 'Local'
    }

    @Input()
    public name: string

    private _required: boolean
    public get required(): boolean {
        return this._required
    }
    @Input()
    public set required(v: boolean) {
        this._required = v
    }

    @Input()
    public autoFill: boolean = true
    @Input()
    public autohide: boolean = true

    @Output()
    public onChange: EventEmitter<Descriptivo | Descriptivo[]> = new EventEmitter()
    private sus: Subscription
    @HostBinding('class.hidden') private selfHide: boolean = false
    private _seleccionable: boolean = false
    public get seleccionable(): boolean {
        return this._seleccionable
    }
    public set seleccionable(v: boolean) {
        this._seleccionable = v
        this.selfHide = this.autohide && !this._seleccionable
    }

    private _localSeleccionado: Descriptivo | Descriptivo[]
    public get localSeleccionado(): Descriptivo | Descriptivo[] {
        return this._localSeleccionado
    }

    private _localDefault: Local

    @Input()
    public set localSeleccionado(v: Descriptivo | Descriptivo[]) {
        this._localSeleccionado = v
        this.onChangeCallback(this._localSeleccionado)
        this.onChange.emit(this._localSeleccionado)
    }

    constructor(public localService: LocalService) {
        this.sus = this.localService.data.subscribe((l) => {
            this.seleccionable = l.length > 1
        })
    }
    ngOnInit(): void {}
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    public disabled: boolean = false

    writeValue(obj: any): void {
        this._localSeleccionado = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
    private susLocal: Subscription
    update = (l) => {
        if (this.autoFill && !this.readonly && l && (!this.localSeleccionado || (!this.localSeleccionado['id'] && !this._localSeleccionado['length']))) {
            this._localDefault = l
            this.updateLocal()
        }
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.localService.localSeleccionado) {
                if (this.susLocal) this.susLocal.unsubscribe()
                this.susLocal = this.localService.localSeleccionadoObs.subscribe(this.update)
            } else {
                this.update(this.localService.localSeleccionado)
            }
        }, 1)
    }

    ngOnDestroy(): void {
        if (this.sus) this.sus.unsubscribe()
        if (this.susLocal) this.susLocal.unsubscribe()
    }

    public updateLocal() {
        if (this.multiple) {
            this.localSeleccionado = [this._localDefault]
        } else {
            this.localSeleccionado = this._localDefault
        }
        if (this.susLocal) {
            this.susLocal.unsubscribe()
        }
    }
}
