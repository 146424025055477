import { Directive, Input } from '@angular/core'
import { AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'

@Directive({
    selector: '[cuitValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CuitValidator, multi: true }],
})
export class CuitValidator implements Validator {
    private _tipoDoc: any
    public get tipoDoc(): any {
        return this._tipoDoc
    }
    @Input('tipoDoc')
    public set tipoDoc(v: any) {
        this._tipoDoc = v
        this.control.control.setErrors(this.validate(this.control))
    }

    private _control: any
    public get control(): any {
        return this._control
    }
    @Input('cuitValidator')
    public set control(v: any) {
        this._control = v
    }

    public static cuit(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let res = control.value ? validateCuit(control.value) : true

            if (!res) {
                return !res ? { cuitInvalido: { value: control.value } } : null
            }

            res = control.value ? digitoVerificador(control.value) : true

            return !res ? { cuitNroInvalido: { value: control.value } } : null
        }
    }

    validate(control: AbstractControl): { [key: string]: any } | null {
        if (!this.tipoDoc || this?.tipoDoc.codigo == '80' || this?.tipoDoc.codigo == '86') {
            return CuitValidator.cuit()(control)
        }
    }
}

export function validateCuit(cuit: string) {
    return new RegExp('^[0-9]{2}-?[0-9]{8}-?[0-9]$').test(cuit)
}
export function digitoVerificador(cuit: string): boolean {
    let baseMultiplos: number[] = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
    let cuitSoloNumeros = cuit.replace(/-/g, '')
    let contador: number = 0

    for (let i = 0; i < 10; i++) {
        contador += Number(cuitSoloNumeros[i]) * baseMultiplos[i]
    }

    let diferencia: number = 11 - (contador % 11)
    let ultimoDigito: number = Number(cuitSoloNumeros[10])

    return (diferencia == 10 && ultimoDigito == 1) || (diferencia == 11 && ultimoDigito == 0) || diferencia == ultimoDigito
}
