import { Injectable } from '@angular/core';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { Usuario } from './../../common/model/Usuario';
import { Registro } from './../../pages/registrarse/Registro';
@Injectable()
export class UsuarioService extends DescriptivosService<Usuario>{
  /*constructor(ns: NovedadService, ms?: MessagesService) {
    super(ns, new BehaviorSubject<Usuario[]>([]), ms)
  }*/
  public newEnt(): Usuario {
    return new Usuario();
  }
  public baseName(): string { return "usuario" }
  public parseToEnt(data: any): Usuario {
    return Usuario.fromData(data);
  }
  public updateTokenPush(userId: number, token: string): Promise<any> {
    return this.http.post(this.getBaseURL() + "updateTokenPush", { idUsuario: userId, token: token }).toPromise();
  }
  public validarToken(token: string): Promise<Boolean> {
    return this.http.post(this.getApiURL() + "login/validar-token", token).toPromise().then((r: any) => {
      if (r) {
        return Promise.resolve(r);
      } else {
        return false;
      }

    });
  }
  public registrar(r: Registro): Promise<any> {
    return this.http.post(this.getApiURL() + "register", r).toPromise().then(r => {
      return true;
    });
  }

  public toString(vo: Usuario) {
    const json: any = Object.assign({}, vo);
    json.configuracion = vo.configuracion.json;
    return JSON.stringify(json);
  }
  public actualizarPassword(pass: string, token: string): Promise<Boolean> {
    return this.http.post(this.getApiURL() + "login/actualizarpassword", { token: token, pass: pass }).toPromise().then((r: any) => {
      if (r) {
        return Promise.resolve(r);
      } else {
        return false;
      }

    });
  }


}