import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[modelBinding]'
})
export class ModelBindingDirective {

  constructor(private element: ElementRef) { }



  private _model: any;
  public get model(): any {
    return this._model;
  }
  @Input()
  public set model(v: any) {
    this._model = v;
    if (this.element) {
      this.element.nativeElement["data"] = this.model;
      this.element.nativeElement.setAttribute('data-x', this.model['x'])
      this.element.nativeElement.setAttribute('data-y', this.model['y'])
    }
  }


  ngOnInit(): void {

  }

}
