import { Injectable } from '@angular/core'
import { DescriptivosService } from 'src/app/common/services/descriptivos.service'
import { CategoriaMenu } from 'src/app/model/CategoriaMenu'
import { Filtro } from '../common/model/Filtro'
import { LoadingService } from '../common/services/loading-data-service.service'
@Injectable({
    providedIn: 'root',
})
export class CategoriaMenuService extends DescriptivosService<CategoriaMenu> {
    public baseName(): string {
        return 'categoria-menu'
    }
    public parseToEnt(data: any): CategoriaMenu {
        return CategoriaMenu.fromData(data)
    }
    public newEnt(): CategoriaMenu {
        return new CategoriaMenu()
    }

    public getHabilitados(filtro?: Filtro, customLoading?: LoadingService): Promise<CategoriaMenu[]> {
        let url = this.getBaseURL() + 'all'

        if (!filtro) {
            filtro = new Filtro(null, null)
        }
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(url, filtro.json)
            .toPromise()
            .then((r) => {
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()
                return this.parse(r)
            })
            .catch((e) => {
                return this.handleError(e, customLoading)
            })
    }
}
