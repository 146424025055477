
import { Parametrico } from 'src/app/common/model/Parametrico';
import { ProfilePic } from 'src/app/common/model/ProfilePic';


export class TipoEntrega extends Parametrico {
    public imagenUrl: string = 'assets/images/default_profile.png';
    public static MESAS: string = 'M';
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false,
        public codigoIdioma: string = 'ES',
        public color?: string,
        public icono: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
        this.imagenUrl = this.icono?.picPathVersion;
    }
    public tipoParametro(): string {
        return "$MODELNAMELOW";
    }
    public static fromData(data: any): TipoEntrega {
        if (!data) return null;
        const o: TipoEntrega = new TipoEntrega(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esDefault,
            data.codigoIdioma,
            data.color,
            ProfilePic.fromData(data.icono),

        );

        return o;

    }



}