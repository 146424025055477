import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Nota } from 'src/app/model/Nota';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class NotaService extends ServicioAbstract<Nota>{
    public baseName(): string {
        return "nota";
    }
    public parseToEnt(data: any): Nota {
        return Nota.fromData(data);
    }
    public newEnt(): Nota {
        return new Nota();
    }


}