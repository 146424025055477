import { Descriptivo } from '../common/model/Descriptivo';
import { CategoriaProducto } from './CategoriaProducto';
import { Restriccion } from './Restriccion';
import { TipoIVAGrabado } from './TipoIVAGrabado';
import { TipoProducto } from './TipoProducto';
import { Unidad } from './Unidad';

export class ProductoCabecera extends Descriptivo {
   
    private _desc: string
    public imagen: string
    public categoriasSort: string;
    public tieneImagen:boolean = false;
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public tipoIva?: TipoIVAGrabado,
        public imagenPortada: string = 'assets/images/default_imagen.png',
        public habilitado: boolean = false,
        public esRapido: boolean = false,
        public unidad?: Unidad,
        public tipoProducto?: TipoProducto,
        public productoRecarga?: ProductoCabecera,
        public descripcionPreview?: string,
        public categorias:Descriptivo[] = [],
        public esVenta?:boolean,
        public restricciones:Restriccion[] =[],
        public centroCosto?: Descriptivo,
        public locales: Descriptivo[] = [],

    ) {
        super(codigo, descripcion, id)
        this._desc = this.productoRecarga ? this.descripcion + ' - ' + this.productoRecarga.descripcion : this.descripcion
        this.imagen = productoRecarga ? productoRecarga.imagen : imagenPortada
        this.tieneImagen = this.imagen != undefined && this.imagen != null;
        this.categoriasSort = categorias.map(c=>c.descripcion).join("_");
    }
    public static fromData(data: any): ProductoCabecera {
        if (!data) return null
        let cats: any = data.categoriasProductoAsociadas || data.categorias || data.categoriasStr;
           
            const o: ProductoCabecera = new ProductoCabecera(
                data.id,
                data.codigo,
                data.productoRecarga ? data.descripcion + ' - ' + data.productoRecarga.descripcion : data.descripcion,
                TipoIVAGrabado.fromData(data.tipoIva),
                data.imagenPortada,
                data.habilitado,
                data.esRapido,
                Unidad.fromData(data.unidad),
                TipoProducto.fromData(data.tipo),
                ProductoCabecera.fromData(data.productoRecarga),
                data.descripcionPreview,
                cats ? (typeof cats === 'string' ? JSON.parse(cats): cats) : [],
                data.esVenta,
                data.restricciones ? data.restricciones.map(r => Restriccion.fromData(r)) : [],
                Descriptivo.fromData(data.centroCosto),
                data.locales ? data.locales.map(r => Descriptivo.fromData(r)) : [],

            )
           
            o.esVenta = data.esVenta;
            o.categorias = o.categorias.sort((a,b)=> a.descripcion > b.descripcion? 1 : -1)
            o.categoriasSort = o.categorias.map(c=>c.descripcion).join("_");
            return o
        
        
   
            
    }
    tieneCategoria(cat: CategoriaProducto): boolean {
        return this.categorias?.length && this.categorias.some(c => c.codigo === cat.codigo);
    }
    public getDescripcion() {
        return this._desc
    }


}
