import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmationService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { UsuarioService } from 'src/app/common/services/usuario.service'
import { Caja } from 'src/app/model/Caja'
import { CuentaContable } from 'src/app/model/CuentaContable'
import { CajaService } from 'src/app/services/caja.service'
import { CentroCostoService } from 'src/app/services/centro-costo.service'
import { CuentaContableService } from 'src/app/services/cuenta-contable.service'
import { LocalService } from 'src/app/services/local.service'
import { SessionComponent } from './../../../common/components/session-component.component'

@Component({
    selector: 'gestor-caja',
    templateUrl: 'gestor-caja.component.html',
    styleUrls: ['gestor-caja.component.less'],
})
export class GestorCajaComponent extends SessionComponent implements OnDestroy, OnInit {
    private routeSub: Subscription
    @Input()
    public item: Caja = new Caja()
    @Input()
    public goBack: boolean = true
    @Input()
    public isModal: boolean = false
    @Input()
    public readonly: boolean = false

    @Output()
    public onGuardado: EventEmitter<Caja> = new EventEmitter<Caja>()

    @Output()
    public onCancelado = new EventEmitter()

    public cuentaContableOptions: Descriptivo[] = []
    public usuarioOptions: Descriptivo[] = []

    constructor(
        messagesService: MessagesService,
        public service: CajaService,
        private router: Router,
        private route: ActivatedRoute,
        private confService: ConfirmationService,
        public centroCostoService: CentroCostoService,
        public localService: LocalService,
        private cuentaContableService: CuentaContableService,
        private usuarioService: UsuarioService,
        private centroService: CentroCostoService
    ) {
        super(messagesService)
    }
    public onEditar(event) {
        this.router.navigate(['caja/edit'], { queryParams: { id: this.item.id } })
    }
    public handleGuardado(item) {
        this.onGuardado.emit(item)
    }
    public handleCancelar(item) {
        this.onCancelado.emit()
    }

    public isValid() {
        return Promise.resolve(this.item.descripcion && this.item.usuariosAutorizados.length > 0)
    }
    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )
        if (this.route.snapshot.data?.parent == 'caja') {
            this.routeSub = this.route.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.service.getById(id).then((r) => {
                        this.item = Caja.fromData(r)
                    })
                } else {
                    this.item = this.service ? this.service.newEnt() : null
                }
            })
        }

        this.cuentaContableService.getDescriptivos().then((r) => {
            this.cuentaContableOptions = r
        })
        this.usuarioService.getDescriptivos().then((r) => {
            this.usuarioOptions = r
        })
    }

    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }

    onCuentaGuardada(cuenta: CuentaContable) {
        this.cuentaContableOptions.push(Descriptivo.fromData(cuenta))
    }
}
