<messages></messages>
<div class="login content">
    <div class="grid login-content">
        <div class="lg:col-6 col-12 left">
            <img src="assets/implementacion/images/logo.png" />
        </div>
        <div class="lg:col-6 col-12 right" *ngIf="valido">
            <div class="grid align-items-center" style="margin-top: 1.5em">
                <div class="lg:col-4 col-12"><label for="pass">Contraseña</label></div>
                <div class="lg:col-7 col-12">
                    <input
                        type="password"
                        pPassword
                        [(ngModel)]="pass"
                        promptLabel="Ingrese una nueva Contraseña"
                        weakLabel="Debil"
                        mediumLabel="Medio"
                        strongLabel="Fuerte"
                    />
                </div>
                <div class="lg:col-4 col-12"><label for="confirm">Reconfirmar</label></div>
                <div class="lg:col-7 col-12"><input type="password" pInputText [(ngModel)]="confPass" /></div>
                <div class="lg:col-12 footer-buttons">
                    <button pButton (keyup.enter)="actualizarPassword()" label="Guardar" (click)="actualizarPassword()" [disabled]="finalizado"></button>
                </div>
            </div>
        </div>
        <div class="lg:col-6 col-12 right">
            <div class="lg:col-12" style="margin-top: 1.5em" *ngIf="!valido">
                <h3>Link invalido. Comuniquese con Administración</h3>
            </div>
        </div>
    </div>
</div>
