import { Descriptivo } from './../common/model/Descriptivo';


export class Nota {
	constructor(
		public id?: number,
		public fechaNota?: Date,
		public fechaHasta?: Date,
		public esPermanente: boolean = false,
		public cabecera?: string,
		public contenido?: string,
		public responsable?: Descriptivo,
		public ultimaModificacion?: Date,
		public color: string = "#ffffff",
		public localesHabilitados: Descriptivo[] = []

	) {
	}
	public static fromData(data: any): Nota {
		if (!data) return null;
		const o: Nota = new Nota(
			data.id,
			data.fechaNota ? new Date(data.fechaNota) : null,
			data.fechaHasta ? new Date(data.fechaHasta) : null,
			data.esPermanente,
			data.cabecera,
			data.contenido,
			Descriptivo.fromData(data.responsable),
			data.ultimaModificacion ? new Date(data.ultimaModificacion) : null,
			data.color,
			data.localesHabilitados ? data.localesHabilitados.map(l => Descriptivo.fromData(l)) : []

		);

		return o;

	}

}