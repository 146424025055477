import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { BehaviorSubject, Subscription } from 'rxjs'
import { BreadcrumbService } from './breadcrumb.service'
import { Descriptivo } from './common/model/Descriptivo'
import { DefaultService } from './common/services/default.service'
import { AuthService } from './services/auth.service'
import { TipoEntregaService } from './services/tipo-entrega.service'

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
    styleUrls: ['app.breadcrumb.component.less'],
})
export class AppBreadcrumbComponent implements OnDestroy, OnInit {
    subscription: Subscription
    @ViewChild('op', { static: true }) op
    _items: MenuItem[] = []

    public items: BehaviorSubject<MenuItem[]> = new BehaviorSubject([])
    public localesDisponibles: Descriptivo[] = []
    public localSeleccionado: Descriptivo
    constructor(
        public breadcrumbService: BreadcrumbService,
        public dfService: DefaultService,
        public authService: AuthService,
        public router: Router,
        public tipoEntregaService: TipoEntregaService
    ) {}

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
    ngOnInit() {
        this.subscription = this.breadcrumbService.itemsSource.subscribe((response) => {
            this.items.next(response)
        })
        this.authService.getUser().subscribe((u) => {
            if (u) {
                this.localSeleccionado = u.configuracion ? u.configuracion.localSeleccionado : null
            } else {
                this.localSeleccionado = null
            }
        })
    }

    public nuevoPedido(idTipoEntrega) {
        if (this.localSeleccionado)
            this.router.navigate(['pedido/nuevo'], { queryParams: { localId: this.localSeleccionado.id, tipoEntregaId: idTipoEntrega } })
    }
}
