import { Cobranza } from './Cobranza'
import { Cobro } from './Cobro'
import { PedidoResumen } from './Pedido'

export class ItemGestorDevolucion {
    pedido?: PedidoResumen
    displayDevolucion?: boolean
    cobranza?: Cobranza
    cobroActual?: Cobro
}
