import { Descriptivo } from './../common/model/Descriptivo';



export class GastoPedido  {
    constructor(
        public id ?: number,
		public descripcion ?: string,
		public importe: number = 0,
        public tipo?: Descriptivo,

    ) {
    }
    public static fromData(data: any): GastoPedido {
        if (!data) return null;
        const o: GastoPedido = new GastoPedido(

					 data.id, 
					 data.descripcion, 
					 data.importe, 
                     Descriptivo.fromData(data.tipo),
        );

        return o;

    }

}