import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { OverlayPanel } from 'primeng/overlaypanel'
import { ArqueoCaja } from 'src/app/model/ArqueoCaja'
import { Caja } from 'src/app/model/Caja'
import { BilleteService } from 'src/app/services/billete.service'
import { CajaService } from 'src/app/services/caja.service'
import { TranslateService } from './../../../../common/services/translate.service'
import { AuthService } from './../../../../services/auth.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'app-apertura-cierre',
    templateUrl: './apertura-cierre.component.html',
    styleUrls: ['./apertura-cierre.component.less', './../dashboard-element-common.component.less'],
})
export class AperturaCierreComponent implements OnInit, IDashboardElement {
    public static TIPO = 'apertura-cierre'
    private _config: DashboardConfiguration

    private _caja: Caja
    public get caja(): Caja {
        return this._caja
    }
    @Input()
    public set caja(v: Caja) {
        this._caja = v
    }
    public verCaja() {
        this.router.navigate(['movimiento-caja'], { queryParams: { id: this.caja.id } })
    }
    public static defaultConfig: DashboardConfiguration = {
        fixedHeader: true,
        x: null,
        y: null,
        rows: 2,
        cols: 2,
        refreshable: true,
        tipo: 'apertura-cierre',
        params: {},
    }
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        if (v.params['idCaja']) {
            this.cajaService.getById(v.params['idCaja']).then((c) => {
                this.caja = c
            })
        }
    }
    public onDialogShow(event, dialog) {
        if (this.isMobile()) {
            dialog.maximized = true
        }
    }
    isMobile() {
        return window.innerWidth <= 640
    }
    public onCancelar(event) {
        this.editandoMovimiento = false
        this.itemEditado = null
    }
    public async genererCambioEstado(caja: Caja) {
        this.itemEditado = new ArqueoCaja()
        this.itemEditado.valores = await this.billeteService.iniciarBilletes()
        this.itemEditado.caja = caja

        this.itemEditado.responsable = this.authService.usuario
        this.editandoMovimiento = true
    }
    public refresh(event?) {
        this.editandoMovimiento = false
        this.itemEditado = null
        this.cajaService.forceRefresh()
    }
    constructor(
        private translateService: TranslateService,
        public cajaService: CajaService,
        public billeteService: BilleteService,
        private authService: AuthService,
        private router: Router
    ) {}

    public itemEditado: ArqueoCaja
    public editandoMovimiento: boolean = false
    @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel
    public goTo(ruta: string) {
        this.router.navigate([ruta])
    }
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}

    ngOnInit() {}
    public aplicarSeleccion(item: Caja) {
        this.op.hide()
        this.caja = item
        this._config.params['idCaja'] = item.id
        this.onChange(this.config)
    }
    public getTitle = () => {
        return this.caja ? this.caja.descripcion : 'Sin definir'
    }
}
