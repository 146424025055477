import * as moment from 'moment';



export class Horario {

    private diasEnum = ["LU", "MA", "MI", "JU", "VI", "SA", "DO;"]
    public get diasSTR() {

        let str = this.dias.map(d => this.diasEnum[d]).join("-");
        return str;
    }
    public get horarioSTR() {
        let hDesde = this.desde ? moment(this.desde).format("HH:mm a") : null;
        let hHasta = this.hasta ? moment(this.hasta).format("HH:mm a") : null;
        return !hDesde ? "SIN_HORARIO" : "DESDE " + hDesde + (hHasta ? " HASTA " + hHasta : "");

    }
    constructor(
        public desde: Date = moment('2000-01-01 07:00').toDate(),
        public hasta: Date = moment('2000-01-01 23:00').toDate(),
        public dias: number[] = [0, 1, 2, 3, 4, 5, 6],

    ) {

    }
    public static fromData(data: any): Horario {
        if (!data) return null;
        const o: Horario = new Horario(

            data.desde ? new Date(data.desde) : null,
            data.hasta ? new Date(data.hasta) : null,
            data.dias ? data.dias : []
        );

        return o;

    }

}