import { Injectable } from '@angular/core';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { Billete } from 'src/app/model/Billete';
import { BilletesCaja } from 'src/app/model/BilletesCaja';
@Injectable({
    providedIn: 'root',
})
export class BilleteService extends ParametricoService<Billete>{
    public baseName(): string {
        return "billete";
    }
    public parseToEnt(data: any): Billete {
        return Billete.fromData(data);
    }
    public newEnt(): Billete {
        return new Billete();
    }

    public iniciarBilletes(): Promise<BilletesCaja[]> {
        if (!this._data) {
            return this.loadData().then(r => {
                return r.map(b => new BilletesCaja(null, b, 0));
            })
        } else {
            return Promise.resolve(this._data.map(b => new BilletesCaja(null, b, 0)));
        }
    }

}

