import { Injectable, Injector } from '@angular/core';
@Injectable()
export class CommonValidator{

    public static injector: Injector = Injector.create([{ provide: CommonValidator, deps: [] }, {provide : Location, deps:[]}]);

    public requerido(v : any):boolean{
        return v !=null && v !== undefined && (typeof v != "string" || (v != "" && v.trim() != ""));
    }

    public emailValido(v : string):boolean{
        return this.requerido(v) && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)
    }
    public dniValid(v :string):boolean{
        return this.requerido(v ) && /^[0-9]*$/.test(v)
    }
 
}