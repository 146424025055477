import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
       
    `
})
export class AppFooterComponent {

}
