import { Descriptivo } from './../common/model/Descriptivo';
import { Injectable } from '@angular/core';
import { StockInsumo } from 'src/app/model/StockInsumo';
import { MovimientoStock } from '../model/MovimientoStock';
import { LoadingService } from './../common/services/loading-data-service.service';
import { BufferedService } from './BufferedService';
import { Deposito } from '../model/Deposito';
@Injectable({
    providedIn: 'root',
})
export class StockInsumoService extends BufferedService<StockInsumo>{

    getByDeposito(deposito: Deposito | Descriptivo, customLoading?: LoadingService): Promise<StockInsumo[]> {

        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.getData().then(p => {
            return p.filter(s => s.deposito?.id === deposito.id);
        }).finally(() => {
            if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        });
    }

    getByProducto(id: number, customLoading?: LoadingService): Promise<StockInsumo> {

        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.getData().then(p => {
            return p.filter(s => s.productoAsociado && s.productoAsociado.id == id)[0];
        }).finally(() => {
            if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        });


    }
    registrarMovimiento(movimientoStock: MovimientoStock, customLoading?: LoadingService): Promise<StockInsumo> {

        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.http.post(this.getBaseURL("movimientoManual"), movimientoStock)
            .toPromise()
            .then((r: any) => {
                return this.update(this.parseSingle(r));
            }, (e) => this.handleError(e, customLoading)).finally(() => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });


    }
    private update(r: StockInsumo) {
        var s = this.parseSingle(r);
        Object.assign(this._data.filter(ss => ss.id == s.id), s);
        this.data.next(this._data);
        return s;
    }
    recargar(movimientoStock: MovimientoStock, customLoading?: LoadingService): Promise<StockInsumo> {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.http.post(this.getBaseURL("recargar"), movimientoStock)
            .toPromise()
            .then((r: any) => {
                return this.update(this.parseSingle(r));
            }, (e) => this.handleError(e, customLoading)).finally(() => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }
    elaborar(movimientoStock: MovimientoStock, customLoading?: LoadingService): Promise<StockInsumo> {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.http.post(this.getBaseURL("elaborar"), movimientoStock)
            .toPromise()
            .then((r: any) => {
                return this.update(this.parseSingle(r));
            }, (e) => this.handleError(e, customLoading)).finally(() => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }
    public baseName(): string {
        return "stock-insumo";
    }
    public parseToEnt(data: any): StockInsumo {
        return StockInsumo.fromData(data);
    }
    public newEnt(): StockInsumo {
        return new StockInsumo();
    }


}