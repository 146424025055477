import { VentasAG } from './VentasAG';
import { AdministracionAuthGuard } from 'src/app/authguards/AdministracionAuthGuard';
import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';
import { FinancieroAG } from './FinancieroAG';






@Injectable({
    providedIn: 'root'
})
export class CobranzasAG extends FinancieroAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_COBRANZAS"]) };



}