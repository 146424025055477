<!-- <div class="color">
    <mat-form-field appearance='outline' floatLabel="always" class="color-mat">
        <mat-label>{{'COLOR' | translate}}</mat-label>
        <input matInput [(ngModel)]='color' pTooltip="{{'DESCRIPCION_TOOLTIP' | translate}}" type="color"
            #picker="ngModel" [readonly]="readonly" [disabled]="readonly">

    </mat-form-field>
</div> -->



    
        
        <input 
            id="color-input"
            pInputText
            [(colorPicker)]="color" 
            [style.background]="color"
            pTooltip="{{'COLOR' | translate}}"
            placeholder="{{'COLOR' | translate}}"
            #picker
            readonly
            [disabled]="readonly"    

            [cpPresetColors]="historial"
            
            cpSaveClickOutside="true"
            cpPresetLabel="Historial"
            cpAlphaChannel="disabled"
            (colorPickerSelect)="agregarHistorial($event)"



        />

        
