import { CobranzaService } from './../../../../services/cobranza.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { OverlayPanel } from 'primeng/overlaypanel'
import { Subscription } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { ID } from 'src/app/app.module'
import { commonAnimations } from 'src/app/common-animations'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { AccionesTabla } from 'src/app/common/model/AccionesTabla'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { ItemGestorDevolucion } from 'src/app/model/ItemGestorDevolucion'
import { Local } from 'src/app/model/Local'
import { Novedad } from 'src/app/model/Novedad'
import { Pedido } from 'src/app/model/Pedido'
import { LocalService } from 'src/app/services/local.service'
import { PedidoService } from 'src/app/services/pedido.service'
import { TipoEntregaService } from 'src/app/services/tipo-entrega.service'
import { environment } from 'src/environments/environment'
import { FiltroPedido } from './../../../../common/model/FiltroPedido'
import { TranslateService } from './../../../../common/services/translate.service'
import { PedidoResumen } from './../../../../model/Pedido'
import { AuthService } from './../../../../services/auth.service'
import { ConfiguracionUsuarioService } from './../../../../services/configuracion-usuario.service'
import { IActualizable, NovedadService } from './../../../../services/novedades.service'
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from './../../dashboard-element/IDashboardElement'

@Component({
    selector: 'pedidos-nuevos',
    templateUrl: 'pedidos-nuevos.component.html',
    styleUrls: ['pedidos-nuevos.component.less', './../dashboard-element-common.component.less'],
    animations: [commonAnimations],
})
export class PedidosNuevosComponent extends SessionComponent implements OnInit, IDashboardElement, IActualizable {
    private _localElegido: Local

    public qrCode: string
    public itemEditado: PedidoResumen
    public itemDevolucion: ItemGestorDevolucion
    public displayMotivo: boolean = false
    hayCobros: boolean

    public verQR: boolean = false
    hacerDevolucion: boolean
    public get localElegido(): Local {
        return this._localElegido
    }
    @Input()
    public set localElegido(v: Local) {
        this._localElegido = v
        if (this._localElegido) {
            this.config.params['idLocal'] = v.id
        } else {
            this.config.params['idLocal'] = null
        }
        this.filtro.local = v
    }

    @ViewChild('op', { static: true }) op: OverlayPanel
    public static TIPO = 'pedidos-nuevos'
    public static defaultConfig: DashboardConfiguration = {
        minItemCols: 3,
        minItemRows: 3,
        fixedHeader: true,
        x: null,
        y: null,
        rows: 5,
        cols: 3,
        tipo: 'pedidos-nuevos',
        refreshable: true,
        params: {},
    }

    private _config: DashboardConfiguration
    public get config(): DashboardConfiguration {
        return this._config
    }
    public key = ID()
    public getKey() {
        return this.key
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        if (this._config && this._config.params['idLocal']) {
            const idLocal = this._config.params['idLocal']
            if (!this.localElegido || this.localElegido.id != idLocal) {
                this.localService.getById(idLocal, this.customLoading).then((r) => {
                    this.localElegido = r
                    if (this.localElegido) {
                        this.novedadService.registrarPushObserver('pedido', this, null, this.localElegido.id)
                        this.suscripto = true
                    }
                })
            }
        }
    }

    public getContacto(p: PedidoResumen): { telefono: string; email: string } {
        let contacto = {
            telefono: p.telefonoContacto,
            email: p.emailContacto,
        }

        return contacto
    }

    public mostrarQR(item: PedidoResumen) {
        this.qrCode = environment.appURL + 'agregar-pedido/' + item.local?.id + '/' + item.id + '/' + item.codigoVerificacion
        this.itemEditado = item
        this.verQR = true
    }
    public acciones = [
        new AccionesTabla(
            this.translateService.get('Confirmar'),
            'pi pi-check',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.pedidoService.aprobar(item.id, this.customLoading).then((r) => {
                    this.filtro.forceUpdate()
                    item.estado = r.estado
                    this.messagesService.success('Pedido aprobado')
                })
            },
            (item: PedidoResumen) => {
                return this.pedidoService.esAprobable([item])
            }
        ),
        new AccionesTabla(
            this.translateService.get('Rechazar'),
            'pi pi-ban',
            'accion-tabla',
            (item: PedidoResumen) => {
                        this.itemEditado = item
                        this.itemDevolucion = new ItemGestorDevolucion()
                        this.itemDevolucion.pedido = item
                        this.mostrarDialogoMotivo(item)      
                
            },
            (item: PedidoResumen) => {
                return this.pedidoService.esRechazable([item])
            }
        ),
        new AccionesTabla(
            this.translateService.get('En proceso'),
            'pi pi-cog',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.pedidoService.enProceso(item.id, this.customLoading).then((r) => {
                    this.filtro.forceUpdate()
                    item.estado = r.estado
                    this.messagesService.success('Pedido en proceso')
                })
            },
            (item: PedidoResumen) => {
                return this.pedidoService.esProcesable([item])
            }
        ),
        new AccionesTabla(
            this.translateService.get('Entregar'),
            'pi pi-cog',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.pedidoService.entregar(item, this.customLoading).then((r) => {
                    this.filtro.forceUpdate()
                    item.estado = r.estado
                    this.messagesService.success('Pedido entregado')
                })
            },
            (item: PedidoResumen) => {
                return this.pedidoService.esEntregable([item])
            }
        ),
        new AccionesTabla(
            this.translateService.get('Entregar a repartidor'),
            'fa fa-bicycle',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.pedidoService.entregarARepartidor(item, this.customLoading).then((r) => {
                    this.filtro.forceUpdate()
                    item.estado = r.estado
                    this.messagesService.success('Pedido en camino')
                })
            },
            (item: PedidoResumen) => {
                return this.pedidoService.esEntregableRepartidor([item])
            }
        ),
        new AccionesTabla(
            this.translateService.get('Editar'),
            'pi pi-pencil',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.editar(item)
            },
            (item: PedidoResumen) => {
                return this.pedidoService.esEditable(item)
            }
        ),
        new AccionesTabla(
            this.translateService.get('Ver'),
            'pi pi-eye',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.ver(item)
            },
            (item: PedidoResumen) => {
                return true
            }
        ),
        new AccionesTabla(
            this.translateService.get('Generar cobro'),
            'pi pi-money-bill',
            'accion-tabla',
            (item: PedidoResumen) => {
                this.generarCobranza([item])
            },
            (item: PedidoResumen) => {
                return item.comprobante != undefined && item.saldo != 0
            }
        ),
        new AccionesTabla(
            this.translateService.get('Ver Verificación'),
            'pi pi-qrcode ',
            'accion-tabla',
            (item) => {
                this.mostrarQR(item)
            },
            (item) => {
                return item.codigoVerificacion != undefined
            }
        ),
    ]

    constructor(
        public tipoEntregaService: TipoEntregaService,
        private router: Router,
        public translateService: TranslateService,
        public messagesService: MessagesService,
        private novedadService: NovedadService,
        public pedidoService: PedidoService,
        public localService: LocalService,
        public authService: AuthService,
        public confUsuario: ConfiguracionUsuarioService,
        private cobranzaService: CobranzaService
    ) {super(messagesService)}
    private filtro: FiltroPedido
    private _ultimaNovedad: Novedad
    public pedidoEditado: Pedido
    public editandoPedido: boolean = false
    public refresh() {
        this.forceUpdate()
    }
    forceUpdate = () => {
        if (this.filtro) this.filtro.forceUpdate()
    }
    next(n: Novedad) {
        if (n.idEntidad) {
            this.pedidoService.getResumenById(n.idEntidad, 'ES', this.customLoading).then((p) => {
                if (this.pedidos.some((p) => p.id == n.idEntidad)) {
                    let toModify = this.pedidos.filter((p) => p.id == n.idEntidad)[0]
                    if (this.filtro.aplica(p)) {
                        Object.assign(toModify, p)
                        this.animate(toModify)
                    } else {
                        this.pedidos = this.pedidos.filter((ped) => ped.id == p.id)
                    }
                } else {
                    if (this.filtro.aplica(p)) {
                        this.pedidos.unshift(p)
                        this.animate(p)
                    }
                }
            })
        }
    }
    public animate(p) {
        p['animate'] = true
        setTimeout(() => {
            p['animate'] = false
        }, 600)
    }
    // public rechazar(comp: Pedido | PedidoResumen) {
    //     if (!comp) return
    //     this.pedidoService.rechazar(comp?.id, this.customLoading).then((r) => {
    //         this.filtro.forceUpdate()
    //         this.messagesService.success('Pedido rechazado')
    //     })
    // }

    public generarCobranza(items: PedidoResumen[]) {
        if (!items.every((r) => r.comprobante && r.cliente?.id == items[0]?.cliente?.id && r.saldo > 0))
            return this.messagesService.error(this.translateService.get('No se puede cobrar el pedido. No tiene saldo'))
        this.router.navigate(['cobranza/nuevo'], { state: { comprobantes: items.map((r) => r.comprobante?.id) } })
    }
    public editar(item) {
        if (!item) return
        this.router.navigate(['pedido/edit'], { queryParams: { id: item.id } })
    }
    public ver(item) {
        if (!item) return
        this.router.navigate(['pedido/vista'], { queryParams: { id: item.id } })
    }
    destroy() {}
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }
    onChange = (conf: DashboardConfiguration) => {}
    public aplicarSeleccion(local) {
        this.localElegido = local
        this.op.hide()
        this.onChange(this.config)
    }
    public onDialogShow(event, dialog) {
        if (this.isMobile()) {
            dialog.maximized = true
        }
    }
    isMobile() {
        return window.innerWidth <= 640
    }
    public onCancelar(event) {
        this.editandoPedido = false
        this.pedidoEditado = null
    }
    private sub: Subscription
    public pedidos: PedidoResumen[] = []
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
        this.novedadService.unregister('pedido', this, null, this.localElegido?.id)
    }
    public customLoading: LoadingService = new LoadingService()
    public merge(r: PedidoResumen[]) {
        this.pedidos = r
    }

    async verPedido(p: PedidoResumen) {
        /*this.pedidoEditado = await this.pedidoService.getById(p.id, this.customLoading);
		this.editandoPedido = true;*/
        this.ver(p)
    }
    private suscripto = false
    ngOnInit() {
        this.filtro = new FiltroPedido(null, 0, 1000, 'id', 2, false)
        this.filtro.fechaDesde = moment().startOf('day').subtract(3, 'month').toDate()
        this.filtro.estados = [
            new Descriptivo('N'),
            new Descriptivo('P'),
            new Descriptivo('A'),
            new Descriptivo('EP'),
            new Descriptivo('EN'),
            new Descriptivo('D'),
        ]

        this.sub = this.filtro.valueChange
            .pipe(
                filter((d) => d != undefined),
                debounceTime(400)
            )
            .subscribe((d) => {
                if (this.filtro.local) {
                    this.pedidoService.getResumen(this.filtro, this.customLoading).then((r) => {
                        this.merge(r)
                    })
                }
            })
    }

    public getTitle = () => {
        return 'Pedidos pendientes ' + (this.localElegido ? ' - ' + this.localElegido.descripcion : '')
    }

    mostrarDialogoMotivo(item: any) {
        this.itemEditado = item

        if (this.itemEditado.comprobante) {
            this.cobranzaService.getByComprobante(this.itemEditado.comprobante.id).then((c) => {
                if (c.length == 0) {
                    this.displayMotivo = true
                    this.hacerDevolucion = false
                    this.hayCobros = false
                } else {
                    this.displayMotivo = true
                    this.hacerDevolucion = true
                    this.hayCobros = true
                }
            })
        } else {
            this.displayMotivo = true
            this.hacerDevolucion = false
            this.hayCobros = false
        }
    }

    public rechazarPedido() {
        if (!this.itemEditado.motivoCancelacion) {
            this.error('Debe ingresar un motivo de rechazo')
            return
        }

        if (this.hacerDevolucion && this.itemDevolucion.cobranza.cobros.length == 0) {
            this.error('Debe ingresar una devolución')
            return
        } else if (this.hacerDevolucion) {
            if (this.itemDevolucion.cobranza.cobros.filter((c) => c.monto == 0 && c.tipoCobro != 'M').length != 0) {
                this.error('No puede haber devoluciones con monto 0')
                return
            }
            if (this.itemDevolucion.cobranza.cobros.filter((c) => !c.caja).length != 0) {
                this.error('Por favor seleccione una caja')
                return
            }
        }
            this.pedidoService
            .rechazar(this.itemDevolucion)
            .then((r) => {
                this.filtro.forceUpdate()
                this.success('Pedido rechazado')
                this.displayMotivo = false
                this.hacerDevolucion = false
            })
            .catch((e) => {
                this.error(e)
            })
        }

    
}
