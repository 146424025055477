import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BaseService } from 'src/app/common/services/base.service'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Novedad } from 'src/app/model/Novedad'
import { AuthService } from './../../../services/auth.service'
import { IActualizable, NovedadService } from './../../../services/novedades.service'
import { AperturaCierreComponent } from './../components/apertura-cierre/apertura-cierre.component'
import { BuscadorPrecioComponent } from './../components/buscador-precio/buscador-precio.component'
import { EstadoStockComponent } from './../components/estado-stock/estado-stock.component'
import { NotasComponent } from './../components/notas/notas.component'
import { PedidoShortcutComponent } from './../components/pedido-shortcut/pedido-shortcut.component'
import { PedidosNuevosComponent } from './../components/pedidos-nuevos/pedidos-nuevos.component'
import { RecargableComponent } from './../components/recargable/recargable.component'
import { ShortcutComponent } from './../components/shortcut/shortcut.component'
import { TotalesPedidoComponent } from './../components/totales-pedido/totales-pedido.component'
import { DashboardConfiguration } from './DashboardConfiguration'

@Injectable({
    providedIn: 'root',
})
export class DashboardService extends BaseService implements IActualizable {
    private _ultimaNovedad: Novedad
    private updateingProm: Promise<any>
    guardar(customLoading?: LoadingService) {
        if (this.updateingProm) return this.updateingProm
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        this.updateingProm = this.http
            .post(this.getApiURL() + 'configuracion-usuario/updateLayout', JSON.stringify(this.configs))
            .toPromise()
            .then((r: string) => {
                this.configs = JSON.parse(r)
            })
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
                this.updateingProm = null
            })
        return this.updateingProm
    }
    public configsBS: BehaviorSubject<DashboardConfiguration[]> = new BehaviorSubject([])

    private _configs: DashboardConfiguration[] = []
    public get configs(): DashboardConfiguration[] {
        return this._configs
    }
    public set configs(v: DashboardConfiguration[]) {
        this._configs = v
        this.configsBS.next(this._configs)
    }
    public opciones: BehaviorSubject<any[]> = new BehaviorSubject([])

    public addConfig(tipo: string, customLoading?: LoadingService) {
        this.configs.push(this.crearConfig(tipo))
        this.guardar(customLoading)
    }
    public removeConfig(conf: DashboardConfiguration, customLoading?: LoadingService) {
        this.configs.splice(this.configs.indexOf(conf), 1)
        this.guardar(customLoading)
    }
    crearConfig(val: string): DashboardConfiguration {
        return DashboardService.newConfiguracion(val)
    }
    public baseName(): string {
        return 'dashboard'
    }
    public getComponents() {
        return DashboardService.types.map((t) => {
            return { tipo: t.TIPO, type: t }
        })
    }

    constructor(messagesService: MessagesService, private authService: AuthService, private novedadesService: NovedadService) {
        super(messagesService)
    }
    next(n: Novedad) {
        if (!this._ultimaNovedad || this._ultimaNovedad.key != n.key) {
            this.update()
        }
    }
    destroy() {
        this._ultimaNovedad = null
    }
    public init() {
        this.authService.getUser().subscribe((c) => {
            if (c) {
                this.configs = JSON.parse(c.configuracion.opcionesLayout)
            } else {
                this.configs = [new DashboardConfiguration()]
            }
        })
        this.novedadesService.registrarObservador('usuario', this)
        this.update()
    }
    private update() {
        const opcionesDashboard: any[] = [
            {
                label: 'Pedido',
                tipo: PedidoShortcutComponent.TIPO,
            },
            {
                label: 'Totales de Pedidos',
                tipo: TotalesPedidoComponent.TIPO,
            },
            {
                label: 'Atajo',
                tipo: ShortcutComponent.TIPO,
            },
            {
                label: 'Arqueo de caja',
                tipo: AperturaCierreComponent.TIPO,
            },
            {
                label: 'Nuevos pedidos',
                tipo: PedidosNuevosComponent.TIPO,
            },
            {
                label: 'Estado stock',
                tipo: EstadoStockComponent.TIPO,
            },
            {
                label: 'Recargable',
                tipo: RecargableComponent.TIPO,
            },
            {
                label: 'Buscador Precios',
                tipo: BuscadorPrecioComponent.TIPO,
            },
            {
                label: 'Notas',
                tipo: NotasComponent.TIPO,
            },
        ]
        this.opciones.next(opcionesDashboard)
    }

    public static types = [
        PedidoShortcutComponent,
        ShortcutComponent,
        TotalesPedidoComponent,
        AperturaCierreComponent,
        PedidosNuevosComponent,
        EstadoStockComponent,
        BuscadorPrecioComponent,
        NotasComponent,
        RecargableComponent,
    ]
    public static factory

    static newConfiguracion(t: string) {
        let o = null
        if (!DashboardService.factory) {
            DashboardService.factory = {}
            DashboardService.types.forEach((tt) => (DashboardService.factory[tt.TIPO] = tt))
        }
        return JSON.parse(JSON.stringify(DashboardService.factory[t].defaultConfig))
    }
}
