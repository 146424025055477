<div class="grid {{ styleClass }}">
    <div class="col-12 caja" *ngFor="let caja of cajaService.data | async">
        <div class="grid">
            <div class="col-12 header">
                <div class="grid">
                    <div class="col" (click)="verCaja(caja)">
                        <h3 class="clickable">
                            {{ caja.descripcion }} - <b>{{ caja.estaCerrada ? 'CERRADA' : ' ABIERTA' }}</b>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="shortcut card" (click)="genererCambioEstado(caja, !caja.estaCerrada)">
                    <span *ngIf="caja?.estaCerrada"><i class="pi pi-unlock"></i>{{ 'Abrir' | translate }}</span>
                    <span *ngIf="!caja?.estaCerrada"><i class="pi pi-lock"></i>{{ 'Cerrar' | translate }}</span>
                    <span class="detalle" *ngIf="caja && caja.ultimoCambioEstado">
                        {{ (caja.estaCerrada ? 'ÚLTIMA_APERTURA' : 'ULTIMO_CIERRE') | translate }}
                        {{ caja.ultimoCambioEstado?.fecha | date: 'dd/MM/yyy HH:mm' }} -
                        {{ caja.ultimoCambioEstado?.responsable?.descripcion }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog
    header=""
    [(visible)]="editandoMovimiento"
    appendTo="body"
    [modal]="true"
    #dialDetail
    [styleClass]="'no-header gestor-dialog'"
    (onShow)="onDialogShow($event, dialDetail)"
>
    <gestor-cambio-estado-caja
        #gestorEstado
        [item]="itemEditado"
        (onGuardado)="refresh($event)"
        [cajaEditable]="false"
        (onCancelado)="onCancelar($event)"
        [goBack]="false"
        [isModal]="true"
        [caja]="itemEditado?.caja"
        [esCierre]="itemEditado?.esCierre"
        *ngIf="itemEditado && editandoMovimiento"
    >
    </gestor-cambio-estado-caja>
</p-dialog>
