import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { FormaPago } from 'src/app/model/FormaPago'
import { Moneda } from 'src/app/model/Moneda'
import { Cobro } from './Cobro'
import { FileData } from './FileData'

export class CobroConCaja extends Cobro {
    public static TIPO = 'A'
    constructor(
        id?: number,
        formaPago?: FormaPago,
        comprobante?: string,
        monto?: number,
        caja?: Descriptivo,
        moneda?: Moneda,
        cotizacion: number = 1,
        adjunto?: FileData
    ) {
        super(id, formaPago, comprobante, monto, moneda, cotizacion, caja, adjunto)
        this.tipoCobro = CobroConCaja.TIPO
    }
    
    public static fromData(data: any): CobroConCaja {
        if (!data) return null
        const o: CobroConCaja = new CobroConCaja(
            data.id,
            FormaPago.fromData(data.formaPago),
            data.comprobante,
            data.monto,
            Descriptivo.fromData(data.caja),
            Moneda.fromData(data.moneda),
            data.cotizaicion,
            FileData.fromData(data.adjunto)
        )

        return o
    }
}
