import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { GlobalInjector } from '../common/GlobalInjector'
import { Usuario } from '../common/model/Usuario'
import { MessagesService } from '../common/services/messages-data-service.service'
import { TranslateService } from './../common/services/translate.service'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    protected http: HttpClient
    public token: string
    tokenObs: BehaviorSubject<string> = new BehaviorSubject(null)
    public interval
    public intentos = 0
    private static _readyOb: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public static AUTENTICANDO: string = 'IP'
    public static LOGUEADO: string = 'L'
    public static NO_LOGUEADO: string = 'N'
    public static curUser: Usuario
    public static autenticateState: BehaviorSubject<string>
    private static userBS: BehaviorSubject<Usuario> = new BehaviorSubject(AuthService.curUser)
    private static _onLogout: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private static _onLogin: BehaviorSubject<Usuario> = new BehaviorSubject(null)
    public get onLogout() {
        return AuthService._onLogout
    }
    private _readyStatus: boolean = false
    public get onLogin() {
        return AuthService._onLogin
    }
    public get readyOb() {
        return AuthService._readyOb
    }
    constructor(private router: Router, protected messages: MessagesService, protected transService: TranslateService) {
        this.http = GlobalInjector.InjectorInstance.get<HttpClient>(HttpClient)
        AuthService.autenticateState = new BehaviorSubject<string>(AuthService.AUTENTICANDO)
        this._readyStatus = true
        this.onLogin.next(this.usuario)
        this.readyOb.next(this._readyStatus)
    }
    getApiURL() {
        return environment.apiUrl
    }
    public getAutenticateState() {
        return AuthService.autenticateState
    }

    public get usuario(): Usuario {
        return AuthService.curUser
    }
    public set usuario(v: Usuario) {
        AuthService.curUser = v
        AuthService.userBS.next(v)

        this.username = v?.username
    }
    public set renewToken(val: string) {
        if (val) {
            localStorage.setItem(environment.tokenKey + '_renewToken', val)
        } else {
            localStorage.removeItem(environment.tokenKey + '_renewToken')
        }
    }
    public get renewToken() {
        return localStorage.getItem(environment.tokenKey + '_renewToken')
    }

    public set username(val: string) {
        if (val) {
            localStorage.setItem(environment.tokenKey + '_username', val)
        } else {
            localStorage.removeItem(environment.tokenKey + '_username')
        }
    }
    public get username() {
        return localStorage.getItem(environment.tokenKey + '_username')
    }
    getUser(): Observable<Usuario> {
        return AuthService.userBS
    }

    protected handleOk = (response: any): string => {
        let res = response
        return res.mensaje
    }
    protected handleError(error: any): Promise<any> {
        return Promise.reject(error.error || error)
    }
    recuperarPass(email): Promise<any> {
        let $this = this
        return this.http
            .post(this.getApiURL() + 'login/recuperar-password', email)
            .toPromise()
            .then((r: any) => {
                return Promise.resolve(r ? Usuario.fromData(r) : null)
            })
            .catch(this.messages.errorHandler)
    }

    async login(user: string, password: string): Promise<Usuario> {
        let $this = this
        return this.http
            .post(this.getApiURL() + 'login', { username: user, password: password })
            .toPromise()
            .then((r: any) => {
                if (r) {
                    $this.usuario = Usuario.fromData(r.usuario)
                    $this.token = r.token
                    if (r.renewToken) {
                        $this.renewToken = r.renewToken
                    }
                   // this.setRenew(r)
                    this.getAutenticateState().next(AuthService.LOGUEADO)
                    this.onLogin.next(this.usuario)
                    return Promise.resolve($this.usuario)
                } else {
                    this.getAutenticateState().next(AuthService.NO_LOGUEADO)
                }

                return Promise.resolve(null)
            })
            .catch((e) => {
                this.messages.error('No se pudo loguear', 'Intente mas tarde')
                console.debug(e)
            })
    }

    async logout(): Promise<any> {
        AuthService.curUser = null
        this.renewToken = null
        this.onLogout.next(true)
        this.token = null
        this.usuario = null
        this.username = null
        return Promise.resolve(true)
    }

    private setRenew(r) {
        if (this.interval) clearInterval(this.interval)
        this.interval = setInterval(
            () => {
                this.refreshToken()
            },
            r.renewTime && r.renewTime >= 900000 ? r.renewTime - 60000 : 840000
        )
    }
    public refreshToken(): Observable<any> {
        let $this = this
        let data: Subject<string> = new Subject<string>()
        if (this.intentos == 4 || !this.renewToken) {
            this.intentos = 0
            this.logout().then((r) => {
                this.router.navigate(['login'])
                data.complete()
            })
            return data
        }
        this.intentos++
        this.http
            .post(this.getApiURL() + 'reauthenticate', { username: this.username, renewToken: this.renewToken })
            .toPromise()
            .then((r: any) => {
                $this.usuario = Usuario.fromData(r.usuario)
                $this.token = r.token
                this.intentos = 0
                if (r.renewToken) {
                    $this.renewToken = r.renewToken
                }
                /*if (!this.interval) {
                    this.setRenew(r)
                }*/
                this.getAutenticateState().next(AuthService.LOGUEADO)
                this.onLogin.next(this.usuario)
                data.next(r)
                data.complete();
            })
            .catch((r) => {
                this.token = null
                this.usuario = null
                this.logout().then((r) => {
                    this.router.navigate(['login'])
                    data.complete()
                })
            })
        return data
    }

    get isLoggedIn(): boolean {
        return this.usuario != undefined && this.token != undefined
    }
    get getCurrentUser(): Usuario {
        return this.usuario
    }
    get esAdministrador() {
        return this.getCurrentUser && this.getCurrentUser.roles.some((r) => r.codigo === 'ROLE_ADMIN')
    }
    public tieneRol(rol: string) {
        return this.getCurrentUser.tieneRol(['ROLE_ADMIN', rol])
    }
    get esSoloVisualizador() {
        return this.usuario.esSoloVisualizador()
    }

    getUserFullName(): string {
        let user = this.getCurrentUser ? this.getCurrentUser : null
        return user ? user.nombre : 'SIN INFORMAR'
    }
}
