<div class='gestor-caja content  content-white'>


    <model-gestor [readonly]="readonly" [service]='service' [(item)]='item' [validar]='isValid'
        [modelName]="'CAJA' | translate" [isModal]="isModal" [goBack]="goBack" (onItemGuardado)="handleGuardado($event)"
        [editable]="permiteEdicion" (onCancelar)="handleCancelar($event)" [form]="CajaForm"
        (onEditar)="onEditar($event)">

        <div class='gestor-form' model-type='dataContent'>
            <form #CajaForm="ngForm" id="Cajaform">
                <div class="grid" *ngIf="item">
                    <centro-selector [multiple]="false" [(ngModel)]="item.centroCosto" #centroSelector
                        [name]="'centros'" [readonly]="readonly" [required]="true" class="col-12 lg:col-4">
                    </centro-selector>

                    <local-selector [multiple]="true" [(ngModel)]="item.locales" [name]="'locales'"
                        [readonly]="readonly" [required]="true" class="col-12 lg:col-8">
                    </local-selector>

                    <div class='col-12 lg:col-6'>
                        <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                            <mat-label>{{'DESCRIPCION' | translate}}</mat-label>
                            <input matInput placeholder="{{'DESCRIPCION_PH' | translate}}" [readonly]="readonly"
                                [(ngModel)]='item.descripcion' pTooltip="{{'DESCRIPCION_TOOLTIP' | translate}}"
                                type="text" #descripcion="ngModel" name="descripcion" autocomplete="off" required>
                            <mat-error>
                                {{getErrorMessage(descripcion)}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 lg:col-6">
                        <mat-form-field appearance='outline' [ngClass]="{'readonly':readonly}">
                            <mat-label>{{'USUARIOSAUTORIZADOS' | translate}}</mat-label>
                            <mat-select [(ngModel)]="item.usuariosAutorizados" multiple [disabled]="readonly"
                                [compareWith]="compareDescriptivos" name="usuariosAutorizados">
                                <mat-option *ngFor="let val of usuarioOptions" [value]="val">{{val.descripcion}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!--div class="col-12">
                        <p-table [value]="item.cuentas">>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:50%">{{'CENTRO_COSTO' | translate}}</th>
                                    <th style="width:50%">{{'CUENTA-CONTABLE' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-it let-i="rowIndex">
                                <tr>
                                    <td>
                                        {{it.centro.descripcion}}
                                    </td>
                                    <td>
                                        <descriptivo-material-selector [options]="cuentaContableOptions"
                                            [label]="'CUENTA-CONTABLE' | translate" [limpiable]="limpiable"
                                            [(ngModel)]="it.cuenta" name="cuenta_{{i}}" [permiteNuevo]="true"
                                            [gestor]="gestorCuenta" [readonly]="readonly">
                                        </descriptivo-material-selector>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div-->
                </div>
            </form>
        </div>
    </model-gestor>
</div>

<ng-template #gestorCuenta let-handler="handler">
    <gestor-cuenta-contable (onGuardado)="handler.onGuardado($event);onCuentaGuardada($event)"
        (onCancelado)="handler.onCancelado($event)" [isModal]="true" [goBack]="false">
    </gestor-cuenta-contable>
</ng-template>
